import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteTreatment } from '../../../../../../services/api/treatment';
import { deleteWaste } from '../../../../../../services/api/waste';
import { InvoiceType } from '../../../../../../types/entities/invoice';
import { User } from '../../../../../../types/entities/user';
import { Column } from '../../../../../../types/table';
import Button from '../../../../../ui/button/Button';
import TableComponent from '../../../../../ui/table/Table';
import './styles.scss';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import { useDeleteInvoice } from '../../hooks/useInvoiceFacilities';

type Props = {
  deleteInvoice: (id: string) => void;
  invoiceToDelete: InvoiceType;
  user: User | null;
  columns: Column[];
  parseData: (invoice: InvoiceType[]) => any;
  closeModal: () => void;
};
function DeleteInvoice({
  user,
  invoiceToDelete,
  deleteInvoice,
  columns,
  parseData,
  closeModal
}: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const { mutateAsync: deleteInvoiceService } = useDeleteInvoice();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteInvoice();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const handleDeleteInvoice = async () => {
    try {
      setLoadingButton(true);
      const deleteFunctionList = {
        waste_water_treatment: deleteTreatment,
        wastes: deleteWaste
      };

      if (deleteFunctionList[invoiceToDelete.type as keyof typeof deleteFunctionList]) {
        await deleteFunctionList[invoiceToDelete.type as keyof typeof deleteFunctionList](
          invoiceToDelete.id,
          user?.selectedOrganization || ''
        );
      } else {
        await deleteInvoiceService({
          id: invoiceToDelete.id,
          organizationId: user?.selectedOrganization || ''
        });
      }

      deleteInvoice(invoiceToDelete.id);
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='delete-invoice'>
      <FormHeader
        title={t('facilityDetail.deleteInvoice')}
        description={t('facilityDetail.areYouSureDelete')}
      />
      {invoiceToDelete.type !== 'wastes' && invoiceToDelete.type !== 'waste_water_treatment' && (
        <div
          className='on-card-gray-bg-color'
          style={{ padding: '24px', borderRadius: '8px', marginTop: '16px' }}>
          <div className='card' style={{ padding: '24px', borderRadius: '8px' }}>
            <TableComponent
              data={parseData([invoiceToDelete])}
              columns={columns.filter(
                (elem) => elem.title && elem.key !== 'uploadedBy' && elem.key !== 'file_url'
              )}
              loading={false}
            />
          </div>
        </div>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={t('general.cancel')}
          onClick={closeModal}
          size='medium'
        />
        <Button
          lookAndFeel='warning'
          text={t('facilityDetail.delete')}
          onClick={handleDeleteInvoice}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
    </div>
  );
}

export default DeleteInvoice;
