import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getRoles, RoleLevel } from 'services/api/roles';

const fixedTags: { [key: string]: string[] } = {
  administrator: ['completeEdition', 'users'],
  member: ['limitedEdition'],
  holding: ['completeEdition', 'users'],
  fund: ['dashboardTag', 'social_governance']
};

const useRoles = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles.predefinedRoles' });
  const queryClient = useQueryClient();

  const invalidateRoles = () => {
    queryClient.invalidateQueries({ queryKey: ['roles'] });
  };

  const { data, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await getRoles(RoleLevel.ALL, true);
      return response;
    },
    select: (data) => {
      const createdRoles: Role[] = [];
      const globalRoles: Role[] = [];

      data.forEach((role: Role) => {
        if (role.organization) {
          createdRoles.push(role);
        } else {
          globalRoles.push({ ...role, fixedTags: fixedTags[role.name.toLowerCase()] });
        }
      });

      const sortedGlobalRoles = Object.keys(fixedTags)
        .map((key) => globalRoles.find((role) => role.name.toLowerCase() === key))
        .filter((role): role is Role => role !== undefined)
        .map((role) => ({ ...role, name: t(role.name.toLowerCase()) }));

      return [...createdRoles, ...sortedGlobalRoles];
    }
  });

  return { data, isLoading, invalidateRoles };
};

export default useRoles;
