import { useTranslation } from 'react-i18next';
import useGetData from '../../hooks/useGetData';

const useOptions = () => {
  const { t } = useTranslation();
  const { units, loadingUnits } = useGetData();

  const frequencyOptions = [
    { id: 'once', name: t('shipments.once') },
    { id: 'weekly', name: t('shipments.weekly') },
    { id: 'monthly', name: t('shipments.monthly') }
  ];

  const refrigeratedOptions = [
    { id: 'yes', name: t('shipments.yes') },
    { id: 'no', name: t('shipments.no') }
  ];

  const unitOptions = units.map((unit) => ({
    id: unit.id,
    name: t(`units.${unit.name}`)
  }));

  return { frequencyOptions, refrigeratedOptions, unitOptions, loadingUnits };
};

export default useOptions;
