const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className='grid grid-cols-9'>{children}</div>;
};

type BaseProps<T extends object> = {
  onDelete: (index: number) => () => void;
  onEdit: (index: number) => (newLine: T) => void;
  lines: T[];
  LineComponent: React.FC<{
    data: T;
    onDelete: () => void;
    onEdit: (newLine: T) => void;
    entireArray: T[];
  }>;
};

const Base = <T extends object>({ onDelete, onEdit, lines, LineComponent }: BaseProps<T>) => {
  return (
    <>
      {lines.map((line, index) => (
        <LineComponent
          data={line}
          onDelete={onDelete(index)}
          onEdit={onEdit(index)}
          entireArray={lines}
          key={index}
        />
      ))}
    </>
  );
};

export const ConsumptionPercentages = {
  Base,
  Layout
};
