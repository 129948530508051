export const DM_CONTEXT_CARDS = [
  {
    id: 'suppliers',
    icon: 'lorry',
    title: 'supplierTitle',
    subtitle: 'supplierSubtitle'
  },
  {
    id: 'clients',
    icon: 'user',
    title: 'clientTitle',
    subtitle: 'clientSubtitle'
  },
  {
    id: 'final_users',
    icon: 'earth',
    title: 'finalUserTitle',
    subtitle: 'finalUserSubtitle'
  },
  {
    id: 'own_workers',
    icon: 'facilities',
    title: 'ownWorkersTitle',
    subtitle: 'ownWorkersSubtitle'
  },
  {
    id: 'investors',
    icon: 'money',
    title: 'investorsTitle',
    subtitle: 'investorsSubtitle'
  }
] as const;
