import Button from 'components/ui/button/Button';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import Icon from 'components/ui/icon/Icon';
import { useRdCodes } from 'customHooks/useRdCodes';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { RDLineType } from '../types/RDLine';

type Props<T = RDLineType> = {
  data: T;
  onDelete: () => void;
  onEdit: (newLine: T) => void;
  entireArray: T[];
};

const adapter = (t: TFunction) => (data: RdCode[]) =>
  data.map((elem) => ({
    id: elem.rd_id,
    name: `${elem.rd_code} ${t(`rd_codes.${elem.rd_name}`)}`
  }));

export const RDLine: React.FC<Props> = ({ data, onDelete, onEdit, entireArray }) => {
  const { t } = useTranslation();

  const { rdCodes, isLoading } = useRdCodes({ adapter: useMemo(() => adapter(t), [t]) });

  const rdCodesFiltered = rdCodes.filter((code) => {
    return code.id === data.id || !entireArray.some((elem) => elem.id === code.id);
  });

  const editValue = (value: string) => {
    onEdit({ ...data, value });
  };

  const editCode = (code: SelectOptionFormat) => {
    onEdit({ ...data, id: code.id, name: code.name });
  };

  const selectedCode = rdCodes.find((code) => code.id === data.id) ?? { id: '', name: '' };

  if (entireArray.length === 1) {
    return (
      <div className='grid grid-cols-12'>
        <InputWrapper iconV2='recycle' className='col-span-12'>
          <Select
            value={selectedCode}
            options={rdCodesFiltered}
            onChange={editCode}
            loading={isLoading}
          />
          <InputNumber.WithPercentageOverlay
            placeholder='100'
            max={100}
            wrapperClassName='w-30 flex-0'
            className='w-30'
            value={data.value}
            onChange={editValue}
          />
        </InputWrapper>
      </div>
    );
  }

  return (
    <div className='grid grid-cols-12'>
      <InputWrapper iconV2='recycle' className='col-span-11'>
        <Select
          value={selectedCode}
          options={rdCodesFiltered}
          onChange={editCode}
          loading={isLoading}
        />
        <InputNumber.WithPercentageOverlay
          placeholder='100'
          max={100}
          wrapperClassName='w-30 flex-0'
          className='w-30'
          value={data.value}
          onChange={editValue}
        />
      </InputWrapper>

      <Button
        iconNode={<Icon icon='close' size='medium' color='gray-dark' />}
        size='small'
        hasIcon
        lookAndFeel='secondary'
        onClick={onDelete}
        style={{
          width: 'fit-content',
          height: 'fit-content',
          justifySelf: 'flex-end'
        }}
      />
    </div>
  );
};
