import Checkbox from 'components/ui/formComponents/checkbox/Checkbox';
import { useErrorTranslations } from 'customHooks/translations';

type PasswordChecksProps = {
  isSubmitted: boolean;
  password: string;
};

export const PasswordChecks = ({ isSubmitted, password }: PasswordChecksProps) => {
  const { t: tError } = useErrorTranslations();

  return (
    <div className='flex-col items-left gap-2 w-full'>
      <Checkbox
        onChange={() => null}
        selected={password.length >= 8}
        text={tError('minEight')}
        disabled={!isSubmitted}
        error={
          isSubmitted && password.length < 8
            ? {
                error: 'minEight',
                description: tError('minEight')
              }
            : undefined
        }
      />
      <Checkbox
        selected={/[A-Z]/.test(password)}
        onChange={() => null}
        text={tError('minUppercase')}
        disabled={!isSubmitted}
        error={
          isSubmitted && !/[A-Z]/.test(password)
            ? {
                error: 'uppercase',
                description: tError('minUppercase')
              }
            : undefined
        }
      />
      <Checkbox
        selected={/[0-9]/.test(password)}
        onChange={() => null}
        text={tError('minNumber')}
        disabled={!isSubmitted}
        error={
          isSubmitted && !/[0-9]/.test(password)
            ? {
                error: 'number',
                description: tError('minNumber')
              }
            : undefined
        }
      />
      <Checkbox
        selected={/[^a-zA-Z0-9]/.test(password)}
        onChange={() => null}
        text={tError('minSpecial')}
        disabled={!isSubmitted}
        error={
          isSubmitted && !/[^a-zA-Z0-9]/.test(password)
            ? {
                error: 'special',
                description: tError('minSpecial')
              }
            : undefined
        }
      />
    </div>
  );
};
