import { EXCEL_DATA_CATEGORIES } from '../../../../constants/excelData';
import apiFetch from '../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { getExcelData } from './getExcelData';

const URL = '/organizations/files/excel-report';

type DownloadExcelScope1_2Params = {
  year: string;
  country?: string;
};

export const downloadExcelScope1_2 = async ({ year, country }: DownloadExcelScope1_2Params) => {
  const dataRequests = [
    getExcelData({ category: EXCEL_DATA_CATEGORIES.combustion, year, country }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.electricity, year, country }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.recharge, year, country }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.vehicles, year, country })
  ];

  const [combustion, electricity, recharge, vehicles] = await Promise.all(dataRequests);

  const body = {
    combustion,
    electricity,
    recharge,
    vehicles
  };

  const response = await apiFetch(
    'POST',
    getUrl(URL, { queryParams: generateQueryParamsFromObject({ year }) }),
    body
  );

  return response.data as string;
};
