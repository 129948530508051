import { zodResolver } from '@hookform/resolvers/zod';
import { loadUser } from 'actions/auth';
import { ROUTES } from 'constants/routes';
import { useErrorTranslations } from 'customHooks/translations';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authLogin, authSignUp, confirmSignUp } from 'services/authService';
import { Session } from 'types/entities/user';
import { buildPasswordValidator } from 'utils/auth';
import { z } from 'zod';

enum SignUpSteps {
  userInfo = 'userInfo',
  emailConfirmation = 'emailConfirmation',
  loading = 'loading'
}

type SignUpData = {
  email: string;
  password: string;
  confirmPassword: string;
  emailCode?: string;
};

export const useSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: tError } = useErrorTranslations();

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState<SignUpSteps>(SignUpSteps.userInfo);
  const [handleSubmitStep, setHandleSubmitStep] = useState<(data: SignUpData) => void>(() => null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
    control,
    clearErrors
  } = useFormWithHelpers({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      emailCode: ''
    },
    resolver: zodResolver(
      z
        .object({
          email: z.string().email({ message: tError('invalidEmail') }),
          password: buildPasswordValidator({ errorTranslation: tError }),
          confirmPassword: z.string(),
          emailCode: z.string()
        })
        .superRefine(({ password, confirmPassword }, ctx) => {
          if (password !== confirmPassword) {
            ctx.addIssue({
              code: 'custom',
              message: tError('passwordsDontMatch'),
              path: ['confirmPassword']
            });
          }
        })
        .superRefine(({ password, email }, ctx) => {
          if (!email) {
            return;
          }
          // Check if password contains email address or email domain
          const emailUsername = email.split('@')[0];
          const emailDomain = email.split('@')[1].split('.')[0];
          if (password.includes(emailUsername) || password.includes(emailDomain)) {
            ctx.addIssue({
              code: 'custom',
              message: tError('passwordContainsUsername'),
              path: ['password']
            });
          }
        })
    )
  });

  const exceptionsMapper = useMemo(
    (): Record<
      string,
      {
        field: keyof typeof errors;
        message: string;
      }
    > => ({
      INVALID_CONFIRMATION_CODE: {
        field: 'emailCode',
        message: tError('invalidVerificationCode')
      },
      EXPIRED_CONFIRMATION_CODE: {
        field: 'emailCode',
        message: tError('expiredVerificationCode')
      },
      USER_ALREADY_EXIST: {
        field: 'email',
        message: tError('userAlreadyExists')
      },
      CORPORATE_MAIL: {
        field: 'email',
        message: tError('corporateEmail')
      }
    }),
    [tError]
  );

  const password = useWatch({ control, name: 'password' });
  const email = useWatch({ control, name: 'email' });

  const handleUserSignIn = async (email: string, password: string) => {
    const sessionData = await authLogin({
      email,
      password
    });
    setStep(SignUpSteps.loading);
    dispatch(
      loadUser(sessionData as Session, true, true, '', () =>
        navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true })
      )
    );
  };

  const submitUserCredentials = async ({ email, password }: SignUpData) => {
    clearErrors('email');
    setIsLoading(true);
    try {
      setStep(SignUpSteps.loading);
      const { signUpResponse, localSignUp } = await authSignUp({
        email: email,
        password: password
      });

      if (signUpResponse && localSignUp) {
        handleUserSignIn(email, password);
        return;
      }
      if (signUpResponse) {
        setStep(SignUpSteps.emailConfirmation);
      }
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage
        ? setError(errorMessage.field, { message: errorMessage.message })
        : setError('email', { message: 'invalidEmail' });
      setStep(SignUpSteps.userInfo);
    } finally {
      setIsLoading(false);
    }
  };

  const submitConfirmationCode = async ({ email, emailCode }: SignUpData) => {
    clearErrors('emailCode');
    setIsLoading(true);
    try {
      await confirmSignUp({
        email: email,
        code: emailCode as string
      });
      handleUserSignIn(email, password);
    } catch (error: any) {
      setStep(SignUpSteps.emailConfirmation);
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage
        ? setError(errorMessage.field, { message: errorMessage.message })
        : setError('emailCode', { message: 'invalidVerificationCode' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === SignUpSteps.userInfo) {
      setHandleSubmitStep(() => submitUserCredentials);
    } else if (step === SignUpSteps.emailConfirmation) {
      setHandleSubmitStep(() => submitConfirmationCode);
    } else {
      setHandleSubmitStep(() => null);
    }
  }, [step]);

  return {
    steps: SignUpSteps,
    currentStep: step,
    handleSubmitStep: handleSubmit(handleSubmitStep),
    isSubmitted,
    registerField: register,
    errors: {
      email: adaptHookFormError(errors.email, tError),
      password: adaptHookFormError(errors.password, tError),
      confirmPassword: adaptHookFormError(errors.confirmPassword, tError),
      emailCode: adaptHookFormError(errors.emailCode, tError)
    },
    formData: { email, password },
    clearErrors,
    loading: isLoading
  };
};
