import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'lib/ReactQuery/client';
import { setNotification } from 'actions/notification';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modifyTopicData } from 'services/api/sotOrganizationData';

type CacheData = {
  pages: {
    items: BackendTopic[];
  }[];
};

const checkMaterial = (topic: BackendTopic, changes: Partial<BackendTopic>) => {
  const newTopic = { ...topic, ...changes };
  return newTopic.financial_score >= 70 || newTopic.impact_score >= 70;
};

export const useModifyTopic = ({ year, language }: { year: string; language: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mutation = useMutation({
    mutationFn: modifyTopicData,
    onMutate: ({
      id,
      changes,
      year
    }: {
      id: string;
      changes: Partial<BackendTopic>;
      year: string;
    }) => {
      const queryKey = ['topicsData', year, language];
      // Cancel any outgoing refetches
      queryClient.cancelQueries({ queryKey: ['topicsData'] });

      // Snapshot previous data
      const previousData = queryClient.getQueryData<CacheData>(queryKey);

      // Optimistically update the cache
      queryClient.setQueryData<CacheData>(queryKey, (oldData) => {
        if (!oldData) return { pages: [] };
        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            items: page.items.map((topic) => {
              if (topic.id === id) {
                return {
                  ...topic,
                  ...changes,
                  material: checkMaterial(topic, changes)
                };
              }
              return topic;
            })
          }))
        };
      });

      // Return the previous data to be used in case of an error
      return { previousData: previousData ?? [] };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData<Topic[]>(
        ['topicsData', year, language],
        (context as { previousData: Topic[] }).previousData
      );
      queryClient.invalidateQueries({ queryKey: ['topicsData'] });
      dispatch(setNotification(t('notification.errorUpdateTopic')));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['csrdOutputData'] });
    }
  });

  const updateImpactScore = (id: string, impactScore: number) => {
    mutation.mutate({
      id,
      changes: { impact_score: impactScore },
      year
    });
  };

  const updateFinancialScore = (id: string, financialScore: number) => {
    mutation.mutate({
      id,
      changes: { financial_score: financialScore },
      year
    });
  };

  const updateMeasuredStatus = (id: string, isMeasured: boolean) => {
    mutation.mutate({
      id,
      changes: { being_measured: isMeasured },
      year
    });
  };

  return {
    ...mutation,
    updateImpactScore,
    updateFinancialScore,
    updateMeasuredStatus
  };
};
