import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import React, { useState } from 'react';
import apiFetch from 'utils/apiFetch';
import useGetData from './hooks/useGetData';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';

type FormData = {
  organization: SelectOptionFormat;
  category: SelectOptionFormat;
  errors: ErrorType[];
};
const CleanTotalImpacts = () => {
  const [formData, setFormData] = useState<FormData>({
    organization: { id: '', name: '' },
    category: { id: '', name: '' },
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const [rowsDeleted, setRowsDeleted] = useState();
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { data: organizations, isLoading } = useGetData();

  const options = [
    'facility',
    'vehicle',
    'employees',
    'business_travel',
    'purchases',
    'transport_distribution'
  ];

  const handleSubmit = async () => {
    setLoadingButton(true);
    const response = await apiFetch('POST', '/v1/admin/clean-total-impacts', {
      organization_id: formData.organization.id,
      category: formData.category.id
    });

    setRowsDeleted(response.data.rows_deleted);

    setLoadingButton(false);
  };

  return (
    <div>
      <h1 className='font-32 weight-600'>Clean Total Impacts</h1>
      {!organizations || isLoading ? (
        <LoaderCard style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
      ) : (
        <div
          className='border-solid main-bg-color rounded-8 p-6 card-border-color mt-12 flex flex-col gap-2'
          style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
          <FormWrapper>
            <FormSelect
              iconV2='organization'
              placeholder='Organization'
              label='Organization'
              value={formData.organization}
              onChange={onChangeValue('organization')}
              options={organizations}
              loading={isLoading}
            />
            <FormSelect
              iconV2='categories'
              placeholder='Category'
              label='Category'
              value={formData.category}
              onChange={onChangeValue('category')}
              options={options.map((elem) => ({ id: elem, name: elem }))}
            />
          </FormWrapper>
          <FormButtonSection>
            <Button
              lookAndFeel='primary'
              onClick={handleSubmit}
              size='medium'
              text='Submit'
              loading={loadingButton}
            />
          </FormButtonSection>
          {rowsDeleted !== undefined && (
            <span className='font-18 weight-600'>Congrats!! {rowsDeleted} rows were deleted</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CleanTotalImpacts;
