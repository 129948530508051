import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import CardList from '../../ui/cardList/CardListRefactored';
import useData from './hooks/useData';
import Icon from 'components/ui/icon/Icon';
import FrameworksCustomizeYearModal from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkCustomizeYearModal';
import { useCSRDConfiguration } from './hooks/useCSRDConfiguration';

export const EsgMetrics = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });
  const { t: tN } = useTranslation();

  const navigate = useNavigate();
  const { data, loading } = useData();

  const { frameworkName, frameworkId } = useCSRDConfiguration();
  const [customizeYearModal, setCustomizeYearModal] = useState(false);

  const handleOnClickButton = () => {
    navigate(ROUTES.MEASURE_ESG_METRICS_INFOGRAPHY);
  };

  return (
    <section className='esg-metrics'>
      <div className='esg-metrics__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
        <Breadcrumb />
      </div>
      <div className='flex justify-end'>
        <Button
          className='flex w-fit-content align-center gap-5'
          lookAndFeel='link-dark'
          size='small'
          iconNode={<Icon icon='settings' color='gray-dark' />}
          onClick={() => setCustomizeYearModal(true)}>
          {tN('measureMain.configureCSRD')}
        </Button>
      </div>
      <div className='infinite-scroll-wrapper-card'>
        <CardList
          listView={false}
          title={t('title')}
          description={t('description')}
          buttons={
            <Button
              lookAndFeel='primary'
              text={t('button')}
              onClick={handleOnClickButton}
              size='small'
              style={{ width: '100%' }}
            />
          }
          loading={loading}>
          <>
            {data?.map((element) => (
              <>{element}</>
            ))}
          </>
        </CardList>
        <FrameworksCustomizeYearModal
          modalOpen={customizeYearModal}
          saveFrameworksHandler={() => setCustomizeYearModal(false)}
          title={frameworkName?.toUpperCase() ?? ''}
          frameworkId={frameworkId ?? ''}
        />
      </div>
    </section>
  );
};

export default EsgMetrics;
