import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useEffect, useState } from 'react';
import { FrameworkPlan } from 'types/entities/organization';

const CSRD_FRAMEWORK_NAME = 'csrd';

export const useCSRDConfiguration = () => {
  const selectedOrganization = useSelectedOrganization();
  const { frameworkPlans } = useFrameworksPlans(selectedOrganization?.id);
  const [csrdFramework, setCsrdFramework] = useState<FrameworkPlan | undefined>();
  useEffect(() => {
    if (frameworkPlans) {
      const csrdFramework = frameworkPlans.find(
        (framework) => framework.framework.name === CSRD_FRAMEWORK_NAME
      );
      setCsrdFramework(csrdFramework);
    }
  }, [frameworkPlans]);

  return {
    frameworkName: csrdFramework?.framework.name,
    frameworkId: csrdFramework?.id
  };
};
