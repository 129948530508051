import Tooltip from 'components/ui/tooltip/Tooltip';
import { DESCRIPTION_MAP } from 'constants/purchaseFrameworks';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import getCountryName from 'utils/getCountryName';
import { limitString } from 'utils/limitString';
import { PurchaseFramework } from 'utils/mapToPurchaseFramework';

export const useColumns = () => {
  const { t, i18n } = useTranslation();

  const columns: Column[] = [
    {
      title: t('doubleMateriality.context.suppliers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('doubleMateriality.context.suppliers.country'),
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => getCountryName(country, i18n.resolvedLanguage as Lang)
    },
    {
      title: t('doubleMateriality.context.suppliers.activity'),
      dataIndex: 'activity',
      key: 'activity',
      render: (value: string) => {
        if (value) {
          const descriptor = DESCRIPTION_MAP[PurchaseFramework.SIC];
          return limitString(descriptor[value as keyof typeof descriptor], 80);
        }

        return '-';
      }
    },
    {
      dataIndex: 'purchases',
      key: 'purchases',
      title: (
        <div className='flex justify-initial gap-2'>
          <span>{t('doubleMateriality.context.suppliers.purchases')}</span>
          <Tooltip text={t('doubleMateriality.context.suppliers.purchasesTooltip')} />
        </div>
      ),
      render: (value: string) => (
        <span>{`${(Number(value.replace(',', '.')) * 100).toFixed(0)}%`}</span>
      )
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: (
        <div className='flex justify-initial gap-2'>
          <span>{t('doubleMateriality.context.suppliers.importance')}</span>
          <Tooltip text={t('doubleMateriality.context.suppliers.importanceTooltip')} />
        </div>
      ),
      render: (_, row: DMSupplier) => (
        <div
          className={`text-tag body3-font ${
            row.highImportance ? 'tag2-bg-text-color' : 'tag-bg-text-color'
          } w-fit-content p-1 rounded-4`}>
          {row.highImportance
            ? t('doubleMateriality.context.high')
            : t('doubleMateriality.context.low')}
        </div>
      )
    }
  ];
  return columns;
};
