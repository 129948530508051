import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  return [
    {
      id: '',
      text: t('doubleMateriality.steps.doubleMateriality')
    },
    {
      id: 'stakeholders',
      text: t('doubleMateriality.steps.stakeholders'),
      disabled: !flags?.doubleMaterialityTabs
    },
    {
      id: 'iros',
      text: t('doubleMateriality.steps.iros'),
      disabled: !flags?.doubleMaterialityTabs
    },
    {
      id: 'survey_results',
      text: t('doubleMateriality.steps.surveyResults'),
      disabled: !flags?.doubleMaterialityTabs
    },
    {
      id: 'results',
      text: t('doubleMateriality.steps.results'),
      disabled: !flags?.doubleMaterialityTabs
    }
  ];
};
