import './styles.scss';

type Props = {
  isSelected: boolean;
  isBlocked?: boolean;
  error?: string;
};

const Line = ({ isSelected, isBlocked, error }: Props) => {
  let colorStyle = undefined;
  if (isSelected) {
    colorStyle = 'selected';
  } else if (isBlocked) {
    colorStyle = 'blocked';
  } else if (error) {
    colorStyle = 'error';
  }
  return <div className={`carousel-line ${colorStyle}`}></div>;
};

export default Line;
