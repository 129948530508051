import { Dispatch, SetStateAction, useState } from 'react';
import { Shipment, ShipmentForm } from 'types/entities/shipment';
import { User } from 'types/entities/user';
import useOptions from './hooks/useOptions';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import Select from 'components/ui/formComponents2/select/Select';
import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from 'components/ui/formComponents/formElementFull/FormElementFull';
import FormCalendar from 'components/ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import { convertDateToString, convertStringToDate } from 'utils/convertDates';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import useEditTransportSections from './hooks/useEditTransportSections';
import checkFormErrors from 'utils/checkFormErrors';
import {
  createShipment,
  createTransportSections,
  patchTransportSections,
  updateShipment
} from 'services/api/transportRoutes';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import TransportSections from '../transportSections/TransportSections';

type Props = {
  formData: ShipmentForm;
  setFormData: Dispatch<SetStateAction<ShipmentForm>>;
  transportDirection: 'upstream' | 'downstream';
  user: User;
  submitShipment: (shipment: Shipment) => void;
  shipmentToEdit?: Shipment;
  loading?: boolean;
};

const FormShipment = ({
  transportDirection,
  formData,
  setFormData,
  shipmentToEdit,
  submitShipment,
  loading
}: Props) => {
  const { t } = useTranslation();
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { unitOptions, loadingUnits, frequencyOptions, refrigeratedOptions } = useOptions();
  const {
    addTransportSection,
    removeTransportSection,
    modifyTransportSection,
    checkTransportSectionErrors,
    transportSectionsFrontToBack,
    INVALID_TRAVEL_MODE_ERRORS
  } = useEditTransportSections({
    formData,
    setFormData
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const type = shipmentToEdit ? 'editShipment' : 'createNewShipment';

  const selectedOrganization = useSelectedOrganization();

  const onChangeRefrigerated = (value: SelectOptionFormat) => {
    const refrigerated = value.id === 'yes';
    setFormData((prev) => ({
      ...prev,
      refrigerated,
      transportSections: refrigerated
        ? prev.transportSections.map((elem) =>
            elem.transport_type === 'car' || elem.transport_type === 'electric_car'
              ? { ...elem, transport_type: '' }
              : elem
          )
        : prev.transportSections,
      errors: prev.errors.filter((elem) => elem.error !== 'refrigerated')
    }));
  };

  const handleSubmit = async () => {
    setLoadingButton(true);

    const optionalFields = ['supplier', 'name'];
    let newErrors = checkFormErrors(formData, [], optionalFields);
    newErrors = newErrors.concat(checkTransportSectionErrors());

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    const shipment = {
      name: formData.name,
      quantity_transported: parseFloat(formData.quantity),
      unit_id: formData.unit,
      supplier: formData.supplier,
      refrigerated: formData.refrigerated,
      start_date: convertStringToDate(formData.startDate),
      end_date: convertStringToDate(formData.startDate),
      transport_frequency: formData.frequency,
      transport_sections: formData.transportSections,
      organization_id: selectedOrganization?.id as string
    };

    const transportRoute = shipmentToEdit
      ? await updateShipment(shipmentToEdit.id as string, shipment)
      : await createShipment({ ...shipment, transport_direction: transportDirection });

    // submit transport sections
    const transportSections = transportSectionsFrontToBack(transportRoute.id);

    const data = shipmentToEdit
      ? await patchTransportSections(selectedOrganization?.id as string, transportSections)
      : await createTransportSections(selectedOrganization?.id as string, transportSections);

    if (INVALID_TRAVEL_MODE_ERRORS.includes(data)) {
      setFormData((prev) => ({
        ...prev,
        errors: [...prev.errors, { error: 'invalidTravelMode' }]
      }));
      setLoadingButton(false);
      return;
    }

    submitShipment({
      ...shipment,
      transport_direction: transportDirection,
      id: transportRoute.id,
      status: transportRoute.status
    });

    setLoadingButton(false);
  };

  if (loadingUnits || loading) {
    return <CustomSkeletonLoader count={6} style={{ marginBottom: '0.5rem' }} />;
  }

  return (
    <>
      <FormHeader
        title={t(`shipments.${transportDirection}.${type}`)}
        description={t(`shipments.${transportDirection}.${type}Description`)}
      />
      <FormWrapper>
        <FormText
          iconV2={'multitag'}
          placeholder={t('shipments.writeShipmentDescription')}
          label={t('shipments.descriptionOptional')}
          value={formData.name}
          onChange={onChangeValue('name')}
          error={formData.errors.find((elem) => elem.error === 'name')}
        />
        <InputWrapper
          iconV2={'add'}
          label={t('shipments.quantity')}
          error={formData.errors.find(
            (elem) => elem.error === 'quantity' || elem.error === 'unit'
          )}>
          <InputNumber
            placeholder={t('shipments.quantity')}
            onChange={onChangeValue('quantity')}
            value={formData.quantity as string}
          />
          <Select
            placeholder={''}
            options={unitOptions}
            value={unitOptions.find((elem) => elem.id === formData.unit) ?? { id: '', name: '' }}
            onChange={(val) =>
              setFormData((prev) => {
                return {
                  ...prev,
                  unit: val.id
                };
              })
            }
          />
        </InputWrapper>
        <FormCalendar
          label={t(`shipments.${transportDirection}.startDate`)}
          handleChangeDate={onChangeValue('startDate')}
          error={formData.errors.find(
            (elem) => elem.error === 'startDate' || elem.error === 'date'
          )}
          dateValue={formData.startDate}
          handleDateError={onChangeValue('startDate')}
          maxDate={convertDateToString(new Date())}
        />
        <FormSelect
          iconV2='clock'
          placeholder={t('shipments.selectFrequency')}
          label={t('shipments.frequency')}
          options={frequencyOptions}
          value={
            frequencyOptions.find((elem) => elem.id === formData.frequency) ?? frequencyOptions[0]
          }
          onChange={(val) =>
            setFormData((prev) => {
              return {
                ...prev,
                frequency: val.id
              };
            })
          }
          error={formData.errors.find((elem) => elem.error === 'frequency')}
        />
        <FormText
          iconV2='layers'
          placeholder={t('shipments.writeSupplier')}
          label={t('shipments.supplierOptional')}
          value={formData.supplier}
          onChange={onChangeValue('supplier')}
          error={formData.errors.find((elem) => elem.error === 'supplier')}
        />
        <FormSelect
          iconV2='electricity'
          placeholder={t('shipments.selectOption')}
          label={t('shipments.commodityRefrigerated')}
          options={refrigeratedOptions}
          value={formData.refrigerated ? refrigeratedOptions[0] : refrigeratedOptions[1]}
          onChange={onChangeRefrigerated}
          error={formData.errors.find((elem) => elem.error === 'refrigerated')}
        />
        <FormElementFull>
          {formData.transportSections.map((elem, index) => (
            <TransportSections
              key={index}
              index={index}
              transportSection={elem}
              transportSections={formData.transportSections}
              editTransportSection={modifyTransportSection}
              addTransportSection={addTransportSection}
              removeTransportSection={removeTransportSection}
              refrigerated={formData.refrigerated}
              errors={formData.errors}
            />
          ))}
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('shipments.save')}
          onClick={handleSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {formData.errors && formData.errors.find((elem) => elem.error === 'invalidTravelMode') && (
        <span className='error-text error-font error-text-color'>
          {t('error.invalidTravelMode')}
        </span>
      )}
    </>
  );
};

export default FormShipment;
