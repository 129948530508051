import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../../../../constants/routes';
import { UserContext } from '../../../../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import { getWasteById, postWaste, updateWaste } from '../../../../../../../../services/api/waste';
import { InvoiceType, PercentagesBackend } from '../../../../../../../../types/entities/invoice';
import {
  FormDataWaste,
  PostTreatmentBody,
  UpdateWasteBody
} from '../../../../../../../../types/entities/waste';

import checkFormErrors from '../../../../../../../../utils/checkFormErrors';
import { formatDate } from '../../../../../../../../utils/formatDate';
import Button from '../../../../../../../ui/button/Button';
import InputNumber from '../../../../../../../ui/formComponents2/inputNumber/InputNumber';
import Select from '../../../../../../../ui/formComponents2/select/Select';
import CustomSkeletonLoader from '../../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import StepCarouselList from '../../../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../../../ui/stepCarouselList/useStepCarousel';
import useGetData from './hooks/useGetData';
import useGetRdCodes from './hooks/useGetRdCodes';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../../../../ui/formComponents2/formInputs/formText/FormText';
import InputWrapper from '../../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import FormCalendarDouble from '../../../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormNumber from '../../../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormButtonSection from '../../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import DivideConsumptionsWrapper from '../common/divideConsumptions/DivideConsumptionsWrapper';
import useSelectedOrganization from '../../../../../../../../customHooks/useSelectedOrganization';
import { Facility } from '../../../../../../../../types/entities/facility';
import moment from 'moment';
import {
  validateDates,
  validateFacilityWithZero,
  validateFacilityWithoutId
} from '../validations/validator';
import FormElementFull from '../../../../../../../ui/formComponents/formElementFull/FormElementFull';
import TooltipWrapper from '../../../../../../../ui/tooltip/TooltipWrapper';
import ErrorText from 'components/ui/errorText/ErrorText';
import DocumentPDFV2 from 'components/ui/pdf/DocumentPDFV2';
import InputFileInvoice from 'components/ui/formComponents/inputFile/InputFileInvoice';
import useGetBase64 from 'customHooks/useGetBase64';
import { Link } from 'react-router-dom';

type Props = {
  addInvoice?: (value: InvoiceType | null) => void;
  editInvoice?: (id: string, invoice: InvoiceType) => void;
  id?: string;
  facility: Facility;
  viewOnly?: boolean;
};

const Waste = ({ addInvoice, editInvoice, id, facility, viewOnly = false }: Props) => {
  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();

  const formDataEmpty: FormDataWaste = {
    file_url: '',
    optionalId: '',
    quantity: '',
    description: '',
    unitDefault: {
      id: '',
      name: ''
    },
    emissionFactor: '',
    unitCustom: { id: '', name: '' },
    low: {
      id: '',
      name: ''
    },
    rd: {
      id: '',
      name: ''
    },
    startDate: '',
    endDate: '',
    destination: '',
    totalKm: '',
    percentages: [
      {
        facility: { id: facility.id, name: facility.name },
        organization: {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        },
        percentage: '100'
      }
    ],
    errors: []
  };

  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const [formData, setFormData] = useState<FormDataWaste>(formDataEmpty);
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { units, lowCodes, loading: loadingData, groups, organizationOptions } = useGetData();
  const { rdCodes } = useGetRdCodes({ lowId: formData.low.id });
  const [loading, setLoading] = useState(false);
  const [unitsCustom, setUnitsCustom] = useState<SelectOptionFormat[]>([]);
  const [groupSelected, setGroupSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });
  const [fileInvoiceToEdit, setFileInvoiceToEdit] = useState<File | string>('');
  const fileRead = useGetBase64(fileInvoiceToEdit as File);
  const groupsFiltered = groups.filter((group) => group.category === 'waste');

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: [
      {
        id: 'default',
        text: t('facilityDetail.default'),
        disabled: viewOnly
      },
      {
        id: 'custom',
        text: t('facilityDetail.custom'),
        disabled: viewOnly
      }
    ]
  });

  useEffect(() => {
    const getWasteToEdit = async () => {
      if (!user?.selectedOrganization || !id) return;
      const response = await getWasteById(id, user.selectedOrganization);
      if (response?.response?.status >= 400) return;

      if (response.custom_emission_factor_id) {
        handleSelect('custom');
      } else {
        handleSelect('default');
      }

      setFormData({
        file_url: response.file_url,
        optionalId: response.identification_name,
        quantity: response.base_quantity,
        description: response.description,
        unitDefault: { id: response.unit.id, name: t(`units.${response.unit.name}`) },
        low: response.low
          ? {
              id: response.low.low_id,
              name: `${response.low.low_code} ${t(`low_codes.${response.low.low_name}`)}`
            }
          : formDataEmpty.low,
        rd: response.rd
          ? {
              id: response.rd.rd_id,
              name: `${response.rd.rd_code} ${t(`rd_codes.${response.rd.rd_name}`)}`
            }
          : formDataEmpty.rd,
        startDate: formatDate(new Date(response.start_date)),
        endDate: formatDate(new Date(response.end_date)),
        destination: response.destination ?? formDataEmpty.destination,
        totalKm: response.total_km_to_waste_center ?? formDataEmpty.totalKm,
        emissionFactor: response.custom_emission_factor_id || '',
        unitCustom:
          response.custom_emission_factor_id && response.unit
            ? {
                id: response.unit.id,
                name: t(`units.${response.unit.name}`)
              }
            : { id: '', name: '' },
        percentages: response.facility_percentages.map((elem: PercentagesBackend) => ({
          facility: {
            id: elem.facility_id,
            name: elem.facility_name
          },
          organization: {
            id: elem.organization_id,
            name: elem.company_name
          },
          percentage: elem.percentage * 100
        })),
        errors: []
      });
    };

    getWasteToEdit();
  }, []);

  useEffect(() => {
    if (formData.emissionFactor === '') return;
    const groupToGet = groups.find((group) => group.group_id === formData.emissionFactor);
    const units: SelectOptionFormat[] =
      groupToGet?.emission_factors.map((elem) => ({
        id: elem?.unit?.id ?? '',
        name: elem?.unit?.name
      })) ?? [];
    setUnitsCustom(units?.at(0) ? [units[0]] : []);
    setGroupSelected({
      id: groupToGet?.group_id ?? '',
      name: groupToGet?.group_name ?? ''
    });
  }, [groups]);

  const onChangeLer = (value: SelectOptionFormat) => {
    setFormData((prev) => {
      if (prev.low.id === value.id) return { ...prev };
      return {
        ...prev,
        low: value,
        rd: formDataEmpty.rd,
        errors: prev.errors.filter((error) => error.error !== 'low' && error.error !== 'rd')
      };
    });
  };

  const handleErrors = async () => {
    const optionalFields = [
      'optionalId',
      'rd',
      'totalKm',
      'destination',
      'file_url',
      'description'
    ];

    if (stepSelected?.id === 'custom') {
      optionalFields.push('low');
      optionalFields.push('unitDefault');
    }
    if (stepSelected?.id === 'default') {
      optionalFields.push('emissionFactor');
      optionalFields.push('unitCustom');
    }
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    const dateError = validateDates(formData.startDate, formData.endDate);
    if (dateError) {
      newErrors.push(dateError);
    }

    const facilityWithZeroError = validateFacilityWithZero(formData.percentages);
    if (facilityWithZeroError) {
      newErrors.push(facilityWithZeroError);
    }

    const facilityWithoutIdError = validateFacilityWithoutId(formData.percentages);
    if (facilityWithoutIdError) {
      newErrors.push(facilityWithoutIdError);
    }

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleCreateWaste = async () => {
    if (loading) return;
    setLoading(true);
    if ((await handleErrors()) || !user?.selectedOrganization || !addInvoice) {
      setLoading(false);
      return;
    }

    const body: PostTreatmentBody = {
      file_url: '',
      identification_name: formData.optionalId,
      quantity: formData.quantity,
      unit_id: stepSelected?.id === 'custom' ? formData.unitCustom.id : formData.unitDefault.id,
      total_km_to_waste_center: formData.totalKm ? Number(formData.totalKm) : undefined,
      destination: formData.destination,
      start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      low_id: formData.low.id ? formData.low.id : null,
      rd_id: formData.rd?.id ? formData.rd.id : undefined, // do not change to formData.rd?.id ?? undefined
      uploaded_by: user?.id,
      facility_id: facility.id,
      status: 'uploaded',
      organization_id: user?.selectedOrganization ?? '',
      custom_emission_factor_id: formData.emissionFactor || null,
      facility_percentages: formData.percentages.map((elem) => ({
        facility_id: elem.facility.id,
        organization_id: elem.organization.id,
        percentage: parseFloat(elem.percentage) / 100
      })),
      description: formData.description
    };

    const data = await postWaste(body);

    setLoading(false);

    if (!data) {
      setFormData((prev) => ({
        ...prev,
        errors: [
          {
            error: 'general',
            message: t('error.somethingWentWrong')
          }
        ]
      }));
      return;
    }

    const newInvoice: InvoiceType = {
      id: data.id,
      unit: data.unit || {
        id: '',
        name: '',
        type: ''
      },
      quantity: data.quantity,
      invoice_id: data.identification_name,
      cups: '',
      type: 'wastes',
      status: data.status,
      start_date: data.start_date,
      end_date: data.end_date,
      user: {
        first_name: data.uploaded_by
      },
      percentage: data.percentage,
      base_quantity: data.base_quantity,
      facility_percentages: data.facility_percentages ?? []
    };
    addInvoice(newInvoice);
  };

  const handleEditWaste = async () => {
    if (loading) return;
    setLoading(true);
    if ((await handleErrors()) || !user?.selectedOrganization || !id || !editInvoice) {
      setLoading(false);
      return;
    }

    const body: UpdateWasteBody = {
      file_url: formData.file_url,
      identification_name: formData.optionalId,
      quantity: formData.quantity,
      unit_id:
        stepSelected?.id === 'custom' && formData.unitCustom.id
          ? formData.unitCustom.id
          : formData.unitDefault.id,
      total_km_to_waste_center: formData.totalKm,
      destination: formData.destination,
      low_id: stepSelected?.id === 'custom' ? undefined : formData.low.id,
      rd_id: stepSelected?.id === 'custom' || !formData.rd.id ? undefined : formData.rd.id,
      start_date: moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      status: 'uploaded',
      facility_id: facility.id,
      organization_id: user?.selectedOrganization ?? '',
      custom_emission_factor_id:
        stepSelected?.id === 'custom' && formData.emissionFactor
          ? formData.emissionFactor
          : undefined,
      uploaded_by: user?.id,
      facility_percentages: formData.percentages.map((elem) => ({
        facility_id: elem.facility.id,
        organization_id: elem.organization.id,
        percentage: parseFloat(elem.percentage) / 100
      })),
      description: formData.description
    };

    if (fileInvoiceToEdit && fileInvoiceToEdit instanceof File) {
      body.file_url = fileRead.fileBase64 as string;
    }

    const response = await updateWaste(id, body);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    const newInvoice: InvoiceType = {
      id: response.id,
      unit: response.unit,
      quantity: response.quantity,
      invoice_id: response.identification_name,
      cups: '',
      type: 'wastes',
      status: response.status,
      start_date: response.start_date,
      end_date: response.end_date,
      file_url: response.file_url,
      user: {
        first_name: response.uploaded_by
      },
      percentage: response.percentage,
      base_quantity: response.base_quantity,
      facility_percentages: response.facility_percentages ?? []
    };
    editInvoice(id, newInvoice);
    setLoading(false);
  };

  const onSelectGroup = (newGroup: SelectOptionFormat) => {
    setGroupSelected(newGroup);
    const groupToGet = groups.find((group) => group.group_id === newGroup.id);
    const units: SelectOptionFormat[] =
      groupToGet?.emission_factors.map((elem) => ({
        id: elem?.unit?.id ?? '',
        name: elem?.unit?.name
      })) ?? [];
    setUnitsCustom(units?.at(0) ? [units[0]] : []);
    setFormData((prev) => ({
      ...prev,
      emissionFactor: groupToGet?.group_id ?? ''
    }));
  };

  const handleUploadSingleFile = (fileUploaded: File) => {
    setFileInvoiceToEdit(fileUploaded);
  };

  const buttonTooltip =
    formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0) === 0
      ? t('facilityDetail.noPercentageAssignedYet')
      : t('facilityDetail.percentageError');

  if (loadingData) return <CustomSkeletonLoader count={2} />;

  const unitOptions = stepSelected?.id === 'custom' ? unitsCustom : units;

  const foundGeneralError = formData.errors.find((error) => error.error === 'general');

  let fileToShow = fileInvoiceToEdit ? fileInvoiceToEdit : formData.file_url;

  let showFile = true;

  // Check if fileToShow is a string of a url of a file that is not a .pdf
  if (typeof fileToShow === 'string' && !fileToShow.includes('.pdf')) {
    fileToShow = '';
    showFile = false;
  }

  const documentPDF = fileToShow ? (
    <DocumentPDFV2 file={fileToShow} />
  ) : (
    <div className='edit-file-upload-wrapper'>
      <InputFileInvoice
        imgUrl='/images/icons/camera.svg'
        handleFile={handleUploadSingleFile}
        description={t('facilityDetail.uploadInvoiceToViewIt')}
      />
    </div>
  );

  return (
    <div className='flex'>
      {(fileToShow || !viewOnly) && editInvoice && showFile && (
        <div className='invoice-pdf-wrapper' style={{ height: '75vh' }}>
          {documentPDF}
        </div>
      )}
      <div>
        <FormWrapper style={{ maxHeight: '40vh', overflowY: 'auto' }}>
          {flags?.customize && (
            <FormElementFull>
              <div className='type-selector'>
                <span className='input-label-font on-light-text-color '>
                  {t('facilityDetail.selectType')}
                  {': '}
                </span>
                <StepCarouselList
                  steps={steps}
                  handleSelect={handleSelect}
                  lookAndFeel={'small'}
                  blockClick={viewOnly}
                />
              </div>
            </FormElementFull>
          )}
          {stepSelected?.id === 'custom' && (
            <FormSelect
              label={
                <>
                  {t('facilityDetail.emissionFactor')}
                  <Link
                    to={ROUTES.CUSTOM_EMISSION_FACTORS}
                    className='highlight-text-color pointer ml-1'>
                    {t('facilityDetail.emissionFactorHere')}
                  </Link>
                </>
              }
              icon={'/images/icons/waste.svg'}
              placeholder={t('facilityDetail.selectGroup')}
              options={groupsFiltered?.map((group) => ({
                id: group.group_id,
                name: group.group_name
              }))}
              value={groupSelected}
              onChange={onSelectGroup}
              error={formData.errors.find((error) => error.error === 'customFactor')}
              disabled={viewOnly}
            />
          )}
          <FormElementFull>
            <FormText
              label={t('waste.description')}
              iconV2='input_text'
              placeholder={viewOnly ? undefined : t('waste.writeDescription')}
              value={formData.description}
              onChange={onChangeValue('description')}
              disabled={viewOnly}
            />
          </FormElementFull>
          <FormText
            label={t('waste.optionalId')}
            iconV2='tag'
            placeholder={viewOnly ? undefined : t('waste.writeOptionalId')}
            value={formData.optionalId}
            onChange={onChangeValue('optionalId')}
            disabled={viewOnly}
          />
          <InputWrapper
            iconV2='weight'
            label={t('facilityDetail.quantity')}
            error={formData.errors.find(
              (elem) =>
                elem.error === 'quantity' ||
                elem.error === 'unitCustom' ||
                elem.error === 'unitDefault'
            )}
            disabled={viewOnly}>
            <InputNumber
              placeholder={t('facilityDetail.selectQuantity')}
              onChange={onChangeValue('quantity')}
              value={formData.quantity as string}
              disabled={viewOnly}
            />
            <Select
              placeholder={''}
              options={unitOptions.map((elem) => ({
                id: elem.id,
                name: t(`units.${elem.name}`)
              }))}
              value={
                stepSelected?.id === 'custom'
                  ? (formData.unitCustom as SelectOptionFormat)
                  : (formData.unitDefault as SelectOptionFormat)
              }
              onChange={
                stepSelected?.id === 'custom'
                  ? onChangeValue('unitCustom')
                  : onChangeValue('unitDefault')
              }
              disabled={viewOnly}
            />
          </InputWrapper>
          {stepSelected?.id !== 'custom' && (
            <div className='input-element full'>
              <FormSelect
                label={t('waste.low')}
                tooltip={t('waste.lowToolTip')}
                iconV2='wastes'
                placeholder={t('waste.writeLer')}
                options={lowCodes}
                value={formData.low}
                onChange={onChangeLer}
                error={formData.errors.find((error) => error.error === 'low')}
                disabled={viewOnly}
              />
            </div>
          )}
          {stepSelected?.id !== 'custom' && (
            <div className='input-element full'>
              <FormSelect
                label={t('waste.rd')}
                tooltip={t('waste.rdToolTip')}
                iconV2='recycle'
                placeholder={viewOnly ? t('input.noSelection') : t('waste.writeRd')}
                options={rdCodes}
                value={formData.rd}
                onChange={onChangeValue('rd')}
                error={formData.errors.find((error) => error.error === 'rd')}
                optional={true}
                disabled={!formData?.low?.id || viewOnly}
              />
            </div>
          )}
          <FormCalendarDouble
            label={t('waste.date')}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            startDate={formData.startDate ? (formData.startDate as string) : ''}
            endDate={formData.endDate ? (formData.endDate as string) : ''}
            error={formData.errors.find(
              (error) =>
                error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
            )}
            disabled={viewOnly}
          />
          <FormNumber
            label={
              <>
                <span className='text'>{t('waste.destination')}</span>
                {/* <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'small'} /> */}
              </>
            }
            iconV2='lorry'
            placeholder={viewOnly ? undefined : t('waste.writeTotalKm')}
            value={formData.totalKm ?? ''}
            onChange={onChangeValue('totalKm')}
            error={formData.errors.find((error) => error.error === 'totalKm')}
            disabled={viewOnly}
          />

          <DivideConsumptionsWrapper
            setFormData={setFormData}
            organizationOptions={organizationOptions}
            formData={{
              ...formData,
              unit: stepSelected?.id === 'custom' ? formData.unitCustom : formData.unitDefault
            }}
            units={unitOptions}
            facility={facility}
            disabled={viewOnly}
          />
        </FormWrapper>

        {!viewOnly && (
          <FormButtonSection>
            <TooltipWrapper
              text={buttonTooltip}
              shouldAppear={!!formData.errors.find((elem) => elem.error === 'percentages')}
              style={{ width: '100%' }}>
              <Button
                lookAndFeel='primary'
                size='medium'
                text={t('waste.confirm')}
                onClick={addInvoice ? handleCreateWaste : handleEditWaste}
                loading={loading}
                disabled={!!formData.errors.find((elem) => elem.error === 'percentages')}
              />
            </TooltipWrapper>
          </FormButtonSection>
        )}
        {foundGeneralError && <ErrorText>{foundGeneralError.message}</ErrorText>}
      </div>
    </div>
  );
};

export default Waste;
