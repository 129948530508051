import { PDFExport } from '@progress/kendo-react-pdf';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import Button from '../../ui/button/Button';
import IconBackground from '../../ui/iconBackground/IconBackground';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import ExcelTableRefactor from '../../ui/table/excelTable.tsx/ExcelTableRefactor';
import CommonSection from '../ghgReport/common/commonSection/CommonSection';
import Layout from '../ghgReport/common/layout/Layout';
import List from '../ghgReport/common/list/List';
import Closing from '../report/closing/Closing';
import FrontPage from './components/FrontPage';
import useExportPdf from './hooks/useExportPdf';
import useGetData from './hooks/useGetData';
import './styles.scss';

const GlecReport = () => {
  const selectedOrganization = useSelectedOrganization();
  const { t: translator } = useTranslation('translation', { keyPrefix: 'glecReport' });
  const { t: translator2 } = useTranslation();

  const queryParameters = new URLSearchParams(window.location.search);
  const startDate = queryParameters.get('start_date') || '';
  const endDate = queryParameters.get('end_date') || '';
  const clientId = queryParameters.get('client_id') || '';
  const language = queryParameters.get('lang') || 'en';
  const companyLogo = queryParameters.get('company_logo') || '';
  const organizationId = selectedOrganization?.id || '';
  const organizationName = selectedOrganization?.company_name || '';

  const t = (key: string, options?: object) => translator(key, { ...options, lng: language });
  const t2 = (key: string, options?: object) => translator2(key, { ...options, lng: language });

  const {
    selfOrganizationDataParsed,
    tocOrganizationDataParsed,
    hocsOrganizationDataParsed,
    transportModeOrganizationDataParsed,
    hubCategoriesOrganizationDataParsed,
    generalDataParsed,
    sumDataParsed,
    sumDataHocsParsed,
    tocDataParsed,
    fuelDataParsed,
    loading
  } = useGetData({
    clientId,
    startDate,
    endDate,
    organizationId,
    language
  });
  const { handleExportPDF, pdfRef, loadingButton } = useExportPdf();
  const numOfPages =
    tocOrganizationDataParsed.length +
    hocsOrganizationDataParsed.length +
    transportModeOrganizationDataParsed.length +
    hubCategoriesOrganizationDataParsed.length +
    4;

  if (loading) return <LoaderTables />;

  return (
    <div className='ghg-report' id='glec-report'>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          onClick={handleExportPDF}
          text={t2('logistics.download')}
          loading={loadingButton}
          size='small'
        />
      </div>

      <PDFExport
        paperSize={'auto'}
        margin={0}
        fileName={`GLEC Report`}
        author='Dcycle'
        forcePageBreak='.page-break'>
        <div ref={pdfRef}>
          <Layout
            logo_url={companyLogo}
            backgroundImage='/images/report/ghgfrontpagebg.png'
            org_name={organizationName}
            newPage={false}
            lookAndFeel='dark'
            page={1}
            total={numOfPages}
            isGlec={true}
            key='front'>
            <FrontPage
              title={t('title')}
              title2={t('title2')}
              content={
                <>
                  <p className='on-dark-text-color subtitle3-font'>
                    {t('subtitle', { startDate, endDate })}
                  </p>
                  <p className='on-dark-text-color subtitle3-font'>
                    {t('clientName')}: {clientId}
                  </p>
                </>
              }
            />
          </Layout>

          <Layout
            org_name={organizationName}
            logo_url={companyLogo}
            page={2}
            total={numOfPages}
            key='organizationData'>
            <div
              className='report'
              style={{
                display: 'flex',
                height: 'calc(100% - 34px)',
                flexDirection: 'column'
              }}>
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '1.5rem',
                  marginTop: '1.5rem'
                }}>
                <IconBackground icon={'/images/icons/shipmentGradient.svg'} alt='transport-icon' />
                <span className='subtitle3-font'>{t('client.title')}</span>
              </div>
              {selfOrganizationDataParsed && (
                <ExcelTableRefactor
                  header={selfOrganizationDataParsed?.header}
                  rows={selfOrganizationDataParsed?.rows}
                />
              )}
              {tocOrganizationDataParsed.length !== 0 && (
                <ExcelTableRefactor
                  header={tocOrganizationDataParsed[0]?.header}
                  rows={tocOrganizationDataParsed[0].rows}
                />
              )}
              {/* {hocsOrganizationDataParsed.length !== 0 && (
                <ExcelTableRefactor
                  header={hocsOrganizationDataParsed[0]?.header}
                  rows={hocsOrganizationDataParsed[0].rows}
                />
              )} */}
              {/* <div className='description-box icon-bg-color'>
              <span className='body1-font' style={{ whiteSpace: 'pre-wrap', marginTop: 'auto' }}>
                {t('smallPrint')}
              </span>
              </div> */}
              <span
                className='body1-font description-box icon-bg-color'
                style={{ whiteSpace: 'pre-wrap', marginTop: 'auto', marginBottom: '0.7rem' }}>
                {t('smallPrint')}
              </span>
            </div>
          </Layout>

          {tocOrganizationDataParsed.length > 2 && (
            <>
              {tocOrganizationDataParsed.map((toc, index) => (
                <>
                  {index !== 0 && (
                    <Layout
                      logo_url={companyLogo}
                      org_name={organizationName}
                      page={index + 2}
                      total={numOfPages}
                      key={index}>
                      <div className='report' style={{ marginTop: '1.5rem' }}>
                        <ExcelTableRefactor header={toc?.header} rows={toc?.rows} />
                      </div>
                    </Layout>
                  )}
                </>
              ))}
            </>
          )}

          {hocsOrganizationDataParsed.map((hoc, index) => (
            <>
              <Layout
                logo_url={companyLogo}
                org_name={organizationName}
                page={hocsOrganizationDataParsed.length + index + 2}
                total={numOfPages}
                key={index}>
                <div className='report' style={{ marginTop: '1.5rem' }}>
                  <ExcelTableRefactor header={hoc?.header} rows={hoc?.rows} />
                </div>
              </Layout>
            </>
          ))}

          {transportModeOrganizationDataParsed.map((transportMode, index) => (
            <>
              <Layout
                logo_url={companyLogo}
                org_name={organizationName}
                page={transportModeOrganizationDataParsed.length + index + 2}
                total={numOfPages}
                key={index}>
                <div className='report' style={{ marginTop: '1.5rem' }}>
                  <ExcelTableRefactor header={transportMode?.header} rows={transportMode?.rows} />
                </div>
                <section
                  className='flex-col justify-end'
                  style={{
                    height: '75%'
                  }}>
                  <div className='description-box icon-bg-color flex-col'>
                    <p className='font-10 margin-0-3'>(1) {t2('logistics.activityDistanceInfo')}</p>
                    <p className='font-10 margin-0-3'>(2) {t2('logistics.adjustmentFactorInfo')}</p>
                  </div>
                </section>
              </Layout>
            </>
          ))}

          {hubCategoriesOrganizationDataParsed.map((hubCategory, index) => (
            <>
              <Layout
                logo_url={companyLogo}
                org_name={organizationName}
                page={hubCategoriesOrganizationDataParsed.length + index + 2}
                total={numOfPages}
                key={index}>
                <div className='report' style={{ marginTop: '1.5rem' }}>
                  <ExcelTableRefactor header={hubCategory?.header} rows={hubCategory?.rows} />
                </div>
              </Layout>
            </>
          ))}

          <Layout
            logo_url={companyLogo}
            org_name={organizationName}
            page={numOfPages - 2}
            total={numOfPages}>
            <div className='report' style={{ marginTop: '1.5rem' }}>
              <ExcelTableRefactor header={sumDataParsed.header} rows={sumDataParsed?.rows} />
              <ExcelTableRefactor
                header={sumDataHocsParsed.header}
                rows={sumDataHocsParsed?.rows}
              />
              <ExcelTableRefactor rows={generalDataParsed?.rows} />
            </div>
          </Layout>

          <Layout
            logo_url={companyLogo}
            org_name={organizationName}
            page={numOfPages - 1}
            total={numOfPages}>
            <CommonSection id={'logisitcs-toc'}>
              <List
                icon={'/images/icons/leaf.svg'}
                columns={[
                  { title: 'TOC' },
                  { title: t('wtwEmissions') },
                  { title: t('defaultLoad') }
                ]}
                rows={tocDataParsed}
              />
            </CommonSection>

            <CommonSection id={'logisitcs-fuel'}>
              <List
                icon={'/images/icons/leaf.svg'}
                columns={[
                  { title: t('fuel') },
                  { title: t('unity') },
                  { title: t('region') },
                  { title: 'wwt' },
                  { title: 'ttw' },
                  { title: 'wtw' }
                ]}
                rows={fuelDataParsed}
              />
            </CommonSection>
          </Layout>

          <Layout
            backgroundImage='/images/report/ghgfrontpagebg.png'
            org_name={organizationName}
            logo_url={companyLogo}
            // newPage={false}
            lookAndFeel='dark'
            page={numOfPages}
            total={numOfPages}>
            <Closing />
          </Layout>
        </div>
      </PDFExport>
    </div>
  );
};

export default GlecReport;
