import { useTranslation } from 'react-i18next';
import SelectCard from './SelectCard';
type CardSize = 'small' | 'big';

type Card = {
  icon: string;
  id: string;
  title: string;
  disabled?: boolean;
  tooltip?: string;
};
type Props = {
  cards: Card[];
  onClickCard: (id: string) => void;
  error?: ErrorType;
  type?: {
    id: string;
    icon: string;
    title: string;
  };
  size?: CardSize;
  mode?: 'create' | 'edit';
};
function SelectCardWrapper({
  cards,
  onClickCard,
  error,
  type,
  size = 'small',
  mode = 'create'
}: Props) {
  const { t } = useTranslation();

  let cardsToShow = [...cards];

  if (mode !== 'create') {
    cardsToShow = cards.map((card) => ({
      ...card,
      disabled: true
    }));
  }

  return (
    <div className='select-card-section'>
      <div className='select-card-wrapper'>
        {cardsToShow.map((card) => (
          <SelectCard
            key={card.id}
            card={card}
            onClickCard={onClickCard}
            selected={card.id === type?.id}
            size={size}
            error={error}
          />
        ))}
      </div>
      {error && (
        <span className='error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
}

export default SelectCardWrapper;
