import React, { ReactNode } from 'react';
import StepBig from './stepBig/StepBig';
import StepSmall from './stepSmall/StepSmall';
import StepBreadcrumb from './stepBreadcrumb/StepBreadcrumb';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: ReactNode;
  isSelected: boolean;
  lookAndFeel?: 'big' | 'small' | 'breadcrumb';
  error?: string;
  disabled?: boolean;
  blocked?: boolean;
}

const StepCarousel = ({
  text,
  isSelected,
  lookAndFeel = 'big',
  error,
  disabled,
  blocked,
  ...rest
}: Props) => {
  switch (lookAndFeel) {
    case 'big':
      return (
        <StepBig
          text={text}
          isSelected={isSelected}
          className={'step-carousel big'}
          disabled={disabled}
          blocked={blocked}
          error={error}
          {...rest}
        />
      );
    case 'small':
      return (
        <StepSmall
          text={text}
          isSelected={isSelected}
          className={'step-carousel small'}
          disabled={disabled}
          blocked={blocked}
          {...rest}
        />
      );
    case 'breadcrumb':
      return (
        <StepBreadcrumb
          text={text}
          isSelected={isSelected}
          className={'step-carousel breadcrumb'}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default StepCarousel;
