import { InfiniteData } from '@tanstack/react-query';

export const adaptFinalUsersBackToFront = (
  backendFinalUsers: InfiniteData<BackendDMFinalUsersResponse, number>
): { total: number; finalUsers: DMFinalUser[] } => ({
  total: backendFinalUsers.pages[0].total,
  finalUsers: backendFinalUsers.pages.flatMap(({ items: finalUsers }) =>
    finalUsers.map((finalUser: BackendDMFinalUser) => ({
      id: finalUser.id,
      name: finalUser.name,
      country: finalUser.country,
      highImportance: finalUser.high_importance
    }))
  )
});

export const adaptFinalUserFrontToBack = (finalUser: DMFinalUser): BackendDMFinalUser => ({
  id: finalUser.id,
  name: finalUser.name,
  country: finalUser.country,
  high_importance: finalUser.highImportance
});
