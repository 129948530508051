import { useQuery, useQueryClient } from '@tanstack/react-query';

import apiFetch from 'utils/apiFetch';

const useGetData = () => {
  const queryClient = useQueryClient();

  const invalidateOrganizations = () => {
    queryClient.invalidateQueries({ queryKey: ['organizations'] });
  };
  const fetchOrganizations = async () => {
    const response = await apiFetch('GET', '/organizations');
    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['organizations'],
    queryFn: async () => {
      const response = await fetchOrganizations();
      return response;
    }
  });
  return { data, isLoading, invalidateOrganizations };
};

export default useGetData;
