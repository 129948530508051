import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Shipment, ShipmentForm } from '../../../../../types/entities/shipment';
import { User } from '../../../../../types/entities/user';
import { formatDate } from '../../../../../utils/formatDate';
import FormShipment from '../common/FormShipment';

type Props = {
  addShipment: (shipment: Shipment) => void;
  user: User;
};
function AddShipment({ addShipment, user }: Props) {
  const [searchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction') || 'downstream';

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());

  const [formData, setFormData] = useState<ShipmentForm>({
    name: '',
    quantity: '',
    unit: '',
    supplier: '',
    refrigerated: false,
    startDate: formatDate(today),
    frequency: 'once',
    transportSections: [
      {
        location_origin_id: '',
        location_destination_id: '',
        origin: '',
        destination: '',
        transport_type: '',
        transport_kilometers: ''
      }
    ],
    errors: [] as ErrorType[]
  });

  return (
    <div className='shipment-form'>
      {(transportDirection === 'upstream' || transportDirection === 'downstream') && (
        <FormShipment
          formData={formData}
          setFormData={setFormData}
          transportDirection={transportDirection}
          user={user}
          submitShipment={addShipment}
        />
      )}
    </div>
  );
}

export default AddShipment;
