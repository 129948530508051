import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';

// I18N
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n/settings';

import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { setNotification } from './actions/notification';
import HideShowChat from './components/ui/hideShowChat/HideShowChat';
import Notification from './components/ui/notification/Notification';
import { NotificationContext } from './context/notificationContext';
import store from 'legacyStore';
import { LDProvider } from './lib/LaunchDarkly/LDProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { queryClient } from 'lib/ReactQuery/client';
import { ClerkProvider } from '@clerk/clerk-react';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const render = (Component: React.FC) => {
  return root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ClerkProvider publishableKey={String(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY)}>
          <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
              <HideShowChat />
              <NotificationContext.Provider value={setNotification}>
                <TooltipProvider delayDuration={0}>
                  <LDProvider>
                    <QueryClientProvider client={queryClient}>
                      <Component />
                    </QueryClientProvider>
                  </LDProvider>
                </TooltipProvider>
                <Notification />
              </NotificationContext.Provider>
            </DndProvider>
          </Provider>
        </ClerkProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
