import { useTranslation } from 'react-i18next';
import IconBackground from '../../../../ui/iconBackground/IconBackground';
import './styles.scss';
import Table from 'rc-table';
import { Column } from 'types/table';

export type ListColumn = {
  title: React.ReactNode;
  align?: Column['align'];
};

type Props = {
  icon?: string;
  columns: ListColumn[];
  rows: string[][];
  totalRow?: string[];
};

const List = ({ icon, columns, rows, totalRow }: Props) => {
  const { t } = useTranslation();

  const mutableRows = [...rows];

  const onRenderRow = (_record: any, index: any) => {
    if ((index - 1) % 2 !== 0) return {};
    return {
      className: 'on-card-gray-bg-color'
    };
  };

  const onHeaderRow = () => {
    return {
      className: 'on-card-gray-bg-color'
    };
  };

  const columnsParsed: Column[] = columns.map((column, index) => ({
    title: (
      <span className='icon-text-wrapper'>
        {index === 0 && icon && <IconBackground icon={icon} alt='list-icon' />}
        <span className='body2-font'>{column.title}</span>
      </span>
    ),
    dataIndex: index.toString(),
    key: index.toString(),
    align: column.align || 'left'
  }));

  const totalRowParsed: any[] = [
    <span className='icon-text-wrapper' key='react-node-0'>
      <IconBackground icon='/images/icons/plus50.svg' alt='plus' />
      <span className='body2-font'>{t('ghgReport.total')}</span>
    </span>
  ];
  if (totalRow) {
    totalRowParsed.push(...totalRow);
    mutableRows.push({ ...totalRowParsed });
  }

  return (
    <div className='ghg-report-list'>
      <Table
        columns={columnsParsed}
        data={mutableRows}
        indentSize={5}
        onRow={onRenderRow}
        onHeaderRow={onHeaderRow}
        expandable={{
          defaultExpandAllRows: false
        }}
      />
    </div>
  );
};

export default List;
