import { OrganizationModal } from '../OrganizationModal';
import { ModalName, ModalType } from './types';
import { ModalManagerContext } from './ModalManagerContext';
import { useModalManager } from './useModalManager';
import { MODAL_NAMES } from './constants';
import { ShareModal } from '../ShareModal';
import { ConfigureModal } from '../ConfigureModa/ConfigureModal';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

type ProviderProps = {
  children: React.ReactNode;
  modal: ModalType;
  setModal: React.Dispatch<React.SetStateAction<ModalType>>;
};

const Provider: React.FC<ProviderProps> = ({ children, modal, setModal }) => {
  const openModal = (modalName: ModalName, payload?: string) => {
    setModal({ modalName, payload });
  };

  const closeModal = () => {
    setModal(null);
  };

  return (
    <ModalManagerContext.Provider value={{ openModal, closeModal, modal }}>
      {children}
    </ModalManagerContext.Provider>
  );
};

export const ModalManager = () => {
  const { modal } = useModalManager();
  const flags = useFeatureFlags();

  return (
    <>
      {modal?.modalName === MODAL_NAMES.ORGANIZATION && modal.payload && (
        <OrganizationModal node_id={modal.payload} />
      )}
      {modal?.modalName === MODAL_NAMES.SHARE && modal.payload && (
        <ShareModal edge_id={modal.payload} />
      )}
      {flags?.webinar060325 && modal?.modalName === MODAL_NAMES.CONFIGURE && <ConfigureModal />}
    </>
  );
};

ModalManager.Root = Provider;
