import { LogisticReportBack, LogisticReportFront } from '../types/entities/logistics';

export const adaptLogisticReportBackToFront = (reportBack: any): LogisticReportFront => {
  // if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  //   reportBack = {
  //     company_emissions: {
  //       transport_activity: {
  //         iv: { value: 82.0, units: 'gCO2e/tkm' },
  //         tkm: { value: 11416.611594999998, units: 'tkm' },
  //         emissions: { value: 936.1621507899998, units: 'kgCo2e' }
  //       },
  //       hub_operation: {
  //         iv: { value: 0.0, units: 'gCO2e/tonne' },
  //         tonne: { value: 0.0, units: 'tonne' },
  //         emissions: { value: 0.0, units: 'kgCo2e' }
  //       }
  //     },
  //     tocs_info: {
  //       packed_goods_average_groupage_ambient_b_5: {
  //         iv: { value: 82.0, units: 'gCO2e/tkm' },
  //         tkm: { value: 11416.611594999998, units: 'tkm' },
  //         emissions: { value: 936.1621507899998, units: 'kgCO2e' },
  //         fuels_used: {},
  //         tons_transported: { value: 25.64800000000047, units: 'tonne' },
  //         km_total: { value: 3301330.615999942, units: 'km' },
  //         km_empty: { value: 561226.2047199903, units: 'km' },
  //         empty_running_factor: { value: 0.17, units: '%' }
  //       }
  //     },
  //     hocs_info: {},
  //     emission_factors_used: {
  //       logistics_fuels: [],
  //       logistics_tocs: [
  //         {
  //           toc_name: 'packed_goods_average_groupage_ambient_b_5',
  //           wtw_ef_subcontracted: { value: 0.082, units: 'kgCO2e/tkm' },
  //           empty_running_factor_subcontracted: { value: 17.0, units: '%' },
  //           empty_running_factor: { value: 0.17, units: '%' }
  //         }
  //       ]
  //     },
  //     transport_mode_info: {
  //       road: {
  //         iv: 81.99999999999999,
  //         tkm: 11416.611594999998,
  //         emissions: 936.1621507899998,
  //         activity_distance: 'SFD',
  //         adjustment_factor: '5%'
  //       }
  //     },
  //     hub_category_info: {}
  //   };
  // }
  const vehicle_ids = Object.entries(reportBack.tocs_info);
  const vehicleInfo = vehicle_ids.reduce((acc, [key, value]: any) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tkm: value.tkm,
        kgCO2e: value.emissions,
        fuelsUsed: value.fuels_used,
        tonsTransported: value.tons_transported,
        kmTotal: value.km_total,
        kmEmpty: value.km_empty,
        emptyRunningFactor: value.empty_running_factor
      }
    };

    return newAcc;
  }, {});

  const hoc_ids = Object.entries(reportBack.hocs_info);
  const hocsInfoParsed = hoc_ids.reduce((acc, [key, value]: any) => {
    const newAcc = {
      ...acc,
      [key]: {
        hubName: value.hub_name,
        emissions: value.emissions,
        intensityValue: value.intensity_value,
        clientTons: value.tonne
      }
    };
    return newAcc;
  }, {});

  const transport_mode_ids = Object.entries(reportBack.transport_mode_info);
  const transportModeInfoParsed = transport_mode_ids.reduce((acc, [key, value]: any) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tkm: value.tkm,
        emissions: value.emissions,
        activityDistance: value.activity_distance,
        adjustmentFactor: value.adjustment_factor
      }
    };
    return newAcc;
  }, {});

  const hub_category_ids: any = Object.entries(reportBack.hub_category_info);
  const hubCategoryInfoParsed = hub_category_ids.reduce((acc: any, [key, value]: any) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tons: value.tons,
        emissions: value.emissions
      }
    };
    return newAcc;
  }, {});

  return {
    total: reportBack.total_scopes ? reportBack.total_scopes.emissions : undefined,
    scope1: reportBack?.scope_1
      ? {
          hubsEmissions: reportBack.scope_1.hubs_emissions,
          ttwEmissions: reportBack.scope_1.ttw_emissions
        }
      : undefined,
    scope2: reportBack?.scope_2
      ? {
          hubsEmissions: reportBack.scope_2.hubs_emissions
        }
      : undefined,
    scope3: reportBack?.scope_3
      ? {
          wttEmissions: reportBack.scope_3.wtt_emissions,
          wtwSubcontracted: reportBack.scope_3.wtw_subcontracted_emissions,
          wtwEmissionsCleaning: reportBack.scope_3.cleaning_emissions,
          hubEmissions: reportBack.scope_3.hub_emissions
        }
      : undefined,
    vehicleInfo,
    emissionFactorsUsed: {
      logisticFuels: reportBack.emission_factors_used?.logistics_fuels.map((fuel: any) => ({
        fuelName: fuel.fuel_name,
        fuelUnit: {
          id: fuel.fuel_unit.name,
          name: fuel.fuel_unit.name,
          type: fuel.fuel_unit.type
        },
        region: fuel.region,
        wtt: fuel.wtt,
        ttw: fuel.ttw,
        wtw: fuel.wtw
      })),
      logisticsTocs: reportBack.emission_factors_used?.logistics_tocs.map((toc: any) => ({
        tocName: toc.toc_name,
        wtwEfSubcontracted: toc.wtw_ef_subcontracted,
        defaultLoad: toc.empty_running_factor_subcontracted
      }))
    },
    tocEmissions: {
      iv: reportBack.company_emissions.transport_activity.iv,
      tkm: reportBack.company_emissions.transport_activity.tkm,
      kgCO2e: reportBack.company_emissions.transport_activity.emissions
    },
    hocEmissions: {
      iv: reportBack.company_emissions.hub_operation.iv,
      tkm: reportBack.company_emissions.hub_operation.tonne,
      kgCO2e: reportBack.company_emissions.hub_operation.emissions
    },
    hocsInfo: hocsInfoParsed,
    transportModeInfo: transportModeInfoParsed,
    hubCategoryInfo: hubCategoryInfoParsed
  };
};
