import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee, EmployeeTable } from 'types/entities/employee';
import { Column } from 'types/table';
import { Status } from 'types/utilsEnums/status';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import CheckboxMultiple from 'components/ui/formComponents/checkboxMultiple/CheckboxMultiple';
import Icon from 'components/ui/icon/Icon';
import Label from 'components/ui/label/Label';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import StatusTagEmployee from '../infoTag/StatusTagEmployee';
import { formatNumberToDecimalNonZero } from 'utils/numberToDecimal';
import { EMPLOYEE_MODALS, EmployeeModals } from '../constants';

type EmployeeStatus = 'active' | 'inactive' | 'loading' | 'terminated';
type ExpandType = {
  expand: boolean;
  id: string;
};

type Props = {
  employees: Employee[];
  expand: ExpandType;
  employeesWithEmail: number;
  setOpenModal: (openModal: { id?: EmployeeModals; payload?: string }) => void;
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
};

const useColumns = ({
  expand,
  employees,
  employeesWithEmail,
  setOpenModal,
  selectAll,
  setSelectAll
}: Props) => {
  const { t } = useTranslation();

  const [employeesSelected, setEmployeesSelected] = useState<Employee[]>([]);
  const [employeesUnselected, setEmployeesUnselected] = useState<Employee[]>([]);

  const selectAllCheckbox =
    ((!selectAll && employeesSelected.length === employeesWithEmail) ||
      (selectAll && employeesUnselected.length === 0)) &&
    employeesWithEmail > 0;

  const onClickSelectAll = () => {
    if (employeesWithEmail === 0) return;
    setSelectAll(!selectAllCheckbox);
    setEmployeesUnselected([]);
    setEmployeesSelected([]);
  };

  const onSelectEmployee = (id: string) => {
    if (selectAll) {
      const newEmployeesUnselected = employeesUnselected.filter((elem) => elem.email !== id);
      setEmployeesUnselected(newEmployeesUnselected);
    } else {
      const foundEmployee = employees.find((elem) => elem.email === id);
      if (foundEmployee) setEmployeesSelected([...employeesSelected, foundEmployee]);
    }
  };

  const onRemoveEmployee = (id: string) => {
    if (selectAll) {
      const foundEmployee = employees.find((elem) => elem.email === id);
      if (foundEmployee) setEmployeesUnselected([...employeesUnselected, foundEmployee]);
    } else {
      const newEmployeesSelected = employeesSelected.filter((elem) => elem.email !== id);
      setEmployeesSelected(newEmployeesSelected);
    }
  };

  const columns: Column[] = [
    {
      title: (
        <div className='flex' style={{ gap: '0.5rem' }}>
          <CheckboxMultiple
            type='square'
            onSelect={onClickSelectAll}
            selected={selectAllCheckbox}
            id='all'
            onRemove={onClickSelectAll}
          />
          <span>{t('employees.selectAll')}</span>
        </div>
      ),
      dataIndex: 'user',
      key: 'user',
      render: (_: unknown, employee: EmployeeTable) => {
        return employee.email ? (
          <div className='icon-text-wrapper' style={{ display: 'flex' }}>
            <CheckboxMultiple
              type='square'
              onSelect={onSelectEmployee}
              selected={
                (!selectAll &&
                  (employeesSelected.find((elem) => elem.email === employee.email)
                    ? true
                    : false)) ||
                (selectAll &&
                  (employeesUnselected.find((elem) => elem.email === employee.email)
                    ? false
                    : true))
              }
              id={employee.email}
              onRemove={onRemoveEmployee}
            />
            {employee.children?.length && employee.children?.length > 0 ? (
              <Icon
                icon={
                  expand.expand && expand.id === employee.employeeId
                    ? 'chevron_down'
                    : 'chevron_right'
                }
                color='gray-dark'
                className='icon-pointer'
              />
            ) : (
              <></>
            )}
            <span>{employee.email}</span>
          </div>
        ) : (
          <div className='icon-text-wrapper' style={{ display: 'flex' }}>
            {employee.children?.length && employee.children?.length > 0 ? (
              <Icon
                icon={
                  expand.expand && expand.id === employee.employeeId
                    ? 'chevron_down'
                    : 'chevron_right'
                }
                className='icon-pointer'
                color='gray-dark'
              />
            ) : (
              <></>
            )}
            <span>{employee.name}</span>
          </div>
        );
      }
    },
    {
      title: t('employees.weeklyTravelsColumn'),
      dataIndex: 'daysPerWeek',
      key: 'daysPerWeek',
      width: '10%'
    },
    {
      title: t('employees.totalKmColumn'),
      dataIndex: 'km',
      key: 'km',
      width: '8%'
    },
    {
      title: t('employees.transport'),
      dataIndex: 'transportType',
      key: 'transport_type'
    },
    {
      title: t('employees.fuelType'),
      dataIndex: 'fuelType',
      key: 'fuel_type'
    },
    {
      title: t('employees.registerDateTable'),
      dataIndex: 'period',
      key: 'register_date'
    },
    {
      title: t('employees.responseWay'),
      dataIndex: 'responseWay',
      key: 'responseWay',
      render: (response: EmployeeTable) => {
        return response ? (
          <Label lookAndFeel={response.responseWay === 'qr' ? 'primary' : 'secondary'}>
            {t(`employees.response${response}`)}
          </Label>
        ) : (
          '-'
        );
      }
    },
    {
      title: t('employees.situation'),
      dataIndex: 'status',
      key: 'status',
      render: (situation: EmployeeStatus) => {
        type AllowedTypes = Status.ACTIVE | Status.PENDING | Status.ERROR | Status.BLOCKED;

        const statusObject = {
          active: Status.ACTIVE,
          loading: Status.PENDING,
          terminated: Status.BLOCKED,
          inactive: Status.BLOCKED
        };

        const statusTooltip = {
          active: t('employees.activeTooltip'),
          loading: t('employees.pendingTooltip'),
          terminated: t('employees.terminatedTooltip'),
          inactive: t('employees.inactiveTooltip')
        };

        const statusText = {
          active: t('employees.active'),
          loading: t('employees.pendingResponse'),
          terminated: t('employees.terminated'),
          inactive: t('employees.inactive')
        };
        return situation ? (
          <TooltipWrapper text={statusTooltip[situation]}>
            <StatusTagEmployee
              status={statusObject[situation] as AllowedTypes}
              text={statusText[situation]}
            />
          </TooltipWrapper>
        ) : null;
      }
    },
    {
      title: 'CO₂ eq',
      dataIndex: 'co2e',
      key: 'co2e',
      render: (record: number) => {
        return record !== null && record !== undefined ? (
          <span
            className='highlight-text-color weight-600'
            style={{ textAlign: 'right', display: 'block' }}>
            {formatNumberToDecimalNonZero(+record)} kg
          </span>
        ) : (
          '-'
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_: unknown, employeeTable: EmployeeTable) => {
        let options = [];
        if (employeeTable.employeeId && employeeTable.user) {
          options = [
            {
              id: 'edit-period',
              name: t('employees.editPeriod'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.PERIOD_TO_EDIT,
                  payload: employeeTable.periodId
                });
              }
            },
            {
              id: `delete-period`,
              name: t('employees.deletePeriod'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.PERIOD_TO_DELETE,
                  payload: employeeTable.periodId
                });
              }
            },
            {
              id: `add-period`, //añadir
              name: t('employees.addPeriod'),
              onClick: () => {
                setOpenModal({ id: EMPLOYEE_MODALS.ADD_PERIOD, payload: employeeTable.employeeId });
              }
            },
            {
              id: `edit-employee`,
              name: t('employees.edit'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.EDIT_EMPLOYEE,
                  payload: employeeTable.employeeId
                });
              }
            },
            {
              id: `delete-employee`,
              name: t('employees.deleteEmployee'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.DELETE_EMPLOYEE,
                  payload: employeeTable.employeeId
                });
              }
            }
          ];
        }

        // Is period
        else {
          options = [
            {
              id: 'edit-period', //uno concreto
              name: t('employees.editPeriod'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.PERIOD_TO_EDIT,
                  payload: employeeTable.periodId
                });
              }
            },
            {
              id: `delete-period`, //uno concreto
              name: t('employees.deletePeriod'),
              onClick: () => {
                setOpenModal({
                  id: EMPLOYEE_MODALS.PERIOD_TO_DELETE,
                  payload: employeeTable.periodId
                });
              }
            }
          ];
        }

        if (employeeTable.email) {
          options.unshift({
            id: `resend`,
            name: t('employees.resend'),
            onClick: () => {
              setOpenModal({
                id: EMPLOYEE_MODALS.RESEND_ONE,
                payload: employeeTable.employeeId
              });
            }
          });
        }

        if (employeeTable.status === 'terminated') {
          options = options.filter((elem) => elem.id !== 'add-period' && elem.id !== 'resend');
        }

        if (!employeeTable.status && employeeTable.periodId) {
          options = options.filter(
            (elem) =>
              elem.id !== 'delete-employee' &&
              elem.id !== 'edit-employee' &&
              elem.id !== 'add-period'
          );
        }

        if (employeeTable.employeeId && !employeeTable.periodId) {
          options = options.filter(
            (elem) => elem.id !== 'delete-period' && elem.id !== 'edit-period'
          );
        }

        if (employeeTable.status === 'loading' && employeeTable.email) {
          options = options.filter((elem) => elem.id === 'resend');
          options.push({
            id: `delete-employee`,
            name: t('employees.deleteEmployee'),
            onClick: () => {
              setOpenModal({
                id: EMPLOYEE_MODALS.DELETE_EMPLOYEE,
                payload: employeeTable.employeeId
              });
            }
          });
        }

        return (
          <>
            <ButtonDropdown
              button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
              options={options}></ButtonDropdown>
          </>
        );
      },
      width: '5%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '4%',
      align: 'center'
    }
  ];

  return {
    columns,
    setEmployeesSelected,
    employeesSelected,
    employeesUnselected
  };
};

export default useColumns;
