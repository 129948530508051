import { InfiniteData } from '@tanstack/react-query';

export const adaptOwnWorkersBackToFront = (
  backendOwnWorkers: InfiniteData<BackendDMOwnWorkersResponse, number>
): { total: number; ownWorkers: DMOwnWorker[] } => ({
  total: backendOwnWorkers.pages[0].total,
  ownWorkers: backendOwnWorkers.pages.flatMap(({ items: ownWorkers }) =>
    ownWorkers.map((ownWorker: BackendDMOwnWorker) => ({
      id: ownWorker.id,
      name: ownWorker.name,
      description: ownWorker.description,
      country: ownWorker.country,
      highImportance: ownWorker.high_importance
    }))
  )
});

export const adaptOwnWorkerFrontToBack = (ownWorker: DMOwnWorker): BackendDMOwnWorker => ({
  id: ownWorker.id,
  name: ownWorker.name,
  description: ownWorker.description,
  country: ownWorker.country,
  high_importance: ownWorker.highImportance
});
