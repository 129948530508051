import { useState } from 'react';
import FormRole from './FormRole';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

export type FormData = {
  name: string;
  permissions: number[];
  errors: ErrorType[];
};

type Props = {
  role: Role;
  onClose: () => void;
  handleSubmit: (formData: FormData) => void;
};

const EditRoleWrapper = ({ role, onClose, handleSubmit }: Props) => {
  const [formData, setFormData] = useState<FormData>({
    name: role.name,
    permissions: role.permissions.map((permission: Permission) => permission.id),
    errors: []
  });

  const selectedOrganization = useSelectedOrganization();

  const disabled =
    (!!role && !role.organization) || role.organization?.id !== selectedOrganization?.id;

  return (
    <FormRole
      onClose={onClose}
      formData={formData}
      setFormData={setFormData}
      onSubmit={() => handleSubmit(formData)}
      disabled={disabled}
      predefined={!!role && !role.organization}
      role={role}
    />
  );
};

export default EditRoleWrapper;
