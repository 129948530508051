import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import {
  createVehicleConsumption,
  updateVehicleConsumption
} from '../../../services/api/vehicleConsumptions';
import { User } from '../../../types/entities/user';
import { ConsumptionType } from '../../../types/entities/vehicleConsumption';
import checkFormErrors from '../../../utils/checkFormErrors';
import { convertStringToDate } from '../../../utils/convertDates';
import { formatDate } from '../../../utils/formatDate';
import Button from '../../ui/button/Button';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../ui/formComponents2/select/Select';
import './styles.scss';
import useGetUnits from './useGetUnits';
import { convertDateToString } from '../../../utils/convertDates';

type Props = {
  mode: string;
  addConsumption?: (value: ConsumptionType) => void;
  editConsumption?: (value: ConsumptionType, id: string) => void;
  consumptionToEdit?: ConsumptionType | null;
  user: User | null;
};
type FormData = {
  quantity: string;
  unit: SelectOptionFormat;
  startDate: string;
  endDate: string;
  customId: string;
  errors: ErrorType[];
};

function EditCreateConsumption({
  mode,
  addConsumption,
  consumptionToEdit,
  user,
  editConsumption
}: Props) {
  const { t } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const aMonthAgo = new Date();
  aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
  aMonthAgo.setHours(0, 0, 0, 0);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleCreateOrEditConsumption();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const params = useParams();
  const { units } = useGetUnits({ id: params.id ?? '' });

  // Find litre_(l) unit
  const defaultUnit = units.find((unit) => unit.name === 'litre_(l)');

  const [formData, setFormData] = useState<FormData>({
    quantity:
      consumptionToEdit && consumptionToEdit.quantity > -1
        ? consumptionToEdit.quantity.toString()
        : '',
    unit: consumptionToEdit
      ? { id: consumptionToEdit.unit.id, name: t(`units.${consumptionToEdit.unit.name}`) }
      : defaultUnit
      ? { id: defaultUnit.id, name: t(`units.${defaultUnit.name}`) }
      : { id: '', name: '' },
    startDate:
      consumptionToEdit && consumptionToEdit.start_date
        ? formatDate(new Date(consumptionToEdit.start_date))
        : formatDate(aMonthAgo),
    endDate:
      consumptionToEdit && consumptionToEdit.end_date
        ? formatDate(new Date(consumptionToEdit.end_date))
        : formatDate(today),
    customId: consumptionToEdit?.custom_id ?? '',
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const handleCreateOrEditConsumption = async () => {
    const optionalFields = ['customId'];
    // Check if there are errors in the form
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    const date1 = moment(
      formData.startDate ? convertStringToDate(formData.startDate as string) : undefined
    );
    const date2 = moment(
      formData.endDate ? convertStringToDate(formData.endDate as string) : undefined
    );

    // if days of the invoice are more than two years do not allow and set an error
    if (date2.diff(date1, 'days') > 730) {
      newErrors.push({ error: 'date', description: t('facilityDetail.dateIncorrect') });
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({ ...prev, errors: newErrors }));
      return;
    }
    if (mode === 'edit' && consumptionToEdit) {
      const consumptionModified = {
        quantity: formData.quantity ? parseFloat(formData.quantity) : consumptionToEdit.quantity,
        unit_id: formData.unit && formData.unit.id ? formData.unit.id : consumptionToEdit.unit.id,
        start_date:
          new Date(date1.format('YYYY-MM-DD')) ||
          new Date(moment(new Date(consumptionToEdit.start_date)).format('YYYY-MM-DD')),
        end_date:
          new Date(date2.format('YYYY-MM-DD')) ||
          new Date(moment(new Date(consumptionToEdit.end_date)).format('YYYY-MM-DD')),
        organization_id: user?.selectedOrganization ?? '',
        custom_id: formData.customId ?? ''
      };
      setLoadingButton(true);

      const data = await updateVehicleConsumption(consumptionToEdit.id, consumptionModified);

      setLoadingButton(false);
      if (editConsumption && data) {
        editConsumption(data, consumptionToEdit.id);
      }
    } else {
      const newConsumption = {
        quantity: parseFloat(formData.quantity),
        unit_id: formData.unit.id,
        start_date: formData.startDate
          ? new Date(moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
          : new Date(moment(aMonthAgo, 'DD/MM/YYYY').format('YYYY-MM-DD')),
        end_date: formData.endDate
          ? new Date(moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
          : new Date(moment(today, 'DD/MM/YYYY').format('YYYY-MM-DD')),
        vehicle_id: params.id ?? '',
        organization_id: user?.selectedOrganization ?? '',
        custom_id: formData.customId ?? ''
      };
      setLoadingButton(true);

      const data = await createVehicleConsumption(newConsumption);
      if (addConsumption && data) {
        addConsumption(data);
      }
      setLoadingButton(false);
    }
  };

  const title =
    mode === 'edit' ? t('vehicleDetail.editConsumption') : t('vehicleDetail.createNewConsumption');
  const description =
    mode === 'edit'
      ? t('vehicleDetail.editConsumptionDescription')
      : t('vehicleDetail.createNewConsumptionDescription');

  const buttonText = mode === 'edit' ? t('vehicleDetail.save') : t('vehicleDetail.create');

  return (
    <div className='new-consumption'>
      <FormHeader title={title} description={description} />
      <FormWrapper>
        <FormText
          icon='/images/icons/car.svg'
          label={t('vehicleDetail.customIdLabel')}
          placeholder={t('vehicleDetail.customIdPlaceholder')}
          onChange={onChangeValue('customId')}
          value={formData.customId}
        />
        <InputWrapper
          icon='/images/icons/plus.svg'
          label={t('vehicleDetail.quantity')}
          error={formData.errors.find(
            (elem) => elem.error === 'quantity' || elem.error === 'unit'
          )}>
          <InputNumber
            placeholder={t('vehicleDetail.quantityPlaceholder')}
            onChange={onChangeValue('quantity')}
            value={formData.quantity as string}
          />
          <Select
            placeholder={t('vehicleDetail.unitPlaceholder')}
            options={units.map((elem) => {
              return {
                id: elem.id,
                name: t(`units.${elem.name}`)
              };
            })}
            value={formData.unit}
            onChange={onChangeValue('unit')}
          />
        </InputWrapper>
        <FormCalendarDouble
          label={t('vehicleDetail.consumptionDates')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          maxEndDate={convertDateToString(new Date(new Date().getFullYear(), 11, 31))}
          error={formData.errors.find(
            (elem) =>
              elem.error === 'startDate' || elem.error === 'endDate' || elem.error === 'date'
          )}
        />
      </FormWrapper>

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={buttonText}
          onClick={handleCreateOrEditConsumption}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default EditCreateConsumption;
