import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { mockSurveyResultsService } from '../../../services/mockSurveyResultsService';
import { SurveyResult, SurveyResultsResponse } from 'types/entities/surveyResults';

type InfiniteQueryData = {
  pages: SurveyResultsResponse[];
  pageParams: number[];
};

export const useSurveyResultsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['surveyResults'],
    queryFn: ({ pageParam = 1 }) => mockSurveyResultsService.getAllSurveyResults(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) return undefined;

      return lastPage.page + 1;
    },
    staleTime: 5 * 60 * 1000
  });
};

export const useSurveyResultByIdQuery = (id: string | null) => {
  return useQuery({
    queryKey: ['surveyResults', id],
    queryFn: () => mockSurveyResultsService.getSurveyResultById(id as string),
    enabled: Boolean(id),
    staleTime: 5 * 60 * 1000
  });
};

export const useCreateSurveyResultMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mockSurveyResultsService.createSurveyResult,
    onSuccess: (newResult: SurveyResult) => {
      queryClient.setQueryData(['surveyResults'], (oldData: InfiniteQueryData | undefined) => {
        if (!oldData) return { pages: [{ items: [newResult], page: 1, size: 1, total: 1 }] };

        return {
          ...oldData,
          pages: oldData.pages.map((page, index) =>
            index === 0 ? { ...page, items: [newResult, ...page.items] } : page
          )
        };
      });
    }
  });
};

export const useUpdateSurveyResultMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<SurveyResult> }) =>
      mockSurveyResultsService.updateSurveyResult(id, data),
    onSuccess: (updatedResult: SurveyResult) => {
      queryClient.setQueryData(['surveyResults'], (oldData: InfiniteQueryData | undefined) => {
        if (!oldData) return { pages: [{ items: [updatedResult], page: 1, size: 1, total: 1 }] };

        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            items: page.items.map((item: SurveyResult) =>
              item.id === updatedResult.id ? updatedResult : item
            )
          }))
        };
      });
    }
  });
};

export const useDeleteSurveyResultMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mockSurveyResultsService.deleteSurveyResult,
    onSuccess: (_, id) => {
      queryClient.setQueryData(['surveyResults'], (oldData: InfiniteQueryData | undefined) => {
        if (!oldData) return { pages: [{ items: [], page: 1, size: 0, total: 0 }] };

        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            items: page.items.filter((item: SurveyResult) => item.id !== id)
          }))
        };
      });
    }
  });
};
