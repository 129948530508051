import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormResponse from '../common/formResponse/FormResponse';
import { useEffect, useState } from 'react';
import { FormSocialData, ResponseSocialData } from '../../../../../types/entities/socialData';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { patchSocialData } from '../../../../../services/api/socialData';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import useGetData from './hooks/useGetData';
import useRole from 'customHooks/useRole';

type Props = {
  socialDataId: string;
  editSocialData: (socialData: ResponseSocialData) => void;
  onClose: () => void;
  yearsAnswered: string[];
};
const EditResponse = ({ socialDataId, editSocialData, onClose, yearsAnswered }: Props) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t } = useTranslation();
  const { isFundRole: disabled } = useRole();

  const { units, questions, socialData, loading } = useGetData({ id: socialDataId });

  const [loadingButton, setLoadingButton] = useState(false);

  const [formData, setFormData] = useState<FormSocialData>({
    year: { id: '', name: '' },
    hasGlobalCompact: {
      id: '',
      name: ''
    },
    isGlobalCompactViolated: {
      id: '',
      name: ''
    },
    isRelatedGuns: {
      id: '',
      name: ''
    },
    anualRevenue: '',
    womanRevenue: '',
    manRevenue: '',
    numberWomanBoardDirectors: '',
    numberMenBoardDirectors: '',
    socialLaboralEvidenceComments: '',
    socialLaboralEvidenceAttached: undefined,
    biodiversityZone: {
      id: '',
      name: ''
    },
    quantityWaste: '',
    unitWaste: {
      id: '',
      name: ''
    },
    naceCode: {
      id: '',
      name: ''
    },
    environmentEvidenceComments: '',
    environmentEvidenceAttached: undefined,
    optionalQuestions: [],
    optionalQuestionsEvidenceComments: '',
    optionalQuestionsEvidenceAttached: undefined,
    errors: []
  });

  useEffect(() => {
    // set form data but if value of key is null do not set it
    const socialDataParsed: any = socialData as any;
    Object.keys(socialDataParsed).forEach((key) => {
      if (socialDataParsed[key] !== null) {
        setFormData((prev) => ({ ...prev, [key]: socialDataParsed[key] }));
      }
    });
  }, [JSON.stringify(socialData)]);

  const handleSubmit = async () => {
    if (disabled) return;
    const optionalFields = [
      'socialLaboralEvidenceAttached',
      'environmentEvidenceAttached',
      'optionalQuestionsEvidenceAttached',
      'socialLaboralEvidenceComments',
      'environmentEvidenceComments',
      'optionalQuestionsEvidenceComments',
      'biodiversityZone',
      'quantityWaste',
      'unitWaste',
      'naceCode',
      'hasGlobalCompact',
      'isGlobalCompactViolated',
      'isRelatedGuns',
      'anualRevenue',
      'womanRevenue',
      'manRevenue',
      'numberWomanBoardDirectors',
      'numberMenBoardDirectors'
    ];

    const errors = checkFormErrors(formData, formData.errors, optionalFields);

    formData.optionalQuestions?.forEach((question) => {
      if (typeof question.response === 'string' && !question.response.trim())
        errors.push({
          error: question.id,
          description: t('error.requiredField')
        });

      // Check question of type float_double and check that length is 2 and both values are numbers
      if (question.type === 'float_double') {
        const values = question.response as string[];
        if (values.length !== 2 || !values.every((value: any) => !isNaN(Number(value))))
          errors.push({
            error: question.id,
            description: t('error.requiredField')
          });
      }
      // Check if question response has an id property
      if (question.type === 'boolean' && !question.response.id)
        errors.push({
          error: question.id,
          description: t('error.requiredField')
        });
    });

    if (formData.optionalQuestions?.length === 0 || !formData.optionalQuestions) {
      questions.forEach((question) => {
        errors.push({
          error: question.id,
          description: t('error.requiredField')
        });
      });
    }

    if (errors.length) {
      setFormData((prev) => ({ ...prev, errors }));
      return;
    }

    setLoadingButton(true);

    const response = await patchSocialData(socialDataId, formData);

    setLoadingButton(false);

    // TODO: set error something went wrong if it fails
    if (!response) return;

    editSocialData(response);
  };

  return (
    <div>
      <FormHeader title={tGeneral('completeYourData')} />
      {loading ? (
        <div
          className='flex'
          style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <CustomSkeletonLoader count={4} />
        </div>
      ) : (
        <FormResponse
          formData={formData}
          setFormData={setFormData}
          onClose={onClose}
          handleSubmit={handleSubmit}
          yearsAnswered={yearsAnswered}
          units={units}
          questions={questions}
          loadingButton={loadingButton}
        />
      )}
    </div>
  );
};

export default EditResponse;
