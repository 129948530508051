import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import { FilterDatesContext } from 'context/filterDatesContext';
import useDebounceValue from 'customHooks/useDebounceValue';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useColumns } from './hooks/useColumns';
import { useObtainFinalUsers } from './hooks/useFinalUsers';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import Modal from 'components/ui/modal/Modal';
import { ModalCreateOrEditFinalUser } from './ModalCreateOrEditFinalUser';
import { ModalDeleteFinalUser } from './ModalDeleteFinalUser';

export const DMFinalUsers = () => {
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const { t: t_notify } = useTranslation('', { keyPrefix: 'notification' });
  const dispatch = useDispatch();

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [finalUserToEdit, setFinalUserToEdit] = useState<DMFinalUser>();
  const [finalUserToDelete, setFinalUserToDelete] = useState<DMFinalUser>();
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceValue(searchValue);

  const columns = useColumns({
    setFinalUserToEdit,
    setFinalUserToDelete
  });
  const { data, fetchNextPage, isLoading } = useObtainFinalUsers({
    year,
    company_name: debounceSearchValue
  });
  const { finalUsers, total } = data ?? {};

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <CardCTA>
        <CardCTA.Header>
          <span className='font-14 weight-600'>{t_dm('finalUserTitle')}</span>
          <span className='font-12'>{t_dm('finalUserSubtitle')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.finalUsers.addFinalUsers')}
            size='small'
            onClick={() =>
              setFinalUserToEdit({
                name: '',
                country: '',
                highImportance: false
              })
            }
          />
          <Button
            lookAndFeel={'primary'}
            text={t_dm('context.copyLinkQuestionnaire')}
            size='small'
            onClick={() => {
              navigator.clipboard.writeText(
                'https://docs.google.com/forms/d/e/1FAIpQLSf6NeuXtbrv1JsQvkJRi9G0hW0ymjIyLyA7n7TB9ZEjNwkznQ/viewform'
              );
              dispatch(setNotification(t_notify('copyOnBoard')));
            }}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <InfiniteList
        fetchData={fetchNextPage}
        data={finalUsers ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        }
      />
      <Modal.WithPortal show={!!finalUserToEdit} onClose={() => setFinalUserToEdit(undefined)}>
        <ModalCreateOrEditFinalUser
          finalUserData={finalUserToEdit}
          closeModal={() => setFinalUserToEdit(undefined)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal show={!!finalUserToDelete} onClose={() => setFinalUserToDelete(undefined)}>
        <ModalDeleteFinalUser
          finalUserData={finalUserToDelete}
          closeModal={() => setFinalUserToDelete(undefined)}
        />
      </Modal.WithPortal>
    </div>
  );
};
