import { useQuery } from '@tanstack/react-query';
import { getRolePermissions } from 'services/api/roles';
import { PermissionTags } from 'types/entitiesEnums/permissions';

const useDefaultPermissions = () => {
  // Returns the general (and csrd_general) permissions of the Member,
  // to be used as the default when creating a new role.
  return useQuery({
    queryKey: ['members-predefined-permissions'],
    queryFn: async () => {
      const response = await getRolePermissions('74c802d7-7b21-44e9-8dca-f3334b0732fa');
      return response;
    },
    select: (data) => {
      return data.items.filter(
        (permission: Permission) =>
          permission.category === 'general' || permission.tag === PermissionTags.CSRD_GENERAL_ALL
      ) as Permission[];
    }
  });
};

export default useDefaultPermissions;
