import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from 'constants/routes';
import { useErrorTranslations } from 'customHooks/translations';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmForgotChangePassword, forgotPassword } from 'services/authService';
import { buildPasswordValidator } from 'utils/auth';
import { z } from 'zod';

export const useCreateNewPassword = () => {
  const { t: tError } = useErrorTranslations();
  const navigate = useNavigate();

  const { email } = useParams();

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.LOGIN);
      return;
    }
    forgotPassword({ email });
  }, [email]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    setError
  } = useFormWithHelpers({
    defaultValues: {
      password: '',
      confirmPassword: '',
      emailCode: ''
    },
    resolver: zodResolver(
      z
        .object({
          emailCode: z.string(),
          password: buildPasswordValidator({ errorTranslation: tError, email: email }),
          confirmPassword: z.string()
        })
        .superRefine(({ password, confirmPassword }, ctx) => {
          if (password !== confirmPassword) {
            ctx.addIssue({
              code: 'custom',
              message: tError('passwordsDontMatch'),
              path: ['confirmPassword']
            });
          }
        })
    )
  });

  const exceptionsMapper = useMemo(
    (): Record<
      string,
      {
        field: keyof typeof errors;
        message: string;
      }
    > => ({
      PASSWORD_HISTORY_POLICY_VIOLATION: {
        field: 'password',
        message: tError('passwordHistory')
      },
      INVALID_CONFIRMATION_CODE: {
        field: 'emailCode',
        message: tError('invalidVerificationCode')
      }
    }),
    [tError]
  );

  const password = useWatch({ control, name: 'password' });

  const submitChangePassword = async ({
    emailCode,
    password
  }: {
    emailCode: string;
    password: string;
  }) => {
    if (!email) return;
    try {
      await confirmForgotChangePassword({ email, emailCode, newPassword: password });
      navigate(ROUTES.SIGNIN);
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage && setError(errorMessage.field, { message: errorMessage.message });
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(submitChangePassword),
    formData: { email, password },
    errors,
    email,
    isSubmitted
  };
};
