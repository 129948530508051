export const COMMUNICATE_ROUTES = {
  COMMUNICATE_CSRD_REPORTING: '/communicate/csrd-reporting',
  COMMUNICATE_CDP_REPORTING: '/communicate/cdp-reporting',
  COMMUNICATE_ECOVADIS_REPORTING: '/communicate/ecovadis-reporting',
  COMMUNICATE_14001: '/communicate/14001',
  COMMUNICATE_REPORTS: '/communicate/reports',
  COMMUNICATE: '/communicate',
  COMMUNICATE_GHG: '/communicate/ghg',
  COMMUNICATE_GLEC: '/communicate/glec',
  COMMUNICATE_WEB: '/communicate/web',
  COMMUNICATE_DOWNLOADABLES: '/communicate/downloadables',
  COMMUNICATE_MITECO: '/communicate/MITECO',
  SNIPPET: '/communicate/snippet'
};
