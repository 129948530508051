import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { useTranslation } from 'react-i18next';
import numberToDecimal from 'utils/numberToDecimal';
import { FROM_KG_TO_TON } from './hooks/useStationaryEmissions';
import StationaryEmissionsDisclaimer from './StationaryEmissionsDisclaimer';
import { Organization } from 'types/entities/organization';
import useStationaryEmissions from './hooks/useStationaryEmissions';
import { FacilityReport } from 'types/entities/facility';

type Props = {
  facilities: FacilityReport[];
  categories: string[];
  organization: Organization;
  organizationCo2e: number;
};

const StationaryEmissionsDetails = ({
  facilities,
  categories,
  organization,
  organizationCo2e
}: Props) => {
  const { t } = useTranslation();

  const {
    groupedFacilities,
    groupedFacilitiesCo2e,
    totalStationaryCo2e,
    totalFugitiveCo2e,
    fugitiveGases
  } = useStationaryEmissions({
    facilities,
    categories
  });

  return (
    <div className='flex-col gap-1'>
      <DescriptionBox
        descriptions={[
          t('ghgReport.stationaryEmissionsDescription.total', {
            total: `${numberToDecimal(totalStationaryCo2e / FROM_KG_TO_TON)}`,
            percentage: `${numberToDecimal((totalStationaryCo2e / organizationCo2e) * 100)}`,
            tradeName: organization.trade_name ?? ''
          }),
          totalFugitiveCo2e > 0
            ? t('ghgReport.stationaryEmissionsDescription.fugitive', {
                percentage: `${numberToDecimal((totalFugitiveCo2e / organizationCo2e) * 100)}`,
                gasName: fugitiveGases.map((gas) => t(`facility_fuels.${gas}`)).join(', ')
              })
            : t('ghgReport.stationaryEmissionsDescription.noFugitive'),
          t('ghgReport.stationaryEmissionsDescription.dirtiestFacility', {
            facilityName:
              groupedFacilities.length > 0 && groupedFacilitiesCo2e[0]
                ? groupedFacilitiesCo2e[0].name
                : ''
          })
        ]}
      />
      <StationaryEmissionsDisclaimer />
    </div>
  );
};

export default StationaryEmissionsDetails;
