import useTreatmentSector from 'customHooks/useTreatmentSector';
import { useTranslation } from 'react-i18next';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import { StepText } from 'types/stepCarousel';
import useRole from 'customHooks/useRole';

const useSteps = () => {
  const { permissions } = useRole();
  const { isTreatmentOrganization } = useTreatmentSector();

  const { t } = useTranslation();

  const stepsText: StepText[] = [
    {
      id: 'heat',
      text: t('facilityDetail.combustion'),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_COMBUSTION_ALL
      )
    },
    {
      id: 'recharge',
      text: t('facilityDetail.recharge'),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_RECHARGE_ALL
      )
    },
    {
      id: 'electricity',
      text: t('facilityDetail.electricity'),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_ELECTRICITY_ALL
      )
    },
    {
      id: 'water',
      text: t('facilityDetail.water'),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_WATER_ALL
      )
    },
    {
      id: 'wastes',
      text: t('facilityDetail.waste'),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_WASTE_ALL
      )
    }
  ];

  if (isTreatmentOrganization) {
    stepsText.push({ id: 'waste_water_treatment', text: t('facilityDetail.treatment') });
  }

  return { stepsText };
};

export default useSteps;
