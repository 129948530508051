import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';
import Tooltip from '../tooltip/Tooltip';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import CardContent from './CardContent';
import './styles.scss';
import useRole from 'customHooks/useRole';

type Props = {
  tooltipPosition: 'top' | 'right' | 'left';
  card: CardTooltip;
};
function Card({ tooltipPosition, card }: Props) {
  const { t } = useTranslation();

  const { roleName } = useRole();

  return (
    <TooltipWrapper
      text={t('general.blockedByRole', { role: roleName })}
      position={'top'}
      shouldAppear={card.blocked === true}
      style={{ height: 'auto', width: 'auto' }}
      delay={800}>
      <div
        id={card.id}
        className={`card ${
          card.disabled ? 'card-disabled-bg-color' : 'main-bg-color'
        } card-border-color ${card.blocked ? 'blocked' : ''}`}>
        <div className='card__tags corner-position'>
          {card.group.map((group) => (
            <TooltipWrapper
              text={t(`scopes.scope${group}`)}
              position='top'
              key={group}
              shouldAppear={!card.blocked}>
              <div
                className={`text-tag body3-font tag-bg-text-color ${
                  card.blocked ? 'blocked' : ''
                }`}>
                {typeof group === 'string'
                  ? t(`measureMain.${group}`)
                  : `${t('measureMain.scope')} ${group}`}
              </div>
            </TooltipWrapper>
          ))}
        </div>
        {card.blocked ? (
          <div className='tooltip'>
            <Tooltip text={card.tooltip} position={tooltipPosition} blocked={card.blocked} />
          </div>
        ) : (
          <Tooltip text={card.tooltip} position={tooltipPosition} blocked={card.blocked} />
        )}
        {(card.disabled || card.soon) && (
          <div className='coming-soon-tag subtitle3-font tag2-bg-text-color'>
            <img src='/images/icons/eyeWhite.svg' alt='eye' />
            {card.soon ?? t('measureMain.comingSoon')}
          </div>
        )}
        {(card.disabled || card.new) && (
          <div className='coming-soon-tag subtitle3-font tag2-bg-text-color'>
            <Icon icon='star' color='white' />
            {t('measureMain.new')}
          </div>
        )}
        <div className='card__content-wrapper'>
          {card.data.map((elem, index) => {
            return (
              <>
                {index === 1 && <div className='divider border-color' key={index} />}
                <CardContent data={elem} card={card} />
              </>
            );
          })}
        </div>
      </div>
    </TooltipWrapper>
  );
}

export default Card;
