import { InfiniteData } from '@tanstack/react-query';

export const adaptSuppliersBackToFront = (
  backendSuppliers: InfiniteData<BackendDMSupplierResponse, number>
): { total: number; suppliers: DMSupplier[] } => ({
  total: backendSuppliers.pages[0].total,
  suppliers: backendSuppliers.pages.flatMap(({ items: suppliers }) =>
    suppliers.map((supplier: BackendDMSupplier, idx: number) => ({
      id: idx.toString(),
      name: supplier.business_name,
      country: supplier.country,
      activity: supplier.code,
      purchases: supplier.quantity_percentage,
      highImportance: supplier.high_importance
    }))
  )
});
