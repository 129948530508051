import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/button/Button';
import { EmailConfirmation } from '../emailConfirmation/EmailConfirmation';
import LoaderLogin from 'components/ui/loaders/loaderLogin/LoaderLogin';
import { useSignUp } from './hooks/useSignUp';
import { useNavigate } from 'react-router-dom';
import { PasswordChecks } from '../passwordChecks/PasswordChecks';
import { InputPassword } from 'components/ui/formComponents2/inputPassword/InputPassword';

export const SignUp = () => {
  const { t } = useTranslation('', { keyPrefix: 'signUp' });

  const navigate = useNavigate();

  const {
    formData: { email, password },
    currentStep,
    steps,
    handleSubmitStep,
    registerField,
    isSubmitted,
    errors,
    loading
  } = useSignUp();

  if (currentStep === steps.loading) return <LoaderLogin />;

  if (currentStep === steps.emailConfirmation) {
    return (
      <EmailConfirmation
        email={email}
        handleConfirmCode={handleSubmitStep}
        inputParams={registerField('emailCode')}
        loading={loading}
        error={errors.emailCode}
      />
    );
  }

  return (
    <OnboardingImageWrapper title={t('title')}>
      <div className='w-80 pt-4'>
        <FormWrapper className='flex-col'>
          <InputWrapper label={t('email')} iconV2='user' error={errors.email}>
            <InputText {...registerField('email')} placeholder={t('email')} />
          </InputWrapper>
          <InputWrapper label={t('password')} iconV2='key' error={errors.password}>
            <InputPassword {...registerField('password')} placeholder={t('password')} />
          </InputWrapper>
          <InputWrapper label={t('confirmPassword')} iconV2='key' error={errors.confirmPassword}>
            <InputPassword
              {...registerField('confirmPassword')}
              placeholder={t('confirmPassword')}
            />
          </InputWrapper>
          <PasswordChecks isSubmitted={isSubmitted} password={password} />
        </FormWrapper>
        <FormButtonSection className='flex-col items-center gap-4'>
          <Button
            lookAndFeel='primary'
            text={t('createAccount')}
            size='medium'
            onClick={handleSubmitStep}
          />
          <div className='font-14'>
            {t('alreadyHaveAccount')}{' '}
            <Button className='font-14' lookAndFeel='link' onClick={() => navigate(ROUTES.SIGNIN)}>
              {t('signIn')}
            </Button>
          </div>
          <div className='flex row w-full'>
            <hr className='w-full mx-4 border-0 border-b-1 border-solid input-border-color' />
            <span className='text-gray-light'>{t('or')}</span>
            <hr className='w-full mx-4 border-0 border-b-1 border-solid input-border-color' />
          </div>
          <Button
            lookAndFeel='link-dark'
            text={t('signInSSO')}
            onClick={() => navigate(ROUTES.SIGNIN)}
          />
        </FormButtonSection>
      </div>
    </OnboardingImageWrapper>
  );
};
