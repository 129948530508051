import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useObtainInvestors } from './hooks/useInvestors';
import { useContext, useState } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import useDebounceValue from 'customHooks/useDebounceValue';
import { useColumns } from './hooks/useColumns';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import Modal from 'components/ui/modal/Modal';
import { ModalCreateOrEditInvestor } from './ModalCreateOrEditInvestor';
import { ModalDeleteInvestor } from './ModalDeleteInvestor';

export const DMInvestors = () => {
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const { t: t_notify } = useTranslation('', { keyPrefix: 'notification' });
  const dispatch = useDispatch();

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [investorToEdit, setInvestorToEdit] = useState<DMInvestor>();
  const [investorToDelete, setInvestorToDelete] = useState<DMInvestor>();
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceValue(searchValue);

  const columns = useColumns({
    setInvestorToEdit,
    setInvestorToDelete
  });
  const { data, fetchNextPage, isLoading } = useObtainInvestors({
    year,
    company_name: debounceSearchValue
  });
  const { investors, total } = data ?? {};

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <CardCTA>
        <CardCTA.Header>
          <span className='font-14 weight-600'>{t_dm('investorsTitle')}</span>
          <span className='font-12'>{t_dm('investorsSubtitle')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.investors.addInvestors')}
            size='small'
            onClick={() =>
              setInvestorToEdit({
                name: '',
                country: '',
                highImportance: false
              })
            }
          />
          <Button
            lookAndFeel={'primary'}
            text={t_dm('context.copyLinkQuestionnaire')}
            size='small'
            onClick={() => {
              navigator.clipboard.writeText(
                'https://docs.google.com/forms/d/e/1FAIpQLScR9QV2de1N-BLEjoyiy6Ak_vMeJMu8z012Wg3WLE0UZ8rhvA/viewform'
              );
              dispatch(setNotification(t_notify('copyOnBoard')));
            }}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <InfiniteList
        fetchData={fetchNextPage}
        data={investors ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        }
      />
      <Modal.WithPortal
        show={investorToEdit !== undefined}
        onClose={() => setInvestorToEdit(undefined)}>
        <ModalCreateOrEditInvestor
          investorData={investorToEdit}
          closeModal={() => setInvestorToEdit(undefined)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={investorToDelete !== undefined}
        onClose={() => setInvestorToDelete(undefined)}>
        <ModalDeleteInvestor
          investorData={investorToDelete}
          closeModal={() => setInvestorToDelete(undefined)}
        />
      </Modal.WithPortal>
    </div>
  );
};
