import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes from '../../../../constants/phonePrefixes';
import Select from '../select/Select';
import './styles.scss';

type Props = {
  icon: string;
  label: string;
  placeholder: string;
  value: string;
  valuePrefix: string;
  onChangeValue?: (phoneNumber: PhoneNumber) => void;
  error?: ErrorType;
  height?: string;
  size?: 'small' | 'big';
  fontClass?: string;
  showOnly?: boolean;
  disabled?: boolean;
};

const InputPhoneNumber = ({
  icon,
  label,
  placeholder,
  value,
  onChangeValue,
  error,
  valuePrefix,
  showOnly,
  height,
  size = 'small',
  disabled = false,
  fontClass = 'input-font'
}: Props) => {
  const { t, i18n } = useTranslation();
  const options = getPrefixes(i18n.resolvedLanguage)
    .map((prefix) => {
      return {
        id: prefix.code,
        name: prefix.dial_code
      };
    })
    .filter((elem) => elem.name !== '');

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) {
      onChangeValue({ phone: e.target.value, prefix: valuePrefix });
    }
  };

  const onChangePrefix = (prefix: SelectOptionFormat) => {
    if (onChangeValue) {
      onChangeValue({ phone: value, prefix: prefix.name });
    }
  };

  if (!height && size === 'small') {
    height = '33px';
  }
  if (!height && size === 'big') {
    height = '48.5px';
  }

  return (
    <div className='input'>
      <label className='input-label-font on-light-text-color'>{label}</label>
      <div
        className={`input__phone flex ${
          error ? 'error-border-color' : 'input-border-color'
        }  ${size} ${disabled ? 'input-disabled-styles' : ''}`}
        style={{ height }}>
        {icon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'} `}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        <Select
          value={{ id: valuePrefix, name: valuePrefix }}
          options={options}
          placeholder='+34'
          onChangeValue={onChangePrefix}
          type='basic'
          showOnly={showOnly}
          sort={false}
          size={size}
          fontClass={fontClass}
          height={height}
        />
        <div className='divider-line--phone' style={{ marginLeft: showOnly ? '0px' : '8px' }}></div>
        <input
          type='number'
          id='phone'
          name='phone'
          className={`input__phone__content on-light-text-color ${fontClass} ${
            disabled ? 'transparent-bg-color' : ''
          }`}
          pattern='[0-9]'
          value={value}
          placeholder={placeholder}
          onChange={onChangePhoneNumber}
        />
        {/* {error && (
          <img
            src='/images/icons/warningError.svg'
            alt='warning'
            className='error-icon'
          />
        )} */}
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputPhoneNumber;
