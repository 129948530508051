export type BaseData = {
  organization: string;
  data: string;
  department: string;
  frameworks: string[];
  assignedTo: string;
  status: 'COMPLETE' | 'WITH_DATA' | 'WITHOUT_DATA';
  source: string;
  evidence: string;
};

export const baseData: BaseData[] = [
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Datos de empleados generales (sexo, salario, puesto de trabajo, categoría, colectivo, etc.)',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Datos de accidentes y bajas laborales de empleados',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITH_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Compras de la empresa',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Luis C.',
    status: 'WITH_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Consumos de electricidad (facturas)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Residuos generados en tus instalaciones',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Combustión utilizada en tus instalaciones (ej. facturas de gas natural)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Consumos de vehículos que están a nombre de tu empresa (propios o renting)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Recargas de gases en tus instalaciones (ej. equipos de refrigeración y extintores)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Electricidad consumida en tus instalaciones (facturas de luz)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Datos de mercancías que tu empresa ha recibido',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Luis C.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Desplazamientos en los viajes de negocio con vehículos que no son de tu empresa',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Luis C.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Desplazamiento de tus empleados a su lugar de trabajo',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Elena R.',
    status: 'WITH_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Datos de mercancías que tu empresa ha enviado',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Donald T.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Selecciona una métrica de intensidad',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Agua consumida en tus instalaciones',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Datos de formación de empleados',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'KPIs de seguimiento de las políticas',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Riesgos asociados a las actividades del grupo',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas destinadas a facilitar el disfrute de la conciliación y fomentar el ejercicio corresponsable de estos por parte de ambos progenitores',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Condiciones de salud y seguridad en el trabajo desagregado por sexo',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Organización del diálogo social, incluyendo procedimientos para informar y consultar al personal y negociar con ellos',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Políticas implementadas en el campo de la formación',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITH_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Accesibilidad universal de las personas con discapacidad',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITH_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas adoptadas para promover la igualdad de trato y de oportunidades entre mujeres y hombres',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITH_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Planes de igualdad',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas adoptadas para promover el empleo, protocolos contra el acoso sexual y por razón de sexo',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Protocolos contra el acoso sexual y por razón de sexo',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Integración y la accesibilidad universal de las personas con discapacidad',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Política contra todo tipo de discriminación y, en su caso, de gestión de la diversidad',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en el medio ambiente, la salud y seguridad',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en la salud y seguridad',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Los procedimientos de evaluación o certificación ambiental',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Los recursos dedicados a la prevención de riesgos ambientales',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'La aplicación del principio de precuación',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'La cantidad de provisiones y garantías para riesgos ambientales',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas para prevenir, reducir, o reparar las emisiones de carbono que afectan gravemente el medio ambiente: teniendo en cuenta cualquier forma de contaminación atmosférica especifica de una actividad, incluyendo ruido y contaminación lumínica',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas de prevención, reciclaje, reutilización, otras formas de recuperación y eliminación de desechos',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Acciones para combatir el desperdicio de alimentos',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Los elementos importantes de las emisiones de gases de efecto invernadero generados como resultado de las actividades de la empresa, incluyendo el uso de los bienes y servicios producidos',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas adoptadas para adaptarse a las consecuencias del cambio climático',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Las metas de reducción establecidas voluntariamente a medio y largo plazo para reducir las emisiones de gases de efecto invernadero y los medios implementados para tal fin',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Rellena los datos de la persona de contacto',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Completa la descripción de la empresa',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Límites de la organización (ubicaciones principales, justificación de inclusión o exclusión de instalaciones, etc.)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Enfoque de contabilidad GEI (operacional, financiero o de participación accionaria)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Introduce el año base (año oficial de referencia)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Selecciona tu tipo de verificación (Ej.: EQA)',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Elige qué alcances quieres incluir y excluir del reporte',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Añade una justificación para los alcances elegidos y excluidos',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Notas adicionales para incluir en el informe que respalden tu verificación',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'En caso de cambio en tu año base, añade una explicación',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD', 'HdC'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas adoptadas para prevenir la corrupción y el soborno',
    department: 'Legal',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Carmela B.',
    status: 'WITH_DATA',
    source: 'Drive',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Aplicación de procedimientos de diligencia debida en materia de derechos humanos',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Luis C.',
    status: 'WITH_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Prevención de los riesgos de vulneración de derechos humanos y, en su caso, medidas para mitigar, gestionar y reparar posibles abusos cometidos',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Luis C.',
    status: 'WITH_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Denuncias por casos de vulneración de derechos humanos',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Promoción y cumplimiento de las disposiciones de los convenios fundamentales de la Organización Internacional del Trabajo relacionadas con el respeto por la libertad de asociación y el derecho a la negociación colectiva',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'La eliminación de la discriminación en el empleo y la ocupación',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'La eliminación del trabajo forzoso u obligatorio; la abolición efectiva del trabajo infantil',
    department: 'RRHH',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Elena R.',
    status: 'WITHOUT_DATA',
    source: 'Workday',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Información relativa a la lucha contra la corrupción y el soborno',
    department: 'Legal',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Carmela B.',
    status: 'WITHOUT_DATA',
    source: 'Drive',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas para luchar contra el blanqueo de capitales',
    department: 'Legal',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Carmela B.',
    status: 'WITHOUT_DATA',
    source: 'Drive',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Aportaciones a fundaciones y entidades sin ánimo de lucro',
    department: 'Financiero',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Sandra F.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'El impacto de la actividad de la sociedad en el empleo y el desarrollo local',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'El impacto de la actividad de la sociedad en las poblaciones locales y en el territorio',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Las relaciones mantenidas con los actores de las comunidades locales y las modalidades del diálogo con estos; las acciones de asociación o patrocinio',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Raquel S.',
    status: 'WITH_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'La inclusión en la política de compras de cuestiones sociales, de igualdad de género y ambientales',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Luis C.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Consideración en las relaciones con proveedores y subcontratistas de su responsabilidad social y ambiental; sistemas de supervisión y auditorías y resultados de las mismas',
    department: 'Compras',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Luis C.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Medidas para la salud y la seguridad de los consumidores; sistemas de reclamación, quejas recibidas y resolución de las mismas',
    department: 'Sostenibilidad',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Donald T.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Los beneficios obtenidos país por país',
    department: 'Financiero',
    frameworks: ['EINF'],
    assignedTo: 'Sandra F.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Los impuestos sobre beneficios pagados',
    department: 'Financiero',
    frameworks: ['EINF'],
    assignedTo: 'Sandra F.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Las subvenciones públicas recibidas',
    department: 'Financiero',
    frameworks: ['EINF'],
    assignedTo: 'Sandra F.',
    status: 'WITHOUT_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Justificación de ausencia de políticas.',
    department: 'Sostenibilidad',
    frameworks: ['EINF'],
    assignedTo: 'Raquel S.',
    status: 'WITHOUT_DATA',
    source: 'Excel',
    evidence: 'Sin evidencias'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    data: 'Detalles sobre los importes detallados en las cuentas anuales consolidadas',
    department: 'Financiero',
    frameworks: ['EINF', 'CSRD'],
    assignedTo: 'Sandra F.',
    status: 'WITH_DATA',
    source: 'SAP',
    evidence: 'Sin evidencias'
  }
];
