import { useQuery } from '@tanstack/react-query';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { getPermissions } from 'services/api/roles';

const categoriesOrder = ['carbon_footprint', 'csrd', 'general'];

const usePermissions = () => {
  const organization = useSelectedOrganization();
  const { frameworkPlans } = useFrameworksPlans(organization?.id);

  const visibleCategories = ['carbon_footprint', 'general'];

  // only show csrd category if the framework is available:
  const foundCsrdPurchasedPlan = frameworkPlans?.find(
    (framework) => framework.framework.name === 'csrd' && framework.purchased
  );
  if (foundCsrdPurchasedPlan) visibleCategories.push('csrd');

  const { data, isLoading } = useQuery({
    queryKey: ['roles-permissions'],
    queryFn: async () => {
      const response = await getPermissions();
      return response;
    },
    select: (data) => {
      return data.items.sort((a: Permission, b: Permission) => a.id - b.id);
    }
  });

  const permissions: Permission[] = data ?? [];

  // Dictionary with key: categories and value: array of permissions
  // where categories are sorted in categoriesOrder
  const permissionsByCategory: { [key: string]: Permission[] } = {};
  permissions
    .sort((a: Permission, b: Permission) => {
      return categoriesOrder.indexOf(a.category) - categoriesOrder.indexOf(b.category);
    })
    .forEach((permission) => {
      if (!visibleCategories.includes(permission.category)) return;
      if (!permissionsByCategory[permission.category]) {
        permissionsByCategory[permission.category] = [];
      }
      permissionsByCategory[permission.category].push(permission);
    });

  return { permissionsByCategory, isLoading };
};

export default usePermissions;
