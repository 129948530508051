import { useTranslation } from 'react-i18next';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import useCustomEFDetails from './hooks/useCustomEFDetails';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import Modal from '../../../ui/modal/Modal';
import AddForm from './components/addForm/AddForm';
import EditForm from './components/editForm/EditForm';
import DeleteModal from '../../../ui/formComponents2/deleteModal/DeleteModal';
import { deleteCustomEFDetail } from '../../../../services/api/customEFDetails';
import { useLocation, useParams } from 'react-router-dom';

const CustomEFDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize' });

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const {
    fetchData,
    firstLoading,
    loading,
    total,
    data,
    columns,
    showAddModal,
    elementToEdit,
    elementToDelete,
    handleShowAddModal,
    handleHideAddModal,
    handleHideEdit,
    handleHideDelete,
    handleAddElement,
    handleEditElement,
    handleDeleteElement
  } = useCustomEFDetails({ groupId: id });

  return (
    <MainPageLayout
      sectionTitle={t('title')}
      title={t('start')}
      description={t('startDescription')}
      breadcrumb={<Breadcrumb crumbsReplace={id && name ? [{ key: id, value: name }] : []} />}
      buttons={
        <div className='buttons'>
          <Button
            lookAndFeel={'primary'}
            text={t('add')}
            size='small'
            onClick={handleShowAddModal}
          />
        </div>
      }>
      <InfiniteList
        fetchData={fetchData}
        data={data}
        columns={columns}
        loading={loading}
        firstLoading={firstLoading}
        total={total}
        header={
          <div className='table-tags' style={{ marginLeft: 'auto' }}>
            <TotalLegend total={total} loading={loading} i18key={'customize'} />
          </div>
        }
      />

      <Modal show={showAddModal} onClose={handleHideAddModal} maxWidth='613px' width='613px'>
        <AddForm groupId={id} addElement={handleAddElement} />
      </Modal>

      <Modal show={!!elementToEdit} onClose={handleHideEdit} maxWidth='613px' width='613px'>
        <EditForm editElement={handleEditElement} id={elementToEdit} />
      </Modal>

      <Modal show={!!elementToDelete} onClose={handleHideDelete} maxWidth='613px' width='613px'>
        <DeleteModal
          id={elementToDelete}
          handleDelete={handleDeleteElement}
          onClose={handleHideDelete}
          deleteElement={(idDetail: string) => deleteCustomEFDetail(idDetail, id || '')}
        />
      </Modal>
    </MainPageLayout>
  );
};

export default CustomEFDetails;
