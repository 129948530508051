import { useState } from 'react';
import CheckboxMultiple from '../formComponents/checkboxMultiple/CheckboxMultiple';
import TooltipCard from '../tooltip/TooltipCard';
import './styles.scss';
import TooltipWrapper from '../tooltip/TooltipWrapper';

type CardSize = 'small' | 'medium' | 'big';

type Props = {
  card: {
    icon: string;
    id: string;
    title: string;
    disabled?: boolean;
    tooltip?: string;
    checkbox?: boolean;
  };
  onClickCard: (id: string) => void;
  selected: boolean;
  size?: CardSize;
  error?: ErrorType;
  tooltip?: string;
};

function SelectCard({ card, onClickCard, selected, size, error }: Props) {
  const handleOnClickCard = () => {
    if (!card.disabled) {
      onClickCard(card.id);
    }
  };

  return (
    <TooltipWrapper
      text={card.tooltip}
      position='top'
      shouldAppear={!!card.tooltip}
      delay={card.disabled ? 800 : undefined}>
      <div
        className={`select-card ${selected ? 'select-card--selected' : ''} ${size} ${
          error ? 'error-border-color' : ''
        } ${card.disabled ? 'select-card--disabled' : ''}`}
        key={card.id}
        onClick={handleOnClickCard}>
        {card.icon && <img src={card.icon} alt={card.id} />}
        {card.checkbox ? (
          <div className='checkbox-wrapper'>
            <CheckboxMultiple
              id={card.title}
              type='square'
              selected={selected}
              colour={!selected ? 'gray' : undefined}
              onSelect={(id: string) => id}
              onRemove={(id: string) => id}
            />
            <span
              className={`${
                size === 'small' ? 'select-card-small-font' : 'select-card-font'
              } on-light-text-color`}>
              {card.title}
            </span>
          </div>
        ) : (
          <span
            className={`${
              size === 'small' ? 'select-card-small-font' : 'select-card-font'
            } on-light-text-color`}>
            {card.title}
          </span>
        )}
      </div>
    </TooltipWrapper>
  );
}

export default SelectCard;
