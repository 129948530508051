import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import useDebounceValue from 'customHooks/useDebounceValue';
import { useEffect, useState } from 'react';
import { IconName } from 'types/utils/iconName';

type ScoreInputProps = {
  subtopicId: string;
  score: string | null;
  onChangeScore: (subtopicId: string, score: number) => void;
  icon: IconName;
};

export const ScoreInput = ({ subtopicId, score, onChangeScore, icon }: ScoreInputProps) => {
  const parsedScore = typeof score == 'string' ? Number(score.replace(',', '.')) : undefined;
  const [newScore, setNewScore] = useState<number | undefined>(parsedScore);
  const debouncedScore = useDebounceValue<number | undefined>(newScore, 300);

  useEffect(() => {
    if (debouncedScore !== null && debouncedScore !== parsedScore && debouncedScore === newScore) {
      onChangeScore(subtopicId, debouncedScore ?? 0);
    }
  }, [debouncedScore]);

  return (
    <InputWrapper iconV2={icon} className='w-20'>
      <InputNumber
        placeholder='0'
        value={newScore}
        onlyIntegers
        decimals={0}
        onChange={(value) => {
          setNewScore(Number(value));
        }}
        max={100}
      />
    </InputWrapper>
  );
};
