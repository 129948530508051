import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../../actions/notification';
import {
  CreateDatapoinSourceDatatBody,
  createDatapointSourceData,
  UpdateDatapoinSourceDatatBody,
  updateDatapointSourceData
} from 'services/api/sotOrganizationData';
import { queryClient } from 'lib/ReactQuery/client';

interface useAddDatapoinSourceDataProps {
  datapointData: CreateDatapoinSourceDatatBody | UpdateDatapoinSourceDatatBody;
  id?: string;
}

export const useAddDatapointOutputData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ id, datapointData }: useAddDatapoinSourceDataProps) => {
      return id
        ? updateDatapointSourceData(id, datapointData as UpdateDatapoinSourceDatatBody)
        : createDatapointSourceData(datapointData as CreateDatapoinSourceDatatBody);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['datapointOutputData'] });
      queryClient.invalidateQueries({ queryKey: ['csrdOutputData'] });
      dispatch(setNotification(t('notification.updateDatapoint')));
    },
    onError: () => {
      dispatch(setNotification(t('notification.errorUpdateDatapoint')));
    }
  });
};
