import { z } from 'zod';

export const buildPasswordValidator = ({
  errorTranslation,
  email,
  fieldName = 'password'
}: {
  errorTranslation: (key: string) => string;
  email?: string;
  fieldName?: string;
}) =>
  z
    .string()
    .min(8, { message: errorTranslation('minEight') })
    .refine((value) => /[a-z]/.test(value), { message: errorTranslation('minLowercase') })
    .refine((value) => /[A-Z]/.test(value), { message: errorTranslation('minUppercase') })
    .refine((value) => /[0-9]/.test(value), { message: errorTranslation('minNumber') })
    .refine((value) => /[^a-zA-Z0-9]/.test(value), { message: errorTranslation('minSpecial') })
    .superRefine((password, ctx) => {
      if (!email) {
        return;
      }
      const emailUsername = email.split('@')[0];
      const emailDomain = email.split('@')[1].split('.')[0];
      if (password.includes(emailUsername) || password.includes(emailDomain)) {
        ctx.addIssue({
          code: 'custom',
          message: errorTranslation('passwordContainsUsername'),
          path: [fieldName]
        });
      }
    });
