import { useDividedConsumptionTagStatus } from '../../../createInvoice/components/common/ConsumptionPercentages';
import { RDLineType } from '../types/RDLine';
import { getValue } from '../utils/getValue';

type UseRDLineStatusParams = {
  lines: RDLineType[];
};

export const useRDLineStatus = ({ lines }: UseRDLineStatusParams) => {
  const { status, totalValue } = useDividedConsumptionTagStatus({ lines, getValue });

  return { status, totalValue };
};
