import { useTranslation } from 'react-i18next';
import { DOUBLE_MATERIALITY_ARTICLE } from 'constants/index';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import SectionHeader from 'components/layout/sectionHeader/SectionHeader';
import Button from 'components/ui/button/Button';
import HelpCTA from 'components/ui/helpCTA/HelpCTA';
import StepCarouselList from 'components/ui/stepCarouselList/StepCarouselList';
import useStepCarousel from 'components/ui/stepCarouselList/useStepCarousel';
import { useSteps } from './hooks/useSteps';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTES } from 'constants/routes';

const DOUBLE_MATERIALITY_ROUTE = 'double_materiality';

const DoubleMateriality = () => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const allSteps = useSteps();
  const location = useLocation();
  const navigate = useNavigate();

  const [initialStep, nestedRoute] = location.pathname
    .split(DOUBLE_MATERIALITY_ROUTE)[1]
    .replace(/^\/+|\/+$/g, '') // remove leading and trailing slashes
    .split('/');

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: allSteps,
    selected: initialStep
  });

  useEffect(() => {
    if (!stepSelected) return;
    navigate(
      `${ROUTES.MEASURE_ESG_METRICS}/${DOUBLE_MATERIALITY_ROUTE}/${stepSelected.id ?? ''}/${
        stepSelected.id == 'stakeholders' && nestedRoute ? nestedRoute : ''
      }`
    );
  }, [stepSelected]);

  const handleGoToArticle = () => {
    window.open(DOUBLE_MATERIALITY_ARTICLE['es'], '_blank');
  };

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('title')} breadcrumb={<Breadcrumb />} />
      <div className='flex justify-between'>
        <StepCarouselList
          steps={steps}
          handleSelect={(id) => {
            handleSelect(id);
          }}
        />
        <HelpCTA description={t('helpDM')}>
          <div className='flex justify-between'>
            <Button
              lookAndFeel='primary'
              text={t('helpDMButton')}
              size='tiny'
              onClick={handleGoToArticle}
              style={{ marginLeft: '0.3rem' }}
            />
          </div>
        </HelpCTA>
      </div>
      <Outlet />
    </section>
  );
};

export default DoubleMateriality;
