import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { FreeTrialContext } from '../../../../context/freeTrialContext';
import { UserContext } from '../../../../context/userContext';
import { checkFreePlan } from '../../../../utils/checkFreePlan';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import './styles.scss';

function KeepImproving() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const freeTrialContext = useContext(FreeTrialContext);
  const user = useContext(UserContext);

  const organization = user?.organizations?.find((org) => org.id === user?.selectedOrganization);

  const navigateToTasks = () => {
    if (checkFreePlan(organization)) {
      freeTrialContext.setShowPremiumFeatureModal(true);
    } else {
      navigate(ROUTES.TODOS);
    }
  };

  let percentageTasksCompleted =
    user?.tasks?.total_completed && user?.tasks.total
      ? (user?.tasks.total_completed / user?.tasks.total) * 100
      : undefined;

  let totalPending = 0;

  if (user?.tasks?.total_completed === 0 && user?.tasks?.total === 0) {
    percentageTasksCompleted = undefined;
  }

  if (
    user?.tasks?.total !== undefined &&
    user?.tasks?.total > 0 &&
    user?.tasks?.total_completed === 0
  ) {
    percentageTasksCompleted = 0;
  }

  if (user?.tasks?.total !== undefined && user?.tasks?.total_completed !== undefined) {
    totalPending = user?.tasks?.total - user?.tasks?.total_completed;
  }

  return (
    <div className='dashboard-card card'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>{t('dashboard.tasks')}</h4>
      </div>
      <div className='improving-cards'>
        <div className='improve-card on-card-gray-bg-color'>
          <div className='improve-card-first-section'>
            <h4 className='headline4-font on-light-text-color'>{t('dashboard.tasksTitle')}</h4>
            <p className='subtitle3-font on-light-text-color'>{t('dashboard.tasksDescription')}</p>
          </div>
          <div className='improve-card-second-section'>
            {percentageTasksCompleted !== undefined && (
              <div className='percentage-wrapper'>
                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.25rem' }}>
                  <div className='percentage-big-font highlight-text-color'>
                    {numberToDecimal(percentageTasksCompleted)}%
                  </div>
                  <span
                    className='subtitle3-font on-light-text-color'
                    style={{ marginBottom: '0.3rem' }}>
                    {t('dashboard.progress')}
                  </span>
                </div>
                <span
                  className='small-card-font on-light-text-color'
                  style={{ marginBottom: '0.3rem' }}>
                  {totalPending} {t('header.pending')}
                </span>
              </div>
            )}
          </div>
          <Button
            lookAndFeel='primary'
            text={t('dashboard.create')}
            onClick={navigateToTasks}
            size='small'
          />
        </div>
      </div>
    </div>
  );
}

export default KeepImproving;
