import { useEffect, useRef } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: SelectOptionFormat;
  onClickOption?: (option: any) => void;
  isSelected?: boolean;
  size?: InputSize;
}

const Option = ({ value, onClickOption, isSelected, size, ...rest }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    if (value.blocked) return;
    if (onClickOption) onClickOption(value);
  };

  useEffect(() => {
    if (!isSelected || !ref?.current) return;
    ref.current.scrollIntoView({
      behavior: 'auto', // You can use 'auto' or 'smooth' for the scrolling behavior
      block: 'center' // You can use 'start', 'center', or 'end' to specify where the element should be aligned
    });
  }, []);

  const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';

  return (
    <div
      ref={ref}
      className={`select-options__item on-light-text-color  ${
        value.blocked ? 'blocked' : isSelected ? 'active' : ''
      } ${sizeClassName}`}
      id={value.id}
      onClick={handleOnClick}
      {...rest}>
      {value.blocked && (
        <TooltipWrapper text={value.tooltip} position='right' delay={800}>
          <span className='flex items-center gap-x-1'>
            {value.name}
            <Icon icon='lock' color='gray-warm' size='small' />
          </span>
        </TooltipWrapper>
      )}
      {!value.blocked && <>{value.name}</>}
    </div>
  );
};

export default Option;
