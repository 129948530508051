import { useAuth as useClerkAuth } from '@clerk/clerk-react';
import { TutorialContext } from 'components/layout/interactiveTutorial/mainTutorial/InteractiveTutorial';
import { ACADEMY_URL } from 'constants/index';
import { PRIVACY_POLICY_URL } from 'constants/index';
import { ROUTES } from 'constants/routes';
import useRole from 'customHooks/useRole';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authLogout } from 'services/authService';

type Subsection = {
  id: string;
  name: string;
  onClick: () => void;
};
type Section = {
  icon: string;
  text: string;
  onClick: () => void;
  subSections: Subsection[];
  conditionSubSections: boolean;
  hide?: boolean;
};
type Props = {
  handleOnClickProfile: () => void;
};
const useSections = ({ handleOnClickProfile }: Props) => {
  const { signOut: signOutClerk } = useClerkAuth();
  const { t, i18n } = useTranslation();
  const { isFundRole } = useRole();

  const navigate = useNavigate();
  const [showLanguages, setShowLanguages] = useState(false);

  const tutorial = useContext(TutorialContext);

  const navigateToProfile = () => {
    navigate(ROUTES.PROFILE, { replace: true });
    handleOnClickProfile();
  };

  const navigateToMembers = () => {
    navigate(ROUTES.MEMBERS, { replace: true });
    handleOnClickProfile();
  };

  const logout = () => {
    authLogout();
    signOutClerk();
  };

  const onClickChangeLang = () => {
    setShowLanguages(!showLanguages);
  };

  const navigateToLegal = () => {
    window.open(PRIVACY_POLICY_URL);
  };

  const navigateToTutorials = () => {
    type AcademyUrl = {
      [key: string]: string;
    };

    const academyUrl: AcademyUrl = ACADEMY_URL;
    let url = academyUrl.en;
    if (academyUrl[i18n.resolvedLanguage]) {
      url = academyUrl[i18n.resolvedLanguage];
    }

    window.open(url);
  };

  const handleShowInteractiveTutorial = () => {
    navigate(ROUTES.IMPROVE_DASHBOARD, { replace: true });
    tutorial?.handleStart();
    handleOnClickProfile();
  };

  const sections: Section[] = [
    {
      icon: '/images/icons/person.svg',
      text: t('header.myProfile'),
      onClick: navigateToProfile,
      subSections: [],
      conditionSubSections: false,
      hide: isFundRole
    },
    {
      icon: '/images/icons/people.svg',
      text: t('header.members'),
      onClick: navigateToMembers,
      subSections: [],
      conditionSubSections: false,
      hide: isFundRole
    },
    {
      icon: '/images/icons/academy.svg',
      text: t('header.tutorials'),
      onClick: navigateToTutorials,
      subSections: [],
      conditionSubSections: false
    },
    {
      icon: '/images/icons/language.svg',
      text: t('header.changeLanguage'),
      onClick: onClickChangeLang,
      subSections: [
        { id: 'en', name: t('languages.en'), onClick: () => i18n.changeLanguage('en') },
        { id: 'es', name: t('languages.es'), onClick: () => i18n.changeLanguage('es') },
        { id: 'ca', name: t('languages.ca'), onClick: () => i18n.changeLanguage('ca') },
        { id: 'pt', name: t('languages.pt'), onClick: () => i18n.changeLanguage('pt') }
      ],
      conditionSubSections: showLanguages
    },
    {
      icon: '/images/icons/book.svg',
      text: t('header.legal'),
      onClick: navigateToLegal,
      subSections: [],
      conditionSubSections: false
    },
    {
      icon: '/images/icons/person.svg',
      text: t('header.showTutorial'),
      onClick: handleShowInteractiveTutorial,
      subSections: [],
      conditionSubSections: false,
      hide: isFundRole
    },
    {
      icon: '/images/icons/logout.svg',
      text: t('header.logout'),
      onClick: logout,
      subSections: [],
      conditionSubSections: false
    }
  ];
  return sections;
};

export default useSections;
