import { useMutation } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import { queryClient } from 'lib/ReactQuery/client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { assignDatapointToUser } from 'services/api/sotOrganizationData';
import { createTask, editTask } from 'services/api/tasks';
import { IFrontendFormTask } from 'types/entities/task';

type UseAssignTaskProps = {
  datapoint: FrameworkDatapoint;
  taskId?: string;
  taskData: IFrontendFormTask;
};

export const useAssignTask = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ datapoint, taskId, taskData }: UseAssignTaskProps) => {
      const task = await (taskId ? editTask(taskData, taskId) : createTask(taskData));
      await assignDatapointToUser(datapoint.id, task.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['datapointOutputData'] });
      queryClient.invalidateQueries({ queryKey: ['csrdOutputData'] });
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      dispatch(setNotification(t('notification.updateDatapoint')));
    },
    onError: () => {
      dispatch(setNotification(t('notification.errorUpdateDatapoint')));
    }
  });
};
