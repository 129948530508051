import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import Button from 'components/ui/button/Button';
import { useTranslation } from 'react-i18next';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { PasswordChecks } from '../passwordChecks/PasswordChecks';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useCreateNewPassword } from './useCreateNewPassword';
import { InputPassword } from 'components/ui/formComponents2/inputPassword/InputPassword';

export const CreateNewPassword = () => {
  const { t } = useTranslation('', { keyPrefix: 'changePassword' });

  const {
    register,
    errors,
    isSubmitted,
    formData: { password },
    handleSubmit,
    email
  } = useCreateNewPassword();

  return (
    <OnboardingImageWrapper
      title={t('title')}
      subtitle={t('subtitle').replace('{{email}}', String(email))}>
      <div className='w-80'>
        <FormWrapper className='flex-col'>
          <InputWrapper
            label={t('verificationCode')}
            iconV2='user'
            error={adaptHookFormError(errors.emailCode)}>
            <InputText {...register('emailCode')} placeholder={t('verificationCode')} />
          </InputWrapper>
          <InputWrapper
            label={t('password')}
            iconV2='key'
            error={adaptHookFormError(errors.password)}>
            <InputPassword {...register('password')} placeholder={t('password')} />
          </InputWrapper>
          <InputWrapper
            label={t('confirmPassword')}
            iconV2='key'
            error={adaptHookFormError(errors.confirmPassword)}>
            <InputPassword {...register('confirmPassword')} placeholder={t('confirmPassword')} />
          </InputWrapper>
          <PasswordChecks isSubmitted={isSubmitted} password={password} />
        </FormWrapper>
        <FormButtonSection className='flex-col items-center gap-2' style={{ marginTop: '1em' }}>
          <Button lookAndFeel='primary' text={t('startNow')} size='medium' onClick={handleSubmit} />
        </FormButtonSection>
      </div>
    </OnboardingImageWrapper>
  );
};
