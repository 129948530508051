import { Card } from 'components/layout/Cards/Cards';
import { DM_CONTEXT_CARDS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const DMStakeholders = () => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const navigate = useNavigate();

  return (
    <Card.Container cardSizeInRem={11.25}>
      {DM_CONTEXT_CARDS.map((card) => (
        <button key={card.title} onClick={() => navigate(card.id)}>
          <Card.Layout key={card.title} type='item'>
            <Card.Icon icon={card.icon} />
            <Card.Title text={t(card.title)} />
          </Card.Layout>
        </button>
      ))}
    </Card.Container>
  );
};
