import React, { Dispatch, SetStateAction } from 'react';
import {
  FormSocialData,
  OptionalQuestion,
  OptionalQuestionResponse
} from '../../../../../types/entities/socialData';
import { useTranslation } from 'react-i18next';
import { TOOLTIP_LIST } from '../../../../../constants/tooltipQuestions';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import InputHeaderWrapper from '../../../../ui/formComponents2/inputUtils/inputHeaderWrapper/InputHeaderWrapper';
import useRole from 'customHooks/useRole';

type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
  question: OptionalQuestion;
};
const QuestionDoubleFloat = ({ formData, setFormData, question }: Props) => {
  const { t } = useTranslation();
  const { isFundRole: disabled } = useRole();

  return (
    <InputWrapper
      tooltip={
        TOOLTIP_LIST.includes(question.name)
          ? t(`funds.questionsTooltip.${question.name}`)
          : undefined
      }
      icon='/images/icons/catalog.svg'
      label={t(`funds.questions.${question.name}_1`)}
      error={formData.errors.find((error) => error.error === question.id)}
      disabled={disabled}>
      <InputNumber
        placeholder={t('socialAndGovernance.enterQuantity')}
        value={
          (formData.optionalQuestions?.find(
            (element: OptionalQuestionResponse) => element.id === question.id
          )?.response[0] as string) ?? ''
        }
        maxLength={10}
        onChange={(value: string) => {
          const foundQuestionIndex =
            formData.optionalQuestions?.findIndex(
              (element: OptionalQuestionResponse) => element.id === question.id
            ) ?? -1;
          if (foundQuestionIndex === -1 || !formData.optionalQuestions) {
            const prevOptionalQuestions = formData.optionalQuestions
              ? [...formData.optionalQuestions]
              : [];

            prevOptionalQuestions.push({
              id: question.id,
              type: question.type,
              response: [value, '']
            });
            setFormData((prev) => ({
              ...prev,
              optionalQuestions: [...prevOptionalQuestions],
              errors: formData.errors.filter((error) => error.error !== question.id)
            }));
            return;
          }
          // update question value
          const newOptionalQuestions = [...formData.optionalQuestions];
          newOptionalQuestions[foundQuestionIndex] = {
            id: question.id,
            type: question.type,
            response: [value, newOptionalQuestions[foundQuestionIndex].response[1]]
          };
          setFormData((prev) => ({
            ...prev,
            optionalQuestions: newOptionalQuestions,
            errors: formData.errors.filter((error) => error.error !== question.id)
          }));
        }}
      />
      <InputHeaderWrapper label={t(`funds.questions.${question.name}_2`)}>
        <InputNumber
          placeholder={t('socialAndGovernance.enterQuantity')}
          value={
            (formData.optionalQuestions?.find(
              (element: OptionalQuestionResponse) => element.id === question.id
            )?.response[1] as string) ?? ''
          }
          maxLength={10}
          onChange={(value: string) => {
            const foundQuestionIndex =
              formData.optionalQuestions?.findIndex(
                (element: OptionalQuestionResponse) => element.id === question.id
              ) ?? -1;
            if (foundQuestionIndex === -1 || !formData.optionalQuestions) {
              const prevOptionalQuestions = formData.optionalQuestions
                ? [...formData.optionalQuestions]
                : [];
              prevOptionalQuestions.push({
                id: question.id,
                type: question.type,
                response: ['', value]
              });
              setFormData((prev) => ({
                ...prev,
                optionalQuestions: [...prevOptionalQuestions],
                errors: formData.errors.filter((error) => error.error !== question.id)
              }));
              return;
            }
            // update question value
            const newOptionalQuestions = [...formData.optionalQuestions];
            newOptionalQuestions[foundQuestionIndex] = {
              id: question.id,
              type: question.type,
              response: [newOptionalQuestions[foundQuestionIndex].response[0], value]
            };
            setFormData((prev) => ({
              ...prev,
              optionalQuestions: newOptionalQuestions,
              errors: formData.errors.filter((error) => error.error !== question.id)
            }));
          }}
        />
      </InputHeaderWrapper>
    </InputWrapper>
  );
};

export default QuestionDoubleFloat;
