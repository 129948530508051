import { useEffect, useState } from 'react';
import Select from '../select/Select';
import moment from 'moment';
import { formatDate } from 'utils/formatDate';
import { useTranslation } from 'react-i18next';
import { convertStringToDate } from 'utils/convertDates';

const normalizeDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const isLastYear = (startDate: Date, endDate: Date) => {
  const lastYearStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
  const lastYearEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
  return (
    startDate.getTime() === lastYearStartDate.getTime() &&
    endDate.getTime() === lastYearEndDate.getTime()
  );
};

const isLast3Months = (startDate: Date, endDate: Date) => {
  const last3Months = new Date();
  last3Months.setMonth(last3Months.getMonth() - 3);
  return (
    startDate.getMonth() === last3Months.getMonth() &&
    startDate.getDate() === endDate.getDate() &&
    endDate.getMonth() === new Date().getMonth()
  );
};

const isLast30Days = (startDate: Date, endDate: Date) => {
  const last30Days = new Date();
  last30Days.setDate(last30Days.getDate() - 30);
  return startDate.getDate() === last30Days.getDate() && endDate.getDate() === new Date().getDate();
};

const isThisYear = (startDate: Date, endDate: Date) => {
  const thisYearStartDate = new Date(new Date().getFullYear(), 0, 1);
  const thisYearEndDate = new Date(new Date().getFullYear(), 11, 31);
  return (
    startDate.getTime() === thisYearStartDate.getTime() &&
    endDate.getTime() === thisYearEndDate.getTime()
  );
};

const isAllTime = (startDate: Date, endDate: Date) => {
  const allTimeStartDate = new Date(0);
  const allTimeEndDate = new Date();
  return (
    startDate.getTime() === allTimeStartDate.getTime() &&
    endDate.getTime() === allTimeEndDate.getTime()
  );
};

export enum DateOptions {
  ALL = 'ALL',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  CUSTOM = 'CUSTOM'
}

type Props = {
  handleOnChangeStartDate: (value: string) => void;
  handleOnChangeEndDate: (value: string) => void;
  startDate: string;
  endDate: string;
  setOpenCustom: (value: boolean) => void;
  options?: DateOptions[];
};

const InputDatePredefined = ({
  handleOnChangeStartDate,
  handleOnChangeEndDate,
  startDate,
  endDate,
  setOpenCustom,
  options = Object.values(DateOptions)
}: Props) => {
  const { t: tFilter } = useTranslation('translation', { keyPrefix: 'general.filters' });

  const [preDefinedDate, setPreDefinedDate] = useState<SelectOptionFormat<DateOptions>>({
    id: DateOptions.THIS_YEAR,
    name: new Date().getFullYear.toString()
  });

  useEffect(() => {
    // Check dates to find corresponding predefined
    const startDateParsed = normalizeDate(convertStringToDate(startDate));
    const endDateParsed = normalizeDate(convertStringToDate(endDate));

    if (isThisYear(startDateParsed, endDateParsed) && options.includes(DateOptions.THIS_YEAR)) {
      setPreDefinedDate({ id: DateOptions.THIS_YEAR, name: new Date().getFullYear().toString() });
    } else if (isLastYear(startDateParsed, endDateParsed)) {
      setPreDefinedDate({
        id: DateOptions.LAST_YEAR,
        name: (new Date().getFullYear() - 1).toString()
      });
    } else if (
      isLast3Months(startDateParsed, endDateParsed) &&
      options.includes(DateOptions.LAST_3_MONTHS)
    ) {
      setPreDefinedDate({
        id: DateOptions.LAST_3_MONTHS,
        name: tFilter(DateOptions.LAST_3_MONTHS)
      });
    } else if (
      isLast30Days(startDateParsed, endDateParsed) &&
      options.includes(DateOptions.LAST_30_DAYS)
    ) {
      setPreDefinedDate({ id: DateOptions.LAST_30_DAYS, name: tFilter(DateOptions.LAST_30_DAYS) });
    } else if (isAllTime(startDateParsed, endDateParsed) && options.includes(DateOptions.ALL)) {
      setPreDefinedDate({ id: DateOptions.ALL, name: tFilter(DateOptions.ALL) });
    } else {
      setOpenCustom(true);
      setPreDefinedDate({ id: DateOptions.CUSTOM, name: tFilter(DateOptions.CUSTOM) });
    }
  }, [startDate, endDate]);

  const onChangePreDefinedDate = (value: SelectOptionFormat) => {
    setOpenCustom(false);
    let startDateParsed: Date | string = new Date(moment(new Date()).format('YYYY-MM-DD'));
    let endDateParsed: Date | string = new Date(moment(new Date()).format('YYYY-MM-DD'));

    switch (value.id) {
      case DateOptions.ALL:
        setPreDefinedDate({ id: DateOptions.ALL, name: tFilter(DateOptions.ALL) });
        startDateParsed = new Date(0);
        endDateParsed = new Date();
        break;
      case DateOptions.THIS_YEAR:
        setPreDefinedDate({
          id: DateOptions.THIS_YEAR,
          name: new Date().getFullYear().toString()
        });
        startDateParsed = new Date(new Date().getFullYear(), 0, 1);
        endDateParsed = new Date(new Date().getFullYear(), 11, 31);
        break;
      case DateOptions.LAST_YEAR:
        setPreDefinedDate({
          id: DateOptions.LAST_YEAR,
          name: (new Date().getFullYear() - 1).toString()
        });
        startDateParsed = new Date(new Date().getFullYear() - 1, 0, 1);
        endDateParsed = new Date(new Date().getFullYear() - 1, 11, 31);
        break;
      case DateOptions.LAST_30_DAYS:
        setPreDefinedDate({
          id: DateOptions.LAST_30_DAYS,
          name: tFilter(DateOptions.LAST_30_DAYS)
        });
        startDateParsed = new Date(new Date().setDate(new Date().getDate() - 30));
        break;
      case DateOptions.LAST_3_MONTHS:
        setPreDefinedDate({
          id: DateOptions.LAST_3_MONTHS,
          name: tFilter(DateOptions.LAST_3_MONTHS)
        });
        startDateParsed = new Date(new Date().setMonth(new Date().getMonth() - 3));
        break;
      case DateOptions.CUSTOM:
        setPreDefinedDate({ id: DateOptions.CUSTOM, name: tFilter(DateOptions.CUSTOM) });
        setOpenCustom(true);
        break;
      default:
        break;
    }

    const formatedDate =
      typeof startDateParsed === 'object' ? formatDate(startDateParsed) : startDateParsed;
    const formatedEndDate =
      typeof endDateParsed === 'object' ? formatDate(endDateParsed) : endDateParsed;

    handleOnChangeStartDate(formatedDate);
    handleOnChangeEndDate(formatedEndDate);
  };

  const formOptions = options.map((option) => {
    return {
      id: option,
      name: tFilter(option)
    };
  });

  return (
    <Select
      options={formOptions}
      value={preDefinedDate}
      onChange={onChangePreDefinedDate}
      sort={false}
    />
  );
};

export default InputDatePredefined;
