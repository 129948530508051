import { useContext, useEffect, useState } from 'react';
import Checkbox from '../../ui/formComponents2/checkbox/Checkbox';
import { FiltersContext } from './FilterContext';
import { FilterOptionType } from './FilterSection';

type FilterCheckboxType = {
  item: FilterOptionType;
};

const FilterCheckbox = ({ item }: FilterCheckboxType) => {
  const { setFilters, remove, filters } = useContext(FiltersContext);
  const [selected, setSelected] = useState(false);
  const onChange = (e: boolean) => {
    if (selected) {
      setSelected(false);
      setFilters((prevState) => prevState.filter((stateItem) => stateItem.id !== item.id));
    } else {
      setSelected(true);
      setFilters((prevState) => [...prevState, item]);
    }
  };
  useEffect(() => {
    if (remove) {
      setSelected(false);
    }
  }, [remove, filters]);
  return (
    <div className='filters__option' key={item.id}>
      <Checkbox
        text={item.name}
        onChange={onChange}
        selected={selected || filters.some((filter) => filter.id === item.id)}
        type='square'
      />
    </div>
  );
};

export default FilterCheckbox;
