import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { FormSocialData } from '../../../../../../../types/entities/socialData';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import FormYesNoRadio from '../../../../../../ui/formComponents2/formInputs/formYesNoRadio/FormYesNoRadio';
import { useTranslation } from 'react-i18next';
import InputWrapper from '../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from '../../../../../../ui/formComponents2/inputNumber/InputNumber';
import Select from '../../../../../../ui/formComponents2/select/Select';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import useNaceCodes from '../../../hooks/useNaceCodes';
import FormElementFull from '../../../../../../ui/formComponents/formElementFull/FormElementFull';
import useRole from 'customHooks/useRole';

type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
  units: SelectOptionFormat[];
};
const FormEnvironment = ({ formData, setFormData, units }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'socialAndGovernance' });
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { isFundRole: disabled } = useRole();

  useEffect(() => {
    if (!units.length) return;
    setFormData((prev) => ({
      ...prev,
      unitWaste: units[0]
    }));
  }, [JSON.stringify(units)]);

  const naceCodes = useNaceCodes();

  return (
    <>
      <FormYesNoRadio
        label={t('biodiversityZone')}
        tooltip={t('biodiversityZoneTooltip')}
        value={formData.biodiversityZone}
        onChange={onChangeValue('biodiversityZone')}
        error={formData.errors.find((error) => error.error === 'biodiversityZone')}
        disabled={disabled}
      />
      <InputWrapper
        icon={'/images/icons/warning.svg'}
        label={t('quantityWaste')}
        tooltip={t('quantityWasteTooltip')}
        error={formData.errors.find(
          (elem) => elem.error === 'quantityWaste' || elem.error === 'unitWaste'
        )}
        disabled={disabled}>
        <InputNumber
          placeholder={t('enterQuantity')}
          onChange={onChangeValue('quantityWaste')}
          value={formData.quantityWaste}
          maxLength={10}
          disabled={disabled}
        />
        <Select
          placeholder={''}
          options={units}
          value={formData.unitWaste}
          onChange={onChangeValue('unitWaste')}
          disabled={disabled}
        />
      </InputWrapper>
      <FormElementFull>
        <FormSelect
          icon='/images/icons/calendar.svg'
          label={t('naceCode')}
          tooltip={t('naceCodeTooltip')}
          value={formData.naceCode}
          onChange={onChangeValue('naceCode')}
          options={naceCodes}
          disabled={disabled}
          error={formData.errors.find((error) => error.error === 'naceCode')}
        />
      </FormElementFull>
    </>
  );
};

export default FormEnvironment;
