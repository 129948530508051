import apiFetch from 'utils/apiFetch';

export enum RoleLevel {
  OWN = 'own',
  HOLDING = 'holding',
  ALL = 'all'
}

export const getRoles = async (level: RoleLevel, includeGlobalRoles = false) => {
  try {
    const response = await apiFetch('GET', '/v1/roles', null, null, {
      level,
      include_global_roles: includeGlobalRoles,
      page: 1,
      size: 100
    });

    return response.data.items;
  } catch (error) {
    console.error(error);
    return [];
  }
};

type RoleBody = {
  name: string;
  description: string;
  permissions: number[]; // Permission IDs
};

type RoleEditBody = {
  name: string;
  description: string;
  permissions_to_add: number[]; // Permission IDs
  permissions_to_delete: number[]; // Permission IDs
};

export const createRole = async (role: RoleBody) => {
  try {
    const response = await apiFetch('POST', '/v1/roles', role);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPermissions = async () => {
  try {
    const response = await apiFetch('GET', '/v1/permissions');

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const editRole = async (role: RoleEditBody, roleId: string) => {
  try {
    const response = await apiFetch('PATCH', `/v1/roles/${roleId}`, role);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteRole = async (roleId: string) => {
  try {
    const response = await apiFetch('DELETE', `/v1/roles/${roleId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRolePermissions = async (roleId: string) => {
  try {
    const response = await apiFetch('GET', `/v1/roles/${roleId}/permissions`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getRoleObject = async (roleId: string) => {
  try {
    const response = await apiFetch('GET', `/v1/roles/${roleId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
