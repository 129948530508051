import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDoubleSelect from 'components/ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { downloadByCategory } from 'services/api/download';

type FormData = {
  startDate: string;
  endDate: string;
  format: SelectOptionFormat;
  errors: ErrorType[];
};

type Props = {
  category: string;
  onClose: () => void;
};
const DownloadCategoryModal = ({ category, onClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'downloadCategoryModal' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tNotification } = useTranslation('translation', { keyPrefix: 'notification' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });
  const params = useParams();

  const selectedOrganization = useSelectedOrganization();

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    format: {
      id: '',
      name: ''
    },
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const dispatch = useDispatch();

  const handleDownloadFile = async () => {
    setLoadingButton(true);
    const facilityCategories = ['water'];
    let facilityId = undefined;
    if (facilityCategories.find((category) => category === category)) {
      facilityId = params.id;
    }

    const response = await downloadByCategory(
      category,
      formData.format.id,
      formData.startDate ?? undefined,
      formData.endDate ?? undefined,
      facilityId ?? undefined
    );
    if (!response) {
      dispatch(setNotification(tError(`somethingWentWrong`)));
    } else {
      dispatch(setNotification(tNotification(`downloadFileEmail`)));
    }
    setLoadingButton(false);

    onClose();
  };

  return (
    <>
      <FormHeader title={t('title')} />
      <FormWrapper>
        <FormCalendarDoubleSelect
          label={t('selectDateRange')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate ? (formData.startDate as string) : ''}
          endDate={formData.endDate ? (formData.endDate as string) : ''}
          error={formData.errors.find(
            (error) =>
              error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
          )}
        />
        <FormSelect
          label={t('selectCategory')}
          onChange={onChangeValue('format')}
          iconV2='file'
          value={formData.format}
          options={[
            {
              id: 'excel',
              name: tGeneral('excel')
            },
            {
              id: 'csv',
              name: tGeneral('csv')
            },
            {
              id: 'json',
              name: tGeneral('json')
            }
          ]}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={
            selectedOrganization?.subscription_plan === 'free_plan' ? 'blocked' : 'primary'
          }
          text={t('download')}
          onClick={handleDownloadFile}
          loading={loadingButton}
          style={{ width: '100%' }}
        />
      </FormButtonSection>
    </>
  );
};

export default DownloadCategoryModal;
