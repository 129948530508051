import { useCarouselTranslation } from '../hooks/useCarouselTranslation';
import Label, { VARIANTS } from '../../../label/Label';
import type { Props } from '../StepCarousel';

const StepSmall = ({ text, isSelected, className, blocked, disabled, ...rest }: Props) => {
  const { t } = useCarouselTranslation();

  if (isSelected) {
    className = `${className} selected`;
  }

  if (disabled || blocked) {
    className = `${className} blocked`;
  }

  let lookAndFeel: keyof typeof VARIANTS = 'secondary';

  if (isSelected && (disabled || blocked)) lookAndFeel = 'dark';
  else if (isSelected) lookAndFeel = 'primary';
  else if (disabled || blocked) lookAndFeel = 'disabled';

  return (
    <div className={className} {...rest}>
      <Label lookAndFeel={lookAndFeel}>{t(text)}</Label>
    </div>
  );
};

export default StepSmall;
