import { useInfiniteQuery } from '@tanstack/react-query';
import { adaptTopicsBackToFront } from 'adapters/topics';
import { fetchTopicsData } from 'services/api/sotOrganizationData';

export const useObtainTopicsData = ({ year, language }: { year: string; language: string }) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, error } = useInfiniteQuery({
    queryKey: ['topicsData', year, language],
    queryFn: fetchTopicsData,
    initialPageParam: 1,
    select: adaptTopicsBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });
  return { data, isLoading, fetchNextPage, hasNextPage, isFetching, error };
};
