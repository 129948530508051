import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import { useTranslation, Trans } from 'react-i18next';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import logoLogicReturner from '../utils/logoLogicReturner';
import dateRenderer from '../../../utils/frameworkDateRenderer';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { ROUTES } from 'constants/routes';

export const EINFDashboard = () => {
  const { frameworkName } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const reverseDate = (date: string): string => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };
  const navigate = useNavigate();
  const buttonLinks = [
    {
      id: 'general',
      title: t('generalInfo'),
      icon: 'vat'
    },
    {
      id: 'environmental',
      title: t('environmental'),
      icon: 'earth'
    },
    {
      id: 'social',
      title: t('social'),
      icon: 'members'
    },
    {
      id: 'governance',
      title: t('governance'),
      icon: 'own_facility'
    }
  ];
  const buttonRenderer = (title: string, icon: IconName, id: string) => {
    return (
      <Button
        key={title}
        title={title}
        onClick={() =>
          navigate(
            getUrl(ROUTES.IMPROVE_DASHBOARD_TOTAL + `/einf/${id}/`, {
              queryParams: generateQueryParamsFromObject({ mode: 'controlPanel' })
            })
          )
        }
        lookAndFeel='secondary'
        style={{
          flexGrow: 1,
          maxWidth: '25%',
          justifyContent: 'flex-start'
        }}
        className='p-4 button-as-card-border'>
        <Icon
          icon={icon}
          color='gradient'
          size='large'
          style={icon === 'own_facility' ? { width: '30px' } : undefined}
        />{' '}
        <span className='font-14'>{title}</span>
      </Button>
    );
  };
  return (
    <div className='framework-dashboard__body'>
      <CardPanel className='p-6'>
        <div className='framework-scope__iconTitle flex gap-8 items-center'>
          <img
            src={logoLogicReturner(frameworkName || 'einf', false)}
            alt='einf'
            className='framework-scope__icon'
            style={{ width: 'auto', height: '55px', border: 'none' }}
          />
          <div className='framework-scope__titleNumber flex items-center gap-8 justify-between'>
            <h4 className='co2-dashboard-font-small on-light-text-color'>
              <Trans
                i18nKey='controlPanel.frameworkProgress.einfDashboardTitle'
                components={{ italic: <i /> }}
              />
            </h4>
            {dateRenderer(
              reverseDate(new Date().toISOString().split('T')[0]),
              false,
              t,
              'calendar'
            )}
          </div>
        </div>
      </CardPanel>
      <CardPanel className='p-6'>
        <div className='flex gap-4'>
          {buttonLinks.map((item) => buttonRenderer(item.title, item.icon as IconName, item.id))}
        </div>
      </CardPanel>
    </div>
  );
};
