import { IconColor } from 'components/ui/IconBase/IconBase';
import { InputSize } from '../../../../../types/utilsEnums/input';
import Icon from '../../../icon/Icon';
import './styles.scss';

type Props = {
  rotate: boolean;
  size?: InputSize;
  color?: IconColor;
};
const Chevron = ({ rotate, size, color = 'gradient' }: Props) => {
  return (
    <Icon
      icon='chevron_down'
      color={color}
      size='medium'
      className={`${rotate ? 'rotate180' : 'rotate-back'} ${size}`}
    />
  );
};

export default Chevron;
