import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import FormTextArea from 'components/ui/formComponents2/formInputs/formTextArea/FormTextArea';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import { DMIROFormData } from '../../hooks/useDMIROsForm';
import { Dispatch, SetStateAction } from 'react';

type DMIROsFormProps = {
  formData: DMIROFormData;
  setFormData: Dispatch<SetStateAction<DMIROFormData>>;
  selectableTopics: SelectOptionFormat[];
  selectableSubtopics: SelectOptionFormat[];
  iroTypes: SelectOptionFormat[];
  errors: ErrorType[];
  disabled?: boolean;
};

export const DMIROsForm: React.FC<DMIROsFormProps> = ({
  formData,
  setFormData,
  selectableTopics,
  selectableSubtopics,
  iroTypes,
  errors = [],
  disabled = false
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros.form'
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <>
      <div className='flex gap-4 mb-4'>
        <FormWrapper className='gap-0'>
          <FormSelect
            label={t('topic.label')}
            icon={'/images/icons/pin.svg'}
            placeholder={t('topic.placeholder')}
            onChange={onChangeValue('topic')}
            value={formData.topic}
            options={selectableTopics}
            error={errors?.find((error) => error.error === 'topic')}
            disabled={disabled}
          />
        </FormWrapper>
        <FormWrapper className='gap-0'>
          <FormSelect
            label={t('subtopic.label')}
            icon={'/images/icons/pin.svg'}
            placeholder={t('subtopic.placeholder')}
            onChange={onChangeValue('subtopic')}
            value={formData.subtopic}
            options={selectableSubtopics}
            disabled={!formData.topic.id || disabled}
            error={errors?.find((error) => error.error === 'subtopic')}
          />
        </FormWrapper>
      </div>
      <FormWrapper className='gap-0'>
        <FormTextArea
          label={t('description.label')}
          placeholder={t('description.placeholder')}
          onChange={onChangeValue('description')}
          value={formData.description}
          error={errors?.find((error) => error.error === 'description')}
          disabled={disabled}
        />
      </FormWrapper>
      <div className='flex gap-4 mb-4'>
        <FormWrapper className='gap-0'>
          <InputWrapper
            label={t('source.label')}
            iconV2='search'
            width='200px'
            error={errors?.find((error) => error.error === 'source')}>
            <InputText
              placeholder={t('source.placeholder')}
              onChange={onChangeValue('source')}
              value={formData.source}
              disabled={disabled}
            />
          </InputWrapper>
        </FormWrapper>
        <FormWrapper className='gap-0'>
          <FormSelect
            label={t('type.label')}
            icon={'/images/icons/catalog.svg'}
            placeholder={t('type.placeholder')}
            onChange={onChangeValue('type')}
            value={formData.type}
            options={iroTypes}
            error={errors?.find((error) => error.error === 'type')}
            tooltip={t('type.tooltip')}
            disabled={disabled}
          />
        </FormWrapper>
      </div>
      <FormWrapper className='gap-0'>
        <InputWrapper
          label={t('importanceScore.label')}
          iconV2='graph'
          tooltip={t('importanceScore.tooltip')}
          error={errors?.find((error) => error.error === 'importanceScore')}>
          <InputNumber
            placeholder={t('importanceScore.placeholder')}
            onChange={onChangeValue('importanceScore')}
            value={formData.importanceScore}
            disabled={disabled}
          />
        </InputWrapper>
      </FormWrapper>
    </>
  );
};
