import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

type Props = {
  options: SelectOptionFormat[];
  category: SelectOptionFormat;
  setCategory: (value: SelectOptionFormat) => void;
};

const CategoryFilter = ({ options, category, setCategory }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const navigate = useNavigate();

  const onChange = (value: SelectOptionFormat<string>) => {
    setCategory(value);
    navigate(
      getUrl(ROUTES.IMPROVE_DASHBOARD_TOTAL, {
        queryParams: generateQueryParamsFromObject({ mode: value.id })
      })
    );
  };

  return (
    <InputWrapper label={t('categoryFilterLabel')} iconV2='categories'>
      <Select options={options} onChange={onChange} value={category} sort={false} />
    </InputWrapper>
  );
};

export default CategoryFilter;
