import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import { ProfileCard } from '../profileCard/ProfileCard';
import { ParentOrg } from '../../../types/entities/organization';
import { useContext } from 'react';
import { useUnbindModal } from '../hooks/useUnbindModal';
import Modal from '../../ui/modal/Modal';
import UnbindAccount from '../unbindAccount/UnbindAccount';
import { UserContext } from '../../../context/userContext';
import useRole from 'customHooks/useRole';

type Props = {
  holding: ParentOrg;
};

export const LinkPending: React.FC<Props> = ({ holding }) => {
  const { t } = useTranslation();
  const { isAdminRole } = useRole();

  const { closeModal, isOpen, onUnbind, openModal } = useUnbindModal();
  const user = useContext(UserContext);

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal.WithPortal show={isOpen} onClose={closeModal} width='428px' maxWidth='428px'>
        <UnbindAccount holding={holding} user={user} onUnbind={onUnbind} />
      </Modal.WithPortal>

      <ProfileCard.Layout>
        <div className='flex gap-x-4 items-center'>
          <ProfileCard.Content>
            <ProfileCard.Title>{t('profile.linkPending')}</ProfileCard.Title>
            <ProfileCard.Description>
              {t('profile.linkPendingDescription').replace('{{holdingId}}', holding.vat)}
            </ProfileCard.Description>
          </ProfileCard.Content>
          <Button
            lookAndFeel='primary'
            size='medium'
            text={t('profile.unbind')}
            style={{ width: 'fit-content' }}
            disabled={!isAdminRole}
            onClick={openModal}
          />
        </div>
      </ProfileCard.Layout>
    </>
  );
};
