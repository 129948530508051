import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import getCountryName from 'utils/getCountryName';

type Props = {
  setOwnWorkerToEdit: (ownWorker: DMOwnWorker) => void;
  setOwnWorkerToDelete: (ownWorker: DMOwnWorker) => void;
};

export const useColumns = ({ setOwnWorkerToEdit, setOwnWorkerToDelete }: Props) => {
  const { t, i18n } = useTranslation();

  const buildActions = (ownWorker: DMOwnWorker) => [
    {
      id: `${ownWorker.id}-edit`,
      name: t('doubleMateriality.context.ownWorkers.edit'),
      onClick: () => {
        setOwnWorkerToEdit(ownWorker);
      }
    },
    {
      id: `${ownWorker.id}-delete`,
      name: t('doubleMateriality.context.ownWorkers.delete'),
      onClick: () => {
        if (ownWorker.id) {
          setOwnWorkerToDelete(ownWorker);
        }
      }
    }
  ];

  const columns: Column[] = [
    {
      title: t('doubleMateriality.context.ownWorkers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('doubleMateriality.context.ownWorkers.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('doubleMateriality.context.ownWorkers.country'),
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => getCountryName(country, i18n.resolvedLanguage as Lang)
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: t('doubleMateriality.context.ownWorkers.importance'),
      render: (_, row: DMSupplier) => (
        <div
          className={`text-tag body3-font ${
            row.highImportance ? 'tag2-bg-text-color' : 'tag-bg-text-color'
          } w-fit-content p-1 rounded-4`}>
          {row.highImportance
            ? t('doubleMateriality.context.high')
            : t('doubleMateriality.context.low')}
        </div>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: (_, ownWorker: DMOwnWorker) => <ButtonDropdown options={buildActions(ownWorker)} />
    }
  ];
  return columns;
};
