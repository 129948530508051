import { useTranslation } from 'react-i18next';
import { highlight_color, highlight_color2 } from '../../../../styles/colors';
import { Organization } from '../../../../types/entities/organization';
import { VehicleReportGHG } from '../../../../types/entities/vehicle';
import numberToDecimal, { numberToScientificNotation } from '../../../../utils/numberToDecimal';
import HorizontalBar from '../common/horizontalBar/HorizontalBar';
import List, { ListColumn } from '../common/list/List';
import './styles.scss';

type CategoryCo2 = {
  total_emissions: number;
  ghg_gas: string;
};
type GroupedVehicles = {
  id: string;
  name: string;
  total_emissions: number;
  ghg_gas: string;
  categories: CategoryCo2[];
};
type Props = {
  vehicles: VehicleReportGHG[];
  organizationCo2e: number;
  organization: Organization;
};

const FROM_G_TO_TON = 1_000_000;
const FROM_KG_TO_TON = 1000;

function MobileEmissions({ vehicles }: Props) {
  const { t } = useTranslation();
  let totalCo2e = 0;
  let totalCo2 = 0;
  let totalCH4 = 0;
  let totalN2O = 0;
  let totalCo2eCH4 = 0;
  let totalCo2eN20 = 0;
  let totalCo2Biomass = 0;

  // group vehicles of same id and add a list of co2e values per cateogry
  const groupedVehicles: GroupedVehicles[] = [];
  vehicles.forEach((vehicle) => {
    if (vehicle.ghg_gas === 'co2e') {
      totalCo2e += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'co2') {
      totalCo2 += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'ch4') {
      totalCH4 += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'n2o') {
      totalN2O += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'co2e_ch4') {
      totalCo2eCH4 += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'co2e_n2o') {
      totalCo2eN20 += vehicle.total_emissions;
    } else if (vehicle.ghg_gas === 'co2_biomass') {
      totalCo2Biomass += vehicle.total_emissions;
    }

    const index = groupedVehicles.findIndex((elem) => elem.id === vehicle.id);
    if (index === -1) {
      groupedVehicles.push({
        id: vehicle.id,
        name: vehicle.name,
        total_emissions: vehicle.ghg_gas === 'co2e' ? vehicle.total_emissions : 0,
        ghg_gas: 'co2e',
        categories: [
          {
            total_emissions: vehicle.total_emissions,
            ghg_gas: vehicle.ghg_gas
          }
        ]
      });
    } else {
      if (vehicle.ghg_gas === 'co2e') {
        groupedVehicles[index].total_emissions += vehicle.total_emissions;
      }

      groupedVehicles[index].categories.push({
        total_emissions: vehicle.total_emissions,
        ghg_gas: vehicle.ghg_gas
      });
    }
  });

  groupedVehicles.sort((a, b) => b.total_emissions - a.total_emissions);

  const groupedVehiclesCo2e = groupedVehicles.filter((vehicle) => {
    return vehicle.ghg_gas === 'co2e';
  });

  const calculateRemaining = (ghg_gas: string) => {
    let remaining = 0;
    groupedVehiclesCo2e.slice(3).forEach((elem) => {
      const categoryCo2 = elem.categories.find((categoryCo2) => categoryCo2.ghg_gas === ghg_gas);
      if (categoryCo2) {
        remaining += categoryCo2.total_emissions;
      }
    });
    return remaining;
  };

  const renderName = (vehicle_name: string, length: number) => {
    const unknownVehicleNames = ['car', 'van', 'passenger_car', 'truck_bus', 'moped_motorcycle'];
    let name = `${vehicle_name.substring(0, length)}${vehicle_name.length > length ? '...' : ''}`;
    if (unknownVehicleNames.includes(vehicle_name)) {
      name = t(`vehicles.${vehicle_name}`);
    }
    return name;
  };

  const remainingCo2e = calculateRemaining('co2e');
  const remainingCo2 = calculateRemaining('co2');

  const remainingCH4 = calculateRemaining('ch4');
  const remainingN2O = calculateRemaining('n2o');

  const remainingCo2eCh4 = calculateRemaining('co2e_ch4');
  const remainingCo2eN2o = calculateRemaining('co2e_n2o');
  const remainingCo2Biomass = calculateRemaining('co2_biomass');

  const columns: ListColumn[] = [
    { title: t('ghgReport.vehicle'), align: 'left' },
    { title: t('ghgReport.n2o(tns)'), align: 'right' },
    { title: t('ghgReport.ch4(tns)'), align: 'right' },
    { title: t('ghgReport.co2(tns)'), align: 'right' },
    { title: t('ghgReport.co2Biomass(tns)'), align: 'right' },
    { title: t('ghgReport.co2eN2o(tns)'), align: 'right' },
    { title: t('ghgReport.co2eCh4(tns)'), align: 'right' },
    { title: t('ghgReport.co2eq.(tns)'), align: 'right' }
  ];

  const rows: string[][] = [];

  groupedVehicles.slice(0, 3).map((vehicle) => {
    const vehicleCo2 = vehicle.categories.find((elem) => elem.ghg_gas === 'co2')?.total_emissions;

    const vehicleCH4 = vehicle.categories.find((elem) => elem.ghg_gas === 'ch4')?.total_emissions;

    const vehicleN2O = vehicle.categories.find((elem) => elem.ghg_gas === 'n2o')?.total_emissions;

    const vehicleCo2e = vehicle.categories.find((elem) => elem.ghg_gas === 'co2e')?.total_emissions;

    const vehicleCo2eCh4 = vehicle.categories.find(
      (elem) => elem.ghg_gas === 'co2e_ch4'
    )?.total_emissions;

    const vehicleCo2eN2o = vehicle.categories.find(
      (elem) => elem.ghg_gas === 'co2e_n2o'
    )?.total_emissions;

    const vehicleCo2Biomass = vehicle.categories.find(
      (elem) => elem.ghg_gas === 'co2_biomass'
    )?.total_emissions;

    const row = [
      renderName(vehicle.name, 13),
      numberToScientificNotation((vehicleN2O || 0) / FROM_G_TO_TON),
      numberToScientificNotation((vehicleCH4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((vehicleCo2 || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((vehicleCo2Biomass || 0) / FROM_KG_TO_TON),
      numberToScientificNotation((vehicleCo2eN2o || 0) / FROM_G_TO_TON),
      numberToScientificNotation((vehicleCo2eCh4 || 0) / FROM_G_TO_TON),
      numberToScientificNotation((vehicleCo2e || 0) / FROM_KG_TO_TON)
    ];

    rows.push(row);
  });

  // Add remaining to rows
  rows.push([
    t('ghgReport.remaining'),
    numberToScientificNotation(remainingN2O / FROM_G_TO_TON),
    numberToScientificNotation((remainingCH4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingCo2 || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingCo2Biomass || 0) / FROM_KG_TO_TON),
    numberToScientificNotation((remainingCo2eN2o || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingCo2eCh4 || 0) / FROM_G_TO_TON),
    numberToScientificNotation((remainingCo2e || 0) / FROM_KG_TO_TON)
  ]);

  return (
    <>
      <div className='mobile-emissions__header'>
        <span className='body2-font'>t CO₂ eq.</span>
      </div>
      {groupedVehiclesCo2e.length > 0 && remainingCo2e > 0 && (
        <div className='mobile-emissions__bar-chart on-card-gray-bg-color'>
          {groupedVehiclesCo2e.slice(0, 3).map((vehicle, index) => (
            <div className='mobile-emissions__bar-chart__item' key={index}>
              <span className='body2-font vehicle-name'>{renderName(vehicle.name, 20)}</span>
              <HorizontalBar
                total={totalCo2e}
                values={vehicle.categories
                  .filter((elem) => elem.ghg_gas === 'co2e')
                  .map((elem) => ({
                    value: elem.total_emissions,
                    color: `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                  }))}
              />
              <span className='body2-font vehicle-total-co2'>
                {numberToDecimal(vehicle.total_emissions / FROM_KG_TO_TON)}
              </span>
            </div>
          ))}
          {remainingCo2e > 0 && (
            <div className='mobile-emissions__bar-chart__item' key='remaining'>
              <span className='body2-font vehicle-name'>{t('ghgReport.remaining')}</span>
              <HorizontalBar
                total={totalCo2e}
                values={[
                  {
                    value: numberToDecimal(remainingCo2e),
                    color: `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                  }
                ]}
              />
              <span className='body2-font vehicle-total-co2'>
                {numberToDecimal(remainingCo2e / FROM_KG_TO_TON)}
              </span>
            </div>
          )}
        </div>
      )}
      <div className='smaller'>
        <List
          icon={'/images/icons/car.svg'}
          columns={columns}
          rows={rows}
          totalRow={[
            numberToScientificNotation(totalN2O / FROM_G_TO_TON),
            numberToScientificNotation(totalCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2 / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2Biomass / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2eN20 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2eCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2e / FROM_KG_TO_TON)
          ]}
        />
      </div>
      <div
        className='flex-col gap-1'
        style={{
          marginTop: 'auto'
        }}>
        <span className='font-10 weight-400 on-light-text-color2'>{t('ghgReport.foot.info1')}</span>{' '}
        <span className='font-10 weight-400 on-light-text-color2'>{t('ghgReport.foot.info2')}</span>
      </div>
    </>
  );
}

export default MobileEmissions;
