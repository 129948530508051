import { useAuth, useSession, useUser } from '@clerk/clerk-react';
import { loadUser } from 'actions/auth';
import LoaderLogin from 'components/ui/loaders/loaderLogin/LoaderLogin';
import { ROUTES } from 'constants/routes';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ClerkCallbackAuthenticated = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      return;
    }

    getToken().then((token) => {
      const sessionData = {
        email: user.primaryEmailAddress?.emailAddress ?? '',
        id: user.externalId ?? user.id, // This is the Dcycle DB user ID
        user_id: user.externalId ?? user.id, // This is the Dcycle DB user ID
        access_token: token ?? '',
        id_token: token ?? '',
        clerk_session: true
      };

      if (!sessionData) return;
      try {
        dispatch(
          loadUser(sessionData, true, true, '', () =>
            navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true })
          )
        );
      } catch (error) {
        navigate(ROUTES.SIGNIN, { replace: true });
      }
    });
  }, [JSON.stringify(user)]);

  return <LoaderLogin />;
};
