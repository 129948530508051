import axios, { AxiosPromise, Method } from 'axios';
import controller from '../environments/controller';
import { authGetIdToken } from '../services/authService';
import { localStorageGetItem } from './localStorage';
const environment = controller();

const PUBLIC_ENDPOINTS = [
  'v1/auth/login',
  '/v1/auth/refresh_token',
  'v1/auth/confirm_mfa_login',
  'v1/auth/sign_up',
  'v1/auth/confirm_sign_up',
  'v1/auth/resend_confirmation_code',
  'v1/auth/forgot_password',
  'v1/auth/confirm_forgot_password'
];

if (process.env.REACT_APP_ENVIRONMENT !== 'local' && process.env.STORYBOOK_RUNNING !== 'true') {
  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      if (PUBLIC_ENDPOINTS.some((endpoint) => config.url?.includes(endpoint))) {
        return config;
      }

      let token = '';
      try {
        token = await authGetIdToken();
      } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        window.location.href = '/login';
        throw error;
      }

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

export default function apiFetch<T = any>(
  method: Method,
  url: string,
  bodyForm?: LooseObject | null,
  customHeaders?: LooseObject | null,
  params?: LooseObject | null,
  timeout?: number | null,
  responseType?: string,
  signal?: AbortSignal
): AxiosPromise<T> {
  const headers: LooseObject = {
    Accept: 'application/json',
    'x-organization-id': localStorage.getItem('x-organization-id')
      ? localStorage.getItem('x-organization-id')
      : '',
    'x-partner': process.env.REACT_APP_PARTNER
  };

  if (process.env.REACT_APP_ENVIRONMENT === 'local' && !url.includes('/auth')) {
    headers['Authorization'] = `Bearer ${localStorageGetItem('id_token')}`;
  }

  if (
    method.toUpperCase() === 'POST' ||
    method.toUpperCase() === 'PUT' ||
    method.toUpperCase() === 'PATCH'
  ) {
    headers['Content-Type'] = 'application/json';
  }

  if (customHeaders) {
    Object.keys(customHeaders).forEach((key) => {
      headers[key] = customHeaders[key];
    });
  }
  const config: LooseObject = {
    method,
    url: `${environment.backendUrl}${url}`,
    headers,
    data: bodyForm || null,
    params: params || null,
    timeout: timeout || null
  };
  if (responseType) {
    config['responseType'] = responseType;
  }
  if (signal) {
    config['signal'] = signal;
  }
  return axios(config);
}
