type Keys<T extends object> = {
  [K in keyof T]: T[K] extends any[] ? K : never;
}[keyof T];

type UseNestedArrayParams<T extends object, Y extends Keys<T>> = {
  outerState: T;
  setOuterState: React.Dispatch<React.SetStateAction<T>>;
  innerStateKey: Y;
};

export const useNestedArray = <T extends object, Y extends Keys<T>>({
  outerState,
  setOuterState,
  innerStateKey
}: UseNestedArrayParams<T, Y>) => {
  const setInnerArray = (entry: ((prev: T[Y]) => T[Y]) | T[Y]) => {
    setOuterState((prev) => {
      if (entry instanceof Function) {
        return { ...prev, [innerStateKey]: entry(prev[innerStateKey]) };
      }

      return { ...prev, [innerStateKey]: entry };
    });
  };

  return [outerState[innerStateKey], setInnerArray] as const;
};
