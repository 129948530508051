import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransportSection } from 'types/entities/shipment';
import useTransportOptions from '../hooks/useTransportOptions';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import IconBackground from 'components/ui/iconBackground/IconBackground';
import Icon from 'components/ui/icon/Icon';
import FormElementFull from 'components/ui/formComponents/formElementFull/FormElementFull';
import Button from 'components/ui/button/Button';
import FormGoogleMapsDouble from 'components/ui/formComponents2/formInputs/formGoogleMapsDouble/FormGoogleMapsDouble';
import { string } from 'prop-types';

type Props = {
  index: number;
  editTransportSection: (transportSectionIndex: number, transportSection: TransportSection) => void;
  addTransportSection: (index: number) => void;
  removeTransportSection: (index: number) => void;
  transportSection: TransportSection;
  transportSections: TransportSection[];
  refrigerated: boolean;
  errors?: ErrorType[];
};

type FormData = {
  transportType: SelectOptionFormat<string>;
  origin: string;
  destination: string;
};

const TransportSections = ({
  index,
  editTransportSection,
  addTransportSection,
  removeTransportSection,
  transportSection,
  transportSections,
  refrigerated,
  errors
}: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    transportType: {
      id: '',
      name: ''
    },
    origin: '',
    destination: ''
  });

  const transportOptions = useTransportOptions().filter((option) => {
    return refrigerated ? option.id !== 'electric_car' && option.id !== 'car' : true;
  });

  const renderTransportType = () => {
    if (transportSection.transport_type === 'road') {
      if (transportSection.electric && transportSection.travel_method === 'car') {
        return 'electric_car';
      }
      if (transportSection.electric && transportSection.travel_method === 'bicycle') {
        return 'electric_bicycle';
      } else {
        return transportSection.travel_method;
      }
    } else {
      return transportSection.transport_type;
    }
  };

  useEffect(() => {
    setFormData({
      transportType: {
        id: transportSection.transport_type,
        name: transportSection.transport_type ? t(`shipments.${renderTransportType()}`) : ''
      },
      origin: transportSection.origin,
      destination: transportSection.destination
    });
  }, [transportSection, transportSections]);

  const onChangeTransport = (selectedOption: SelectOptionFormat) => {
    editTransportSection(index, {
      ...transportSection,
      transport_type: selectedOption.id
    });
  };

  const onChangeLocation =
    (point: 'destination' | 'origin') => (e: ChangeEvent<HTMLInputElement> | string) => {
      editTransportSection(index, {
        ...transportSection,
        [point]: (e as ChangeEvent<HTMLInputElement>)?.target?.value ?? e
      });
    };

  return (
    <div className='transport-section'>
      <FormWrapper>
        {index > 0 && <div className='line-divider' />}
        <FormElementFull className='transport'>
          <FormSelect
            iconV2='lorry'
            options={transportOptions}
            value={formData.transportType}
            onChange={onChangeTransport}
            label={t('shipments.transportType')}
            error={errors?.find(
              (error) => error.error === `transportSectionsTransportType-${index}`
            )}
          />
          {index > 0 && (
            <IconBackground
              iconV2={'close'}
              alt={'close'}
              size='large'
              onClick={() => removeTransportSection(index)}
            />
          )}
        </FormElementFull>
        <FormElementFull>
          <FormGoogleMapsDouble
            error={errors?.find((error) => error.error === `transportSectionsRoute-${index}`)}
            origin={{
              placeholder: t('shipments.origin'),
              value: formData.origin,
              onChange: onChangeLocation('origin'),
              disabled: index > 0,
              types: []
            }}
            destination={{
              placeholder: t('shipments.destination'),
              value: formData.destination,
              onChange: onChangeLocation('destination'),
              types: []
            }}
          />
        </FormElementFull>
        <Button
          onClick={() => addTransportSection(index)}
          lookAndFeel={'link'}
          className='font-10 weight-400 flex gap-1 items-center'
          iconNode={<Icon icon='add' color='gradient' size='small' />}>
          {t('shipments.addAnotherRoute')}
        </Button>
      </FormWrapper>
    </div>
  );
};

export default TransportSections;
