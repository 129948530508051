import { TAG_STATUS } from './constants';

type UseDividedConsumptionTagStatusParams<T extends object> = {
  lines: T[];
  getValue: (line: T) => number;
  max?: number;
  min?: number;
};

export const useDividedConsumptionTagStatus = <T extends object>({
  lines,
  getValue,
  max = 100,
  min = 0
}: UseDividedConsumptionTagStatusParams<T>) => {
  const totalValue = lines.reduce((acc, line) => acc + getValue(line), 0);

  if (totalValue === max) {
    return { status: TAG_STATUS.SUCCESS, totalValue } as const;
  }

  if (totalValue < max && totalValue >= min) {
    return { status: TAG_STATUS.WARNING, totalValue } as const;
  }

  return { status: TAG_STATUS.ERROR, totalValue } as const;
};
