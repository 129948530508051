import { useInfiniteQuery } from '@tanstack/react-query';
import { adaptClientsBackToFront } from 'adapters/clients';
import { fetchDMClients } from 'services/api/sotOrganizationData';

export const useObtainClients = ({
  year,
  company_name
}: {
  year: string;
  company_name: string;
}) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['clients', year, company_name],
    queryFn: fetchDMClients,
    initialPageParam: 1,
    select: adaptClientsBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};
