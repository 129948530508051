import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import {
  fifth_color,
  fourth_color,
  gray_color2,
  scope1_color,
  scope2_color,
  scope3_color
} from '../../../../styles/colors';
import { Organization } from '../../../../types/entities/organization';
import { TransportDistributionReport } from '../../../../types/entities/shipment';
import numberToDecimal, { numberToScientificNotation } from '../../../../utils/numberToDecimal';
import LineChart2 from '../../../ui/charts/LineChart2';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import List, { ListColumn } from '../common/list/List';
import PieChartDistribution from '../common/pieChart/PieChart';
import useDataCo2 from '../hooks/useDataCo2';
import './styles.scss';
type Props = {
  data: TransportDistributionReport;
  startDate: string;
  endDate: string;
  organizationCo2e: number;
  organization: Organization;
  i18key: string;
};
const TransportDistribution = ({
  data,
  startDate,
  endDate,
  organizationCo2e,
  organization,
  i18key
}: Props) => {
  const { t, i18n } = useTranslation();

  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });

  const dataQuantity = dataCo2.map((elem) => ({ x: elem.x, y: 0 }));
  let totalCo2 = 0;
  let totalTonsKms = 0;

  data.monthly.forEach((elem) => {
    totalTonsKms += (elem.quantity_transported / 1000) * elem.kms;
    totalCo2 += elem.co2e;
  });

  data.monthly.forEach((elem) => {
    const elemDate = moment(new Date(elem.year, elem.month - 1)).format('YYYY-MM');

    const foundIndex = dataCo2.findIndex((elem2) => elem2.x === elemDate);
    if (foundIndex > -1) {
      dataCo2[foundIndex].y += elem.co2e / 1000;
      dataQuantity[foundIndex].y += elem.quantity_transported;
    }
  });

  const columns: ListColumn[] = [
    { title: t('ghgReport.month') },
    { title: t('ghgReport.co2eq.(tns)') }
  ];

  const rows: string[][] = [];

  dataCo2.forEach((elem) => {
    const dateString = elem.x;
    const date = new Date(dateString);
    const month = months.find((elem) => elem.number === date.getMonth() + 1);
    const lang = i18n.resolvedLanguage;
    rows.push([month?.name[lang], numberToScientificNotation(elem.y)]);
  });

  const pieChartData: { title: string; value: number; color: string }[] = [];
  type Color = {
    [key: string]: string;
  };
  const colors: Color = {
    car: scope3_color,
    air: scope1_color,
    truck: fourth_color,
    rail: fifth_color,
    maritime: scope2_color,
    do_not_know: gray_color2
  };
  data.by_type.forEach((elem) => {
    pieChartData.push({
      title: t(`shipments.${elem.transport_type}`),
      value: (elem.co2e * 100) / totalCo2,
      color: colors[elem.transport_type]
    });
  });

  const intensity = totalTonsKms > 0 ? totalCo2 / totalTonsKms : 0;
  return (
    <div className='ghg-transport-distribution'>
      <LineChartWrapper
        legends={[
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <LineChart2
          labels={monthLabels}
          chartData={[
            {
              label: 'scope3',
              borderColor: scope3_color,
              data: dataCo2.map((elem) => elem.y),
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              stepped: false,
              backgroundColor: 'rgba(41, 69, 255, 0.1)',
              fill: true,
              tension: 0
            }
          ]}
          disabled={false}
        />
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={'/images/icons/calendar50.svg'}
          columns={columns}
          rows={rows}
          totalRow={[numberToScientificNotation(totalCo2 / 1000)]}
        />
        <div className='distribution-description-wrapper'>
          <div className='distribution-card on-card-gray-bg-color'>
            <h3 className='body1-bold-font'>{t('ghgReport.emissionsDistribution')}</h3>
            <PieChartDistribution
              pieChartData={pieChartData}
              legends={pieChartData.map((elem) => ({
                title: elem.title,
                color: elem.color,
                value: numberToDecimal(elem.value)
              }))}
            />
          </div>
          <DescriptionBox
            descriptions={[
              t(`ghgReport.${i18key}EmissionsDescription.total`, {
                total: numberToScientificNotation(totalCo2 / 1000),
                percentage: numberToScientificNotation((totalCo2 / organizationCo2e) * 100),
                tradeName: organization.trade_name ?? ''
              }),
              t(`ghgReport.${i18key}EmissionsDescription.intensity`, {
                intensity: numberToScientificNotation(intensity)
              })
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default TransportDistribution;
