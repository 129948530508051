import { useState } from 'react';

type UseFormLineHelpersParams<T extends object> = {
  setLines: (entry: ((prev: T[]) => T[]) | T[]) => void;
};

export const useFormLineHelpers = <T extends object>({ setLines }: UseFormLineHelpersParams<T>) => {
  const onDelete = (index: number) => () => {
    setLines((prev) => prev.filter((_, i) => i !== index));
  };

  const onEdit = (index: number) => (newLine: T) => {
    setLines((prev) => prev.map((line, i) => (i === index ? newLine : line)));
  };

  const onAdd = (newLine: T) => {
    setLines((prev) => [...prev, newLine]);
  };

  return { onDelete, onEdit, onAdd };
};

export const useLines = <T extends object>(defaultLines?: T[]) => {
  const [lines, setLines] = useState(defaultLines ?? []);

  const { onDelete, onEdit } = useFormLineHelpers({ setLines });

  return { lines, onDelete, onEdit, setLines };
};
