import React, { useContext, useEffect, useState } from 'react';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';
import StepCarouselList from '../../../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../../../ui/stepCarouselList/useStepCarousel';
import { useTranslation } from 'react-i18next';
import { Facility } from '../../../../../../../../types/entities/facility';
import { InvoiceType, Percentages } from '../../../../../../../../types/entities/invoice';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../../../../customHooks/useSelectedOrganization';
import useGetData from '../../hooks/useGetData';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { FacilityFuelType } from '../../../../../../../../types/entities/facilityFuel';
import { ROUTES } from '../../../../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import InputWrapper from '../../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from '../../../../../../../ui/formComponents2/inputNumber/InputNumber';
import Select from '../../../../../../../ui/formComponents2/select/Select';
import FormText from '../../../../../../../ui/formComponents2/formInputs/formText/FormText';
import FormCalendarDouble from '../../../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import DivideConsumptionsWrapper from '../common/divideConsumptions/DivideConsumptionsWrapper';
import FormButtonSection from '../../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import TooltipWrapper from '../../../../../../../ui/tooltip/TooltipWrapper';
import Button from '../../../../../../../ui/button/Button';
import checkFormErrors from '../../../../../../../../utils/checkFormErrors';
import { UserContext } from '../../../../../../../../context/userContext';
import CustomSkeletonLoader from '../../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useGetUnits from '../../hooks/useGetUnits';
import { buildPostEssentials } from '../../builders/buildPostEssentials';
import { backPostInvoiceToFront } from '../../adapters/backPostInvoiceToFront';
import FormElementFull from '../../../../../../../ui/formComponents/formElementFull/FormElementFull';
import {
  validateDates,
  validateFacilityWithZero,
  validateFacilityWithoutId
} from '../validations/validator';
import ErrorText from '../../../../../../../ui/errorText/ErrorText';
import { useAddInvoice } from 'components/measure/facilities/facilityDetail/hooks/useInvoiceFacilities';
import { defaultFacilityInvoiceForm } from '../../constants';

type Props = {
  switchToAuto: () => void;
  facility: Facility;
  addInvoice: (invoice: InvoiceType | null) => void;
  type: string;
};

type FormData = {
  emissionFactor: string;
  facilityFuel: SelectOptionFormat;
  unitDefault: SelectOptionFormat;
  unitCustom: SelectOptionFormat;
  quantity: string;
  invoiceId: string;
  startDate: string;
  endDate: string;
  cups: string;
  percentages: Percentages[];
  errors: ErrorType[];
};

const Water = ({ switchToAuto, facility, addInvoice, type }: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const navigate = useNavigate();
  const { mutateAsync: mutate } = useAddInvoice();

  const selectedOrganization = useSelectedOrganization();
  const user = useContext(UserContext);

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: [
      {
        id: 'default',
        text: t('facilityDetail.default')
      },
      {
        id: 'custom',
        text: t('facilityDetail.custom')
      }
    ]
  });

  const [formData, setFormData] = useState<FormData>({
    ...defaultFacilityInvoiceForm,
    percentages: [
      {
        facility: { id: facility.id, name: facility.name },
        organization: {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        },
        percentage: '100'
      }
    ],
    errors: []
  });
  const [unitsCustom, setUnitsCustom] = useState<SelectOptionFormat[]>([]);
  const [groupSelected, setGroupSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { facilityFuels, groups, loadingData, organizationOptions, lastUsedFacilityPercentages } =
    useGetData({
      type,
      country: facility.country,
      facilityId: facility.id
    });

  useEffect(() => {
    if (lastUsedFacilityPercentages.length > 0) {
      setFormData((prev) => ({
        ...prev,
        percentages: lastUsedFacilityPercentages.map((elem) => ({
          facility: {
            id: elem.facility_id,
            name: elem.facility_name
          },
          organization: {
            id: elem.organization_id,
            name: elem.company_name
          },
          percentage: (elem.percentage * 100).toString()
        }))
      }));
    }
  }, [JSON.stringify(lastUsedFacilityPercentages)]);
  const { units, setUnits } = useGetUnits({ type, country: facility.country });

  const onChangeFacilityFuel = (value: SelectOptionFormat) => {
    setFormData((prev) => ({
      ...prev,
      facilityFuel: value,
      unitDefault: { id: '', name: '' },
      errors: formData.errors.filter((elem) => elem.error !== 'facilityFuel')
    }));
    const foundFacilityFuel = facilityFuels?.find((elem: FacilityFuelType) => elem.id === value.id);
    if (!foundFacilityFuel) return;
    setUnits(foundFacilityFuel.units);
  };

  const onSelectGroup = (newGroup: SelectOptionFormat) => {
    setGroupSelected(newGroup);
    const groupToGet = groups.find((group) => group.group_id === newGroup.id);
    const units: SelectOptionFormat[] =
      groupToGet?.emission_factors.map((elem) => ({
        id: elem?.unit?.id ?? '',
        name: elem?.unit?.name ?? ''
      })) ?? [];
    setUnitsCustom(units?.at(0) ? [units[0]] : []);
    setFormData((prev) => ({
      ...prev,
      emissionFactor: groupToGet?.group_id ?? ''
    }));
  };

  const renderByStep = () => {
    if (stepSelected?.id === 'default') {
      return (
        <>
          {facilityFuels && facilityFuels.length > 0 && (
            <FormSelect
              icon='/images/icons/catalog.svg'
              placeholder={t('facilityDetail.selectAFuel')}
              label={t('facilityDetail.fuel')}
              options={facilityFuels.map((elem: FacilityFuelType) => ({
                id: elem.id,
                name: t(`facility_fuels.${elem.name}`)
              }))}
              value={formData.facilityFuel as SelectOptionFormat}
              onChange={onChangeFacilityFuel}
              error={formData.errors.find((elem) => elem.error === 'facilityFuel')}
            />
          )}
        </>
      );
    }
    return (
      <FormSelect
        label={
          <span>
            {t('facilityDetail.emissionFactor')}{' '}
            <a
              onClick={() => navigate(ROUTES.CUSTOM_EMISSION_FACTORS)}
              className='highlight-text-color pointer'>
              {t('facilityDetail.emissionFactorHere')}
            </a>
          </span>
        }
        icon={'/images/icons/fire50.svg'}
        placeholder={t('facilityDetail.selectGroup')}
        options={groups?.map((group) => ({
          id: group.group_id,
          name: group.group_name
        }))}
        value={groupSelected}
        onChange={onSelectGroup}
        error={formData.errors.find((error) => error.error === 'customFactor')}
      />
    );
  };

  const handleCreateInvoice = async () => {
    const optionalFields = ['invoiceId', 'cups', 'supplier', 'facilityFuel'];
    if (stepSelected?.id === 'default') {
      optionalFields.push('emissionFactor');
    }
    if (stepSelected?.id === 'custom') {
      optionalFields.push('facilityFuel');
      optionalFields.push('unitDefault');
    } else {
      optionalFields.push('unitCustom');
    }

    // Check if there are errors in the form
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    const dateError = validateDates(formData.startDate, formData.endDate);
    if (dateError) {
      newErrors.push(dateError);
    }

    const facilityWithZeroError = validateFacilityWithZero(formData.percentages);
    if (facilityWithZeroError) {
      newErrors.push(facilityWithZeroError);
    }

    const facilityWithoutIdError = validateFacilityWithoutId(formData.percentages);
    if (facilityWithoutIdError) {
      newErrors.push(facilityWithoutIdError);
    }

    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);

    const mode = stepSelected?.id === 'custom' ? 'custom' : 'default';

    const { invoice, facility_fuel, parsedUnitId } = buildPostEssentials({
      facility_id: facility.id,
      formData,
      user,
      type,
      mode,
      facilityFuels
    });

    setLoadingButton(false);

    const data = await mutate({ invoice });

    if ((data && 'error' in data) || !user) {
      setFormData((prev) => ({
        ...prev,
        errors: [{ error: 'general', description: t('error.somethingWentWrong') }]
      }));
      return;
    }

    const unit = units.find((unit) => unit.id === parsedUnitId);

    addInvoice(backPostInvoiceToFront(data, facility.id, facility_fuel, user, unit));
  };

  const unitOptions = stepSelected?.id === 'custom' ? unitsCustom : units;

  const foundGeneralError = formData.errors.find((error) => error.error === 'general');

  const buttonTooltip =
    formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0) === 0
      ? t('facilityDetail.noPercentageAssignedYet')
      : t('facilityDetail.percentageError');

  if (loadingData) return <CustomSkeletonLoader count={2} />;

  return (
    <>
      <FormWrapper style={{ maxHeight: '40vh', overflowY: 'auto' }}>
        {flags?.customize && (
          <FormElementFull>
            <div className='type-selector'>
              <span className='input-label-font on-light-text-color '>
                {t('facilityDetail.selectType')}
                {': '}
              </span>
              <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'small'} />
            </div>
          </FormElementFull>
        )}
        {renderByStep()}
        <InputWrapper
          icon={'/images/icons/plus.svg'}
          label={t('facilityDetail.quantity')}
          error={formData.errors.find(
            (elem) =>
              elem.error === 'quantity' ||
              elem.error === 'unitCustom' ||
              elem.error === 'unitDefault'
          )}>
          <InputNumber
            placeholder={t('facilityDetail.selectQuantity')}
            onChange={onChangeValue('quantity')}
            value={formData.quantity as string}
          />
          <Select
            placeholder={''}
            options={unitOptions.map((elem) => ({
              id: elem.id,
              name: t(`units.${elem.name}`)
            }))}
            value={
              stepSelected?.id === 'custom'
                ? (formData.unitCustom as SelectOptionFormat)
                : (formData.unitDefault as SelectOptionFormat)
            }
            onChange={
              stepSelected?.id === 'custom'
                ? onChangeValue('unitCustom')
                : onChangeValue('unitDefault')
            }
          />
        </InputWrapper>
        <FormText
          icon='/images/icons/star.svg'
          placeholder={t('facilityDetail.selectInvoiceId')}
          label={t('facilityDetail.invoiceId')}
          value={formData.invoiceId as string}
          onChange={onChangeValue('invoiceId')}
          error={formData.errors.find((elem) => elem.error === 'invoiceId')}
        />
        <FormCalendarDouble
          label={t('facilityDetail.invoiceDates')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate ? (formData.startDate as string) : ''}
          endDate={formData.endDate ? (formData.endDate as string) : ''}
          error={formData.errors.find(
            (error) =>
              error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
          )}
        />
        <DivideConsumptionsWrapper
          setFormData={setFormData}
          organizationOptions={organizationOptions}
          formData={{
            ...formData,
            unit: stepSelected?.id === 'custom' ? formData.unitCustom : formData.unitDefault
          }}
          units={unitOptions}
          facility={facility}
        />
      </FormWrapper>
      <FormButtonSection>
        <TooltipWrapper
          text={buttonTooltip}
          shouldAppear={!!formData.errors.find((elem) => elem.error === 'percentages')}
          style={{ width: '100%' }}>
          <Button
            lookAndFeel='primary'
            text={t('facilityDetail.confirm')}
            onClick={handleCreateInvoice}
            loading={loadingButton}
            disabled={!!formData.errors.find((elem) => elem.error === 'percentages')}
          />
        </TooltipWrapper>
      </FormButtonSection>
      {foundGeneralError && <ErrorText>{foundGeneralError.description}</ErrorText>}
    </>
  );
};

export default Water;
