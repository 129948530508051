import Icon from 'components/ui/icon/Icon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Category from './Category';
import { geti18nKey } from '../../utils';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

type Props = {
  role: Role;
  onClick: () => void;
  setRoleToDelete: (role: Role) => void;
  disableDelete: boolean;
};

const RoleCard = ({ role, onClick, setRoleToDelete, disableDelete }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles.categories' });
  const { t: tManageRoles } = useTranslation('translation', { keyPrefix: 'members.manageRoles' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [maxCategories, setMaxCategories] = useState(0);
  const [widthCategories, setWidthCategories] = useState(0);

  const selectedOrganization = useSelectedOrganization();

  const MAX_WIDTH_CATEGORIES = 140; // in px

  const omitParenthesized = (str: string) => str.replace(/\s*\(.*?\)/g, '');

  const buildTooltipMessage = (categories: string[]) => {
    return (
      t('tooltip') +
      ' ' +
      categories
        .map((category, index) => {
          if (index === categories.length - 1) return tGeneral('and') + ' ' + t(category);
          return t(category);
        })
        .join(', ') +
      '.'
    );
  };

  const addWidthCallback = (width: number) => {
    // Add up widths of categories until it reaches
    setWidthCategories((prevWidth) => {
      const newWidth = prevWidth + width;
      if (newWidth < MAX_WIDTH_CATEGORIES) {
        setMaxCategories((prevMax) => prevMax + 1);
        return newWidth;
      }
      return prevWidth;
    });
  };

  const predefined = !role.organization?.id;

  // omit predefined role permissions from rolecard details
  let permissions = role.permissions.filter((permission) => permission.category !== 'general');
  // omit 'csrd:general' from rolecard details
  permissions = permissions.filter((permission) => permission.resource !== 'csrd:general');

  const visibleCategories = role.fixedTags?.length
    ? role.fixedTags.map((tag) => ({ resource: tag } as Permission))
    : permissions
        .sort((a, b) => {
          // sort by the number of chars in the category name, first the ones with less chars
          return (
            omitParenthesized(t(geti18nKey(a.resource))).length -
            omitParenthesized(t(geti18nKey(b.resource))).length
          );
        })
        .slice(0, maxCategories);

  const rest = predefined ? 0 : permissions.length - visibleCategories.length;

  const dropdownOptions: DropDownActionOption[] = [
    {
      id: 'edit',
      name: tManageRoles('edit'),
      onClick: onClick
    },
    {
      id: 'delete',
      name: tManageRoles('delete'),
      onClick: () => setRoleToDelete(role),
      disabled: disableDelete,
      tooltip: disableDelete ? tManageRoles('cannotDelete') : undefined
    }
  ];

  const isSameOrganization = role.organization?.id === selectedOrganization?.id;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className='flex rounded-8 py-6 px-4 solid-border hover:border-primary items-center gap-4 pointer'
      style={{
        flexBasis: 'calc(50% - 1rem)',
        boxSizing: 'border-box',
        maxWidth: 'calc(50% - 1rem)',
        position: 'relative'
      }}
      onClick={onClick}>
      {!predefined && isSameOrganization && (
        <div style={{ position: 'absolute', top: '1rem', right: '0.6rem' }}>
          <ButtonDropdown
            button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
            options={dropdownOptions}
          />
        </div>
      )}
      <div
        className={`flex items-center justify-center rounded-8 w-8 h-8 ${
          predefined ? 'bg-purple-40' : 'bg-purple'
        }`}>
        <Icon icon='user' color='white' size='large' />
      </div>
      <div className='flex flex-col gap-2'>
        <span className='font-14 weight-600'>{role.name}</span>
        <div className='flex gap-1 items-center'>
          {visibleCategories.map((permission: Permission) => {
            return (
              <Category
                name={omitParenthesized(t(geti18nKey(permission.resource)))}
                key={permission.id}
                addWidthCallback={addWidthCallback}
              />
            );
          })}
          {rest > 0 && (
            <TooltipWrapper
              key={'rest'}
              text={buildTooltipMessage(
                permissions.map((permission) => geti18nKey(permission.resource))
              )}
              position='right'>
              <Category name={`+${rest}`} addWidthCallback={addWidthCallback} />
            </TooltipWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleCard;
