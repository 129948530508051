import { useTranslation } from 'react-i18next';

const useOptions = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  return [
    {
      id: 'all',
      name: t('all')
    },
    {
      id: '1',
      name: t('scope1')
    },
    {
      id: '2',
      name: t('scope2')
    },
    {
      id: '3',
      name: t('scope3')
    }
  ];
};

export default useOptions;
