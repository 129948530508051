import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useUser } from 'customHooks/useUser';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDownloadMaterials } from '../hooks/useDownloadMaterials';

export const DownloadMaterialsBTN = () => {
  const { t } = useLCATranslation();
  const dispatch = useDispatch();
  const org = useSelectedOrganization();
  const user = useUser();
  const [wasClicked, setWasClicked] = useState(false);
  const { downloadMaterials, downloading } = useDownloadMaterials();

  const onClickDownload = async () => {
    if (!org) return;

    await downloadMaterials({ org_id: org.id, userId: user?.id ?? '' });

    setWasClicked(true);

    dispatch(
      setNotification(t('materialManagement.downloadRequestedTooltip', { email: user?.email }))
    );
  };

  if (wasClicked) {
    return (
      <Button
        text={t('materialManagement.downloadRequested')}
        lookAndFeel='completed'
        iconNode={<Icon icon='success' color='white' />}
        size='small'
      />
    );
  }

  return (
    <Button
      text={t('materialManagement.downloadMaterials')}
      lookAndFeel='secondary'
      size='small'
      iconNode={!downloading ? <Icon icon='download' color='gray-dark' /> : undefined}
      onClick={onClickDownload}
      loading={downloading}
    />
  );
};
