import { useEffect, useState } from 'react';
import { getSuppliers } from 'services/api/waste';

const useGetData = () => {
  const [suppliers, setSuppliers] = useState<SelectOptionFormat[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getSuppliers();
      const mappedSuppliers = data.map((provider: any) => ({
        id: provider,
        name: provider
      }));

      setSuppliers(mappedSuppliers);
      setLoading(false);
    };

    getData();
  }, []);

  return {
    suppliers,
    loading
  };
};

export default useGetData;
