import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import MainPageLayout from 'components/layout/mainPageLayout/MainPageLayout';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import Modal from 'components/ui/modal/Modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import EditMember from './editMember/EditMember';
import { User } from 'types/entities/user';
import DeleteMember from './deleteMember/DeleteMember';
import AddMemberV2 from './addMember/AddMemberV2';
import { Filters } from 'components/layout/NewFilters/Filters';
import FiltersDate from 'components/layout/NewFilters/FiltersDate';
import { FilterText } from 'components/layout/NewFilters/FilterText';
import Sorting from 'components/ui/sorting/Sorting';
import FilterHandlers from 'components/layout/NewFilters/FiltersHandlers';
import { InputSize } from 'types/utilsEnums/input';
import useSortingOptions from './hooks/useSortingOptions';
import ManageRolesWrapper from './manageRoles/ManageRolesWrapper';
import { setNotification } from 'actions/notification';
import { useDispatch } from 'react-redux';
import useRoles from './manageRoles/hooks/useRoles';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import moment from 'moment';

const MembersV2 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const { t: tInput } = useTranslation('translation', { keyPrefix: 'input' });
  const { t: tNotification } = useTranslation('translation', { keyPrefix: 'notification' });

  const selectedOrganization = useSelectedOrganization();

  const hasManageUsersPermission = !!selectedOrganization?.role?.permissions.find(
    (permission: Permission) => permission.tag === PermissionTags.AUTH_USERS_ALL
  );

  const dispatch = useDispatch();

  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [showManageRoles, setShowManageRoles] = useState(false);

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'created_at',
      order: 'desc'
    }
  ]);
  const [open, setOpen] = useState(false);

  const [roles, setRoles] = useState<Role[]>([]);

  const { data, isLoading, invalidateRoles } = useRoles();

  useEffect(() => {
    if (data) setRoles(data);
  }, [JSON.stringify(data)]);

  const { columns, memberToEdit, memberToDelete, setMemberToEdit, setMemberToDelete } =
    useColumns();

  const sortingOptions = useSortingOptions();

  const dateFilters: Primitive[] =
    (filters.find((filter) => filter.field === 'created_at')?.value as Primitive[]) || [];

  const startDateFilterTimestamp =
    dateFilters && dateFilters.length ? parseInt(dateFilters[0] as string, 10) : undefined;
  const endDateFilterTimestamp =
    dateFilters && dateFilters.length > 1 ? parseInt(dateFilters[1] as string, 10) : undefined;

  const startDateFilterString = startDateFilterTimestamp
    ? moment((startDateFilterTimestamp as number) * 1000).format('YYYY-MM-DD')
    : undefined;
  const endDateFilterString = endDateFilterTimestamp
    ? moment((endDateFilterTimestamp as number) * 1000).format('YYYY-MM-DD')
    : undefined;

  const { members, setMembers, fetchData, loading, firstLoading, total, invalidate } = useGetData({
    email: filters.find((filter) => filter.field === 'email')?.value as string,
    startDate: startDateFilterString,
    endDate: endDateFilterString,
    status: filters.find((filter) => filter.field === 'status')?.value as string,
    sort: sorters.length
      ? `${sorters[0].order === 'desc' ? '-' : ''}${sorters[0].field}`
      : undefined
  });

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };

  const addMember = (member: User) => {
    // TODO: insert member into list at the right place depending on sorters and filters:
    setMembers([member, ...members]);
    if (!member.first_name) {
      dispatch(setNotification(tNotification('invitationSent')));
    } else {
      dispatch(setNotification(tNotification('addMember')));
    }
    invalidate();
  };

  const deleteMember = (id: string) => {
    setMembers(members.filter((member) => member.id !== id));
    dispatch(setNotification(tNotification('deleteMember')));
    setMemberToDelete(undefined);
    invalidate();
  };

  const editMember = (member: User) => {
    setMembers(
      members.map((m) => {
        if (m.id === member.id) {
          return member;
        }
        return m;
      })
    );
    dispatch(setNotification(tNotification('editMember')));
    setMemberToEdit(undefined);
    invalidate();
  };

  return (
    <MainPageLayout
      sectionTitle={t('title')}
      title={hasManageUsersPermission ? t('subtitle') : undefined}
      gridTemplateRows='4.5rem 0.05fr 10rem 4rem 1fr'
      breadcrumb={hasManageUsersPermission && <Breadcrumb />}
      buttons={
        hasManageUsersPermission && (
          <div className='flex gap-1 items-center'>
            <Button
              lookAndFeel='primary'
              text={t('addNewUser')}
              iconNode={<Icon icon='add' color='white' />}
              onClick={() => setShowAddNewUser(true)}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              text={t('manageRolesButton')}
              iconNode={<Icon icon='settings' color='gray-dark' />}
              onClick={() => setShowManageRoles(true)}
              size='small'
            />
          </div>
        )
      }>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu>
          <FiltersDate fields={['created_at']} />
          {/* <FilterSection.Multiple
            title={t('purchases.status')}
            field='status'
            type='in'
            options={Object.values(SHIPMENT_STATUS).map((status) => ({
              value: status,
              label: statusIconSelect(status, [], false)
            }))}
          /> */}
        </Filters.Menu>
        <InfiniteList
          data={members.map((member) => ({ ...member, disabled: member.isInvitation }))}
          fetchData={fetchData}
          columns={columns}
          loading={loading}
          firstLoading={firstLoading}
          total={total}
          header={
            <>
              <div className='flex gap-x-2 items-center'>
                <FilterText
                  field='email'
                  type='il'
                  placeholder={t('email')}
                  size={InputSize.MEDIUM}
                />
                <Sorting
                  options={sortingOptions}
                  label={tInput('sorting.orderBy')}
                  placeholder={tInput('placeholderSelect')}
                  onSort={onSort}
                  size={InputSize.MEDIUM}
                />
                <FilterHandlers blacklistedFilters={{ all: ['email'] }} />
              </div>
              <TotalLegend total={total} loading={loading} i18key='members' />
            </>
          }
        />
      </Filters.Root>
      <Modal.WithPortal
        show={showAddNewUser}
        onClose={() => setShowAddNewUser(false)}
        width='657px'
        maxWidth='657px'>
        <AddMemberV2
          onClose={() => setShowAddNewUser(false)}
          members={members}
          addMember={addMember}
          roles={roles}
          isLoading={isLoading}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!memberToEdit}
        onClose={() => setMemberToEdit(undefined)}
        width='570px'
        maxWidth='570px'>
        {memberToEdit && (
          <EditMember
            memberToEdit={memberToEdit}
            onClose={() => setMemberToEdit(undefined)}
            editMember={editMember}
            roles={roles}
            isLoading={isLoading}
          />
        )}
      </Modal.WithPortal>

      <Modal.WithPortal
        show={!!memberToDelete}
        onClose={() => setMemberToDelete(undefined)}
        width='428px'
        maxWidth='428px'>
        {memberToDelete && (
          <DeleteMember
            deleteMember={deleteMember}
            memberToDelete={memberToDelete}
            onClose={() => setMemberToDelete(undefined)}
          />
        )}
      </Modal.WithPortal>
      <ManageRolesWrapper
        showManageRoles={showManageRoles}
        setShowManageRoles={setShowManageRoles}
        roles={roles}
        setRoles={(roles) => {
          setRoles(roles);
          invalidateRoles();
        }}
        isLoading={isLoading}
      />
    </MainPageLayout>
  );
};

export default MembersV2;
