import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import Button from 'components/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Icon from 'components/ui/icon/Icon';

type Props = {
  handleDelete: () => Promise<void>;
  onClose: () => void;
  roleName: string;
};

const DeleteRole = ({ handleDelete, onClose, roleName }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles.deleteRole' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    setLoadingButton(true);
    await handleDelete();
    setLoadingButton(false);
    onClose();
  };

  return (
    <>
      <FormHeader title={t('title') + ` '${roleName}'`} description={t('description')} />
      <FormButtonSection>
        <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={handleSubmit}
          loading={loadingButton}
          iconNode={<Icon icon='trash' color='error' />}
        />
      </FormButtonSection>
    </>
  );
};

export default DeleteRole;
