import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import { IHeavyFileStatusFrontend } from 'types/entities/heavyFilesStatus';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import {
  CATEGORY_WITH_DELETE_OPTION,
  DEPLOY_DATE,
  STATUS_TOOLTIP,
  STATUS_WITH_DELETE_OPTION
} from '../constants';
import { CategoriesUploadedFiles } from '../constants';
import NameIcon from 'components/ui/nameIcon/NameIcon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { hasKey } from 'utils/objects';
import { UploadedFilesStatusTag } from '../components/uploadedFilesStatusTag/UploadedFilesStatusTag';
import { Ellipsis } from 'components/ui/ellipsis/Ellipsis';
import moment from 'moment';
import useErrors from 'customHooks/useErrors';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import useRole from 'customHooks/useRole';

type Params = {
  setFileToDelete: (file: IHeavyFileStatusFrontend) => void;
  category: CategoriesUploadedFiles;
};

export const useColumns = ({ setFileToDelete, category }: Params) => {
  const { t } = useTranslation();
  const { permissions } = useRole();

  // const { isOwner } = useIsOwner();
  const ERRORS = useErrors();

  const columns: Column<IHeavyFileStatusFrontend>[] = [
    {
      title: t('uploadedFiles.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: IHeavyFileStatusFrontend['name']) => {
        return <Ellipsis width='400px'>{name}</Ellipsis>;
      }
    },
    {
      title: t('uploadedFiles.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: t('uploadedFiles.uploadedBy'),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (user: IHeavyFileStatusFrontend['uploadedBy']) => {
        return (
          <span className='flex gap-x-2 items-center'>
            <NameIcon name={user?.first_name} lastName={user?.last_name} />
            {user?.first_name} {user?.last_name}
          </span>
        );
      }
    },
    {
      title: t('uploadedFiles.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: IHeavyFileStatusFrontend['status'], row) => {
        if (!status) {
          return null;
        }

        const statusText = t(`uploadedFiles.statusTexts.${status}`);

        if (hasKey(STATUS_TOOLTIP, status)) {
          const deletedBy = [];

          if (row.deletedBy?.first_name) {
            deletedBy.push(row.deletedBy.first_name);
          }

          if (row.deletedBy?.last_name) {
            deletedBy.push(row.deletedBy.last_name);
          }

          let errorToShow = '';
          if (row?.errorInfo && row.errorInfo in ERRORS.FILES)
            errorToShow = ERRORS.FILES[row.errorInfo as keyof typeof ERRORS.FILES];

          return (
            <TooltipWrapper
              text={t(STATUS_TOOLTIP[status], {
                category,
                deletedAt: row.deletedAt,
                deletedBy: deletedBy.join(' '),
                errorInfo: errorToShow
              })}>
              <UploadedFilesStatusTag status={status} text={statusText} />
            </TooltipWrapper>
          );
        }

        return <UploadedFilesStatusTag status={status} text={statusText} />;
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (_id, elem) => {
        const options: DropDownActionOption[] = [
          {
            id: elem.id + '-download',
            name: t('uploadedFiles.download'),
            onClick: () => {
              window.open(elem.url, '_blank');
            }
          }
        ];

        if (elem.status && CATEGORY_WITH_DELETE_OPTION.includes(category)) {
          options.push({
            id: elem.id + '-delete',
            disabled:
              !STATUS_WITH_DELETE_OPTION.includes(elem.status) ||
              moment.utc(elem.createdAt, 'DD/MM/YYYY').isBefore(DEPLOY_DATE) ||
              !permissions?.find(
                (permission: Permission) => permission.tag === PermissionTags.FILE_DELETE
              ),
            name: t('uploadedFiles.delete'),
            onClick: () => {
              setFileToDelete(elem);
            }
          });
        }
        return <ButtonDropdown options={options} />;
      }
    }
  ];

  return { columns };
};
