import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '../inputLabel/InputLabel';

import './styles.scss';

type Input = {
  label?: string;
  tooltip?: string;
  input: ReactNode;
};
export type Props = {
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: string; // small for smaller paddings and margins
  disabled?: boolean;
  inputs: Input[];
};

const InputMultiple = ({
  inputs,
  error,
  fontClass = 'input-font',
  height = '33px',
  size = '',
  disabled = false
}: Props) => {
  const { t } = useTranslation();
  const foundOneLabel = inputs.find((elem) => elem.label);
  return (
    <div className={`input input-multiple ${foundOneLabel ? 'has-label' : ''}`}>
      <div
        className={`input__text-select flex input-font small input-border-color multiple ${fontClass} ${size} ${
          disabled ? 'input-disabled-styles' : ''
        } ${error ? 'error-border-color' : 'input-border-color'}`}
        style={{ height }}>
        {inputs.map((elem, index) => (
          <>
            <div className='input-element-wrapper' key={index}>
              {elem.label && <InputLabel label={elem.label} tooltip={elem.tooltip ?? ''} />}
              {elem.input}
            </div>
            {index < inputs.length - 1 && (
              <div className='divider-line' key={'divider-' + index}></div>
            )}
          </>
        ))}
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputMultiple;
