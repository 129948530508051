export type OutputData = {
  organization: string;
  category: string;
  data: string;
  type: string;
  frameworks: string[];
  status: 'COMPLETE' | 'WITH_DATA' | 'WITHOUT_DATA';
};

export const outputData: OutputData[] = [
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Descripción del modelo de negocio',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Políticas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'KPIs de seguimiento de las políticas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Riesgos asociados a las actividades del grupo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Número total de empleados',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Distribución de empleados por sexo, edad, país y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Número total y distribución de modalidades de contrato de trabajo',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos a tiempo parcial por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos indefinidos por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos temporales por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Número de despidos por sexo, edad y clasificación personal',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Remuneraciones medias y su evolución desagregados por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Brecha salarial',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Remuneración de puestos de trabajo iguales o de media de la sociedad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Remuneración media de los consejeros y directivos (incluyendo retribución variable, dieta, indemnizaciones, el pago a los sitemas de previsión de ahorro a largo plazo y cualquier otra percepción) desagregada por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Impantación de políticas de desconexión laboral',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Empleados con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Número de horas de absentismo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Medidas destinadas a facilitar el disfrute de la conciliación y fomentar el ejercicio corresponsable de estos por parte de ambos progenitores',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Condiciones de salud y seguridad en el trabajo desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Accidentes de trabajo, su frecuencia y gravedad desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Enfermedades profesionales desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Organización del diálogo social, incluyendo procedimientos para informar y consultar al personal y negociar con ellos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Porcentaje de empleados cubiertos por convenio colectivo por país',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Balance de los convenios colectivos, particularmente en el campo de la salud y la seguridad en el trabajo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Políticas implementadas en el campo de la formación',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Cantidad total de horas de formación por categorías profesionales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Accesibilidad universal de las personas con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Medidas adoptadas para promover la igualdad de trato y de oportunidades entre mujeres y hombres',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Planes de igualdad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Medidas adoptadas para promover el empleo, protocolos contra el acoso sexual y por razón de sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Protocoloes contra el acoso sexual y por razón de sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Integración y la accesibilidad universal de las personas con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Política contra todo tipo de discriminación y, en su caso, de gestión de la diversidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Agua',
    data: 'Consumo de agua',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Economía circular',
    data: 'Generación total de residuos',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Número total y distribución de modalidades de contrato de trabajo sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Consumo electricidad',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Consumo energía renovable',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Consumo electricidad de origen renovable',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Consumo de combustible',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en el medio ambiente, la salud y seguridad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en la salud y seguridad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Medio ambiente',
    data: 'Los procedimientos de evaluación o certificación ambiental',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Medio ambiente',
    data: 'Los recursos dedicados a la prevención de riesgos ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Medio ambiente',
    data: 'La aplicación del principio de precuación',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Medio ambiente',
    data: 'La cantidad de provisiones y garantías para riesgos ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Medidas para prevenir, reducir, o reparar las emisiones de carbono que afectan gravemente el medio ambiente: teniendo en cuenta cualquier forma de contaminación atmosférica especifica de una actividad, incluyendo ruido y contaminación lumínica',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Economía circular',
    data: 'Medidas de prevención, reciclaje, reutilización, otras formas de recuperación y eliminación de desechos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Economía circular',
    data: 'Acciones para combatir el desperdicio de alimentos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Los elementos importantes de las emisiones de gases de efecto invernadero generados como resultado de la actividades de la empresa, incluyendo el uso de los bienes y servicios producidos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Medidas adoptadas para adaptarse a las consecuencias del cambio climático',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Las metas de reducción establecidas voluntariamente a medio y largo plazo para reducir la emisiones de gases de efecto invernadero y los medios implementados para tal fin',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Huella de carbono total, alcance 1, 2 y 3 por empresa',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Huella de carbono',
    data: 'Huella de carbono por instalación',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Gobernanza',
    data: 'Medidas\nadoptadas para prevenir la corrupción y el soborno',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Derechos Humanos',
    data: 'Aplicación de procedimientos de diligencia debida en materia de derechos humanos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Derechos Humanos',
    data: 'Prevención de los riesgos de vulneración de derechos humanos y, en su caso, medidas para mitigar, gestionar y reparar posibles abusos cometidos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Derechos Humanos',
    data: 'Denuncias por casos de vulneración de derechos humanos',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Derechos Humanos',
    data: 'Promoción y cumplimiento de las disposiciones de los convenios fundamentales de la Organización Internacional del Trabajo relacionadas con el respeto por la libertad de asociación y el derecho a la negociación colectiva\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Trabajadores',
    data: 'La eliminación de la discriminación en el empleo y la ocupación\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Derechos Humanos',
    data: 'La eliminación del trabajo forzoso u obligatorio; la abolición efectiva del trabajo infantil\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Gobernanza',
    data: 'Información relativa a la lucha contra la corrupción y el soborno',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Gobernanza',
    data: 'Medidas para luchar contra el blanqueo de capitales\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Gobernanza',
    data: 'Aportaciones a fundaciones y entidades sin ánimo de lucro',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Comunidades locales',
    data: 'El impacto de la actividad de la sociedad en el empleo y el desarrollo local\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Comunidades locales',
    data: 'El impacto de la actividad de la sociedad en las poblaciones locales y en el territorio\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Comunidades locales',
    data: 'Las relaciones mantenidas con los actores de las comunidades locales y las modalidades del diálogo con estos; las acciones de asociación o patrocinio\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Cadena de valor',
    data: 'La inclusión en la política de compras de cuestiones sociales, de igualdad de género y ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Cadena de valor',
    data: 'Consideración en las relaciones con proveedores y subcontratistas de su responsabilidad social y  \nambiental; sistemas de supervisión y auditorias y resultados de las mismas\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Consumidores',
    data: 'Medidas para la salud y la seguridad de los consumidores;  \nsistemas de reclamación, quejas recibidas y resolución de las mismas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Los beneficios obtenidos país por país',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Los impuestos sobre beneficios pagados\n',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Las subvenciones públicas recibidas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Justificación de ausencia de políticas.',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS ESTELARES S.A.',
    category: 'Info general',
    data: 'Detalles sobre los importes detallados en las cuentas anuales consolidadas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Descripción del modelo de negocio',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Políticas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'KPIs de seguimiento de las políticas',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Riesgos asociados a las actividades del grupo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Número total de empleados',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Distribución de empleados por sexo, edad, país y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Número total y distribución de modalidades de contrato de trabajo',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos a tiempo parcial por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos indefinidos por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Promedio anual de contratos temporales por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Número de despidos por sexo, edad y clasificación personal',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Remuneraciones medias y su evolución desagregados por sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Brecha salarial',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Remuneración de puestos de trabajo iguales o de media de la sociedad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Remuneración media de los consejeros y directivos (incluyendo retribución variable, dieta, indemnizaciones, el pago a los sitemas de previsión de ahorro a largo plazo y cualquier otra percepción) desagregada por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Impantación de políticas de desconexión laboral',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Empleados con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Número de horas de absentismo',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Medidas destinadas a facilitar el disfrute de la conciliación y fomentar el ejercicio corresponsable de estos por parte de ambos progenitores',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Condiciones de salud y seguridad en el trabajo desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Accidentes de trabajo, su frecuencia y gravedad desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Enfermedades profesionales desagregado por sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Organización del diálogo social, incluyendo procedimientos para informar y consultar al personal y negociar con ellos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Porcentaje de empleados cubiertos por convenio colectivo por país',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Balance de los convenios colectivos, particularmente en el campo de la salud y la seguridad en el trabajo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Políticas implementadas en el campo de la formación',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Cantidad total de horas de formación por categorías profesionales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Accesibilidad universal de las personas con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Medidas adoptadas para promover la igualdad de trato y de oportunidades entre mujeres y hombres',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Planes de igualdad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Medidas adoptadas para promover el empleo, protocolos contra el acoso sexual y por razón de sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Protocoloes contra el acoso sexual y por razón de sexo',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Integración y la accesibilidad universal de las personas con discapacidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Política contra todo tipo de discriminación y, en su caso, de gestión de la diversidad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Agua',
    data: 'Consumo de agua',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Economía circular',
    data: 'Generación total de residuos',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Número total y distribución de modalidades de contrato de trabajo sexo, edad y clasificación profesional',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Consumo electricidad',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Consumo energía renovable',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Consumo electricidad de origen renovable',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Consumo de combustible',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en el medio ambiente, la salud y seguridad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en la salud y seguridad',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Medio ambiente',
    data: 'Los procedimientos de evaluación o certificación ambiental',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Medio ambiente',
    data: 'Los recursos dedicados a la prevención de riesgos ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Medio ambiente',
    data: 'La aplicación del principio de precuación',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Medio ambiente',
    data: 'La cantidad de provisiones y garantías para riesgos ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Medidas para prevenir, reducir, o reparar las emisiones de carbono que afectan gravemente el medio ambiente: teniendo en cuenta cualquier forma de contaminación atmosférica especifica de una actividad, incluyendo ruido y contaminación lumínica',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Economía circular',
    data: 'Medidas de prevención, reciclaje, reutilización, otras formas de recuperación y eliminación de desechos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Economía circular',
    data: 'Acciones para combatir el desperdicio de alimentos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Los elementos importantes de las emisiones de gases de efecto invernadero generados como resultado de la actividades de la empresa, incluyendo el uso de los bienes y servicios producidos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Medidas adoptadas para adaptarse a las consecuencias del cambio climático',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Las metas de reducción establecidas voluntariamente a medio y largo plazo para reducir la emisiones de gases de efecto invernadero y los medios implementados para tal fin',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Huella de carbono total, alcance 1, 2 y 3 por empresa',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Huella de carbono por instalación',
    type: 'Cuantitativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Gobernanza',
    data: 'Medidas\nadoptadas para prevenir la corrupción y el soborno',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Derechos Humanos',
    data: 'Aplicación de procedimientos de diligencia debida en materia de derechos humanos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Derechos Humanos',
    data: 'Prevención de los riesgos de vulneración de derechos humanos y, en su caso, medidas para mitigar, gestionar y reparar posibles abusos cometidos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Derechos Humanos',
    data: 'Denuncias por casos de vulneración de derechos humanos',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Derechos Humanos',
    data: 'Promoción y cumplimiento de las disposiciones de los convenios fundamentales de la Organización Internacional del Trabajo relacionadas con el respeto por la libertad de asociación y el derecho a la negociación colectiva\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Trabajadores',
    data: 'La eliminación de la discriminación en el empleo y la ocupación\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Derechos Humanos',
    data: 'La eliminación del trabajo forzoso u obligatorio; la abolición efectiva del trabajo infantil\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Gobernanza',
    data: 'Información relativa a la lucha contra la corrupción y el soborno',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Gobernanza',
    data: 'Medidas para luchar contra el blanqueo de capitales\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Gobernanza',
    data: 'Aportaciones a fundaciones y entidades sin ánimo de lucro',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Comunidades locales',
    data: 'El impacto de la actividad de la sociedad en el empleo y el desarrollo local\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Comunidades locales',
    data: 'El impacto de la actividad de la sociedad en las poblaciones locales y en el territorio\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Comunidades locales',
    data: 'Las relaciones mantenidas con los actores de las comunidades locales y las modalidades del diálogo con estos; las acciones de asociación o patrocinio\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Cadena de valor',
    data: 'La inclusión en la política de compras de cuestiones sociales, de igualdad de género y ambientales',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Cadena de valor',
    data: 'Consideración en las relaciones con proveedores y subcontratistas de su responsabilidad social y  \nambiental; sistemas de supervisión y auditorias y resultados de las mismas\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Consumidores',
    data: 'Medidas para la salud y la seguridad de los consumidores;  \nsistemas de reclamación, quejas recibidas y resolución de las mismas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Los beneficios obtenidos país por país',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Los impuestos sobre beneficios pagados\n',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Las subvenciones públicas recibidas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Justificación de ausencia de políticas.',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Info general',
    data: 'Detalles sobre los importes detallados en las cuentas anuales consolidadas',
    type: 'Narrativo',
    frameworks: ['EINF'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Combustión estacionaria',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Combustión móvil',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones fugitivas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de proceso',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones Flag',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de plantas de tratamiento de aguas residuales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Descargas de plantas de tratamiento de aguas residuales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Electricidad',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Compras',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'CapEx',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones relacionadas con el combustible',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Mercancías recibidas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Residuos',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Viajes de negocios',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Empleados',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Bienes alquilados',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Mercancías enviadas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Procesamiento de productos vendidos',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Uso del producto final',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tratamiento de residuos del producto final',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de tus alquileres',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Franquicias',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Inversiones',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Agua',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Datos de contacto',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Métricas de intensidad',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Descripción de la empresa',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Límites de la organización',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tipo de enfoque',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Año base',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tipo de verificación',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Alcances incluidos y excluidos en el informe',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Justificación de los alcances incluidos y excluidos',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Notas adicionales',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Cambios en el año base (si los hay)',
    type: 'Narrativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 1',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 2',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 3',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Combustión estacionaria',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Combustión móvil',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones fugitivas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de proceso',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones Flag',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de plantas de tratamiento de aguas residuales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Descargas de plantas de tratamiento de aguas residuales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Electricidad',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Compras',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'CapEx',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones relacionadas con el combustible',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Mercancías recibidas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Residuos',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Viajes de negocios',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Empleados',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Bienes alquilados',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Mercancías enviadas',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Procesamiento de productos vendidos',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Uso del producto final',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tratamiento de residuos del producto final',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones de tus alquileres',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Franquicias',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Inversiones',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Agua',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Datos de contacto',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Métricas de intensidad',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Descripción de la empresa',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Límites de la organización',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tipo de enfoque',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Año base',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Tipo de verificación',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Alcances incluidos y excluidos en el informe',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Justificación de los alcances incluidos y excluidos',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Notas adicionales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Cambios en el año base (si los hay)',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 1',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 2',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales del alcance 3',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  },
  {
    organization: 'GALLETAS S.L',
    category: 'Huella de carbono',
    data: 'Emisiones GEI totales',
    type: 'Cuantitativo',
    frameworks: ['Huella de carbono'],
    status: 'WITHOUT_DATA'
  }
];
