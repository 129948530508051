import { allowedFrameworkDashboards } from './utils/constants';
import { useParams } from 'react-router-dom';
import GHGDashboard from './components/GHGDashboard';
import CSRDDashboard from './components/CSRDDashboard';
import ErrorFramework from './components/ErrorFramework';
import { EINFDashboard } from './components/EINFDashboard';

const FrameworkDashboard = () => {
  const { frameworkName } = useParams();
  if (frameworkName && allowedFrameworkDashboards.includes(frameworkName)) {
    switch (frameworkName) {
      case 'ghg_protocol':
        return <GHGDashboard />;
      case 'csrd':
        return <CSRDDashboard />;
      case 'einf':
        return <EINFDashboard />;
      default:
        return <ErrorFramework />;
    }
  }
  return <ErrorFramework />;
};

export default FrameworkDashboard;
