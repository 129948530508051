import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useTranslation } from 'react-i18next';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import { resendConfirmationCode } from 'services/authService';
import { useDispatch } from 'react-redux';
import { useErrorTranslations } from 'customHooks/translations';
import { setNotification } from 'actions/notification';

type EmailConfirmationProps = {
  email: string;
  handleConfirmCode: () => void;
  inputParams: any;
  loading?: boolean;
  error: ErrorType | undefined;
};

export const EmailConfirmation = ({
  email,
  handleConfirmCode,
  inputParams,
  loading,
  error
}: EmailConfirmationProps) => {
  const { t } = useTranslation('', { keyPrefix: 'signUp.emailConfirmation' });
  const { t: tError } = useErrorTranslations();
  const dispatch = useDispatch();

  const resendCode = async () => {
    try {
      await resendConfirmationCode({ email });
      dispatch(setNotification(t('codeResent')));
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === 'CODE_DELIVERY_FAILED') {
        dispatch(setNotification(tError('codeDeliveryFailed')));
        return;
      }
    }
  };

  return (
    <OnboardingImageWrapper title={t('title')} subtitle={t('subtitle')}>
      <div className='flex-col overflow-hidden w-80'>
        <InputWrapper label={t('verificationCode')} iconV2='key' error={error}>
          <InputText {...inputParams} placeholder={t('verificationCode')} />
        </InputWrapper>
        <FormButtonSection className='flex-col items-center gap-2' style={{ marginTop: '1em' }}>
          <Button
            lookAndFeel='primary'
            text={t('confirmAccount')}
            size='medium'
            loading={loading}
            onClick={handleConfirmCode}
          />
          <div className='flex justify-center gap-1 font-14'>
            {t('didntReceive')}
            <Button className='font-14' lookAndFeel='link' onClick={resendCode}>
              {t('resend')}
            </Button>
          </div>
        </FormButtonSection>
      </div>
    </OnboardingImageWrapper>
  );
};
