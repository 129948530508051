import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import getCountryName from 'utils/getCountryName';

type Props = {
  setInvestorToEdit: (investor: DMInvestor) => void;
  setInvestorToDelete: (investorId: DMInvestor) => void;
};

export const useColumns = ({ setInvestorToEdit, setInvestorToDelete }: Props) => {
  const { t, i18n } = useTranslation();

  const buildActions = (investor: DMInvestor) => [
    {
      id: `${investor.id}-edit`,
      name: t('doubleMateriality.context.investors.edit'),
      onClick: () => {
        setInvestorToEdit(investor);
      }
    },
    {
      id: `${investor.id}-delete`,
      name: t('doubleMateriality.context.investors.delete'),
      onClick: () => {
        if (investor.id) {
          setInvestorToDelete(investor);
        }
      }
    }
  ];

  const columns: Column[] = [
    {
      title: t('doubleMateriality.context.investors.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('doubleMateriality.context.investors.country'),
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => getCountryName(country, i18n.resolvedLanguage as Lang)
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: t('doubleMateriality.context.investors.importance'),
      render: (_, row: DMSupplier) => (
        <div
          className={`text-tag body3-font ${
            row.highImportance ? 'tag2-bg-text-color' : 'tag-bg-text-color'
          } w-fit-content p-1 rounded-4`}>
          {row.highImportance
            ? t('doubleMateriality.context.high')
            : t('doubleMateriality.context.low')}
        </div>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: (_, investor: DMInvestor) => <ButtonDropdown options={buildActions(investor)} />
    }
  ];
  return columns;
};
