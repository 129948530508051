import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { IPurchaseSupplierFrontend } from '../../../../../../types/purchaseSupplier';
import { Status } from '../../../../../../types/utilsEnums/status';
import { PurchasesStatusTag } from '../../components/statusTag/StatusTag';
import { PurchaseStatus } from '../../type';
import { ActionButton } from 'components/ui/actionButton/ActionButton';
import { mapToPurchaseFramework } from 'utils/mapToPurchaseFramework';
import { limitString } from 'utils/limitString';
import { DESCRIPTION_MAP } from 'constants/purchaseFrameworks';
import { OLD_PURCHASE_SUPPLIER_OFFICIAL_SUPPLIER_ID } from '../../constants';

type Params = {
  setSupplierToUpdate: (supplier: IPurchaseSupplierFrontend | null) => void;
};

export const useColumns = ({ setSupplierToUpdate }: Params) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable'
  });

  const columns: Column<IPurchaseSupplierFrontend>[] = [
    {
      title: t('columns.supplier'),
      key: 'name',
      dataIndex: 'name',
      className: 'max-chars-15'
    },
    {
      title: t('columns.country'),
      key: 'country',
      dataIndex: 'country',
      width: 100
    },
    {
      title: t('columns.code'),
      key: 'code',
      dataIndex: 'code',
      width: 100,
      render: (code) => code ?? '-'
    },
    {
      title: t('columns.codeType'),
      key: 'codeType',
      dataIndex: 'codeType',
      width: 200,
      render: (codeType) => (codeType ? mapToPurchaseFramework(codeType) : '-')
    },
    {
      title: t('columns.description'),
      dataIndex: 'code',
      key: 'description',
      width: 300,
      render: (_, item) => {
        if (!item.code || !item.codeType) {
          return '-';
        }

        const descriptions = DESCRIPTION_MAP[item.codeType as keyof typeof DESCRIPTION_MAP];

        if (item.code in descriptions) {
          return limitString(descriptions[item.code as keyof typeof descriptions], 80);
        }

        return '-';
      }
    },
    {
      title: t('columns.purchasesCount'),
      key: 'purchasesCount',
      dataIndex: 'purchasesCount',
      width: 200
    },
    {
      title: t('columns.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: PurchaseStatus) => {
        if (status === 'active') {
          return <PurchasesStatusTag status={Status.ACTIVE} text={t(`tags.${status}`)} />;
        }

        return (
          <>
            <TooltipWrapper text={t(`tooltip.${status}`)} position='top'>
              <PurchasesStatusTag status={status} text={t(`tags.${status}`)} />
            </TooltipWrapper>
          </>
        );
      },
      width: 150
    },
    {
      title: t('columns.co2e'),
      key: 'co2e',
      dataIndex: 'co2e',
      width: 150,
      render: (co2e, row) => {
        if (row.status !== Status.ACTIVE) {
          return '-';
        }

        return <span className='font-body-b1-sb text-gradient'>{co2e ?? 0} kg</span>;
      }
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 50,
      render: (_, row) => {
        const isOfficialSupplier =
          row.officialPurchaseSupplierId === OLD_PURCHASE_SUPPLIER_OFFICIAL_SUPPLIER_ID;

        const onClick = () => {
          if (isOfficialSupplier) {
            return;
          }

          setSupplierToUpdate(row);
        };

        return (
          <ActionButton
            onClick={onClick}
            disabled={isOfficialSupplier}
            icon='elipsis_horizontal'
            color='gradient'
          />
        );
      }
    }
  ];

  return { columns };
};
