import { useClerk } from '@clerk/clerk-react';
import LoaderLogin from 'components/ui/loaders/loaderLogin/LoaderLogin';
import { ROUTES } from 'constants/routes';
import { useEffect } from 'react';

export const ClerkCallback = () => {
  const { handleRedirectCallback } = useClerk();

  useEffect(() => {
    handleRedirectCallback({
      continueSignUpUrl: ROUTES.CLERK_CALLBACK_AUTH,
      signInUrl: ROUTES.CLERK_CALLBACK_AUTH,
      signUpForceRedirectUrl: ROUTES.CLERK_CALLBACK_AUTH
    }).then();
  }, []);

  return <LoaderLogin />;
};
