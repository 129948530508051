import React from 'react';
import { useTranslation } from 'react-i18next';

const useSortingOptions = () => {
  const { t } = useTranslation();
  const sortingOptions = [
    { id: 'created_at', name: t('input.sorting.shipments.creationDate') },
    { id: 'email', name: t('members.email') }
  ];
  return sortingOptions;
};

export default useSortingOptions;
