import { useEffect } from 'react';
import Router from './components/layout/router/Router';
import './styles/index.scss';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { User } from './types/entities/user';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { getUnits } from 'services/api/unit';
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  const ldClient = useLDClient();
  const user: User | null = useSelector((state: any) => state.auth.user, _.isEqual);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === 'local-dev' ||
      process.env.REACT_APP_ENVIRONMENT === 'dev'
    ) {
      amplitude.init('6676e28fb6909a7be7d809317e6b2a7f', { autocapture: false });
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      amplitude.init('adf7f6ece849833790dd408c157103f7', { autocapture: false });
    }
  }, []);

  useEffect(() => {
    if (!user || !foundOrganization) return;
    // Launchdarkly config
    ldClient?.identify({
      kind: 'multi',
      organization: {
        key: foundOrganization?.id,
        name: foundOrganization?.company_name,
        sector: foundOrganization?.sector,
        partner: foundOrganization?.partner
      },
      user: {
        key: user?.id,
        user_email: user?.email
      }
    });
    // Amplitude config
    if (!foundOrganization.role || !user.created_at) return;
    const identifyEvent = new amplitude.Identify();
    const dcycleUserProperties = {
      user_id: user.id,
      user_email: user.email,
      user_role: foundOrganization.role,
      company_is_fund: foundOrganization.is_group_fund,
      company_id: foundOrganization.id,
      company_name: foundOrganization.company_name,
      users_created_at: user.created_at
    };
    identifyEvent.set('dcycle_user_properties', dcycleUserProperties);
    amplitude.identify(identifyEvent);
  }, [user]);

  useEffect(() => {
    if (!foundOrganization) {
      return;
    }

    queryClient.prefetchQuery({
      queryKey: ['units'],
      queryFn: () => getUnits() as Promise<Unit[]>
    });
  }, [queryClient, foundOrganization]);

  return <Router />;
};

export default App;
