import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useTranslation } from 'react-i18next';
import SlideToggleFacade from 'components/ui/formComponents/slideToggle3/SlideToggleFacade';
import { useState } from 'react';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import { useBaseOutputData } from './hooks/useBaseOutputData';
import Modal from 'components/ui/modal/Modal';
import { ReactComponent as VerifyDataSVG } from '../../../../../assets/img/verifyDataModal.svg';

const BASE_DATA = 'baseData';
const OUTPUT_DATA = 'outputData';

export const BaseOutputDataTable = () => {
  const { t: tGeneral } = useTranslation();
  const { t } = useTranslation('', { keyPrefix: 'controlPanel' });
  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState<'baseData' | 'outputData'>(OUTPUT_DATA);

  const onVerify = () => {
    setModalOpen(true);
  };

  const { data, columns, totalToComplete, totalCompleted } = useBaseOutputData({
    selectedData,
    searchValue,
    onVerify
  });

  return (
    <>
      <InfiniteList
        fetchData={() => null}
        data={data}
        columns={columns}
        loading={false}
        firstLoading={false}
        total={4}
        style={{
          height: '80vh'
        }}
        header={
          <div className='flex flex-col gap-4 w-full'>
            <div className='flex place-center gap-4'>
              <span>{t('manageDataTitle')}</span>
              <SlideToggleFacade
                handleSwitchMode={(value) => setSelectedData(value as 'baseData' | 'outputData')}
                idSelected={selectedData}
                options={[
                  {
                    id: OUTPUT_DATA,
                    name: t('outputData.title')
                  },
                  {
                    id: BASE_DATA,
                    name: t('baseData.title')
                  }
                ]}
              />
            </div>
            <div className='flex gap-4 justify-between place-center w-full'>
              <div className='flex gap-4'>
                <InputWrapper iconV2='search' width='200px'>
                  <InputText
                    placeholder={tGeneral('esgMetrics.filters.search')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </InputWrapper>
                <Button
                  lookAndFeel='secondary'
                  iconNode={<Icon icon='filter' size='small' color='gray-dark' />}
                  text={tGeneral('general.filter')}
                  size='small'
                  onClick={() => null}
                  style={{
                    width: 'fit-content'
                  }}
                />
              </div>
              <TotalLegend
                total={totalToComplete}
                total2={totalCompleted}
                loading={false}
                i18key={'controlPanel'}
              />
            </div>
          </div>
        }
      />
      <Modal.WithPortal
        show={modalOpen}
        onClose={() => setModalOpen(false)}
        styles={{
          padding: '0',
          paddingTop: '2rem'
        }}>
        <VerifyDataSVG onClick={() => setModalOpen(false)} />
      </Modal.WithPortal>
    </>
  );
};
