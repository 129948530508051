import { STEPS_SOCIAL } from 'components/measure/esgMetrics/constants';
import useRole from 'customHooks/useRole';
import { useTranslation } from 'react-i18next';
import { PermissionTags } from 'types/entitiesEnums/permissions';

type Props = {
  category: string;
};
const useSteps = ({ category }: Props) => {
  const { t } = useTranslation();
  const { permissions } = useRole();

  const stepsEnvironmental = [
    {
      id: 'climate_change',
      text: 'esgMetrics.environmental.climate_change',
      isSelected: true
    },
    {
      id: 'pollution',
      text: 'esgMetrics.environmental.pollution',
      isSelected: false
    },
    {
      id: 'water_and_marine_resources',
      text: 'esgMetrics.environmental.water_and_marine_resources',
      isSelected: false
    },
    {
      id: 'biodiversity_and_ecosystems',
      text: 'esgMetrics.environmental.biodiversity_and_ecosystems',
      isSelected: false
    },
    {
      id: 'resource_use_and_circular_economy',
      text: 'esgMetrics.environmental.resources_and_circular_economy',
      isSelected: false
    }
  ];

  const stepsSocial = [
    {
      id: STEPS_SOCIAL.WORKERS_IN_THE_VALUE_CHAIN,
      text: t('esgMetrics.social.workers_in_the_value_chain'),
      isSelected: false
    },

    {
      id: STEPS_SOCIAL.CONSUMERS_AND_END_USERS,
      text: t('esgMetrics.social.consumers_and_end-users'),
      isSelected: false
    },

    {
      id: STEPS_SOCIAL.AFFECTED_COMMUNITIES,
      text: t('esgMetrics.social.affected_communities'),
      isSelected: false
    },
    {
      id: STEPS_SOCIAL.OWN_WORKFORCE,
      text: t('esgMetrics.social.own_workforce'),
      isSelected: false,
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.CSRD_SENSITIVE_ALL
      )
    }
  ];

  switch (category) {
    case 'environmental':
      return { steps: stepsEnvironmental };
    case 'social':
      return { steps: stepsSocial };
    default:
      return { steps: [] };
  }
};

export default useSteps;
