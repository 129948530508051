import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRoles from '../manageRoles/hooks/useRoles';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import checkFormErrors from 'utils/checkFormErrors';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import Icon from 'components/ui/icon/Icon';
import { User } from 'types/entities/user';
import { validate } from 'email-validator';
import { addMembership } from 'services/api/membership';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

type FormData = {
  email: string;
  role: SelectOptionFormat;
  errors: ErrorType[];
};

type Props = {
  onClose: () => void;
  members: User[];
  addMember: (member: User) => void;
  roles: Role[];
  isLoading: boolean;
  organizationId?: string;
};

const AddMemberV2 = ({ onClose, members, addMember, roles, isLoading, organizationId }: Props) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'members' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [formData, setFormData] = useState<FormData>({
    email: '',
    role: { id: '', name: '' },
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const organization = useSelectedOrganization();

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleAddMember = async () => {
    setLoadingButton(true);

    const newErrors = checkFormErrors(formData, formData.errors);

    if (!organization) return;

    if (!validate(formData.email)) {
      newErrors.push({
        error: 'email',
        description: t('invalidEmail')
      });
    }

    if (members?.length && members.find((elem) => elem.email === formData.email.toLowerCase())) {
      newErrors.push({
        error: 'email',
        description: t('cannotInvite')
      });
    }

    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      setLoadingButton(false);
      return;
    }

    const member = await addMembership(
      formData.email,
      organizationId ?? organization.id,
      formData.role.id,
      true,
      i18n.resolvedLanguage
    );

    if (member) addMember(member);

    setLoadingButton(false);
    onClose();
  };

  return (
    <>
      <FormHeader title={t('addNewMember')} />
      <FormWrapper className='mb-10'>
        <FormText
          iconV2='mail'
          label={t('email')}
          placeholder={t('emailExample')}
          onChange={onChangeValue('email')}
          value={formData.email}
          error={formData.errors.find((error) => error.error === 'email')}
        />
        <FormSelect
          iconV2='user'
          label={t('assignedRole')}
          placeholder={t('selectRole')}
          onChange={onChangeValue('role')}
          value={formData.role}
          options={roles.map((role) => ({ id: role.id, name: role.name }))}
          error={formData.errors.find((error) => error.error === 'role')}
          className='border-primary-purple'
          loading={isLoading}
        />
        <BlueWrapper className={'py-1 px-2 gap-1'}>
          <>
            <Icon icon='status' color='pending' size='medium' />
            <span className={'font-12 weight-400 text-secondary-purple'}>{t('addUserNotice')}</span>
          </>
        </BlueWrapper>
      </FormWrapper>
      <FormButtonSection>
        <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('saveAndInvite')}
          onClick={handleAddMember}
          loading={loadingButton}
        />
      </FormButtonSection>
    </>
  );
};

export default AddMemberV2;
