import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import { adaptFormValuesToIROData, useDMIROsForm } from '../../hooks/useDMIROsForm';
import { DMIROsForm } from './DMIROsForm';
import { useCreateIROMutation } from '../../queries/useIROsQuery';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const DMIROsAddModal: React.FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros.addModal'
  });

  const createIROMutation = useCreateIROMutation();

  const formHook = useDMIROsForm({
    onSubmit: async (values) => {
      try {
        await createIROMutation.mutateAsync(adaptFormValuesToIROData(values));
        onClose();
      } catch (error) {
        console.error('Error creating IRO:', error);
      }
    }
  });

  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Content>
        <DMIROsForm
          formData={formHook.formData}
          setFormData={formHook.setFormData}
          selectableTopics={formHook.selectableTopics}
          selectableSubtopics={formHook.selectableSubtopics}
          iroTypes={formHook.iroTypes}
          errors={formHook.formData.errors}
          disabled={createIROMutation.isPending}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('cancel')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('add')}
          size='medium'
          onClick={formHook.handleSubmit}
          disabled={formHook.formData.errors.length > 0 || createIROMutation.isPending}
          loading={createIROMutation.isPending}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
