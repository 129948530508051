import { useCarouselTranslation } from '../hooks/useCarouselTranslation';
import './style.scss';
import type { Props } from '../StepCarousel';

const StepBreadcrumb = ({ text, isSelected, className, ...rest }: Props) => {
  const { t } = useCarouselTranslation();

  return (
    <div className={`${className} ${isSelected ? 'selected' : ''}`} {...rest}>
      {t(text)}
      {!isSelected && ' > '}
    </div>
  );
};

export default StepBreadcrumb;
