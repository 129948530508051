import { useQuery } from '@tanstack/react-query';
import { Organization } from 'types/entities/organization';
import apiFetch from 'utils/apiFetch';

const useGetData = () => {
  const fetchOrganizations = async () => {
    const response = await apiFetch('GET', '/organizations');
    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['organizations'],
    queryFn: async () => {
      const response = await fetchOrganizations();
      return response;
    },
    select: (data: Organization[]) => {
      return data.map((org: Organization) => ({
        id: org.id,
        name: org.company_name
      }));
    }
  });
  return { data, isLoading };
};

export default useGetData;
