export enum PermissionTags {
  AUTH_USERS_ALL = 'auth:users::all',
  COMPANY_HOLDING_ALL = 'company:holding::all',
  TASK_ALL = 'task::all',
  COMPANY_LEGAL_INFORMATION_ALL = 'company:legal_information::all',
  FILE_DELETE = 'file::delete',
  DASHBOARD_ALL = 'dashboard::all',
  IMPROVE_AND_COMMUNICATE_ALL = 'improve_and_communicate::all',
  COMPANY_INVOICE_SPLIT_ALL = 'company:invoice_split::all',
  EMISSION_CUSTOM_EF_CREATE = 'emission:custom_ef::create',
  METRIC_INTENSITY_ALL = 'metric:intensity::all',
  EMISSION_COMBUSTION_ALL = 'emission:combustion::all',
  EMISSION_RECHARGE_ALL = 'emission:recharge::all',
  EMISSION_ELECTRICITY_ALL = 'emission:electricity::all',
  EMISSION_WATER_ALL = 'emission:water::all',
  EMISSION_WASTE_ALL = 'emission:waste::all',
  EMISSION_VEHICLE_ALL = 'emission:vehicle::all',
  EMISSION_EMPLOYEE_ALL = 'emission:employee::all',
  EMISSION_BUSINESS_TRAVEL_ALL = 'emission:business_travel::all',
  EMISSION_TRANSPORT_DOWNSTREAM_ALL = 'emission:transport_downstream::all',
  EMISSION_TRANSPORT_UPSTREAM_ALL = 'emission:transport_upstream::all',
  EMISSION_PURCHASE_ALL = 'emission:purchase::all',
  EMISSION_SOCIAL_GOVERNANCE_ALL = 'emission:social_governance::all',
  CSRD_SENSITIVE_ALL = 'csrd:sensitive::all',
  CSRD_GENERAL_ALL = 'csrd:general::all'
}
