import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

type DisableMFAModalProps = {
  onDisable: () => void;
};

export const DisableMFAModal = ({ onDisable }: DisableMFAModalProps) => {
  const { t } = useTranslation('', { keyPrefix: 'profile.securityAndAccesses.mfa' });
  const { t: tGeneral } = useTranslation('', { keyPrefix: 'general' });

  return (
    <>
      <Modal.Header title={t('disableTitle')} />
      <Modal.Content>
        <p className='font-12 m-0'>{t('disableDescription')}</p>
        <Button
          lookAndFeel='warning'
          text={tGeneral('delete')}
          onClick={onDisable}
          size='medium'
          className='mt-5'
        />
      </Modal.Content>
    </>
  );
};
