import { useEffect, useState } from 'react';
import FormRole from './FormRole';
import useDefaultPermissions from './hooks/useDefaultPermissions';

export type FormData = {
  name: string;
  permissions: number[];
  errors: ErrorType[];
};

type Props = {
  onClose: () => void;
  handleSubmit: (formData: FormData) => void;
};

const CreateRoleWrapper = ({ onClose, handleSubmit }: Props) => {
  const { data, isLoading } = useDefaultPermissions();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    permissions: [],
    errors: []
  });

  useEffect(() => {
    // Populate the general actions section with predefined permissions
    if (!data) return;

    setFormData((prev) => ({
      ...prev,
      permissions: Array.from(new Set([...prev.permissions, ...data.map((p) => p.id)]))
    }));
  }, [JSON.stringify(data)]);

  return (
    <FormRole
      onClose={onClose}
      formData={formData}
      setFormData={setFormData}
      onSubmit={() => handleSubmit(formData)}
      loading={isLoading}
    />
  );
};

export default CreateRoleWrapper;
