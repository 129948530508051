import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { useDeleteOwnWorker } from './hooks/useOwnWorkers';

type ModalOwnWorkerProps = {
  ownWorkerData?: DMOwnWorker;
  closeModal: () => void;
};

export const ModalDeleteOwnWorker = ({ ownWorkerData, closeModal }: ModalOwnWorkerProps) => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality.context.ownWorkers' });
  const { deleteOwnWorker } = useDeleteOwnWorker();

  return (
    <div className='delete-modal'>
      <FormHeader title={t('delete')} description={t('areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={() => {
            deleteOwnWorker(ownWorkerData?.id ?? '');
            closeModal();
          }}
        />
      </FormButtonSection>
    </div>
  );
};
