/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FreeTrialContext } from '../../../../context/freeTrialContext';
import { UserContext } from '../../../../context/userContext';
import { usePremiumRoutes } from '../../../../customHooks/usePremiumRoutes';
import './styles.scss';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useTranslation } from 'react-i18next';
import useRole from 'customHooks/useRole';

type Props = {
  section: SubSectionType;
  subscriptionPlan: string;
};

function SubSection({ section, subscriptionPlan }: Props) {
  const { text, route } = section;

  const { t } = useTranslation();
  const { roleName } = useRole();

  const freeTrialContext = useContext(FreeTrialContext);
  const PREMIUM_ROUTES = usePremiumRoutes();
  const user = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname + location.search;

  const handleOnClick = () => {
    if (section.blocked || section.disabled) return;
    const premium = PREMIUM_ROUTES.find((elem) => elem === route);

    if (!route && (user?.email.includes('dcycle') || !user?.selectedOrganization)) {
      return;
    }

    if (!route) {
      section.onClickCallback?.();
      return;
    }

    if (!premium || subscriptionPlan !== 'free_plan') {
      const urlSearchParams = new URLSearchParams(route.split('?')[1]);

      const actualUrlSearchParams = new URLSearchParams(location.search.split('?')[1]);

      urlSearchParams.forEach((value, key) => {
        actualUrlSearchParams.set(key, value);
      });

      const routeParsed = route.split('?')[0] + '?' + actualUrlSearchParams.toString();
      navigate(routeParsed, { replace: false });
    }

    if (premium && subscriptionPlan === 'free_plan') {
      freeTrialContext?.setShowPremiumFeatureModal(true);
    }

    section.onClickCallback?.();
  };

  if (section.hide) return null;

  const selected = route && pathname?.includes(route) ? 'active' : '';

  return (
    <>
      <TooltipWrapper
        text={t('general.blockedByRole', { role: roleName })}
        position='top'
        shouldAppear={!!section.blocked}
        delay={800}>
        <div
          className={`subsection side-menu-subsection-font on-light-text-color ${selected} ${
            section.disabled || section.blocked ? 'blocked' : ''
          }`}
          onClick={handleOnClick}>
          <span className={selected}>{text}</span>
          {section.blocked && (
            <div className='blocked-icon-wrapper'>
              <Icon icon='lock' color='gray-warm' size='medium' />
            </div>
          )}
        </div>
      </TooltipWrapper>
    </>
  );
}

export default SubSection;
