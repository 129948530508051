import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { adaptOwnWorkerFrontToBack, adaptOwnWorkersBackToFront } from 'adapters/ownWorkers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  createOwnWorker,
  deleteOwnWorker,
  fetchDMOwnWorkers,
  updateOwnWorker
} from 'services/api/sotOrganizationData';

export const useObtainOwnWorkers = ({
  year,
  company_name
}: {
  year: string;
  company_name: string;
}) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['ownWorkers', year, company_name],
    queryFn: fetchDMOwnWorkers,
    initialPageParam: 1,
    select: adaptOwnWorkersBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};

export const useCreateOrUpdateOwnWorker = () => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (ownWorker: DMOwnWorker) => {
      const backendOwnWorker = adaptOwnWorkerFrontToBack(ownWorker);
      return ownWorker.id
        ? updateOwnWorker(ownWorker.id, backendOwnWorker)
        : createOwnWorker(backendOwnWorker);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['ownWorkers'] });
      dispatch(setNotification(t('edit')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { createOrUpdateOwnWorker: mutate };
};

export const useDeleteOwnWorker = () => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (ownWorkerId: string) => {
      return deleteOwnWorker(ownWorkerId);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['ownWorkers'] });
      dispatch(setNotification(t('delete')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { deleteOwnWorker: mutate };
};
