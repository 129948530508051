import { forwardRef } from 'react';

type Props = {
  children: React.ReactNode;
  title?: string;
  disabled?: boolean;
  highlighted?: boolean;
};

export const BlueTag = forwardRef<HTMLSpanElement, Props>(
  ({ children, title, disabled, highlighted, ...rest }, ref) => {
    let styling = 'text-blue-tag bg-blue-tag';

    if (highlighted) {
      styling = 'text-white bg-secondary-purple-20';
    }

    if (disabled) {
      styling = 'text-neutral-gray-20 bg-neutral-gray-warm-60';
    }

    return (
      <span
        className={`${styling} font-12 weight-400 p-1 w-fit-content m-0 rounded-4 nodrag nopan`}
        title={title}
        ref={ref}
        {...rest}>
        {children}
      </span>
    );
  }
);

BlueTag.displayName = 'BlueTag';
