import { SurveyResult, SurveyResultsResponse } from 'types/entities/surveyResults';
import { delay } from 'utils/delay';

const mockSurveyResults: SurveyResult[] = [
  {
    id: '1',
    category: 'environmental',
    topic: 'Biodiversity and ecosystems',
    subtopic: 'Biodiversity and ecosystems',
    financialScore: 85,
    impactScore: 78
  },
  {
    id: '2',
    category: 'environmental',
    topic: 'Climate change',
    subtopic: 'Adaptation to climate change',
    financialScore: 32,
    impactScore: 88
  },
  {
    id: '3',
    category: 'environmental',
    topic: 'Biodiversity and ecosystems',
    subtopic: 'Pollution',
    financialScore: 76,
    impactScore: 95
  },
  {
    id: '4',
    category: 'social',
    topic: 'Own workers',
    subtopic: 'Adequate housing',
    financialScore: 65,
    impactScore: 33
  },
  {
    id: '5',
    category: 'environmental',
    topic: 'Water and marine resources',
    subtopic: 'Water spills into the oceans',
    financialScore: 65,
    impactScore: 33
  },
  {
    id: '6',
    category: 'environmental',
    topic: 'Biodiversity and ecosystems',
    subtopic: 'Biodiversity and ecosystems',
    financialScore: 85,
    impactScore: 78
  },
  {
    id: '7',
    category: 'environmental',
    topic: 'Climate change',
    subtopic: 'Adaptation to climate change',
    financialScore: 32,
    impactScore: 88
  },
  {
    id: '8',
    category: 'environmental',
    topic: 'Biodiversity and ecosystems',
    subtopic: 'Pollution',
    financialScore: 76,
    impactScore: 95
  },
  {
    id: '9',
    category: 'social',
    topic: 'Own workers',
    subtopic: 'Adequate housing',
    financialScore: 65,
    impactScore: 33
  },
  {
    id: '10',
    category: 'environmental',
    topic: 'Water and marine resources',
    subtopic: 'Water spills into the oceans',
    financialScore: 65,
    impactScore: 33
  }
];

export const mockSurveyResultsService = {
  getAllSurveyResults: async (page = 1): Promise<SurveyResultsResponse> => {
    await delay(500);

    return {
      items: [...mockSurveyResults],
      page,
      size: mockSurveyResults.length,
      total: mockSurveyResults.length
    };
  },

  getSurveyResultById: async (id: string): Promise<SurveyResult | undefined> => {
    await delay(300);
    return mockSurveyResults.find((result) => result.id === id);
  },

  createSurveyResult: async (data: Partial<SurveyResult>): Promise<SurveyResult> => {
    await delay(500);
    const newResult = {
      ...data,
      id: (mockSurveyResults.length + 1).toString(),
      createdAt: new Date().toISOString()
    } as SurveyResult;
    mockSurveyResults.push(newResult);
    return newResult;
  },

  updateSurveyResult: async (id: string, data: Partial<SurveyResult>): Promise<SurveyResult> => {
    await delay(500);
    const index = mockSurveyResults.findIndex((result) => result.id === id);
    if (index === -1) {
      throw new Error(`Survey result with id ${id} not found`);
    }

    const updatedResult = { ...mockSurveyResults[index], ...data };
    mockSurveyResults[index] = updatedResult;
    return updatedResult;
  },

  deleteSurveyResult: async (id: string): Promise<{ success: boolean }> => {
    await delay(500);
    const index = mockSurveyResults.findIndex((result) => result.id === id);
    if (index === -1) {
      throw new Error(`Survey result with id ${id} not found`);
    }

    mockSurveyResults.splice(index, 1);
    return { success: true };
  }
};
