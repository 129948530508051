import { useContext, useState } from 'react';
import Button from '../../ui/button/Button';
import { ProfileCard } from '../profileCard/ProfileCard';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import Modal from '../../ui/modal/Modal';
import { UserContext } from '../../../context/userContext';
import LinkAccountModal from './LinkAccountModal';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import { useTranslation } from 'react-i18next';
import { useUserInvites } from '../hooks/useUserInvites';
import useRole from 'customHooks/useRole';

export const LinkAccount = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { data: invites } = useUserInvites();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const org = useSelectedOrganization();
  const { isAdminRole } = useRole();

  const openLinkAccountModal = () => {
    setIsOpen(true);
  };

  const closeLinkAccountModal = () => {
    setIsOpen(false);
  };

  const onLinkAccount = () => {
    queryClient.invalidateQueries({ queryKey: ['orgParents', org?.id] });
    dispatch(setNotification(t('notification.linkHolding')));
    closeLinkAccountModal();
  };

  const user = useContext(UserContext);

  if (!user || !org) {
    return null;
  }

  return (
    <>
      <Modal.WithPortal
        show={isOpen}
        onClose={closeLinkAccountModal}
        width='428px'
        maxWidth='428px'>
        <LinkAccountModal user={user} onLinkAccount={onLinkAccount} invites={invites} />
      </Modal.WithPortal>

      <ProfileCard.Layout>
        <div className='flex gap-x-4 items-center'>
          <ProfileCard.Content>
            <ProfileCard.Title>{t('profile.linkAccount')}</ProfileCard.Title>
            <ProfileCard.Description>{t('profile.linkAccountDescription')}</ProfileCard.Description>
          </ProfileCard.Content>
          <Button
            lookAndFeel='primary'
            size='medium'
            text={t('profile.link')}
            onClick={openLinkAccountModal}
            style={{ width: 'fit-content' }}
            disabled={!isAdminRole}
          />
        </div>
      </ProfileCard.Layout>
    </>
  );
};
