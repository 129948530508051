import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { getCountries } from 'constants/phonePrefixes';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { useTranslation } from 'react-i18next';
import { useCreateOrUpdateInvestor } from './hooks/useInvestors';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useErrorTranslations } from 'customHooks/translations';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

type ModalInvestorProps = {
  investorData?: DMInvestor;
  closeModal: () => void;
};

export const ModalCreateOrEditInvestor = ({ investorData, closeModal }: ModalInvestorProps) => {
  const { t: tError } = useErrorTranslations();
  const { t: t_context, i18n } = useTranslation('', {
    keyPrefix: 'doubleMateriality.context'
  });
  const { t } = useTranslation();

  const HIGH_RELEVANCE = { id: 'high', name: t_context('high') };
  const LOW_RELEVANCE = { id: 'low', name: t_context('low') };

  const foundOrganization = useSelectedOrganization();

  const countries = getCountries(
    i18n.resolvedLanguage,
    foundOrganization ? foundOrganization.country : 'ES'
  );

  const { register, formState, getSelectValue, onSelectionChange, handleSubmit } =
    useFormWithHelpers({
      defaultValues: {
        name: investorData?.name ?? '',
        country: investorData?.country ?? '',
        importance: investorData?.highImportance ? HIGH_RELEVANCE.id : LOW_RELEVANCE.id
      },
      resolver: zodResolver(
        z.object({
          name: z.string().min(1, tError('required')),
          country: z.string().min(1, tError('required'))
        })
      )
    });

  const { createOrUpdateInvestor } = useCreateOrUpdateInvestor();

  return (
    <>
      <FormHeader title={t_context('investors.addModalTitle')} />
      <div className='flex flex-col gap-8'>
        <div className='grid grid-cols-2 gap-4'>
          <InputWrapper
            iconV2='money'
            label={t_context('investors.nameLabel')}
            error={adaptHookFormError(formState.errors.name, tError)}>
            <InputText {...register('name')} placeholder={t_context('investors.name')} />
          </InputWrapper>
          <InputWrapper
            label={t_context('investors.country')}
            iconV2='location'
            error={adaptHookFormError(formState.errors.country, tError)}>
            <Select
              value={getSelectValue('country', countries)}
              onChange={onSelectionChange('country')}
              options={countries}
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            label={t_context('investors.importance')}
            iconV2='graph'
            tooltip={t_context('investors.importanceTooltip')}
            error={adaptHookFormError(formState.errors.importance, tError)}>
            <Select
              value={getSelectValue('importance', [HIGH_RELEVANCE, LOW_RELEVANCE])}
              onChange={onSelectionChange('importance')}
              options={[HIGH_RELEVANCE, LOW_RELEVANCE]}
            />
          </InputWrapper>
        </div>
      </div>
      <FormButtonSection style={{ marginTop: '1em' }}>
        <Button
          lookAndFeel='secondary'
          text={t('general.cancel')}
          onClick={closeModal}
          size='medium'
        />
        <Button
          lookAndFeel='primary'
          text={t('general.saveAndClose')}
          size='medium'
          onClick={handleSubmit((formData) => {
            createOrUpdateInvestor({
              id: investorData?.id,
              name: formData.name,
              country: formData.country,
              highImportance: formData.importance === HIGH_RELEVANCE.id
            });
            closeModal();
          })}
        />
      </FormButtonSection>
    </>
  );
};
