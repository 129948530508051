import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Shipment, ShipmentForm, TransportSection } from '../../../../../types/entities/shipment';
import { User } from '../../../../../types/entities/user';
import { formatDate } from '../../../../../utils/formatDate';
import { getTransportSections } from 'services/api/transportRoutes';
import FormShipment from '../common/FormShipment';
import './styles.scss';

type Props = {
  editShipment: (shipment: Shipment) => void;
  shipmentToEdit: Shipment;
  user: User;
};
const EditShipment = ({ editShipment, shipmentToEdit, user }: Props) => {
  const [searchParams] = useSearchParams();

  const transportDirection = searchParams.get('transport_direction') || 'downstream';

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());

  let shipmentStartDate = null;
  if (shipmentToEdit.start_date && transportDirection === 'downstream') {
    shipmentStartDate = new Date(shipmentToEdit.start_date);
  }
  if (shipmentToEdit.end_date && transportDirection === 'upstream') {
    shipmentStartDate = new Date(shipmentToEdit.end_date);
  }

  if (shipmentStartDate) {
    shipmentStartDate.setHours(0, 0, 0, 0);
  }

  const [formData, setFormData] = useState<ShipmentForm>({
    name: shipmentToEdit.name,
    quantity: shipmentToEdit.quantity_transported.toString() ?? '',
    unit: shipmentToEdit.unit_id,
    supplier: shipmentToEdit.supplier ?? '',
    refrigerated: shipmentToEdit.refrigerated,
    startDate: shipmentStartDate ? formatDate(shipmentStartDate) : formatDate(today),
    frequency: shipmentToEdit.transport_frequency,
    transportSections: [
      {
        location_origin_id: '',
        location_destination_id: '',
        origin: '',
        destination: '',
        transport_type: ''
      }
    ],
    errors: [] as ErrorType[]
  });

  // const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const responseTransportSections = await getTransportSections(shipmentToEdit.id ?? '');
    setLoading(false);

    const orderedTransportSections: TransportSection[] = [];
    const firstElement = responseTransportSections.find(
      (elem: TransportSection) =>
        !responseTransportSections.find(
          (elem2: TransportSection) => elem.location_origin_id === elem2.location_destination_id
        )
    );
    if (firstElement) {
      orderedTransportSections.push(firstElement);
    }

    for (let i = 0; i < responseTransportSections.length - 1; i++) {
      const foundElement = responseTransportSections.find(
        (elem: TransportSection) =>
          elem.location_origin_id === orderedTransportSections[i].location_destination_id
      );
      if (foundElement) {
        orderedTransportSections.push(foundElement);
      }
    }

    if (orderedTransportSections.length === 0) return;

    setFormData((prev) => ({
      ...prev,
      transportSections: orderedTransportSections.map((elem: TransportSection) => {
        let transportType = elem.transport_type;
        if (transportType === 'road') {
          if (elem.travel_method === 'car' && elem.electric) {
            transportType = 'electric_car';
          } else {
            transportType = elem.travel_method || '';
          }
        }
        return {
          ...elem,
          transport_type: transportType
        };
      })
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='shipment-form'>
      {(transportDirection === 'upstream' || transportDirection === 'downstream') && (
        <FormShipment
          formData={formData}
          setFormData={setFormData}
          transportDirection={transportDirection}
          user={user}
          submitShipment={editShipment}
          shipmentToEdit={shipmentToEdit}
          loading={loading}
        />
      )}
    </div>
  );
};

export default EditShipment;
