import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_INVOICES_PER_REQUEST, MAX_PAYLOAD_SIZE } from '../../../../../../../constants';
import { Facility } from '../../../../../../../types/entities/facility';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import './styles.scss';
import { InputNuvoWastes } from './nuvo/InputNuvoWastes';
import Button from 'components/ui/button/Button';
import UploadFile from '../common/uploadFile/UploadFile';
import { InvoiceAuto, InvoiceToUpload } from 'types/entities/invoice';
import { FormDataUploadInvoices } from '../UploadInvoices';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { uploadWasteInvoices } from 'services/api/waste';
import SlideToggleFacade from 'components/ui/formComponents/slideToggle3/SlideToggleFacade';
import useToggleOptions from './hooks/useToggleOptions';
import useGetData from './hooks/useGetData';
import FormNumber from 'components/ui/formComponents2/formInputs/formNumber/FormNumber';
import checkFormErrors from 'utils/checkFormErrors';
import { GrayBox } from 'components/ui/GrayBox';
import { FormRD } from './components/FormRD';
import { useNestedArray } from 'customHooks/useNestedArray';
import { TAG_STATUS } from '../../createInvoice/components/common/ConsumptionPercentages';
import { useRDLineStatus } from './hooks/useRDLineStatus';
import { InvoiceStatus } from 'types/entitiesEnums/invoice';

type Props = {
  facility: Facility;
  formData: FormDataUploadInvoices;
  setFormData: Dispatch<SetStateAction<FormDataUploadInvoices>>;
  documentsToUpload: InvoiceToUpload[];
  setDocumentsToUpload: Dispatch<SetStateAction<InvoiceToUpload[]>>;
  handleClose: () => void;
  addInvoice: (invoiceUploaded: InvoiceAuto[]) => void;
};

const UploadWasteInvoices = ({
  facility,
  formData,
  setFormData,
  documentsToUpload,
  setDocumentsToUpload,
  handleClose,
  addInvoice
}: Props) => {
  const { t } = useTranslation();

  // STATES
  const [loadingButton, setLoadingButton] = useState(false);
  const [sizesBiggerThanMax, setSizesBiggerThanMax] = useState<{ size: number; index: number }[]>(
    []
  );
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const [lines, setLines] = useNestedArray({
    outerState: formData,
    setOuterState: setFormData,
    innerStateKey: 'rdCode'
  });

  const { status } = useRDLineStatus({ lines });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { suppliers, loading } = useGetData();

  const [mode, setMode] = useState('excel');

  const toggleOptions = useToggleOptions();

  const handleUploadInvoice = async () => {
    const newErrors = checkFormErrors(formData, formData.errors, [
      'totalKm',
      'rdCode',
      'facilityFuel'
    ]);

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    let sizeBase64 = 0;

    const sizes: number[] = [];
    documentsToUpload.forEach((document) => {
      sizeBase64 = document.file_url.length / (1024 * 1024);
      sizes.push(sizeBase64);
    });

    const sizesBiggerThanMaxList: {
      size: number;
      index: number;
    }[] = [];
    sizes.forEach((elem, index) => {
      if (elem >= MAX_PAYLOAD_SIZE) {
        sizesBiggerThanMaxList.push({
          size: elem,
          index
        });
      }
    });

    setSizesBiggerThanMax(sizesBiggerThanMaxList);

    if (!documentsToUpload || documentsToUpload.length === 0 || sizesBiggerThanMax.length > 0) {
      newErrors.push({
        error: 'file',
        description: t('error.maxPayloadSize')
      });
    }
    if (documentsToUpload.length > MAX_INVOICES_PER_REQUEST) {
      newErrors.push({
        error: 'file',
        description: t('error.maxInvoicesPerRequest')
      });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoadingButton(true);

    try {
      // Call api to upload documents adapted
      const requests = documentsToUpload.map((document) =>
        uploadWasteInvoices(
          document.file,
          facility.id,
          formData.supplier.id,
          formData.rdCode
            .filter(({ id, value }) => Boolean(id) && !isNaN(Number(value)))
            .map((rd) => ({
              id: rd.id,
              percentage: rd.value
            })),
          formData.totalKm
        )
      );
      const response = await Promise.all(requests);
      setLoadingButton(false);
      const invoicesToAdd: InvoiceAuto[] = [];
      response.forEach((invoices) => {
        invoices.data.forEach((data: any) => {
          if (!data) {
            setErrors((prev) => [
              ...prev,
              { error: 'request', description: t('error.somethingWentWrong') }
            ]);
            return;
          }
          if (!data) {
            setErrors((prev) => [
              ...prev,
              { error: 'request', description: t('error.somethingWentWrong') }
            ]);
            return;
          }
          if (data === 'ERROR_EMPTY_PDF') {
            setErrors((prev) => [...prev, { error: 'request', description: t('error.emptyPdf') }]);
            return;
          }
          if (data === 'ERROR_CANNOT_READ_FILE') {
            setErrors((prev) => [
              ...prev,
              { error: 'request', description: t('error.cannotReadFile') }
            ]);
            return;
          }
          if (data === 'WASTE_PROVIDER_NOT_FOUND') {
            setErrors((prev) => [
              ...prev,
              { error: 'request', description: t('error.wasteProviderNotFound') }
            ]);
            return;
          }
          if (data === 'WASTE_EF_NOT_FOUND') {
            setErrors((prev) => [
              ...prev,
              { error: 'request', description: t('error.wasteEfNotFound') }
            ]);
            return;
          }
          invoicesToAdd.push({
            ...data,
            type: 'wastes',
            status: InvoiceStatus.LOADING
          });
        });
      });
      addInvoice(invoicesToAdd);
      handleClose();
    } catch (err: any) {
      let responseStatus = 200;
      if (err.response && err.response.status) {
        responseStatus = err.response.status;
      }
      if (responseStatus === 413) {
        setErrors([{ error: 'file', description: t('error.maxPayloadSize') }]);
      } else {
        setErrors([{ error: 'request', description: t('error.uploadInvoices') }]);
      }
      setLoadingButton(false);
    }
  };

  const renderByMode = () => {
    if (mode === 'excel') {
      return (
        <div className='app-bg-color p-4 flex flex-col items-center justify-center mt-4 rounded-8'>
          <span className='font-12 weight-600 mb-4'>{t('waste.uploadExcelTitle')}</span>
          <span className='font-12 weight-400'>{t('waste.uploadExcelDescription1')}</span>
          <span className='font-12 weight-400 mb-8'>{t('waste.uploadExcelDescription2')}</span>
          <InputNuvoWastes handleClose={handleClose} />
        </div>
      );
    }

    if (mode === 'pdf') {
      return (
        <>
          <GrayBox.Padless className='p-4 gap-x-4 grid grid-cols-3'>
            {loading ? (
              <>
                <CustomSkeletonLoader className='col-span-3' count={1} />
                <CustomSkeletonLoader className='col-span-3' count={1} />
              </>
            ) : (
              <>
                <FormSelect
                  label={t('waste.supplier')}
                  tooltip={t('waste.supplierTooltip')}
                  iconV2='dropdown'
                  placeholder={t('waste.selectSupplier')}
                  onChange={onChangeValue('supplier')}
                  value={formData.supplier}
                  options={suppliers}
                  error={formData.errors.find((error) => error.error === 'supplier')}
                  className='col-span-2'
                />
                <FormNumber.WithOverlay
                  overlay='km'
                  label={<span className='text'>{t('waste.destination')}</span>}
                  placeholder={t('waste.writeTotalKm')}
                  value={formData.totalKm ?? ''}
                  onChange={onChangeValue('totalKm')}
                />
                <div className='col-span-3'>
                  <FormRD lines={lines} setLines={setLines} />
                </div>
              </>
            )}
            <div className='col-span-3'>
              <UploadFile
                formData={formData}
                documentsToUpload={documentsToUpload}
                setDocumentsToUpload={setDocumentsToUpload}
                facility={facility}
                setFormData={setFormData}
              />
            </div>
          </GrayBox.Padless>
          <FormButtonSection className='mt-2'>
            <Button
              lookAndFeel={'primary'}
              size='medium'
              text={t('facilityDetail.uploadInvoiceButton')}
              onClick={handleUploadInvoice}
              loading={loadingButton}
              disabled={
                !!errors.find((error) => error.error === 'percentages') ||
                status === TAG_STATUS.ERROR
              }
            />
          </FormButtonSection>
          {errors.find((error) => error.error === 'request') && (
            <ErrorText>{errors.find((error) => error.error === 'request')?.description}</ErrorText>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div className='flex gap-2 items-center mt-6 mb-4'>
        <span className='font-12 weight-600'>{t('waste.fileType')}</span>
        <SlideToggleFacade
          handleSwitchMode={(value) => {
            setMode(value);
          }}
          idSelected={mode}
          options={toggleOptions}
        />
      </div>
      {renderByMode()}
    </>
  );
};

export default UploadWasteInvoices;
