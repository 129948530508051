import moment from 'moment';
import {
  createInvoice,
  createInvoiceEmissionFactor
} from '../../../../../../services/api/invoices';
import type { Percentages } from '../../../../../../types/entities/invoice';
import { InvoiceStatus } from '../../../../../../types/entitiesEnums/invoice';

export type InvoiceFormData = {
  emissionFactor: string;
  supplier?: SelectOptionFormat;
  facilityFuel?: SelectOptionFormat;
  unitDefault: SelectOptionFormat;
  unitCustom: SelectOptionFormat;
  quantity: string;
  invoiceId: string;
  startDate: string;
  endDate: string;
  cups?: string;
  percentages: Percentages[];
  errors: ErrorType[];
  toc?: SelectOptionFormat;
};

export type FacilityPercentage = {
  organization_id: string;
  facility_id: string;
  percentage: number;
};

export type InvoicePostBack = {
  id: string;
  custom_emission_factor_id?: string | null;
  type: string;
  supplier_id: string | null;
  facility_fuel_id: string | null;
  cups?: string;
  base_quantity: number;
  // quantity: number;
  unit_id: string;
  start_date: Date | string;
  end_date: Date | string;
  invoice_id: string;
  status: InvoiceStatus;
  uploaded_by: string;
  facility_id: string;
  facility_percentages: FacilityPercentage[];
  toc?: string;
};

export type InvoiceBody = Omit<InvoicePostBack, 'id'>;

export const SERVICE_MAP = {
  custom: createInvoiceEmissionFactor,
  default: createInvoice
} as const;

export const defaultFacilityInvoiceForm = {
  emissionFactor: '',
  supplier: { id: '', name: '' },
  facilityFuel: { id: '', name: '' },
  unitDefault: { id: '', name: '' },
  unitCustom: { id: '', name: '' },
  quantity: '',
  invoiceId: '',
  startDate: moment.utc().format('DD/MM/YYYY'),
  endDate: moment.utc().subtract(1, 'month').format('DD/MM/YYYY'),
  cups: '',
  percentages: [],
  errors: []
};
