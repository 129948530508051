import Button from 'components/ui/button/Button';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import Icon from 'components/ui/icon/Icon';
import NameIcon from 'components/ui/nameIcon/NameIcon';
import DisabledLabel from 'components/ui/statusLabels/disabledLabel/DisabledLabel';
import SuccessLabel from 'components/ui/statusLabels/successLabel/SuccessLabel';
import { useTranslation } from 'react-i18next';
import { User } from 'types/entities/user';
import { Column } from 'types/table';

const BASE_DATA = 'baseData';

type Props = {
  selectedData: 'baseData' | 'outputData';
  onVerify: () => void;
};

export const useColumns = ({ selectedData, onVerify }: Props) => {
  const { t, i18n } = useTranslation();
  const { t: tMetrics } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics'
  });

  const statusLabel = {
    COMPLETE: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
    // WITH_DATA: <PendingLabel>{t('general.withData')}</PendingLabel>,
    WITH_DATA: <SuccessLabel>{t('general.withData')}</SuccessLabel>,
    WITHOUT_DATA: (
      <DisabledLabel icon='error' style={{ paddingInline: '8px', paddingBlock: '2px' }}>
        {t('general.withoutData')}
      </DisabledLabel>
    )
  };

  const buildActions = (baseData: any) => [
    {
      id: `${baseData.id}-assign`,
      name: t('controlPanel.assign'),
      onClick: () => null
    },
    {
      id: `${baseData.id}-complete`,
      name: t('controlPanel.goToComplete'),
      onClick: () => null
    },
    {
      id: `${baseData.id}-edit`,
      name: t('controlPanel.edit'),
      onClick: () => null
    }
  ];

  const baseDataColumns: Column[] = [
    {
      title: t('controlPanel.baseData.organization'),
      dataIndex: 'organization',
      key: 'organization'
    },
    {
      title: t('controlPanel.baseData.data'),
      dataIndex: 'data',
      key: 'data'
    },
    {
      title: t('controlPanel.baseData.department'),
      dataIndex: 'department',
      key: 'department',
      render: (dep: string) => (
        <span className='font-12 p-1 rounded-4 text-white bg-secondary-purple-20'>{dep}</span>
      )
    },
    {
      title: t('controlPanel.baseData.frameworks'),
      dataIndex: 'frameworks',
      key: 'frameworks',
      render: (frs: [string]) => (
        <div className='flex gap-1'>
          {frs.map((fr, idx) => (
            <span
              key={idx}
              className='font-12 weight-400 p-1 rounded-4 text-secondary-purple-20 bg-secondary-purple-80'>
              {fr}
            </span>
          ))}
        </div>
      )
    },
    {
      title: t('controlPanel.baseData.assignedTo'),
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      render: (name: string) => (
        <span className='flex gap-x-2 items-center'>
          <NameIcon name={name} />
          {name ?? tMetrics('unassigned')}
        </span>
      )
    },
    {
      title: t('controlPanel.baseData.status'),
      dataIndex: 'status',
      key: 'status',
      render: (dataStatus: 'COMPLETE' | 'WITH_DATA' | 'WITHOUT_DATA') => statusLabel[dataStatus]
    },
    {
      title: t('controlPanel.baseData.source'),
      dataIndex: 'source',
      key: 'source'
    },
    {
      title: t('controlPanel.baseData.evidence'),
      dataIndex: 'evidence',
      key: 'evidence',
      render: (evidence: string) => (
        <div className='flex gap-1 place-center'>
          <Icon icon='files_documents' size='medium' color='gray-dark' />
          <span className='font-12 weight-400 inter'>{evidence}</span>
        </div>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'verify',
      render: () => (
        <Button
          size='tiny'
          lookAndFeel='secondary'
          onClick={onVerify}
          iconNode={<Icon icon='success' size='medium' color='gray-dark' />}
          style={{ width: 'fit-content' }}>
          {t('controlPanel.verify')}
        </Button>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: (_, investor: DMInvestor) => <ButtonDropdown options={buildActions(investor)} />
    }
  ];

  const outputDataColumns: Column[] = [
    {
      title: t('controlPanel.outputData.organization'),
      dataIndex: 'organization',
      key: 'organization'
    },
    {
      title: t('controlPanel.outputData.category'),
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: t('controlPanel.outputData.data'),
      dataIndex: 'data',
      key: 'data'
    },
    {
      title: t('controlPanel.outputData.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => (
        <span className='font-12 p-1 rounded-4 text-white bg-secondary-purple-20'>{type}</span>
      )
    },
    {
      title: t('controlPanel.outputData.frameworks'),
      dataIndex: 'frameworks',
      key: 'frameworks',
      render: (frs: [string]) => (
        <div className='flex gap-1'>
          {frs.map((fr, idx) => (
            <span
              key={idx}
              className='font-12 weight-400 p-1 rounded-4 text-secondary-purple-20 bg-secondary-purple-80'>
              {fr}
            </span>
          ))}
        </div>
      )
    },
    {
      title: t('controlPanel.outputData.status'),
      dataIndex: 'status',
      key: 'status',
      render: (dataStatus: 'COMPLETE' | 'WITH_DATA' | 'WITHOUT_DATA') => statusLabel[dataStatus]
    },
    {
      title: '',
      dataIndex: '',
      key: 'verify',
      render: () => (
        <Button
          size='tiny'
          lookAndFeel='secondary'
          onClick={onVerify}
          iconNode={<Icon icon='success' size='medium' color='gray-dark' />}
          style={{ width: 'fit-content' }}>
          {t('controlPanel.verify')}
        </Button>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: (_, investor: DMInvestor) => <ButtonDropdown options={buildActions(investor)} />
    }
  ];

  return selectedData === BASE_DATA ? baseDataColumns : outputDataColumns;
};
