import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { getMembershipsPaginated } from 'services/api/membership';
import { generateQueryParamsFromObject } from 'utils/url';
import { useEffect, useState } from 'react';
import { User } from 'types/entities/user';
import { convertDateFrontToBack, convertDateToStringBackend } from 'utils/convertDates';

type Params = {
  email?: string;
  fullName?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  sort?: string;
};
const useGetData = ({ email, fullName, startDate, endDate, status, sort }: Params) => {
  const queryClient = useQueryClient();
  const [members, setMembers] = useState<User[]>([]);

  const makeFetch = async ({ page, size }: Params & { page: number; size: number }) => {
    const queryParamsObject: Record<string, Primitive | undefined> = {
      page,
      size
    };

    if (email) {
      queryParamsObject.email = email;
    }
    if (fullName) {
      queryParamsObject.full_name = fullName;
    }
    if (startDate) {
      // To YYYY-MM-DD
      queryParamsObject.start_date = startDate;
    }
    if (endDate) {
      queryParamsObject.end_date = endDate;
    }
    if (status) {
      queryParamsObject.status = status;
    }
    if (sort) {
      queryParamsObject.sort = sort;
    }
    const queryParams = generateQueryParamsFromObject(queryParamsObject);

    const response = await getMembershipsPaginated({ queryParams });

    return response;
  };

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['memberships'] });
  };

  const { data, refetch, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: ['memberships', email, fullName, startDate, endDate, status, sort],
    queryFn: ({ pageParam }) => makeFetch({ page: pageParam, size: SIZE_PAGINATION_BIG }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
    select: (data) => {
      return data.pages.reduce(
        (acc, current) => {
          return {
            total: current.total,
            total2: current.total2,
            page: current.page,
            size: current.size,
            items: [...acc.items, ...current.items].map((item) => ({
              ...item,
              isInvitation: item.status === 'pending'
            }))
          };
        },
        { total: 0, total2: 0, page: 0, size: 0, items: [] }
      );
    }
  });

  useEffect(() => {
    if (data) {
      setMembers(data.items);
    }
  }, [JSON.stringify(data?.items)]);

  return {
    members,
    setMembers,
    total: data?.total ?? 0,
    total2: data?.total2 ?? 0,
    fetchData: fetchNextPage,
    loading: isFetching,
    firstLoading: isLoading,
    refreshData: refetch,
    invalidate
  };
};

export default useGetData;
