import Tooltip from 'components/ui/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import getCountryName from 'utils/getCountryName';

export const useColumns = () => {
  const { t, i18n } = useTranslation();

  const columns: Column[] = [
    {
      title: t('doubleMateriality.context.clients.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('doubleMateriality.context.clients.country'),
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => getCountryName(country, i18n.resolvedLanguage as Lang)
    },
    {
      title: t('doubleMateriality.context.clients.activity'),
      dataIndex: 'activity',
      key: 'activity'
    },
    {
      dataIndex: 'sales',
      key: 'sales',
      title: (
        <div className='flex justify-initial gap-2'>
          <span>{t('doubleMateriality.context.clients.sales')}</span>
          <Tooltip text={t('doubleMateriality.context.clients.salesTooltip')} />
        </div>
      ),
      render: (value: string) => <span>{`${Number(value.replace(',', '.')) * 100}%`}</span>
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: (
        <div className='flex justify-initial gap-2'>
          <span>{t('doubleMateriality.context.clients.importance')}</span>
          <Tooltip text={t('doubleMateriality.context.clients.importanceTooltip')} />
        </div>
      ),
      render: (_, row: DMClient) => (
        <div
          className={`text-tag body3-font ${
            row.highImportance ? 'tag2-bg-text-color' : 'tag-bg-text-color'
          } w-fit-content p-1 rounded-4`}>
          {row.highImportance
            ? t('doubleMateriality.context.high')
            : t('doubleMateriality.context.low')}
        </div>
      )
    }
  ];
  return columns;
};
