import { loadUser } from 'actions/auth';
import { setNotification } from 'actions/notification';
import { ROUTES } from 'constants/routes';
import { useErrorTranslations } from 'customHooks/translations';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  authLogin,
  confirmMFALogin,
  confirmSignUp,
  MFAResponse,
  NonMFAData,
  resendConfirmationCode
} from 'services/authService';
import { Session } from 'types/entities/user';
import { useAuth, useSignIn as useClerkSignIn } from '@clerk/clerk-react';
import { z } from 'zod';

export enum SignInSteps {
  email = 'email',
  password = 'password',
  totp = 'totp',
  loading = 'loading',
  emailConfirmation = 'emailConfirmation'
}

type SignInData = {
  email: string;
  password: string;
  emailCode?: string;
  totpCode?: string;
};

const emailValidator = z.object({
  email: z.string().email('Invalid email')
});

const MAX_TRIES = 3;
const BLOCKED_TIME = 1000 * 60 * 5; // 5 minutes

export const useSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: tError } = useErrorTranslations();

  const { signIn: signInClerk, isLoaded: isClerkLoaded } = useClerkSignIn();
  const { signOut: signOutClerk, isSignedIn: isSignedInByClerk } = useAuth();

  const [sessionData, setSessionData] = useState<Session>();
  const [mfaSession, setMfaSession] = useState('');
  const [tries, setTries] = useState(0);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [blockedTime, setBlockedTime] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState<SignInSteps>(SignInSteps.loading);
  const [handleSubmitStep, setHandleSubmitStep] = useState<(data: SignInData) => void>(() => null);

  useEffect(() => {
    if (isClerkLoaded) {
      setStep(SignInSteps.email);
    }
  }, [isClerkLoaded]);

  useEffect(() => {
    if (!isSignedInByClerk) return;
    signOutClerk({ redirectUrl: ROUTES.SIGNIN }).then();
  }, [isSignedInByClerk]);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
    setFocus
  } = useFormWithHelpers({
    defaultValues: {
      email: '',
      password: '',
      emailCode: '',
      totpCode: ''
    }
  });

  const exceptionsMapper = useMemo(
    (): Record<
      string,
      {
        field: keyof typeof errors;
        message: string;
      }
    > => ({
      INVALID_CONFIRMATION_CODE: {
        field: 'emailCode',
        message: tError('invalidVerificationCode')
      },
      EXPIRED_CONFIRMATION_CODE: {
        field: 'emailCode',
        message: tError('expiredVerificationCode')
      },
      INVALID_MFA_CODE: {
        field: 'totpCode',
        message: tError('invalidVerificationCode')
      },
      EXPIRED_MFA_CODE: {
        field: 'totpCode',
        message: tError('expiredVerificationCode')
      }
    }),
    [tError]
  );

  const email = useWatch({ control, name: 'email' });

  useEffect(() => {
    // Temporary fix for the dates filter issue
    localStorage.removeItem('end_date_dashboard');
    localStorage.removeItem('start_date_dashboard');
  }, []);

  useEffect(() => {
    if (step === SignInSteps.email) {
      setFocus('email');
    } else if (step === SignInSteps.password) {
      setFocus('password');
    }
  }, [step]);

  useEffect(() => {
    if (localStorage.getItem('signInBlockedTime')) {
      const blockedTime = parseInt(localStorage.getItem('signInBlockedTime') ?? '0');
      if (blockedTime > new Date().getTime()) {
        setBlockedTime(blockedTime);
        setTries(MAX_TRIES);
        setError('email', { message: 'signInBlocked' });
      }
    }
  }, []);

  useEffect(() => {
    if (blockedTime > new Date().getTime()) return;

    if (tries >= MAX_TRIES) {
      // Set blocked time to 5 minutes
      const blockedTime = new Date().getTime() + BLOCKED_TIME;
      setBlockedTime(blockedTime);
      localStorage.setItem('signInBlockedTime', blockedTime.toString());
      setError('password', { message: 'invalidCredentialsTryAgain' });
      return;
    }

    if (tries > 0) {
      setError('password', {
        message: tError('invalidCredentialsAttempts', { count: MAX_TRIES - tries })
      });
      return;
    }

    clearErrors('password');
  }, [tries]);

  useEffect(() => {
    if (blockedTime > new Date().getTime()) {
      setTimeout(() => {
        localStorage.removeItem('signInBlockedTime');
        setBlockedTime(0);
        setTries(0);
        clearErrors('password');
      }, blockedTime - new Date().getTime());
    }
  }, [blockedTime]);

  const submitEmail = async ({ email }: SignInData) => {
    clearErrors('email');

    if (!emailValidator.safeParse({ email }).success) {
      setError('email', { message: 'invalidEmail' });
      return;
    }

    try {
      setIsLoading(true);
      // Check if user has + in email of dcycle.io domain
      const [emailUser, emailDomain] = email.split('@');
      if (emailDomain === 'dcycle.io' && !emailUser.includes('+sso')) {
        throw new Error('Dcycle custom email');
      }

      // const signInInstance = await signInClerk?.create(authParams);
      const signInInstance = await signInClerk?.create({
        identifier: email,
        strategy: 'enterprise_sso' as const,
        redirectUrl: '/clerk-callback'
      });

      if (!signInInstance) {
        setStep(SignInSteps.password);
        return;
      }

      await signInInstance?.authenticateWithRedirect({
        identifier: email,
        strategy: 'enterprise_sso' as const,
        emailAddress: email,
        redirectUrl: '/clerk-callback',
        redirectUrlComplete: '/clerk-callback',
        continueSignUp: true
      });
    } catch (error: any) {
      // If the user is not found in the SSO provider, user will be requested to enter password
      setStep(SignInSteps.password);
    } finally {
      setIsLoading(false);
    }
  };

  const submitPassword = async ({ email, password }: SignInData) => {
    setIsLoading(true);
    clearErrors('password');
    try {
      const { mfaEnabled, ...data } = await authLogin({
        email: email,
        password: password
      });
      if (mfaEnabled) {
        const mfaData = data as MFAResponse;
        setMfaSession(mfaData.session);
        setStep(SignInSteps.totp);
        return;
      }

      setSessionData(data as Session);
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === 'USER_NOT_CONFIRMED') {
        try {
          resendConfirmationCode({ email });
        } catch (error: any) {
          const errorCode = error?.response?.data?.code;
          if (errorCode === 'CODE_DELIVERY_FAILED') {
            dispatch(setNotification(tError('codeDeliveryFailed')));
            return;
          }
        }
        setStep(SignInSteps.emailConfirmation);
        return;
      } else if (errorCode === 'NOT_FOUND') {
        setError('email', { message: 'emailNotFound' });
      } else {
        setError('password', { message: 'invalidCredentials' });
        setTries((prev) => prev + 1);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitConfirmationCode = async ({ email, password, emailCode }: SignInData) => {
    clearErrors('emailCode');
    setIsLoading(true);
    try {
      const ok = await confirmSignUp({
        email: email,
        code: emailCode as string
      });
      const data = await authLogin({
        email,
        password
      });
      setSessionData(data as Session);
    } catch (error: any) {
      setStep(SignInSteps.emailConfirmation);
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage
        ? setError(errorMessage.field, { message: errorMessage.message })
        : setError('emailCode', { message: 'invalidVerificationCode' });
    } finally {
      setIsLoading(false);
    }
  };

  const submitTOTP = async ({ email, totpCode }: SignInData) => {
    setIsLoading(true);
    clearErrors('totpCode');
    try {
      const data = await confirmMFALogin({
        email: email,
        session: mfaSession,
        mfa_authenticator_code: totpCode ?? ''
      });

      setSessionData(data as Session);
    } catch (error: any) {
      setStep(SignInSteps.totp);
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage
        ? setError(errorMessage.field, { message: errorMessage.message })
        : setError('totpCode', { message: 'invalidVerificationCode' });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === SignInSteps.email) {
      setHandleSubmitStep(() => submitEmail);
    } else if (step === SignInSteps.password) {
      setHandleSubmitStep(() => submitPassword);
    } else if (step === SignInSteps.emailConfirmation) {
      setHandleSubmitStep(() => submitConfirmationCode);
    } else if (step === SignInSteps.totp) {
      setHandleSubmitStep(() => submitTOTP);
    } else {
      setHandleSubmitStep(() => null);
    }
  }, [step]);

  useEffect(() => {
    if (!sessionData) return;
    if ((sessionData as NonMFAData).password_change_required) {
      setChangePasswordModalOpen(true);
      return;
    }
    setStep(SignInSteps.loading);
    dispatch(
      loadUser(sessionData, true, true, '', () =>
        navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true })
      )
    );
  }, [JSON.stringify(sessionData)]);

  return {
    steps: SignInSteps,
    currentStep: step,
    handleSubmitStep: handleSubmit(handleSubmitStep),
    handlePasswordChange: () => {
      setSessionData({
        ...(sessionData as NonMFAData),
        password_change_required: false
      } as Session);
    },
    registerField: register,
    setFieldValue: setValue,
    blocked: tries >= MAX_TRIES,
    email,
    errors: {
      email: adaptHookFormError(errors.email, tError),
      password: adaptHookFormError(errors.password, tError),
      totpCode: adaptHookFormError(errors.totpCode, tError),
      emailCode: adaptHookFormError(errors.emailCode, tError)
    },
    clearErrors,
    loading: isLoading,
    changePasswordModalOpen
  };
};
