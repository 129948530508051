import { Invite, User } from '../../types/entities/user';
import apiFetch from '../../utils/apiFetch';

type GetUserInvitesParams = {
  user: User | null;
};

export const getUserInvites = async ({ user }: GetUserInvitesParams): Promise<Invite[]> => {
  if (!user) {
    throw new Error('No user provided');
  }

  const response = await apiFetch('get', `/invite_user/${user?.email}/holding`, null, {
    'x-organization-id': user?.selectedOrganization
  });

  return response.data;
};

export const editInvite = async (invite_id: string, role_id: string) => {
  try {
    const response = await apiFetch('PATCH', `/v1/invites/${invite_id}`, {
      role_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteInvite = async (invite_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/v1/invites/${invite_id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};
