import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { SIZE_PAGINATION_SMALL } from '../../../constants';
import { UserContext } from '../../../context/userContext';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { getUnits } from '../../../services/api/unit';
import { getVehicle, updateVehicle } from '../../../services/api/vehicle';
import {
  getVehicleConsumptionById,
  getVehicleConsumptionsPaginated
} from '../../../services/api/vehicleConsumptions';
import { Vehicle } from '../../../types/entities/vehicle';
import { ConsumptionType } from '../../../types/entities/vehicleConsumption';
import { InputSize } from '../../../types/utilsEnums/input';
import apiFetch from '../../../utils/apiFetch';
import formatNumber from '../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../ui/icon/Icon';
import InfiniteList from '../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../ui/infiniteList/hooks/useFetchInfiniteList';
import Modal from '../../ui/modal/Modal';
import Sorting from '../../ui/sorting/Sorting';
import PendingLabel from '../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import Validation, { ValidationCategory } from '../../validation/Validation';
import DeleteConsumption from './DeleteConsumption';
import EditCreateConsumption from './EditCreateConsumption';
import useColumns from './hooks/useColumns';
import useSortingOptions from './hooks/useSortingOptions';
import LimitCompleted from './limitCompleted/LimitCompleted';
import './styles.scss';
import { generateQueryParamsFromObject, getUrl } from '../../../utils/url';
import { CategoriesUploadedFiles } from '../uploadedFiles/constants';
import { ROUTES } from '../../../constants/routes';
import TotalLegendV2 from '../../ui/totalLegend/TotalLegendV2';
import WarningLabel from '../../ui/statusLabels/warningLabel/WarningLabel';
import { Filters } from '../../layout/NewFilters/Filters';
import FiltersDate from '../../layout/NewFilters/FiltersDate';
import FiltersHandlers from '../../layout/NewFilters/FiltersHandlers';
import { FilterText } from '../../layout/NewFilters/FilterText';
import { FilterSection } from '../../layout/NewFilters/FilterSection';

interface RestPagination {
  total_error: number;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  setNotification
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

function VehicleDetail({ setNotification }: Props) {
  const { t, i18n } = useTranslation();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const params = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const flags = useFeatureFlags();

  const selectedOrganization = useSelectedOrganization();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortingOptions = useSortingOptions();

  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [vehicle, setVehicle] = useState<Vehicle>();
  const [showNewConsumption, setShowNewConsumption] = useState(false);
  const [consumptionToEdit, setConsumptionToEdit] = useState<ConsumptionType>();
  const [consumptionToDelete, setConsumptionToDelete] = useState<ConsumptionType>();
  const [units, setUnits] = useState<Unit[]>([]);

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'start_date',
      order: 'desc'
    }
  ]);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showLimitCompleted, setShowLimitCompleted] = useState(false);

  const fetch = async (page: number): Promise<Pagination<ConsumptionType> | undefined> => {
    if (!params?.id) return;

    const response = await getVehicleConsumptionsPaginated(
      params?.id,
      page,
      SIZE_PAGINATION_SMALL,
      filters,
      sorters
    );
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    addElement,
    removeElement,
    editElement,
    data,
    rest
  } = useFetchInfiniteList<ConsumptionType, RestPagination>(fetch, [
    JSON.stringify(filters),
    JSON.stringify(sorters)
  ]);

  const fetchDataVehicle = async () => {
    if (!params.id || !user?.selectedOrganization) {
      return;
    }
    const vehicleDetail = await getVehicle(params.id);

    let name = vehicleDetail.name || '';

    if (vehicleDetail.unknown_vehicle && i18n.exists(`vehicles.${vehicleDetail.name}`)) {
      name = `${t(`vehicles.${vehicleDetail.name}`)}`;
    }
    setVehicle({
      ...vehicleDetail,
      name
    });
    const units = await getUnits();
    const allowedUnits = units.filter(
      (unit: { name: string }) => unit.name === 'litre_(l)' || unit.name === 'kilometer_(km)'
    );
    setUnits(allowedUnits);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchDataVehicle();
      setLoading(false);
    };

    fetchData();
  }, [params.id]);

  const fetchConsumptionToEdit = async (id: string) => {
    if (!id) return;
    const data = await getVehicleConsumptionById(id);
    setConsumptionToEdit(data);
  };

  const fetchConsumptionToDelete = async (id: string) => {
    if (!id) return;
    const data = await getVehicleConsumptionById(id);
    setConsumptionToDelete(data);
  };

  useEffect(() => {
    setShowValidationModal(false);
    // Extract the query parameter you want to monitor
    const queryParams = new URLSearchParams(location.search);
    const myQueryParam = queryParams.get('show');

    if (myQueryParam === 'create') setShowNewConsumption(true);
    if (myQueryParam === 'edit') {
      fetchConsumptionToEdit(queryParams.get('id') || '');
    }
    if (myQueryParam === 'delete') {
      fetchConsumptionToDelete(queryParams.get('id') || '');
    }
  }, [location.search]);

  const renderDropdownComponent = (consumption: ConsumptionType) => {
    if (vehicle?.status === 'archived') {
      return (
        <TooltipWrapper
          text={t('vehicleDetail.archivedVehicleTooltip')}
          style={{ marginLeft: 'auto' }}>
          <Icon icon='locked' color={'gradient'} />
        </TooltipWrapper>
      );
    }

    const options = [
      {
        id: `${consumption.id}-edit`,
        name: t('vehicleDetail.edit'),
        onClick: () => {
          setConsumptionToEdit(consumption);
        }
      },
      {
        id: `${consumption.id}-delete`,
        name: t('vehicleDetail.delete'),
        onClick: () => {
          setConsumptionToDelete(consumption);
        }
      }
    ];

    if (consumption.file_name) {
      options.push({
        id: `${consumption.id}-goToFile`,
        name: t('vehicleDetail.goToFile'),
        onClick: () => {
          navigate(
            getUrl(
              `${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS}`,
              {
                queryParams: generateQueryParamsFromObject({
                  name: consumption.file_name as string
                })
              }
            )
          );
        }
      });
    }

    return <ButtonDropdown options={options} />;
  };

  const renderCustomIdComponent = (custom_id: string) => {
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/cloudGray.svg' alt='cloud' />
        <span>{custom_id}</span>
      </div>
    );
  };

  const { id } = useParams();

  const onCloseModal = () => {
    setShowNewConsumption(false);
    setConsumptionToEdit(undefined);
    setConsumptionToDelete(undefined);
    // delete query param "show"
    searchParams.delete('show');
    setSearchParams(searchParams);
  };

  const handleShowNewConsumption = () => {
    setShowNewConsumption(true);
  };

  const addConsumption = (consumption: ConsumptionType) => {
    const newConsumption = {
      id: consumption.id,
      quantity: consumption.quantity,
      unit: consumption.unit,
      start_date: consumption.start_date,
      end_date: consumption.end_date,
      status: 'active',
      custom_id: consumption.custom_id,
      co2e: undefined,
      error_messages: []
    };

    addElement(newConsumption);
    onCloseModal();
    setNotification(t('notification.createConsumption'));
  };

  const editConsumption = (consumption: ConsumptionType) => {
    editElement({
      ...consumption,
      co2e: undefined,
      status: 'loading'
    });
    setConsumptionToEdit(undefined);
    onCloseModal();
    setNotification(t('notification.editConsumption'));
  };

  const deleteConsumption = (id: string) => {
    removeElement(id);
    setNotification(t('notification.deleteConsumption'));
    setConsumptionToDelete(undefined);
  };

  const getErrorMessage = (errorMessages: string[]) => {
    if (errorMessages.length === 0) {
      return {
        error: t('general.withErrors'),
        tooltip: t('vehicleDetail.errors.general')
      };
    }

    const uniqueTooltipMessages: string[] = [];

    errorMessages.forEach((elem) => {
      const formattedElem = i18n.exists(`vehicleDetail.errors.${elem.toLowerCase()}_tooltip`)
        ? t(`vehicleDetail.errors.${elem.toLowerCase()}_tooltip`)
        : t('vehicleDetail.errors.general');
      if (!uniqueTooltipMessages.includes(formattedElem)) {
        uniqueTooltipMessages.push(formattedElem);
      }
    });

    if (uniqueTooltipMessages.length === 1) {
      const msg = uniqueTooltipMessages[0];
      return {
        error: i18n.exists(`vehicleDetail.errors.${msg.toLowerCase()}`)
          ? t(`vehicleDetail.errors.${msg.toLowerCase()}`)
          : t('general.withErrors'),
        tooltip: msg
      };
    }

    return {
      error: t('general.reviewSeveral'),
      tooltip: (
        <>
          <span>{t('general.reviewTheFollowingIncidences')}</span>
          <ul>
            {uniqueTooltipMessages.map((elem: string) => (
              <li key={elem}>{elem}</li>
            ))}
          </ul>
        </>
      )
    };
  };

  const statusIconSelect = (status: string, errorMessages: string[], showTooltip = true) => {
    const { error, tooltip } = getErrorMessage(errorMessages ?? []);
    const statusMap: { [key: string]: ReactNode } = {
      active: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
      success: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
      loading: (
        <TooltipWrapper
          text={t('vehicleDetail.processingTooltip')}
          position='top'
          shouldAppear={showTooltip}>
          <PendingLabel>{t('general.processing')}</PendingLabel>
        </TooltipWrapper>
      ),
      error: (
        <TooltipWrapper text={tooltip} position='top' shouldAppear={showTooltip}>
          <WarningLabel>{error}</WarningLabel>
        </TooltipWrapper>
      )
    };
    return statusMap[status] ?? null;
  };

  const parseData = (consumptionsFiltered: ConsumptionType[]) => {
    return consumptionsFiltered.map((consumption) => {
      const consumptionStartDate = consumption.start_date
        ? new Date(consumption.start_date).toLocaleDateString()
        : '-';
      const consumptionEndDate = consumption.end_date
        ? new Date(consumption.end_date).toLocaleDateString()
        : '-';
      return {
        ...consumption,
        custom_id: renderCustomIdComponent(consumption.custom_id ?? '-'),
        quantity: (
          <span>
            {formatNumber(consumption.quantity)} {t(`units_short.${consumption.unit.name}`)}
          </span>
        ),
        co2e:
          consumption.status === 'loading' || !consumption.co2e ? (
            <TooltipWrapper text={t('vehicleDetail.co2eTooltip')}>
              <span className='highlight-text-color' style={{ fontWeight: 600 }}>
                -
              </span>
            </TooltipWrapper>
          ) : (
            <span
              className='highlight-text-color'
              style={{ fontWeight: 600, textAlign: 'right', display: 'block' }}>
              {formatNumber(numberToDecimalNonZero(consumption.co2e))} kg
            </span>
          ),
        startDate: consumptionStartDate,
        endDate: consumptionEndDate,
        edit: renderDropdownComponent(consumption),
        status: statusIconSelect(consumption.status, consumption.error_messages)
      };
    });
  };

  const columns = useColumns();

  const sideBarFilters = [
    {
      options: units.map((unit) => ({
        id: unit.id,
        name: t(`units.${unit.name}`),
        total: undefined,
        type: 'unit_id'
      })),
      title: t('templates.vehicles_consumptions.unit_measure'),
      elToShow: 0
    }
  ];

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };

  const handleDearchiveVehicle = async () => {
    setLoadingButton(true);

    const responseVehicles = await apiFetch(
      'GET',
      '/vehicles',
      undefined,
      {},
      {
        page: 1,
        size: SIZE_PAGINATION_SMALL,
        filter_by: 'status:eqactive'
      }
    );
    if (!responseVehicles || !responseVehicles.data) {
      setLoadingButton(false);
      return;
    }
    if (
      selectedOrganization?.limit_vehicles &&
      selectedOrganization?.limit_vehicles <= responseVehicles.data.total
    ) {
      setLoadingButton(false);
      setShowLimitCompleted(true);
      return;
    }
    if (!vehicle) {
      setLoadingButton(false);
      return;
    }
    const response = await updateVehicle(vehicle.id, { status: 'active' });
    setLoadingButton(false);
    if (!response) return;
    setVehicle({
      ...vehicle,
      status: 'active'
    });
    setNotification(t('notification.dearchiveVehicle'));
  };

  if (!vehicle) return null;

  return (
    <section className='vehicle-detail'>
      <div className='vehicle-detail__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
        <Breadcrumb crumbsReplace={id ? [{ key: id, value: vehicle.name }] : []} />
      </div>
      <div className='vehicle-detail__body main-bg-color solid-border '>
        <div className='vehicle-detail-card main-bg-color solid-border '>
          <h1 className='headline4-font'>
            {vehicle.status !== 'archived'
              ? t('vehicleDetail.start')
              : t('vehicleDetail.archivedVehicle')}
          </h1>
          <p className='subtitle3-font'>
            {vehicle.status !== 'archived'
              ? t('vehicleDetail.startDescription')
              : t('vehicleDetail.archivedVehicleDescription')}
          </p>

          <div className='buttons'>
            {vehicle.status !== 'archived' ? (
              <Button
                lookAndFeel='primary'
                text={t('vehicleDetail.addConsumption')}
                size='small'
                onClick={handleShowNewConsumption}
                disabled={loading}
              />
            ) : (
              <Button
                lookAndFeel='primary'
                text={t('vehicleDetail.dearchiveVehicle')}
                iconNode={<Icon icon='locked' color={'white'} />}
                size='small'
                onClick={handleDearchiveVehicle}
                disabled={loading}
                loading={loadingButton}
              />
            )}
          </div>
        </div>
      </div>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu>
          <FiltersDate fields={['start_date']} />
          <FilterSection.Multiple
            title={t('purchases.status')}
            field='status'
            type='in'
            options={['success', 'loading', 'error'].map((status) => ({
              value: status,
              label: statusIconSelect(status, [], false)
            }))}
          />
          <FilterSection.Multiple
            title={t('vehicleDetail.unit')}
            field='unit_id'
            type='in'
            options={units.map((elem) => ({
              value: elem.id,
              label: t(`units.${elem.name}`)
            }))}
          />
        </Filters.Menu>

        <InfiniteList
          data={parseData(data)}
          columns={columns}
          loading={loadingTable}
          firstLoading={firstLoading}
          fetchData={fetchData}
          total={total}
          header={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <FilterText
                    field='custom_id'
                    type='il'
                    placeholder={t(`vehicleDetail.inputFilter`)}
                    size={InputSize.MEDIUM}
                  />
                  <Sorting
                    options={sortingOptions}
                    label={t('input.sorting.orderBy')}
                    placeholder={t('input.placeholderSelect')}
                    onSort={onSort}
                    defaultSort={{
                      id: 'start_date',
                      name: t('input.sorting.facilities.startDate'),
                      direction: 'desc'
                    }}
                  />
                  <FiltersHandlers />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {flags?.dataValidation && vehicle.status !== 'archived' ? (
                    <Button
                      lookAndFeel='primary'
                      text={t('validation.validate')}
                      size='small'
                      onClick={() => setShowValidationModal(true)}
                    />
                  ) : null}
                  <TotalLegendV2
                    totalLabel={tGeneral('total')}
                    totalElements={[
                      {
                        value: total,
                        label: t('vehicleDetail.total')
                      },
                      {
                        value: rest?.total_error ?? 0,
                        label: t('vehicleDetail.total2')
                      }
                    ]}
                  />
                </div>
              </div>
            </>
          }
        />
      </Filters.Root>

      <Modal show={showNewConsumption} onClose={onCloseModal} width='630px' maxWidth='630px'>
        <EditCreateConsumption user={user} mode='create' addConsumption={addConsumption} />
      </Modal>

      <Modal show={!!consumptionToEdit} onClose={onCloseModal} width='630px' maxWidth='630px'>
        <EditCreateConsumption
          user={user}
          mode='edit'
          consumptionToEdit={consumptionToEdit}
          editConsumption={editConsumption}
        />
      </Modal>

      <Modal show={!!consumptionToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        {consumptionToDelete && (
          <DeleteConsumption
            user={user}
            deleteConsumption={deleteConsumption}
            consumptionToDelete={consumptionToDelete}
          />
        )}
      </Modal>
      <Modal
        show={showValidationModal}
        onClose={() => setShowValidationModal(false)}
        width='900px'
        maxWidth='900px'>
        <Validation
          category={ValidationCategory.VEHICLES}
          subcategories={[]}
          entity={vehicle.name}
          entityId={params.id ?? ''}
        />
      </Modal>
      <Modal
        show={showLimitCompleted}
        onClose={() => setShowLimitCompleted(false)}
        maxWidth='484px'>
        <LimitCompleted handleClose={() => setShowLimitCompleted(false)} />
      </Modal>
    </section>
  );
}

export default connector(VehicleDetail);
