import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import Icon from 'components/ui/icon/Icon';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import useYearOptions from 'customHooks/useYearOptions';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkFormErrors from 'utils/checkFormErrors';
import { downloadExcelScope1_2 } from '../MITECO/services/downloadExcelScope1_2';
import { useDispatch } from 'react-redux';
import { setNotification } from 'actions/notification';

type Props = {
  onClose: () => void;
};
type FormData = {
  year: SelectOptionFormat;
  errors: ErrorType[];
};
const Scope1_2 = ({ onClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scope1_2Download' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<FormData>({
    year: {
      id: moment.utc().year().toString(),
      name: moment.utc().year().toString()
    },
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const yearOptions = useYearOptions();

  const handleSubmit = async () => {
    const errors = checkFormErrors(formData, formData.errors);
    if (errors.length) {
      setFormData({ ...formData, errors });
      return;
    }
    setLoadingButton(true);
    try {
      const downloadURL = await downloadExcelScope1_2({ year: formData.year.id });

      const link = document.createElement('a');

      link.href = downloadURL;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      dispatch(setNotification(t('notification.success')));
    } catch {
      dispatch(setNotification(t('notification.error')));
    } finally {
      setLoadingButton(false);
    }
    setLoadingButton(false);
  };

  return (
    <>
      <FormHeader title={t('title')} description={t('description')} />
      <FormWrapper>
        <FormSelect
          iconV2='calendar'
          label={t('yearInput')}
          options={yearOptions.years}
          value={formData.year}
          onChange={onChangeValue('year')}
          error={formData.errors.find((error) => error.error === 'year')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('downloadExcel')}
          onClick={handleSubmit}
          loading={loadingButton}
          iconNode={<Icon icon='download' color='white' />}
        />
      </FormButtonSection>
    </>
  );
};

export default Scope1_2;
