import { useTranslation } from 'react-i18next';
import IconBackground from '../../../ui/iconBackground/IconBackground';
import { ReactNode } from 'react';
import ReportTableStandard from '../../../ui/reports/reportTableStandard/ReportTableStandard';
import './styles.scss';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { Column } from '../../../../types/table';
import { numberToScientificNotation } from '../../../../utils/numberToDecimal';

type TableElement = {
  formula: ReactNode;
  name: ReactNode;
  pca: ReactNode;
};

type Props = {
  scopes: number[];
  verification?: string;
  ipccVersion?: string;
  gwpTable?: {
    co2: number;
    ch4: number;
    n2o: number;
  };
};

function Methodology({ scopes, verification, ipccVersion, gwpTable }: Props) {
  const { t } = useTranslation();
  const { t: tG } = useTranslation('translation', { keyPrefix: 'ghgReport.methodolgyTable' });

  let scopesString = '';

  scopes.forEach((scope, index) => {
    if (index === 0) return (scopesString += scope);
    scopesString += `, ${scope}`;
  });

  const description = t('ghgReport.methodologyDescription', {
    ipcc: ipccVersion,
    scopes: scopesString
  });

  const columns: Column[] = [
    {
      title: tG('formula'),
      dataIndex: 'formula',
      key: 'formula',
      width: '33%'
    },
    {
      title: tG('name'),
      dataIndex: 'name',
      key: 'name',
      width: '33%'
    },
    {
      title: tG('pca'),
      dataIndex: 'pca',
      key: 'pca',
      width: '33%',
      align: 'right'
    }
  ];

  const data: TableElement[] = [
    {
      formula: 'CO₂',
      name: tG('co2'),
      pca: numberToScientificNotation(gwpTable?.co2 ?? 0)
    },
    {
      formula: 'CH₄',
      name: tG('ch4'),
      pca: numberToScientificNotation(gwpTable?.ch4 ?? 0)
    },
    {
      formula: 'N₂O',
      name: tG('n20'),
      pca: numberToScientificNotation(gwpTable?.n2o ?? 0)
    }
  ];

  let verificationText: string | undefined = t('ghgReport.foot.otherVerification', {
    verification
  });
  if (verification === 'eqa') verificationText = t(`ghgReport.foot.${verification.toLowerCase()}`);
  if (verification === 'none') verificationText = undefined;

  const descriptionArray: string[] = [t('ghgReport.foot.dcycleTool')];
  if (verificationText) descriptionArray.push(verificationText);

  return (
    <>
      <div className='methodology-scope-period__item on-card-gray-bg-color'>
        <div className='methodology-scope-period__item__image-wrapper'>
          <IconBackground icon='/images/icons/menu.svg' alt='menu' />
          <h2 className='body2-font'>{t('ghgReport.methodology')}</h2>
        </div>
        <p className='body3-font'>{description}</p>
      </div>
      <ReportTableStandard
        columns={columns}
        data={data}
        style={{
          marginTop: '0.5rem'
        }}
      />
      <div
        style={{
          marginTop: 'auto'
        }}>
        <DescriptionBox descriptions={descriptionArray} />
      </div>
      {!scopes.includes(3) && (
        <span
          className='body1-font on-light-text-color2'
          style={{
            marginTop: '0.5em'
          }}>
          {t('ghgReport.foot.noScope3')}
        </span>
      )}
    </>
  );
}

export default Methodology;
