export enum PredefinedRoles {
  ADMINISTRATOR = 'Administrator',
  MEMBER = 'Member',
  FUND = 'Fund'
}

export enum PredefinedRoleIds {
  ADMIN_ROLE_ID = 'dc4bab21-5a58-4856-88cb-137a0a15a15d',
  MEMBER_ROLE_ID = '74c802d7-7b21-44e9-8dca-f3334b0732fa',
  FUND_ROLE_ID = '49303142-f6a1-419b-9353-f4a6f3245235'
}
