import { forwardRef, InputHTMLAttributes, useState } from 'react';
import InputText from '../inputText/InputText';
import Button from 'components/ui/button/Button';
import { InputSize } from 'types/utilsEnums/input';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize;
  wrapperClassName?: string;
}

export const InputPassword = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation('', { keyPrefix: 'general' });

  return (
    <InputText.WithOverlay
      ref={ref}
      overlay={
        <Button lookAndFeel='disabled' onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? t('hide') : t('show')}
        </Button>
      }
      placeholder={t('confirmPassword')}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  );
});

InputPassword.displayName = 'InputPassword';
