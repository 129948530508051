import { InfiniteData } from '@tanstack/react-query';

export const adaptClientsBackToFront = (
  backendClients: InfiniteData<BackendDMClientsResponse, number>
): { total: number; clients: DMClient[] } => ({
  total: backendClients.pages[0].total,
  clients: backendClients.pages.flatMap(({ items: clients }) =>
    clients.map((client: BackendDMClient, idx: number) => ({
      id: idx.toString(),
      name: client.business_name,
      country: client.country,
      activity: client.sector,
      sales: client.quantity_percentage,
      highImportance: client.high_importance
    }))
  )
});
