import { useTranslation } from 'react-i18next';
import Modal from '../../../ui/modal/Modal';
import Button from '../../../ui/button/Button';
import { downloadExcelScope1_2 } from '../services/downloadExcelScope1_2';
import Select from '../../../ui/formComponents2/select/Select';
import moment from 'moment';
import { useMemo, useState } from 'react';
import InputWrapper from '../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import styles from './excelModal.module.scss';
import Icon from '../../../ui/icon/Icon';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';

type Props = Omit<React.ComponentProps<typeof Modal>, 'children'>;

export const ExcelModal: React.FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'certifications.excelModal' });
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();

  const [year, setYear] = useState<SelectOptionFormat>({
    id: moment.utc().year().toString(),
    name: moment.utc().year().toString()
  });

  const handleDownloadExcel = async () => {
    if (downloading) {
      return;
    }

    try {
      setDownloading(true);
      const downloadURL = await downloadExcelScope1_2({ year: year.id, country: 'ES' });

      const link = document.createElement('a');

      link.href = downloadURL;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      dispatch(setNotification(t('notification.success')));
    } catch {
      dispatch(setNotification(t('notification.error')));
    } finally {
      setDownloading(false);
    }
  };

  const options = useMemo(() => generateSelectOptions(), []);

  return (
    <Modal.WithPortal show={show} onClose={onClose} maxWidth='600px'>
      <Modal.Header title={t('title')} description={t('description')} />
      <Modal.Content>
        <InputWrapper iconV2='calendar' className={styles['modal-content']} label={t('yearInput')}>
          <Select value={year} onChange={setYear} options={options} disabled={downloading} />
        </InputWrapper>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          text={t('download')}
          size='medium'
          lookAndFeel='primary'
          onClick={handleDownloadExcel}
          iconNode={<Icon icon='download' color='white' />}
          loading={downloading}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};

const generateSelectOptions = () => {
  const thisYear = moment.utc().year();

  const options: SelectOptionFormat[] = [];

  for (let year = 2015; year <= thisYear; year++) {
    options.push({
      id: year.toString(),
      name: year.toString()
    });
  }

  return options;
};
