import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import useGetData from 'components/tasks/hooks/useGetData';
import Button from 'components/ui/button/Button';
import FormElementHalf from 'components/ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormCalendar from 'components/ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDateToString } from 'utils/convertDates';
import { useAssignTask } from './hooks/useAssignTask';
import { IFrontendFormTask } from 'types/entities/task';
import { useObtainAssignedTask } from './hooks/useObtainAssignedTask';
import { UserContext } from 'context/userContext';

const getTranslatedName = (datapoint: FrameworkDatapoint, currentLanguage: string) => {
  switch (currentLanguage) {
    case 'es':
      return datapoint.name_es;
    case 'pt':
      return datapoint.name_pt;
    default:
      return datapoint.name_en;
  }
};

type AssignModalProps = {
  datapoint: FrameworkDatapoint;
  closeModal: () => void;
};

const dateInAWeek = new Date();
dateInAWeek.setDate(dateInAWeek.getDate() + 7);

const dateInAYear = new Date();
dateInAYear.setDate(dateInAYear.getDate() + 365);

export const AssignModal = ({ datapoint, closeModal }: AssignModalProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { users } = useGetData();
  const user = useContext(UserContext);
  const { data: task } = useObtainAssignedTask(datapoint.task?.id);

  const [assignedUser, setAssignedUser] = useState({
    id: task?.userSelected.id ?? '',
    name: task?.userSelected.name ?? ''
  });
  const [dueDate, setDueDate] = useState(task ? task.dueDate : convertDateToString(dateInAWeek));

  useEffect(() => {
    if (task) {
      setAssignedUser({
        id: task.userSelected.id,
        name: task.userSelected.name
      });
      setDueDate(task.dueDate);
    }
  }, [task]);

  const { mutate } = useAssignTask();

  const handleSave = () => {
    const taskData: IFrontendFormTask = task
      ? {
          ...task,
          userSelected: assignedUser,
          dueDate
        }
      : {
          userSelected: assignedUser,
          dueDate,
          category: {
            id: 'other',
            name: 'other'
          },
          description: `Rellenar el datapoint ${datapoint.datapoint_id}`,
          tag: '',
          tagsSelected: [],
          createdBy: user?.id ?? '',
          organizationId: user?.selectedOrganization ?? '',
          files: [],
          errors: []
        };

    mutate({ datapoint, taskData, taskId: datapoint.task?.id });
    closeModal();
  };

  return (
    <>
      <FormHeader
        title={
          datapoint.task
            ? t('esgMetrics.general.editModalTitle')
            : t('esgMetrics.general.addResponseModalTitle')
        }
      />
      <p>
        {datapoint.datapoint_id} - {getTranslatedName(datapoint, currentLanguage)}
      </p>
      <div
        style={{
          maxHeight: '350px',
          overflowY: 'auto'
        }}>
        <FormWrapper>
          <FormElementHalf className='flex justify-between items-end gap-4'>
            <FormSelect
              iconV2='user'
              placeholder={t('tasks.selectUser')}
              label={t('tasks.assignResponsible')}
              options={users}
              value={assignedUser}
              onChange={setAssignedUser}
            />
            <FormCalendar
              handleChangeDate={setDueDate}
              dateValue={dueDate}
              label={t('tasks.dueDate')}
              maxDate={convertDateToString(dateInAYear)}
              minDate={convertDateToString(new Date())}
            />
          </FormElementHalf>
          <Button
            onClick={handleSave}
            text={t('tasks.save')}
            lookAndFeel='primary'
            disabled={assignedUser.id === ''}
          />
        </FormWrapper>
      </div>
    </>
  );
};
