import { useQuery } from '@tanstack/react-query';
import { adaptTaskBackToFront } from 'adapters/tasks';
import { useTranslation } from 'react-i18next';
import { getTaskById } from 'services/api/tasks';
import { IGetBackendTask } from 'types/entities/task';

export const useObtainAssignedTask = (taskId: string | undefined) => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ['tasks', taskId],
    queryFn: async () => {
      if (!taskId) return;
      return (await getTaskById(taskId)) as IGetBackendTask;
    },
    select: (data) => {
      if (!data) return;
      return adaptTaskBackToFront(data, t);
    }
  });

  return { data };
};
