import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from 'components/ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import Icon from 'components/ui/icon/Icon';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from './hooks/usePermissions';
import Checkbox from 'components/ui/formComponents2/checkbox/Checkbox';
import checkFormErrors from 'utils/checkFormErrors';
import { error_color_text } from 'styles/colors';
import { FormData } from './CreateRoleWrapper';
import Tooltip from 'components/ui/tooltip/Tooltip';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import { geti18nKey } from '../../utils';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { PermissionTags } from 'types/entitiesEnums/permissions';

const Tag = ({ name }: { name: string }) => {
  return (
    <div
      className='font-12 weight-400 bg-purple text-white rounded-4 px-2 py-1 w-fit-content flex items-center gap-1'
      key={name}>
      <Icon icon='categories' color='white' />
      {name}
    </div>
  );
};

type Props = {
  onClose: () => void;
  formData: FormData;
  setFormData: Dispatch<SetStateAction<FormData>>;
  onSubmit: () => void;
  loading?: boolean;
  disabled?: boolean;
  predefined?: boolean;
  role?: Role;
};

const FormRole = ({
  onClose,
  formData,
  setFormData,
  onSubmit,
  loading,
  disabled,
  predefined,
  role
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'members.manageRoles.createOrEditRole'
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tCategories } = useTranslation('translation', {
    keyPrefix: 'members.manageRoles.categories'
  });

  const { permissionsByCategory, isLoading } = usePermissions();

  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    setLoadingButton(true);

    const newErrors = checkFormErrors(formData, formData.errors);

    if (formData.permissions.length === 0) {
      newErrors.push({ error: 'categories' });
    }

    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      setLoadingButton(false);
      return;
    }

    await onSubmit();

    setLoadingButton(false);
  };

  const renderTooltip = (permission: Permission) => {
    return permission.tag === PermissionTags.CSRD_SENSITIVE_ALL ? (
      <Tooltip
        text={t('csrdTooltip')}
        position='right'
        style={{ display: 'flex', alignItems: 'center' }}
      />
    ) : null;
  };

  return (
    <>
      <FormHeader title={t('title')} description={t('description')} />
      <FormWrapper className={disabled ? 'gap-6' : 'gap-6 mb-10'}>
        <FormElementHalf>
          <FormText
            iconV2='user'
            disabled={disabled}
            label={t('roleName')}
            placeholder={t('roleNamePlaceholder')}
            onChange={onChangeValue('name')}
            value={formData.name}
            error={formData.errors.find((error) => error.error === 'name')}
          />
          {(disabled || predefined) && (
            <BlueWrapper className='inline-flex w-fit-content overflow-visible whitespace-nowrap mt-2 px-2 py-1'>
              <div className='flex items-center gap-1'>
                <Icon icon='status' color='pending' />
                <span className={'font-12 weight-400 text-secondary-purple-20'}>
                  {predefined && t('changesDisabled')}
                  {disabled &&
                    !predefined &&
                    t('parentRole', { parentCompany: role?.organization?.company_name ?? '' })}
                </span>
              </div>
            </BlueWrapper>
          )}
        </FormElementHalf>
        {loading || isLoading ? (
          <CustomSkeletonLoader count={disabled ? 20 : 12} />
        ) : (
          <div className='flex flex-col gap-4'>
            <span className='font-12 weight-600'>{t('selectPermissions')}</span>
            {Object.keys(permissionsByCategory).map((category) => {
              return (
                <>
                  <Tag name={t(`sections.${category}`)} />
                  <div className='flex wrap gap-4'>
                    {permissionsByCategory[category].map((permission) => {
                      const blockCheckbox =
                        permission.tag === PermissionTags.CSRD_GENERAL_ALL ||
                        disabled ||
                        category === 'general';
                      return (
                        <Checkbox
                          disabled={blockCheckbox}
                          color={blockCheckbox ? 'gray' : 'gradient'}
                          key={permission.id as unknown as string}
                          text={
                            <div className='flex items-center font-10 weight-600'>
                              {tCategories(geti18nKey(permission.resource))}
                              {<div className='ml-2'>{renderTooltip(permission)}</div>}
                            </div>
                          }
                          onChange={(value: boolean) => {
                            if (value) {
                              setFormData({
                                ...formData,
                                permissions: [...formData.permissions, permission.id],
                                errors: formData.errors.filter(
                                  (error) => error.error !== 'categories'
                                )
                              });
                            } else {
                              setFormData({
                                ...formData,
                                permissions: formData.permissions.filter(
                                  (cat) => cat !== permission.id
                                )
                              });
                            }
                          }}
                          selected={formData.permissions.includes(permission.id)}
                          style={{
                            flexBasis: category === 'csrd' ? '100%' : 'calc(50% - 2rem)',
                            boxSizing: 'border-box',
                            color: formData.errors.find((error) => error.error === 'categories')
                              ? error_color_text
                              : 'inherit',
                            marginTop: '0',
                            marginBottom: '0'
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
      </FormWrapper>
      {!disabled && (
        <FormButtonSection>
          <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
          <Button
            lookAndFeel='primary'
            text={tGeneral('saveAndClose')}
            onClick={handleSubmit}
            loading={loadingButton}
          />
        </FormButtonSection>
      )}
    </>
  );
};

export default FormRole;
