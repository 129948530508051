export const EINFMockDatapoints = {
  general: [
    {
      id: '1',
      name_dcycle: 'Descripción del modelo de negocio',
      name_en: 'Business model description',
      name_es: 'Descripción del modelo de negocio',
      name_pt: 'Descrição do modelo de negócio',
      sources: ['Sostenibilidad']
    },
    {
      id: '2',
      name_dcycle: 'Políticas',
      name_en: 'Policies',
      name_es: 'Políticas',
      name_pt: 'Políticas',
      sources: ['Sostenibilidad']
    },
    {
      id: '3',
      name_dcycle: 'KPIs de seguimiento de las políticas',
      name_en: 'Policy tracking KPIs',
      name_es: 'KPIs de seguimiento de las políticas',
      name_pt: 'KPIs de acompanhamento de políticas',
      sources: ['Sostenibilidad']
    },
    {
      id: '4',
      name_dcycle: 'Riesgos asociados a las actividades del grupo',
      name_en: 'Risks associated with group activities',
      name_es: 'Riesgos asociados a las actividades del grupo',
      name_pt: 'Riscos associados às atividades do grupo',
      sources: ['Finanzas']
    }
  ],
  social: [
    {
      id: '1',
      name_dcycle: 'Número total de empleados',
      name_en: 'Total number of employees',
      name_es: 'Número total de empleados',
      name_pt: 'Número total de empregados',
      sources: ['RRHH']
    },
    {
      id: '2',
      name_dcycle: 'Distribución de empleados por sexo, edad, país y clasificación profesional',
      name_en: 'Employee distribution by gender, age, country, and professional classification',
      name_es: 'Distribución de empleados por sexo, edad, país y clasificación profesional',
      name_pt: 'Distribuição de funcionários por sexo, idade, país e classificação profissional',
      sources: ['RRHH']
    },
    {
      id: '3',
      name_dcycle: 'Número total y distribución de modalidades de contrato de trabajo',
      name_en: 'Total number and distribution of employment contract types',
      name_es: 'Número total y distribución de modalidades de contrato de trabajo',
      name_pt: 'Número total e distribuição dos tipos de contrato de trabalho',
      sources: ['RRHH']
    },
    {
      id: '4',
      name_dcycle:
        'Promedio anual de contratos a tiempo parcial por sexo, edad y clasificación profesional',
      name_en:
        'Annual average of part-time contracts by gender, age, and professional classification',
      name_es:
        'Promedio anual de contratos a tiempo parcial por sexo, edad y clasificación profesional',
      name_pt:
        'Média anual de contratos a tempo parcial por sexo, idade e classificação profissional',
      sources: ['RRHH']
    },
    {
      id: '5',
      name_dcycle:
        'Promedio anual de contratos indefinidos por sexo, edad y clasificación profesional',
      name_en:
        'Annual average of permanent contracts by gender, age, and professional classification',
      name_es: 'Promedio anual de contratos indefinidos por sexo, edad y clasificación profesional',
      name_pt: 'Média anual de contratos permanentes por sexo, idade e classificação profissional',
      sources: ['RRHH']
    },
    {
      id: '6',
      name_dcycle:
        'Promedio anual de contratos temporales por sexo, edad y clasificación profesional',
      name_en:
        'Annual average of temporary contracts by gender, age, and professional classification',
      name_es: 'Promedio anual de contratos temporales por sexo, edad y clasificación profesional',
      name_pt: 'Média anual de contratos temporários por sexo, idade e classificação profissional',
      sources: ['RRHH']
    },
    {
      id: '7',
      name_dcycle: 'Número de despidos por sexo, edad y clasificación personal',
      name_en: 'Number of layoffs by gender, age, and personal classification',
      name_es: 'Número de despidos por sexo, edad y clasificación personal',
      name_pt: 'Número de despedimentos por sexo, idade e classificação pessoal',
      sources: ['RRHH']
    },
    {
      id: '8',
      name_dcycle:
        'Remuneraciones medias y su evolución desagregados por sexo, edad y clasificación profesional',
      name_en:
        'Average salaries and their evolution by gender, age, and professional classification',
      name_es:
        'Remuneraciones medias y su evolución desagregados por sexo, edad y clasificación profesional',
      name_pt: 'Salários médios e sua evolução por sexo, idade e classificação profissional',
      sources: ['RRHH']
    },
    {
      id: '9',
      name_dcycle: 'Brecha salarial',
      name_en: 'Salary gap',
      name_es: 'Brecha salarial',
      name_pt: 'Diferença salarial',
      sources: ['RRHH']
    },
    {
      id: '10',
      name_dcycle: 'Remuneración de puestos de trabajo iguales o de media de la sociedad',
      name_en: 'Remuneration for equal jobs or the average in society',
      name_es: 'Remuneración de puestos de trabajo iguales o de media de la sociedad',
      name_pt: 'Remuneração de postos de trabalho iguais ou média da sociedade',
      sources: ['RRHH']
    },
    {
      id: '11',
      name_dcycle:
        'Remuneración media de los consejeros y directivos (incluyendo retribución variable, dieta, indemnizaciones, el pago a los sistemas de previsión de ahorro a largo plazo y cualquier otra percepción) desagregada por sexo',
      name_en:
        'Average remuneration of directors and executives (including variable compensation, allowances, indemnities, payments to long-term savings systems, and other benefits) disaggregated by gender',
      name_es:
        'Remuneración media de los consejeros y directivos (incluyendo retribución variable, dieta, indemnizaciones, el pago a los sistemas de previsión de ahorro a largo plazo y cualquier otra percepción) desagregada por sexo',
      name_pt:
        'Remuneração média dos conselheiros e executivos (incluindo compensação variável, dietas, indenizações, pagamento a sistemas de previdência de longo prazo e outros benefícios) desagregada por sexo',
      sources: ['RRHH']
    },
    {
      id: '12',
      name_dcycle: 'Implantación de políticas de desconexión laboral',
      name_en: 'Implementation of work disconnection policies',
      name_es: 'Implantación de políticas de desconexión laboral',
      name_pt: 'Implementação de políticas de desconexão laboral',
      sources: ['RRHH']
    },
    {
      id: '13',
      name_dcycle: 'Empleados con discapacidad',
      name_en: 'Employees with disabilities',
      name_es: 'Empleados con discapacidad',
      name_pt: 'Funcionários com deficiência',
      sources: ['RRHH']
    },
    {
      id: '14',
      name_dcycle: 'Número de horas de absentismo',
      name_en: 'Number of absentee hours',
      name_es: 'Número de horas de absentismo',
      name_pt: 'Número de horas de absentismo',
      sources: ['RRHH']
    },
    {
      id: '15',
      name_dcycle:
        'Medidas destinadas a facilitar el disfrute de la conciliación y fomentar el ejercicio corresponsable de estos por parte de ambos progenitores',
      name_en:
        'Measures to facilitate work-life balance and encourage shared responsibility by both parents',
      name_es:
        'Medidas destinadas a facilitar el disfrute de la conciliación y fomentar el ejercicio corresponsable de estos por parte de ambos progenitores',
      name_pt:
        'Medidas para facilitar a conciliação e incentivar o exercício corresponsável pelos dois pais',
      sources: ['RRHH']
    },
    {
      id: '16',
      name_dcycle: 'Condiciones de salud y seguridad en el trabajo desagregado por sexo',
      name_en: 'Health and safety conditions at work disaggregated by gender',
      name_es: 'Condiciones de salud y seguridad en el trabajo desagregado por sexo',
      name_pt: 'Condições de saúde e segurança no trabalho desagregadas por sexo',
      sources: ['RRHH']
    },
    {
      id: '17',
      name_dcycle: 'Accidentes de trabajo, su frecuencia y gravedad desagregado por sexo',
      name_en: 'Work accidents, their frequency, and severity disaggregated by gender',
      name_es: 'Accidentes de trabajo, su frecuencia y gravedad desagregado por sexo',
      name_pt: 'Acidentes de trabalho, sua frequência e gravidade desagregados por sexo',
      sources: ['RRHH']
    },
    {
      id: '18',
      name_dcycle: 'Enfermedades profesionales desagregado por sexo',
      name_en: 'Occupational diseases disaggregated by gender',
      name_es: 'Enfermedades profesionales desagregado por sexo',
      name_pt: 'Doenças profissionais desagregadas por sexo',
      sources: ['RRHH']
    },
    {
      id: '19',
      name_dcycle:
        'Organización del diálogo social, incluyendo procedimientos para informar y consultar al personal y negociar con ellos',
      name_en:
        'Social dialogue organization, including procedures to inform, consult, and negotiate with employees',
      name_es:
        'Organización del diálogo social, incluyendo procedimientos para informar y consultar al personal y negociar con ellos',
      name_pt:
        'Organização do diálogo social, incluindo procedimentos para informar, consultar e negociar com os empregados',
      sources: ['RRHH']
    },
    {
      id: '20',
      name_dcycle: 'Porcentaje de empleados cubiertos por convenio colectivo por país',
      name_en: 'Percentage of employees covered by collective agreements by country',
      name_es: 'Porcentaje de empleados cubiertos por convenio colectivo por país',
      name_pt: 'Percentagem de funcionários cobertos por convenções coletivas por país',
      sources: ['RRHH']
    },
    {
      id: '21',
      name_dcycle:
        'Balance de los convenios colectivos, particularmente en el campo de la salud y la seguridad en el trabajo',
      name_en: 'Balance of collective agreements, particularly in health and safety at work',
      name_es:
        'Balance de los convenios colectivos, particularmente en el campo de la salud y la seguridad en el trabajo',
      name_pt:
        'Balanço dos acordos coletivos, particularmente na área de saúde e segurança no trabalho',
      sources: ['RRHH']
    },
    {
      id: '22',
      name_dcycle: 'Políticas implementadas en el campo de la formación',
      name_en: 'Policies implemented in the field of training',
      name_es: 'Políticas implementadas en el campo de la formación',
      name_pt: 'Políticas implementadas na área de formação',
      sources: ['RRHH']
    },
    {
      id: '23',
      name_dcycle: 'Cantidad total de horas de formación por categorías profesionales',
      name_en: 'Total number of training hours by professional categories',
      name_es: 'Cantidad total de horas de formación por categorías profesionales',
      name_pt: 'Número total de horas de formação por categorias profissionais',
      sources: ['RRHH']
    },
    {
      id: '24',
      name_dcycle: 'Accesibilidad universal de las personas con discapacidad',
      name_en: 'Universal accessibility for people with disabilities',
      name_es: 'Accesibilidad universal de las personas con discapacidad',
      name_pt: 'Acessibilidade universal para pessoas com deficiência',
      sources: ['RRHH']
    },
    {
      id: '25',
      name_dcycle:
        'Medidas adoptadas para promover la igualdad de trato y de oportunidades entre mujeres y hombres',
      name_en:
        'Measures taken to promote equality of treatment and opportunities between women and men',
      name_es:
        'Medidas adoptadas para promover la igualdad de trato y de oportunidades entre mujeres y hombres',
      name_pt:
        'Medidas tomadas para promover a igualdade de tratamento e oportunidades entre mulheres e homens',
      sources: ['RRHH']
    },
    {
      id: '26',
      name_dcycle: 'Planes de igualdad',
      name_en: 'Equality plans',
      name_es: 'Planes de igualdad',
      name_pt: 'Planos de igualdade',
      sources: ['RRHH']
    },
    {
      id: '27',
      name_dcycle:
        'Medidas adoptadas para promover el empleo, protocolos contra el acoso sexual y por razón de sexo',
      name_en:
        'Measures adopted to promote employment, protocols against sexual harassment and gender-based violence',
      name_es:
        'Medidas adoptadas para promover el empleo, protocolos contra el acoso sexual y por razón de sexo',
      name_pt:
        'Medidas adotadas para promover o emprego, protocolos contra o assédio sexual e por razão de sexo',
      sources: ['RRHH']
    },
    {
      id: '28',
      name_dcycle: 'Protocolos contra el acoso sexual y por razón de sexo',
      name_en: 'Protocols against sexual harassment and gender-based violence',
      name_es: 'Protocolos contra el acoso sexual y por razón de sexo',
      name_pt: 'Protocolos contra assédio sexual e por razão de sexo',
      sources: ['RRHH']
    },
    {
      id: '29',
      name_dcycle: 'Integración y la accesibilidad universal de las personas con discapacidad',
      name_en: 'Integration and universal accessibility for people with disabilities',
      name_es: 'Integración y la accesibilidad universal de las personas con discapacidad',
      name_pt: 'Integração e acessibilidade universal para pessoas com deficiência',
      sources: ['RRHH']
    },
    {
      id: '30',
      name_dcycle:
        'Política contra todo tipo de discriminación y, en su caso, de gestión de la diversidad',
      name_en:
        'Policy against all forms of discrimination and, if applicable, diversity management',
      name_es:
        'Política contra todo tipo de discriminación y, en su caso, de gestión de la diversidad',
      name_pt:
        'Política contra qualquer forma de discriminação e, se aplicável, gestão da diversidade',
      sources: ['RRHH']
    }
  ],
  environmental: [
    {
      id: '31',
      name_dcycle: 'Consumo de agua',
      name_en: 'Water consumption',
      name_es: 'Consumo de agua',
      name_pt: 'Consumo de água',
      sources: ['Sostenibilidad']
    },
    {
      id: '32',
      name_dcycle: 'Generación total de residuos',
      name_en: 'Total waste generation',
      name_es: 'Generación total de residuos',
      name_pt: 'Geração total de resíduos',
      sources: ['Sostenibilidad']
    },
    {
      id: '33',
      name_dcycle:
        'Número total y distribución de modalidades de contrato de trabajo sexo, edad y clasificación profesional',
      name_en:
        'Total number and distribution of employment contract types by gender, age, and professional classification',
      name_es:
        'Número total y distribución de modalidades de contrato de trabajo sexo, edad y clasificación profesional',
      name_pt:
        'Número total e distribuição dos tipos de contrato de trabalho por sexo, idade e classificação profissional',
      sources: ['Sostenibilidad']
    },
    {
      id: '34',
      name_dcycle: 'Consumo electricidad',
      name_en: 'Electricity consumption',
      name_es: 'Consumo electricidad',
      name_pt: 'Consumo de eletricidade',
      sources: ['Sostenibilidad']
    },
    {
      id: '35',
      name_dcycle: 'Consumo energía renovable',
      name_en: 'Renewable energy consumption',
      name_es: 'Consumo energía renovable',
      name_pt: 'Consumo de energia renovável',
      sources: ['Sostenibilidad']
    },
    {
      id: '36',
      name_dcycle: 'Consumo electricidad de origen renovable',
      name_en: 'Renewable electricity consumption',
      name_es: 'Consumo electricidad de origen renovable',
      name_pt: 'Consumo de eletricidade de origem renovável',
      sources: ['Sostenibilidad']
    },
    {
      id: '37',
      name_dcycle: 'Consumo de combustible',
      name_en: 'Fuel consumption',
      name_es: 'Consumo de combustible',
      name_pt: 'Consumo de combustível',
      sources: ['Sostenibilidad']
    },
    {
      id: '38',
      name_dcycle:
        'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en el medio ambiente, la salud y seguridad',
      name_en:
        "Detailed information about the current and foreseeable effects of the company's activities on the environment, health, and safety",
      name_es:
        'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en el medio ambiente, la salud y seguridad',
      name_pt:
        'Informações detalhadas sobre os efeitos atuais e previsíveis das atividades da empresa no meio ambiente, saúde e segurança',
      sources: ['Sostenibilidad']
    },
    {
      id: '39',
      name_dcycle:
        'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en la salud y seguridad',
      name_en:
        "Detailed information about the current and foreseeable effects of the company's activities on health and safety",
      name_es:
        'Información detallada sobre los efectos actuales y previsibles de las actividades de la empresa en la salud y seguridad',
      name_pt:
        'Informações detalhadas sobre os efeitos atuais e previsíveis das atividades da empresa na saúde e segurança',
      sources: ['Sostenibilidad']
    },
    {
      id: '40',
      name_dcycle: 'Los procedimientos de evaluación o certificación ambiental',
      name_en: 'Environmental evaluation or certification procedures',
      name_es: 'Los procedimientos de evaluación o certificación ambiental',
      name_pt: 'Procedimentos de avaliação ou certificação ambiental',
      sources: ['Sostenibilidad']
    },
    {
      id: '41',
      name_dcycle: 'Los recursos dedicados a la prevención de riesgos ambientales',
      name_en: 'Resources dedicated to the prevention of environmental risks',
      name_es: 'Los recursos dedicados a la prevención de riesgos ambientales',
      name_pt: 'Recursos dedicados à prevenção de riscos ambientais',
      sources: ['Sostenibilidad']
    },
    {
      id: '42',
      name_dcycle: 'La aplicación del principio de precaución',
      name_en: 'Application of the precautionary principle',
      name_es: 'La aplicación del principio de precaución',
      name_pt: 'Aplicação do princípio da precaução',
      sources: ['Sostenibilidad']
    },
    {
      id: '43',
      name_dcycle: 'La cantidad de provisiones y garantías para riesgos ambientales',
      name_en: 'Amount of provisions and guarantees for environmental risks',
      name_es: 'La cantidad de provisiones y garantías para riesgos ambientales',
      name_pt: 'Quantidade de provisões e garantias para riscos ambientais',
      sources: ['Sostenibilidad']
    },
    {
      id: '44',
      name_dcycle:
        'Medidas para prevenir, reducir, o reparar las emisiones de carbono que afectan gravemente el medio ambiente: teniendo en cuenta cualquier forma de contaminación atmosférica especifica de una actividad, incluyendo ruido y contaminación lumínica',
      name_en:
        'Measures to prevent, reduce, or repair carbon emissions that severely affect the environment, considering any specific atmospheric pollution from an activity, including noise and light pollution',
      name_es:
        'Medidas para prevenir, reducir, o reparar las emisiones de carbono que afectan gravemente el medio ambiente: teniendo en cuenta cualquier forma de contaminación atmosférica especifica de una actividad, incluyendo ruido y contaminación lumínica',
      name_pt:
        'Medidas para prevenir, reduzir ou reparar as emissões de carbono que afetam gravemente o meio ambiente: considerando qualquer forma de poluição atmosférica específica de uma atividade, incluindo poluição sonora e luminosa',
      sources: ['Sostenibilidad']
    },
    {
      id: '45',
      name_dcycle:
        'Medidas de prevención, reciclaje, reutilización, otras formas de recuperación y eliminación de desechos',
      name_en:
        'Measures for prevention, recycling, reuse, other forms of recovery, and disposal of waste',
      name_es:
        'Medidas de prevención, reciclaje, reutilización, otras formas de recuperación y eliminación de desechos',
      name_pt:
        'Medidas de prevenção, reciclagem, reutilização, outras formas de recuperação e eliminação de resíduos',
      sources: ['Sostenibilidad']
    },
    {
      id: '46',
      name_dcycle: 'Acciones para combatir el desperdicio de alimentos',
      name_en: 'Actions to combat food waste',
      name_es: 'Acciones para combatir el desperdicio de alimentos',
      name_pt: 'Ações para combater o desperdício de alimentos',
      sources: ['Sostenibilidad']
    },
    {
      id: '47',
      name_dcycle:
        'Los elementos importantes de las emisiones de gases de efecto invernadero generados como resultado de las actividades de la empresa, incluyendo el uso de los bienes y servicios producidos',
      name_en:
        "Key elements of greenhouse gas emissions generated as a result of the company's activities, including the use of produced goods and services",
      name_es:
        'Los elementos importantes de las emisiones de gases de efecto invernadero generados como resultado de las actividades de la empresa, incluyendo el uso de los bienes y servicios producidos',
      name_pt:
        'Elementos chave das emissões de gases de efeito estufa geradas como resultado das atividades da empresa, incluindo o uso dos bens e serviços produzidos',
      sources: ['Sostenibilidad']
    },
    {
      id: '48',
      name_dcycle: 'Medidas adoptadas para adaptarse a las consecuencias del cambio climático',
      name_en: 'Measures adopted to adapt to the consequences of climate change',
      name_es: 'Medidas adoptadas para adaptarse a las consecuencias del cambio climático',
      name_pt: 'Medidas adotadas para se adaptar às consequências das mudanças climáticas',
      sources: ['Sostenibilidad']
    },
    {
      id: '49',
      name_dcycle:
        'Las metas de reducción establecidas voluntariamente a medio y largo plazo para reducir la emisiones de gases de efecto invernadero y los medios implementados para tal fin',
      name_en:
        'Voluntarily set medium- and long-term reduction targets to reduce greenhouse gas emissions and the means implemented for this purpose',
      name_es:
        'Las metas de reducción establecidas voluntariamente a medio y largo plazo para reducir la emisiones de gases de efecto invernadero y los medios implementados para tal fin',
      name_pt:
        'Metas de redução estabelecidas voluntariamente a médio e longo prazo para reduzir as emissões de gases de efeito estufa e os meios implementados para tal fim',
      sources: ['Sostenibilidad']
    },
    {
      id: '50',
      name_dcycle: 'Huella de carbono total, alcance 1, 2 y 3 por empresa',
      name_en: 'Total carbon footprint, scope 1, 2, and 3 by company',
      name_es: 'Huella de carbono total, alcance 1, 2 y 3 por empresa',
      name_pt: 'Pegada de carbono total, alcance 1, 2 e 3 por empresa',
      sources: ['Sostenibilidad']
    },
    {
      id: '51',
      name_dcycle: 'Huella de carbono por instalación',
      name_en: 'Carbon footprint by facility',
      name_es: 'Huella de carbono por instalación',
      name_pt: 'Pegada de carbono por instalação',
      sources: ['Sostenibilidad']
    }
  ],
  governance: [
    {
      id: '52',
      name_dcycle: 'Medidas adoptadas para prevenir la corrupción y el soborno',
      name_en: 'Measures adopted to prevent corruption and bribery',
      name_es: 'Medidas adoptadas para prevenir la corrupción y el soborno',
      name_pt: 'Medidas adotadas para prevenir a corrupção e o suborno',
      sources: ['Legal']
    }
  ]
};
