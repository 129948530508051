import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { mockIROsService, IRO } from '../services/mockIROsService';

// React Query hooks
export const useIROsQuery = () => {
  return useQuery({
    queryKey: ['iros'],
    queryFn: mockIROsService.getAllIROs,
    staleTime: 5 * 60 * 1000
  });
};

// Use a selector to get a single IRO from the collection
export const useIROByIdQuery = (id: string | null) => {
  return useQuery({
    queryKey: ['iros'],
    queryFn: mockIROsService.getAllIROs,
    select: (data) => (id ? data.find((iro) => iro.id === id) : undefined),
    enabled: Boolean(id),
    staleTime: 5 * 60 * 1000
  });
};

export const useCreateIROMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mockIROsService.createIRO,
    onSuccess: (newIRO) => {
      // Update the cache with the new IRO
      queryClient.setQueryData(['iros'], (oldData: IRO[] | undefined) => {
        return oldData ? [...oldData, newIRO] : [newIRO];
      });
    }
  });
};

export const useUpdateIROMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mockIROsService.updateIRO,
    onSuccess: (updatedIRO) => {
      // Update the cache with the updated IRO
      queryClient.setQueryData(['iros'], (oldData: IRO[] | undefined) => {
        if (!oldData) return [updatedIRO];
        return oldData.map((iro) => (iro.id === updatedIRO.id ? updatedIRO : iro));
      });
    }
  });
};

export const useDeleteIROMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mockIROsService.deleteIRO,
    onSuccess: (_, id) => {
      // Remove the deleted IRO from the cache
      queryClient.setQueryData(['iros'], (oldData: IRO[] | undefined) => {
        if (!oldData) return [];
        return oldData.filter((iro) => iro.id !== id);
      });
    }
  });
};
