import { ReactNode, useEffect } from 'react';
import ShadowBackground from '../shadowBackground/ShadowBackground';
import './styles.scss';
import Icon from '../icon/Icon';
import { ICON_SIZE_MAP } from '../icon/utils/constants';
import useOutsideDoubleClick from '../../../customHooks/useOutsideDoubleClick';
import { createPortal } from 'react-dom';
import FormHeader from '../formComponents/formHeader/FormHeader';

type Props = {
  show: boolean;
  children: ReactNode;
  onClose: () => void;
  width?: string;
  maxWidth?: string;
  styles?: React.CSSProperties;
  closeOnOutsideClick?: boolean;
  closeButton?: boolean;
};

type ModalPortalProps = {
  children: ReactNode;
};

const ModalPortal: React.FC<ModalPortalProps> = ({ children }) => {
  return createPortal(children, document.body);
};

type ModalWrapperProps = {
  onClose: () => void;
  show: boolean;
  width: string;
  maxWidth?: string;
  styles?: React.CSSProperties;
  inputSelector: string;
  children?: ReactNode;
  closeOnOutsideClick?: boolean;
  closeButton?: boolean;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  onClose,
  show,
  width,
  maxWidth,
  styles,
  inputSelector,
  children,
  closeOnOutsideClick,
  closeButton = true
}) => {
  const wrapperRef = useOutsideDoubleClick(onClose, closeOnOutsideClick);

  useEffect(() => {
    // Select first input element in modal if it exists and focus it
    const input: HTMLInputElement | null = document.querySelector(inputSelector);
    if (input) input.focus();
  }, [show]);

  return (
    /* <Draggable bounds='parent'> */
    <div
      className='modal main-bg-color card-border-color modal-box-shadow'
      style={{ minWidth: width, maxWidth, ...styles }}
      ref={wrapperRef}>
      {closeButton && (
        <Icon
          icon='close'
          color='gray'
          className='close'
          onClick={onClose}
          size={ICON_SIZE_MAP.LARGE}
        />
      )}
      {children}
    </div>
    /* </Draggable> */
  );
};

function Modal({
  show,
  children,
  onClose,
  width = '428px',
  maxWidth,
  styles,
  closeButton = true
}: Props) {
  if (!show) return null;

  return (
    <>
      <ShadowBackground>
        <ModalWrapper
          onClose={onClose}
          width={width}
          maxWidth={maxWidth}
          styles={styles}
          show={show}
          closeButton={closeButton}
          inputSelector='.modal-wrapper .modal input'>
          {children}
        </ModalWrapper>
      </ShadowBackground>
    </>
  );
}

const ModalWithPortal = ({
  show,
  children,
  onClose,
  width = '428px',
  maxWidth,
  styles,
  closeOnOutsideClick,
  closeButton = true
}: Props) => {
  if (!show) return null;

  const newStyles: React.CSSProperties = {
    ...styles,
    maxHeight: 'none'
  };

  return (
    <ModalPortal>
      <ShadowBackground.WithPortal>
        <ModalWrapper
          onClose={onClose}
          width={width}
          maxWidth={maxWidth}
          show={show}
          styles={newStyles}
          closeOnOutsideClick={closeOnOutsideClick}
          inputSelector='.modal-wrapper-with-portal .modal input'
          closeButton={closeButton}>
          {children}
        </ModalWrapper>
      </ShadowBackground.WithPortal>
    </ModalPortal>
  );
};

const ModalContent: React.FC<{ style?: React.CSSProperties }> = ({ children, style }) => {
  return (
    <div className='modal-content' id='modal-content' style={style}>
      {children}
    </div>
  );
};

const ModalButtons: React.FC = ({ children }) => {
  return <div className='modal-buttons'>{children}</div>;
};
Modal.Portal = ModalPortal;
Modal.WithPortal = ModalWithPortal;
Modal.Content = ModalContent;
Modal.Header = FormHeader;
Modal.Buttons = ModalButtons;

export default Modal;
