import {
  ColumnAPI,
  HookedRecordValue,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult,
  Row
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../../customHooks/useErrors';
import useNuvoButton from '../../../../../../customHooks/useNuvoButton';
import useNuvoFileName from '../../../../../../customHooks/useNuvoFileName';
import { uploadFilePresignedUrl } from '../../../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../../../services/api/nuvoAnalytics';
import { getPresignedUrlVehiclesConsumptions } from '../../../../../../services/api/vehicleConsumptions';
import { basicNuvoStyle } from '../../../../../../styles/nuvo';
import { convertStringToDateBackend } from '../../../../../../utils/convertDates';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../../../utils/nuvo';
import NuvoImporter from '../../../../../ui/nuvoImporter/NuvoImporter';
import useGetLicensePlates from '../../hooks/useGetLicensePlates';

type FuelUnitIdKeys = 'kilogram' | 'liter' | 'kilometer';

const FuelUnitIds: Record<FuelUnitIdKeys, string> = {
  kilogram: '61743a63-ff70-459c-9567-5eee8f7dfd5c',
  liter: '54a709bf-79fe-4acb-ab85-5e1d7cd26eb4',
  kilometer: 'accd2a4d-ff0c-4e7c-8e17-a187b01e49b4'
};

const InputNuvoConsumptions = () => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();
  const lookAndFeel = 'secondary';

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({ lookAndFeel });
  useNuvoButton({ onClick: handleGetFileName, lookAndFeel });

  const { allowedFuels, licensePlates, loading } = useGetLicensePlates();

  const category = 'vehicles_consumptions';

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'description(optional)',
      label: t('templates.vehicles_consumptions.description'),
      columnType: 'string'
    },
    {
      key: 'vehicle_license_plate',
      label: t('templates.vehicles_consumptions.vehicle_license_plate'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'start_date_recharge',
      label: t('templates.vehicles_consumptions.start_date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'end_date_recharge',
      label: t('templates.vehicles_consumptions.end_date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'quantity',
      label: t('templates.vehicles_consumptions.quantity'),
      columnType: 'float',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'unit_measure',
      label: t('templates.vehicles_consumptions.unit_measure'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.vehicles_consumptions.units.liter'),
          value: 'liter',
          type: 'string'
        },
        {
          label: t('templates.vehicles_consumptions.units.kilometer'),
          value: 'kilometer',
          type: 'string'
        },
        {
          label: t('templates.vehicles_consumptions.units.kilogram'),
          value: 'kilogram',
          type: 'string'
        }
      ],
      isMultiSelect: false,
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  const validateRow: OnEntryInit = (row: Row) => {
    const errors = {} as any;

    const quantity = row.quantity;

    if (typeof quantity === 'number' && quantity <= 0) {
      errors.quantity = {
        value: quantity,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.VALUE_MUST_BE_POSITIVE
          }
        ]
      };
    }

    if (convertStringToDateBackend(row.start_date_recharge as string) > new Date()) {
      errors.start_date_recharge = {
        value: row.start_date_recharge,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.END_DATE_BEFORE_START_DATE
          }
        ]
      };
    }

    if (
      convertStringToDateBackend(row.end_date_recharge as string).getFullYear() >
      new Date().getFullYear()
    ) {
      errors.end_date_recharge = {
        value: row.end_date_recharge,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.END_DATE_AFTER_CURRENT_YEAR
          }
        ]
      };
    }
    if (
      convertStringToDateBackend(row.start_date_recharge as string) >
      convertStringToDateBackend(row.end_date_recharge as string)
    )
      errors.end_date_recharge = {
        value: row.end_date_recharge,
        info: [
          {
            message: ERRORS.NUVO.END_DATE_BEFORE_START_DATE,
            level: 'error'
          }
        ]
      };

    const minYearErrors = validateDateFields(['start_date_recharge', 'end_date_recharge'])(row);

    for (const key in minYearErrors) {
      errors[key] = minYearErrors[key];
    }

    if (row.vehicle_license_plate && !licensePlates.includes(row.vehicle_license_plate as string)) {
      errors.vehicle_license_plate = {
        value: row.vehicle_license_plate,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.VEHICLE_LICENSE_PLATE_NOT_FOUND
          }
        ]
      };
    }

    if (row.unit_measure && row.vehicle_license_plate) {
      const allowedUnits = allowedFuels[row.vehicle_license_plate as string]?.fuelIds;
      if (allowedUnits && !allowedUnits.includes(FuelUnitIds[row.unit_measure as FuelUnitIdKeys])) {
        errors.unit_measure = {
          value: row.unit_measure,
          info: [
            {
              level: 'error',
              message: ERRORS.NUVO.FUEL_TYPE_NOT_ALLOWED
            }
          ]
        };
      }
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => {
        return Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0;
      })
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  const onEntryInit: OnEntryInit = (row, index) => {
    return validateRow(row, index);
  };

  if (loading) return null;

  return (
    <NuvoImporter
      lookAndFeel='primary'
      btnI18nKey='vehicles.consumptions'
      settings={{
        multipleFileUpload: true,
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        enableExamples: true,
        identifier: 'vehicle_consumptions_travels_template_Dcycle', // Template file name
        columns
      }}
      onCancel={handleExit}
      onEntryChange={onEntryChange}
      onEntryInit={onEntryInit}
      columnHooks={{
        quantity: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 0
            },
            index
          ])
      }}
      onResults={async (results, errors, complete) => {
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presinged url
        const data = await getPresignedUrlVehiclesConsumptions({
          file_name: finalFileName
        });

        // error uploading file
        if (data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: category
        });

        complete();
      }}
    />
  );
};

export default InputNuvoConsumptions;
