import GHGLogo from 'assets/logos/ghg.png';
import CSRDLogo from 'assets/logos/csrd.png';
import EINFLogo from 'assets/logos/einf.png';
import MITECOLogo from 'assets/logos/miteco.png';
import ISO14064Logo from 'assets/logos/iso14064.png';
import SBTiLogo from 'assets/logos/sbti.png';
import ISO14001Logo from 'assets/logos/iso14001.png';
import CDPLogo from 'assets/logos/cdp.png';
import ECOVADISLogo from 'assets/logos/ecovadis.png';
import GHGLogoBlocked from 'assets/logos/ghgBlocked.png';
import CSRDLogoBlocked from 'assets/logos/csrdBlocked.png';
import MITECOLogoBlocked from 'assets/logos/mitecoBlocked.png';
import ISO14064LogoBlocked from 'assets/logos/iso14064Blocked.png';
import SBTiLogoBlocked from 'assets/logos/sbtiBlocked.png';
import ISO14001LogoBlocked from 'assets/logos/iso14001Blocked.png';
import CDPLogoBlocked from 'assets/logos/cdpBlocked.png';
import ECOVADISLogoBlocked from 'assets/logos/ecovadisBlocked.png';
import ISO50001Logo from 'assets/logos/iso50001.png';
import ISO50001LogoBlocked from 'assets/logos/iso50001Blocked.png';
import ISO45001Logo from 'assets/logos/iso45001.png';
import ISO45001LogoBlocked from 'assets/logos/iso45001Blocked.png';
import CustomKPIsLogo from 'assets/logos/customKPIs.png';
const logoLogicReturner = (frameworkName: string, blocked: boolean) => {
  switch (frameworkName) {
    case 'ghg_protocol':
      return blocked ? GHGLogoBlocked : GHGLogo;
    case 'csrd':
      return blocked ? CSRDLogoBlocked : CSRDLogo;
    case 'einf':
      return EINFLogo;
    case 'miteco':
      return blocked ? MITECOLogoBlocked : MITECOLogo;
    case 'iso_14064':
      return blocked ? ISO14064LogoBlocked : ISO14064Logo;
    case 'sbti':
      return blocked ? SBTiLogoBlocked : SBTiLogo;
    case 'iso_14001':
      return blocked ? ISO14001LogoBlocked : ISO14001Logo;
    case 'cdp':
      return blocked ? CDPLogoBlocked : CDPLogo;
    case 'ecovadis':
      return blocked ? ECOVADISLogoBlocked : ECOVADISLogo;
    case 'iso_50001':
      return blocked ? ISO50001LogoBlocked : ISO50001Logo;
    case 'iso_45001':
      return blocked ? ISO45001LogoBlocked : ISO45001Logo;
    case 'custom':
      return CustomKPIsLogo;
    default:
      return GHGLogo;
  }
};
export default logoLogicReturner;
