import { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../tooltip/Tooltip';
import './styles.scss';
import Icon from '../../icon/Icon';
import { IconName } from '../../../../types/utils/iconName';

type Props = {
  icon?: string;
  newIcon?: IconName;
  label?: string | ReactNode;
  tooltip?: string;
  placeholder: string;
  value: string;
  onChangeValue: (value: string) => void;
  error?: ErrorType;
  fontClass?: string;
  height?: string;
  size?: 'small' | 'big'; // small for smaller paddings and margins
  disabled?: boolean;
  onlyIntegers?: boolean;
};

const InputNumber = ({
  icon,
  newIcon,
  label,
  tooltip,
  placeholder,
  value,
  onChangeValue,
  error,
  fontClass = 'input-font',
  height,
  size = 'small',
  disabled = false,
  onlyIntegers = false
}: Props) => {
  const { t } = useTranslation();

  const handleOnChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    let result = value;
    // if only integers is true do not let the user to type a dot
    if (onlyIntegers && e.target.value.includes('.')) {
      return;
    }

    if (/^\d+(\.\d{0,2})?$/.test(e.target.value) || e.target.value === '') {
      result = e.target.value;
    }

    onChangeValue(result);
  };

  if (!height && size === 'small') {
    height = '33px';
  }
  if (!height && size === 'big') {
    height = '48.5px';
  }

  return (
    <div className='input'>
      {label && (
        <label className='input-label-font on-light-text-color label label-wrapper'>
          {label}
          {tooltip && <Tooltip position='top' size='small' text={tooltip} />}
        </label>
      )}
      <div
        className={`input__number flex ${fontClass} ${size} ${
          error ? 'error-border-color' : 'input-border-color'
        } ${disabled ? 'input-disabled-styles' : ''}`}
        style={{ height }}>
        {icon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
            <img src={icon} alt='input-icon' width={16} />
          </div>
        )}
        {newIcon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
            <Icon icon={newIcon} size='medium' color='gradient' />
          </div>
        )}
        <input
          type='text'
          className={`input__number__content on-light-text-color ${fontClass} ${
            error ? 'error-border-color' : 'input-border-color'
          } ${disabled ? 'transparent-bg-color' : ''}`}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChangeValue}
        />
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputNumber;
