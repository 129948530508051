import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { LANGUAGES } from '../../../../../constants';
import { UserContext } from '../../../../../context/userContext';
import {
  resendPendingEmployeesForm,
  resendToAllEmployeesForm,
  resendToEmployeesForm
} from '../../../../../services/api/employees';

import moment from 'moment';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { convertDateFrontToBack } from '../../../../../utils/convertDates';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';

type Props = {
  onConfirm: () => void;
  sendToPending?: boolean;
  title: string;
  sendToAll?: boolean;
  emailsToSend?: string[];
  exceptEmails?: string[];
  filters?: IFilter[];
};

type FormDataEmail = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};
const ConfirmResend = ({
  onConfirm,
  title,
  sendToPending,
  sendToAll,
  emailsToSend,
  exceptEmails,
  filters
}: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<SelectOptionFormat>({
    id: i18n.resolvedLanguage,
    name: t(`languages.${i18n.resolvedLanguage}`)
  });
  const [error, setError] = useState<ErrorType>();
  const [formData, setFormData] = useState<FormDataEmail>({
    startDate: '',
    endDate: '',
    errors: []
  });
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const resendFormsToPending = async () => {
    if (handleErrors()) return;
    try {
      setLoading(true);
      const response = await resendPendingEmployeesForm(
        user?.selectedOrganization ?? '',
        language.id,
        convertDateFrontToBack(formData.startDate),
        convertDateFrontToBack(formData.endDate)
      );
      if (response === 'MAX_EMAILS_EXCEEDED' && !sendToPending) {
        setError({
          error: 'maxEmailsExceeded',
          description: t('error.maxEmailsExceeded')
        });
      }
      onConfirm();
      dispatch(setNotification(t('notification.formsResent')));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resendToSelected = async () => {
    if (handleErrors()) return;
    try {
      if (!sendToAll && (!emailsToSend || emailsToSend?.length === 0)) {
        dispatch(setNotification(t('employees.formsResentErrorNoEmail')));
        return;
      }
      setLoading(true);
      let response: any;
      if (sendToAll && exceptEmails) {
        response = await resendToAllEmployeesForm(
          user?.selectedOrganization ?? '',
          language.id,
          exceptEmails,
          convertDateFrontToBack(formData.startDate),
          convertDateFrontToBack(formData.endDate),
          filters
        );
      } else if (emailsToSend) {
        response = await resendToEmployeesForm(
          user?.selectedOrganization ?? '',
          language.id,
          emailsToSend,
          convertDateFrontToBack(formData.startDate),
          convertDateFrontToBack(formData.endDate)
        );
      }

      if (response.data === 'MAX_EMAILS_EXCEEDED') {
        dispatch(setNotification(t('employees.formsResentErrorTooManyEmails')));
        setLoading(false);
        return;
      }

      onConfirm();
      dispatch(setNotification(t('notification.formsResent')));
      setLoading(false);
    } catch (error) {
      dispatch(setNotification(t('notification.formsResentError')));
    }
  };

  const onChangeLanguage = (value: SelectOptionFormat) => {
    setLanguage(value);
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  return (
    <div className='confirm-resend'>
      <FormHeader title={title} description={t('employees.confirmResendDescription')} />
      <FormWrapper>
        <FormSelect
          label={t('sendEmailForm.language')}
          options={LANGUAGES.map((language) => ({
            id: language,
            name: t(`languages.${language}`)
          }))}
          placeholder={t('sendEmailForm.language')}
          value={language}
          onChange={onChangeLanguage}
          height='32px'
        />
      </FormWrapper>
      <FormWrapper>
        <FormCalendarDouble
          label={t('sendEmailForm.measurement_period')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          error={formData.errors.find(
            (error) =>
              error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
          )}
          handleEndDateError={handleDateError('endDate')}
          handleStartDateError={handleDateError('startDate')}
          maxEndDate={`31/12/${currentYear}`}
          minStartDate='01/01/1960'
          minEndDate='01/01/1960'
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.resend')}
          onClick={sendToPending ? resendFormsToPending : resendToSelected}
          loading={loading}
          style={{ marginTop: '2em' }}
        />
      </FormButtonSection>
      {error && <p className='error-text-color body1-bold-font'>{error.description}</p>}
    </div>
  );
};

export default ConfirmResend;
