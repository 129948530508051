import type { ExcelDataCategory } from '../../../../constants/excelData';
import apiFetch from '../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';

const URL = '/organizations/excel-data';

type Params = {
  category: ExcelDataCategory;
  year: string;
  country?: string;
};

export const getExcelData = async ({ category, year, country }: Params) => {
  const queryParams: Record<string, string> = {
    category,
    year
  };

  if (country) {
    queryParams.country = country;
  }
  const response = await apiFetch(
    'GET',
    getUrl(URL, { queryParams: generateQueryParamsFromObject(queryParams) })
  );

  return response.data;
};
