import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import Icon from 'components/ui/icon/Icon';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import { useIROsQuery } from '../../queries/useIROsQuery';
import { DMIROsEditModal } from './DMIROsEditModal';
import { DMIROsDeleteModal } from './DMIROsDeleteModal';
import { IRO } from '../../services/mockIROsService';
import Label from 'components/ui/label/Label';

export const DMIROsTable = () => {
  const [searchValue, setSearchValue] = useState('');

  const [iroToDelete, setIroToDelete] = useState('');
  const [iroToEdit, setIroToEdit] = useState('');

  const { data: iros = [], isLoading, refetch: fetchIROs } = useIROsQuery();

  const { t: tG } = useTranslation();
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros'
  });

  const searchFilteredIROs = useMemo(
    () =>
      iros.filter(
        (iro: IRO) =>
          iro.description.toLowerCase().includes(searchValue.toLowerCase()) ||
          iro.source.toLowerCase().includes(searchValue.toLowerCase()) ||
          iro.topic.toLowerCase().includes(searchValue.toLowerCase()) ||
          iro.subtopic.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [iros, searchValue]
  );

  const columns: Column<IRO>[] = [
    {
      title: t('table.subtopic'),
      dataIndex: 'subtopic',
      key: 'subtopic',
      width: '23%',
      render: (_, row) => row.subtopic
    },
    {
      title: t('table.type'),
      dataIndex: 'type',
      key: 'type',
      width: '16%',
      render: (_, row) => {
        const highlightedTypes: IRO['type'][] = ['risk', 'positive_impact'];
        const isHighlighted = highlightedTypes.includes(row.type);

        return (
          <Label lookAndFeel={isHighlighted ? 'primary' : 'secondary'} size='large'>
            {t(`types.${row.type}`)}
          </Label>
        );
      }
    },
    {
      title: t('table.description'),
      dataIndex: 'description',
      key: 'description',
      width: '48%'
    },
    {
      title: t('table.score'),
      dataIndex: 'importanceScore',
      key: 'importanceScore',
      width: '11%',
      numberFormatter: false,
      render: (_, row) => {
        return (
          <Label
            lookAndFeel={row?.importanceScore && +row.importanceScore > 70 ? 'error' : 'success'}
            size='medium'
            className='font-12'>
            {row.importanceScore}
          </Label>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      width: '2%',
      render: (_, row) => (
        <div className='flex gap-2'>
          <ButtonDropdown
            button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
            options={[
              {
                id: `edit`,
                name: t('table.edit'),
                onClick: () => setIroToEdit(row.id)
              },
              {
                id: `delete`,
                name: t('table.delete'),
                onClick: () => setIroToDelete(row.id)
              }
            ]}
          />
        </div>
      )
    }
  ];

  return (
    <>
      <InfiniteList
        containerStyle={{
          height: '72vh',
          marginTop: 0
        }}
        fetchData={fetchIROs}
        data={searchFilteredIROs}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={searchFilteredIROs.length}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-4'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={tG('general.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
            <TotalLegend
              total={searchFilteredIROs.length}
              loading={isLoading}
              i18key={'doubleMateriality.iros.table'}
            />
          </div>
        }
      />
      <DMIROsEditModal show={!!iroToEdit} onClose={() => setIroToEdit('')} iroId={iroToEdit} />
      <DMIROsDeleteModal
        show={!!iroToDelete}
        onClose={() => setIroToDelete('')}
        iroId={iroToDelete}
      />
    </>
  );
};
