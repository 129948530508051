import { HTMLAttributes } from 'react';
import './styles.scss';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  id: string;
  selected: boolean;
  name: React.ReactNode;
  blocked?: boolean;
  handleOnClick: (id: string) => void;
}
const ToggleElement = ({ id, selected, name, blocked, handleOnClick, ...rest }: Props) => {
  const className = `status-switch ${selected ? 'selected highlight-bg-color' : ''} ${
    blocked ? 'blocked' : ''
  }`;

  const onClick = () => {
    if (blocked) {
      return;
    }
    handleOnClick(id);
  };

  return (
    <div className={className} {...rest} onClick={onClick}>
      {blocked ? (
        <>
          <span className='flex items-center gap-x-1'>
            {name}
            <Icon icon='lock' color='gray-warm' size='small' />
          </span>
        </>
      ) : (
        <>{name}</>
      )}
    </div>
  );
};

export default ToggleElement;
