import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { countriesNuvo } from 'constants/countriesNuvo';
import useErrors from 'customHooks/useErrors';
import useNuvoButton from 'customHooks/useNuvoButton';
import useNuvoFileName from 'customHooks/useNuvoFileName';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { ColumnAPI, LanguageType, RejectSubmitResult } from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { postNuvoAnalytics } from 'services/api/nuvoAnalytics';
import { basicNuvoStyle } from 'styles/nuvo';
import { transformNuvoResultsIntoCsv } from 'utils/nuvo';
import { sessionStorageSetItem } from 'utils/sessionStorage';

export const InputNuvoClients = () => {
  const { t, i18n } = useTranslation();
  const selectedOrganization = useSelectedOrganization();

  const category = 'clients';

  const lookAndFeel = 'secondary';
  const ERRORS = useErrors();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({ lookAndFeel });
  const onClick = () => {
    handleGetFileName();
    sessionStorageSetItem('isNuvoOpen', true);
  };
  useNuvoButton({ onClick });

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'name',
      label: t('doubleMateriality.context.clients.name'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'country',
      label: t('doubleMateriality.context.clients.country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'sales',
      label: t('doubleMateriality.context.clients.sales'),
      columnType: 'float',
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  return (
    <NuvoImporter
      lookAndFeel={lookAndFeel}
      btnI18nKey='doubleMateriality.context.clients.uploadSales'
      settings={{
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'clients_template_Dcycle',
        columns
      }}
      onCancel={handleExit}
      onResults={async (results, errors, complete) => {
        if (results.length <= 0 || !selectedOrganization?.id) return complete(nuvoError);

        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // TODO: File upload with presigned url

        // analytics
        void postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category
        });

        complete();
      }}
    />
  );
};
