import useSelectedOrganization from './useSelectedOrganization';
import { PredefinedRoleIds } from 'types/entitiesEnums/roles';

const useRole = () => {
  const selectedOrganization = useSelectedOrganization();
  const role = selectedOrganization?.role;

  return {
    permissions: role?.permissions as Permission[] | undefined,
    roleName: role?.name as string | undefined,
    roleId: role?.id as string | undefined,
    isFundRole: role?.id === PredefinedRoleIds.FUND_ROLE_ID,
    isAdminRole: role?.id === PredefinedRoleIds.ADMIN_ROLE_ID
  };
};

export default useRole;
