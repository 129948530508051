import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { scope3_color } from '../../../../styles/colors';
import { BusinessTravelGHGReport } from '../../../../types/entities/businessTravels';

import { FuelTypes } from '../../../../types/entitiesEnums/employee';
import { YesNo } from '../../../../types/utilsEnums';
import {
  numberToScientificNotation,
  parseFloatLangSensitive
} from '../../../../utils/numberToDecimal';
import LineChart2 from '../../../ui/charts/LineChart2';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import List, { ListColumn } from '../common/list/List';
import useDataCo2 from '../hooks/useDataCo2';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';

type Props = {
  data: BusinessTravelGHGReport[];
  startDate: string;
  endDate: string;
};
const BusinessTravel = ({ data, startDate, endDate }: Props) => {
  const { t } = useTranslation();

  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });

  //Set list of fuel types
  const fuelTypes = data.map((elem) => {
    if (elem.fuel_type === FuelTypes.ELECTRIC && elem.renewable_energy === YesNo.YES) {
      return FuelTypes.ELECTRIC_RENEWABLE;
    }
    return elem.fuel_type ? elem.fuel_type : FuelTypes.DO_NOT_KNOW;
  });
  const uniqueFuelTypes = new Set(fuelTypes);

  //Set list of transport types
  const transportTypes = data
    .filter(
      (elem) =>
        elem.transport_type !== null &&
        elem.transport_type !== 'bicycle' &&
        elem.transport_type !== 'walking'
    )
    .map((elem) => elem.transport_type);
  const uniqueTransportTypes = new Set(transportTypes);

  // Group data by date
  const groupedByMonth = [] as GroupByMonth[];

  data.forEach((elem: BusinessTravelGHGReport) => {
    const elemDate = moment(new Date(elem.year, elem.month - 1)).format('YYYY-MM');
    const foundIndex = groupedByMonth.findIndex((elem2) => elem2.date === elemDate);

    if (foundIndex > -1) {
      groupedByMonth[foundIndex].emissions_per_month += elem.co2e;
    } else {
      groupedByMonth.push({
        date: elemDate,
        emissions_per_month: elem.co2e
      });
    }
  });

  groupedByMonth.forEach((elem) => {
    const foundIndex = dataCo2.findIndex((elem2) => elem2.x === elem.date);
    if (foundIndex > -1) {
      dataCo2[foundIndex].y += elem.emissions_per_month / 1000;
    }
  });

  const columns: ListColumn[] = [{ title: t('ghgReport.businessTravelFuel') }];
  // Push unique transport types to columns
  uniqueTransportTypes.forEach((elem) => {
    columns.push({ title: t(`businessTravels.${elem}`) });
  });

  const rows = [] as string[][];

  // Push unique fuel types to rows
  uniqueFuelTypes.forEach((elem) => {
    const row = columns.slice(0, columns.length - 1).map((elem2) => '0');
    row.unshift(t(`businessTravels.${elem}`));
    rows.push(row);
  });

  const groupedByFuelTypeAndTransportType = [] as GroupByFuelAndTransportType[]; // This will be for a list of fuel types as rows and transport types as columns

  // Group data by fuel type and transport type
  data.forEach((elem: BusinessTravelGHGReport) => {
    let elemFuelType = elem.fuel_type ? elem.fuel_type : FuelTypes.DO_NOT_KNOW;
    if (elemFuelType === FuelTypes.ELECTRIC && elem.renewable_energy === YesNo.YES) {
      elemFuelType = FuelTypes.ELECTRIC_RENEWABLE;
    }
    const foundIndex = groupedByFuelTypeAndTransportType.findIndex(
      (elem2) => elem2.fuel_type === elemFuelType && elem2.transport_type === elem.transport_type
    );

    if (foundIndex > -1) {
      groupedByFuelTypeAndTransportType[foundIndex].co2e += elem.co2e;
    } else {
      groupedByFuelTypeAndTransportType.push({
        fuel_type: elemFuelType,
        transport_type: elem.transport_type,
        co2e: elem.co2e
      });
    }
  });

  const totalRow = columns.slice(0, columns.length - 1).map((elem) => '0');
  // Push data to rows where it belongs
  groupedByFuelTypeAndTransportType.forEach((elem) => {
    const rowIndex = rows.findIndex((elem2) => elem2[0] === t(`businessTravels.${elem.fuel_type}`));
    const columnIndex = columns.findIndex(
      (elem2) => elem2.title === t(`businessTravels.${elem.transport_type}`)
    );

    if (rowIndex > -1 && columnIndex > -1) {
      rows[rowIndex][columnIndex] = numberToScientificNotation(elem.co2e / 1000);
    }
  });

  // Calculate totalRow adding values in columns
  for (let i = 1; i < columns.length; i++) {
    let sum = 0;
    for (let j = 0; j < rows.length; j++) {
      sum += parseFloatLangSensitive(rows[j][i]);
    }
    totalRow[i - 1] = numberToScientificNotation(sum);
  }

  return (
    <div className='ghg-business-travel'>
      <LineChartWrapper
        legends={[
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <LineChart2
          labels={monthLabels}
          chartData={[
            {
              label: 'data',
              borderColor: scope3_color,
              data: dataCo2.map((elem) => elem.y),
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              stepped: false,
              backgroundColor: 'rgba(41, 69, 255, 0.1)',
              fill: true,
              tension: 0
            }
          ]}
          disabled={false}
        />
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={columns.length < 6 ? '/images/icons/plane.svg' : undefined}
          columns={columns}
          rows={rows}
          totalRow={totalRow}
        />
      </div>
      <div
        style={{
          marginTop: 'auto'
        }}>
        <DescriptionBox
          descriptions={[
            t('ghgReport.businessTravelDisclaimer.description1'),
            t('ghgReport.businessTravelDisclaimer.description2'),
            t('ghgReport.businessTravelDisclaimer.description3'),
            t('ghgReport.businessTravelDisclaimer.description4'),
            t('ghgReport.businessTravelDisclaimer.description5'),
            t('ghgReport.businessTravelDisclaimer.description6')
          ]}
        />
      </div>
    </div>
  );
};

export default BusinessTravel;
