import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import RoleCard from './components/roleCard/RoleCard';
import Icon from 'components/ui/icon/Icon';
import Button from 'components/ui/button/Button';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { User } from 'types/entities/user';

type Props = {
  setRoleToEdit: (role: Role) => void;
  setShowCreateRole: (show: boolean) => void;
  setRoleToDelete: (role: Role) => void;
  roles: Role[];
  members: User[];
  isLoading: boolean;
};

const ManageRoles = ({
  setRoleToEdit,
  setShowCreateRole,
  setRoleToDelete,
  roles,
  members,
  isLoading
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles' });

  return (
    <>
      <FormHeader
        title={t('title')}
        description={
          <>
            {t('description')}
            {
              /* TODO: only show for administator user */
              <BlueWrapper className='inline-flex w-fit-content mt-6 py-1 px-2'>
                <div className='flex items-center gap-1'>
                  <Icon icon='status' color='pending' />
                  <span className={'font-12 text-secondary-purple-20 weight-400'}>
                    {t('administratorMessage')}
                  </span>
                </div>
              </BlueWrapper>
            }
          </>
        }
      />
      {isLoading ? (
        <CustomSkeletonLoader count={4} />
      ) : (
        <div
          className='flex gap-4 rounded-8 wrap'
          style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <div
            className='rounded-8 app-bg-color flex items-center justify-center p-4'
            style={{ flexBasis: 'calc(50% - 1rem)', boxSizing: 'border-box' }}>
            <Button
              lookAndFeel='primary'
              text={t('createNewRole')}
              iconNode={<Icon icon='add' color='white' />}
              size='small'
              style={{ width: 'fit-content' }}
              onClick={() => {
                setShowCreateRole(true);
              }}
            />
          </div>
          {roles?.map((role: Role) => (
            <RoleCard
              role={role}
              key={role.name}
              onClick={() => setRoleToEdit(role)}
              setRoleToDelete={setRoleToDelete}
              disableDelete={members.some(
                (member) => member.role?.toLocaleLowerCase() === role.name.toLocaleLowerCase() // TODO: check by id instead of name
              )}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ManageRoles;
