import Button from 'components/ui/button/Button';
import ErrorText from 'components/ui/errorText/ErrorText';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteInvite } from 'services/api/invites';
import { deleteMembership } from 'services/api/membership';
import { User } from 'types/entities/user';

type Props = {
  deleteMember: (id: string) => void;
  memberToDelete: User;
  onClose: () => void;
};
const DeleteMember = ({ deleteMember, memberToDelete, onClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const [loadingButton, setLoadingButton] = useState(false);
  const organization = useSelectedOrganization();

  const [error, setError] = useState<ErrorType>();

  const handleDeleteMember = async () => {
    setLoadingButton(true);
    if (!organization) {
      setLoadingButton(false);
      return;
    }

    let response = null;

    if (memberToDelete.isInvitation) {
      response = await deleteInvite(memberToDelete.id);
    } else {
      response = await deleteMembership(memberToDelete.id, organization.id);
    }

    if (!response && response !== '') {
      setError({
        error: 'deleteMember',
        description: t('errorDeleteMember')
      });
      setLoadingButton(false);
      return;
    }

    deleteMember(memberToDelete.id);
    onClose();
    setLoadingButton(false);
  };
  return (
    <>
      <FormHeader title={t('deleteMember')} description={t('areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={handleDeleteMember}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
};

export default DeleteMember;
