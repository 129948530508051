import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const useCarouselTranslation = () => {
  const { t: translate, i18n } = useTranslation();

  const t = (text: ReactNode, params?: any) => {
    if (typeof text === 'string' && i18n.exists(text)) {
      return translate(text, params);
    }

    return text;
  };

  return { t };
};
