import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';
import InputGoogleMaps from '../../inputGoogleMaps/InputGoogleMaps';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children' | 'icon'>;
type InputGoogleMapsType = React.ComponentProps<typeof InputGoogleMaps>;

type Props = { origin: InputGoogleMapsType; destination: InputGoogleMapsType } & InputWrapperType;

const FormGoogleMapsDouble = (props: Props) => {
  return (
    <InputWrapper iconV2='location' {...props}>
      <InputGoogleMaps {...props.origin} />
      <InputGoogleMaps {...props.destination} />
    </InputWrapper>
  );
};

export default FormGoogleMapsDouble;
