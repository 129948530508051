import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import { User } from 'types/entities/user';
import checkFormErrors from 'utils/checkFormErrors';
import { addMembership, deleteMembership, editMembership } from 'services/api/membership';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { deleteInvite, editInvite } from 'services/api/invites';
import Checkbox from 'components/ui/formComponents2/checkbox/Checkbox';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { PredefinedRoles } from 'types/entitiesEnums/roles';

type FormData = {
  role: SelectOptionFormat;
  applyToChildren: boolean;
  errors: ErrorType[];
};
type Props = {
  memberToEdit: User;
  onClose: () => void;
  editMember: (member: User) => void;
  roles: Role[];
  isLoading: boolean;
};
const EditMember = ({ memberToEdit, onClose, editMember, roles, isLoading }: Props) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'members' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const renderRole = (role: string) => {
    const blacklist = Object.values(PredefinedRoles);
    if (blacklist.includes(role as PredefinedRoles))
      return t(`manageRoles.predefinedRoles.${role.toLowerCase()}`);
    return role;
  };

  const [formData, setFormData] = useState<FormData>({
    role: {
      id: memberToEdit.role_id ?? '',
      name: memberToEdit.role ? renderRole(memberToEdit.role) : ''
    },
    applyToChildren: false,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const organization = useSelectedOrganization();

  const handleEditMember = async () => {
    if (!organization) return;

    const newErrors = checkFormErrors(formData, formData.errors, []);
    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      return;
    }
    setLoadingButton(true);

    let response = null;

    if (formData.applyToChildren) {
      // delete and reinvite the user to apply the role to all children
      if (memberToEdit.isInvitation) {
        response = await deleteInvite(memberToEdit.id);
      } else {
        response = await deleteMembership(memberToEdit.id, organization.id);
      }

      if (!response && response !== '') return;

      response = await addMembership(
        memberToEdit.email,
        organization.id,
        formData.role.id,
        false,
        i18n.resolvedLanguage
      );

      if (!response) return;

      editMember({
        ...memberToEdit,
        role: response.role,
        role_id: response.role_id
      });

      setLoadingButton(false);
      onClose();
      return;
    }

    if (memberToEdit.isInvitation) {
      response = await editInvite(memberToEdit.id, formData.role.id);
    } else {
      response = await editMembership(memberToEdit.id, organization.id, formData.role.id);
    }

    if (!response) return;
    editMember({
      ...memberToEdit,
      role: response.role_.name,
      role_id: response.role_.id
    });

    setLoadingButton(false);
    onClose();
  };

  return (
    <>
      <FormHeader title={t('editMember')} />
      <FormWrapper>
        {isLoading ? (
          <CustomSkeletonLoader count={1} />
        ) : (
          <FormSelect
            icon={'/images/icons/key.svg'}
            placeholder={t('selectRole')}
            label={t('role')}
            value={formData.role}
            onChange={onChangeValue('role')}
            options={roles.map((role) => ({ id: role.id, name: role.name }))}
            error={formData.errors.find((elem) => elem.error === 'role')}
          />
        )}
      </FormWrapper>
      <FormWrapper className='mb-10'>
        <Checkbox
          text={
            <div className='flex items-center'>
              {t('applyToChildren')}
              {
                <div className='ml-2'>
                  <Tooltip
                    text={t('applyToChildrenTooltip')}
                    position='right'
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
              }
            </div>
          }
          selected={formData.applyToChildren}
          onChange={() => setFormData({ ...formData, applyToChildren: !formData.applyToChildren })}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='primary'
          disabled={!formData.applyToChildren && formData.role.id === memberToEdit?.role_id}
          text={t('save')}
          onClick={handleEditMember}
          loading={loadingButton}
        />
      </FormButtonSection>
    </>
  );
};

export default EditMember;
