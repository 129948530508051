import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { useDeleteInvestor } from './hooks/useInvestors';

type ModalInvestorProps = {
  investorData?: DMInvestor;
  closeModal: () => void;
};

export const ModalDeleteInvestor = ({ investorData, closeModal }: ModalInvestorProps) => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality.context.investors' });
  const { deleteInvestor } = useDeleteInvestor();

  return (
    <div className='delete-modal'>
      <FormHeader title={t('delete')} description={t('areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={() => {
            deleteInvestor(investorData?.id ?? '');
            closeModal();
          }}
        />
      </FormButtonSection>
    </div>
  );
};
