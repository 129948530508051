export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL';

export const SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS = 'SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const UPLOAD_IMAGE_PROFILE = 'UPLOAD_IMAGE_PROFILE';

export const CREATE_USER_RECOMMENDATION = 'CREATE_USER_RECOMMENDATION';
export const CREATE_USER_RECOMMENDATION_FAIL = 'CREATE_USER_RECOMMENDATION_FAIL';
export const REMOVE_USER_RECOMMENDATION = 'REMOVE_USER_RECOMMENDATION';
export const REMOVE_USER_RECOMMENDATION_FAIL = 'REMOVE_USER_RECOMMENDATION_FAIL';

export const GET_TOTAL_IMPACTS_SUCCESS = 'GET_TOTAL_IMPACTS_SUCCESS';
export const GET_TOTAL_IMPACTS_FAIL = 'GET_TOTAL_IMPACTS_FAIL';

export const SWITCH_ORGANIZATION = 'SWITCH_ORGANIZATION';
export const UPDATE_ORGANIZATION_DESCRIPTION = 'UPDATE_ORGANIZATION_DESCRIPTION';
export const UPDATE_ORGANIZATION_FISCAL_DATA = 'UPDATE_ORGANIZATION_FISCAL_DATA';

export const UPDATE_USER_SIGN_IN_COUNT = 'UPDATE_USER_SIGN_IN_COUNT';

export const UPDATE_ORGANIZATION_INVOICING_DATA = 'UPDATE_ORGANIZATION_INVOICING_DATA';

export const CREATE_ORGANIZATION_AND_LINK_TO_HOLDING = 'CREATE_ORGANIZATION_AND_LINK_TO_HOLDING';

export const REMOVE_ORGANIZATION_FROM_USER = 'REMOVE_ORGANIZATION_FROM_USER';

export const SET_INTERACTIVE_TUTORIAL_AS_READY = 'SET_INTERACTIVE_TUTORIAL_AS_READY';

export const SET_INTERACTIVE_TUTORIAL_AS_NOT_READY = 'SET_INTERACTIVE_TUTORIAL_AS_NOT_READY';

export const SWITCH_GROUPING = 'SWITCH_GROUPING';

export const SET_GROUPINGS = 'SET_GROUPINGS';

export const GET_PENDING_TASKS = 'GET_PENDING_TASKS';

export const PURCHASES_ACTIONS = {
  START_LOADING_PURCHASES: 'START_LOADING_PURCHASES',
  STOP_LOADING_PURCHASES: 'STOP_LOADING_PURCHASES'
} as const;

// export const SET_WELCOME_TUTORIAL = 'SET_WELCOME_TUTORIAL';
