import { useEffect, useState } from 'react';
import { ProfileCard } from '../profileCard/ProfileCard';
import { useTranslation } from 'react-i18next';
import SlideToggle from 'components/ui/formComponents/slideToggle/SlideToggle';
import Modal from 'components/ui/modal/Modal';
import { MultifactorQRModal } from './MultifactorQRModal';
import { associateTotpMFA, disableTotpMFA, enableTotpMFA, isMFAActive } from 'services/authService';
import Icon from 'components/ui/icon/Icon';
import { useUser } from 'customHooks/useUser';
import Button from 'components/ui/button/Button';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DisableMFAModal } from './DisableMFAModal';

export const SecurityAndAccesses = () => {
  const { t } = useTranslation('', { keyPrefix: 'profile.securityAndAccesses' });
  const user = useUser();
  const [isMFAModalOpen, setIsMFAModalOpen] = useState(false);
  const [isDisableMFAModalOpen, setIsDisableMFAModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  const [mfaLink, setMfaLink] = useState('');
  const [isMFAenabled, setIsMFAEnabled] = useState(false);

  useEffect(() => {
    isMFAActive({ email: user?.email ?? '' }).then((res) => {
      setIsMFAEnabled(res);
    });
  }, [JSON.stringify(user)]);

  const handleSubmitCode = async (totpCode: string) => {
    await enableTotpMFA({
      email: user?.email ?? '',
      totpCode: totpCode
    });
    setIsMFAEnabled(true);
  };

  const handleDisableModal = async () => {
    try {
      await disableTotpMFA({ email: user?.email ?? '' });
      setIsMFAEnabled(false);
    } catch (error) {
      setIsMFAEnabled(true);
    } finally {
      setIsDisableMFAModalOpen(false);
    }
  };

  return (
    <ProfileCard.Layout>
      <ProfileCard.Content>
        <ProfileCard.Title>{t('title')}</ProfileCard.Title>
        <div className='flex place-center gap-x-1'>
          <div className='font-body-b2-r'>{t('changePasswordMsg')}</div>
          <Button
            className='font-12'
            lookAndFeel='link'
            onClick={() => setIsChangePasswordModalOpen(true)}>
            {t('newPassword')}
          </Button>
        </div>
        <div className='flex place-center gap-x-4'>
          <span className='font-body-b2-r'>{t('activeTwoFactor')}</span>
          <SlideToggle
            checked={isMFAenabled}
            setChecked={async (checked) => {
              if (checked) {
                const { totp_qr_code } = await associateTotpMFA({
                  email: user?.email ?? ''
                });
                setMfaLink(totp_qr_code);
                setIsMFAModalOpen(true);
              } else {
                setIsDisableMFAModalOpen(true);
              }
            }}
          />
        </div>
        <div className='flex gap-2 place-xenter p-1 px-2 rounded-16 app-bg-color w-fit-content'>
          <Icon icon='information' color={'blocked'} />
          <div className='secondary-text-color font-12'>{t('2faInfoMessage')}</div>
        </div>
      </ProfileCard.Content>
      <Modal.WithPortal
        show={isMFAModalOpen}
        onClose={() => setIsMFAModalOpen(false)}
        maxWidth='700px'>
        <MultifactorQRModal
          QRValue={mfaLink}
          onSubmitCode={handleSubmitCode}
          onClose={() => setIsMFAModalOpen(false)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={isDisableMFAModalOpen}
        onClose={() => setIsDisableMFAModalOpen(false)}
        maxWidth='700px'>
        <DisableMFAModal onDisable={handleDisableModal} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        maxWidth='700px'>
        <ChangePasswordModal onClose={() => setIsChangePasswordModalOpen(false)} />
      </Modal.WithPortal>
    </ProfileCard.Layout>
  );
};
