import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { updateFacility } from '../../../services/api/facility';
import { Facility, WasteWaterTreatmentFacility } from '../../../types/entities/facility';
import { InputSize } from '../../../types/utilsEnums/input';
import { convertStringToDate } from '../../../utils/convertDates';
import { limitString } from '../../../utils/limitString';
import { FiltersContext } from '../../layout/Filters/FilterContext';
import FilterSection, {
  FilterOptionType,
  FilterSectionType
} from '../../layout/Filters/FilterSection';
import Filters from '../../layout/Filters/Filters';
import FiltersHandlers from '../../layout/Filters/FiltersHandlers';
import useFilters from '../../layout/Filters/hooks/useFilters';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import CardList from '../../ui/cardList/CardListRefactored';
import CardsFilter from '../../ui/filter/CardsFilter';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import Modal from '../../ui/modal/Modal';
import useOnSort from '../../ui/sorting/hooks/useOnSort';
import TotalLegend from '../../ui/totalLegend/TotalLegend';
import { MoveFacilityToHub } from '../hubs/components/moveFacilityToHub/MoveFacilityToHub';
import DeleteFacility from './deleteFacility/DeleteFacility';
import EditCreateFacility from './editCreateFacility/EditCreateFacility';

import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import Card from '../../ui/cards/card/Card';
import Icon from '../../ui/icon/Icon';
import Label from '../../ui/label/Label';
import Sorting from '../../ui/sorting/Sorting';
import ArchiveFacility from './archiveFacility/ArchiveFacility';
import useSideBarFilters from './hooks/useSideBarFilters';
import useSortingOptions from './hooks/useSortingOptions';
import LimitCompleted from './limitCompleted/LimitCompleted';
import './styles.scss';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import { getCountries } from '../../../constants/phonePrefixes';

const BASE_URL = '/facilities';

function Facilities() {
  const { t, i18n } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);
  const flags = useFeatureFlags();

  const dispatch = useDispatch();

  const sortingOptions = useSortingOptions();

  const defaultSort = {
    ...sortingOptions[0],
    direction: 'desc' as 'desc' | 'asc'
  };

  const sideBarFilters: FilterSectionType[] = useSideBarFilters();

  const [showCreateFacility, setShowCreateFacility] = useState(false);
  const [facilityToEdit, setFacilityToEdit] = useState<Facility>();
  const [facilityToDelete, setFacilityToDelete] = useState('');
  const [facilityToArchive, setFacilityToArchive] = useState<Facility>();

  const [showArchivedFacilities, setShowArchivedFacilities] = useState(false);
  const [showLimitCompleted, setShowLimitCompleted] = useState(false);
  const [facilityMoveToHubs, setFacilityMoveToHubs] = useState<Facility>();

  const [loading, setLoading] = useState(true);

  const [listView, setListView] = useState(false);
  const [url, setUrl] = useState(BASE_URL + `?sort_by=${defaultSort.id}:${defaultSort.direction}`);
  const [dates, setDates] = useState<{ startDate: string; endDate: string } | undefined>();
  const [clearDates, setClearDates] = useState(false);
  const [remove, setRemoveFilters] = useState(false);
  const [sideFilters, setFilters] = useState<FilterOptionType[]>([
    {
      id: 'active',
      name: '',
      type: 'status'
    }
  ]);
  const [open, setOpen] = useState(false);

  // Filters
  const [searchValue, setSearchValue] = useState('');

  const debounceValue = useDebounceValue(searchValue);

  const onSort = useOnSort({ url, setUrl });

  useFilters({
    setUrl,
    url,
    setFilters,
    filters: sideFilters,
    removeFilters: setRemoveFilters,
    remove,
    dates,
    setDates,
    setClearDates,
    dateKeys: ['created_at'],
    searchFilters: [
      {
        key: 'name',
        value: debounceValue,
        setValue: setSearchValue
      }
    ]
  });
  const size = SIZE_PAGINATION_BIG;

  const navigate = useNavigate();
  const user = useContext(UserContext);
  const foundOrganization = useSelectedOrganization();

  const filters = {
    inputTextList: [],
    startDate: Math.floor(convertStringToDate(startDate).getTime() / 1000),
    endDate: Math.floor(convertStringToDate(endDate).getTime() / 1000)
  };

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues: setFacilities,
    total,
    setTotal,
    total2,
    setTotal2
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization,
    filters
  });
  const facilities: (Facility | WasteWaterTreatmentFacility)[] = values;

  useEffect(() => {
    if (dateError) return;
    if (!url.includes('filter_by')) return;

    fetchData();
  }, [url, startDate, endDate]);

  useEffect(() => {
    const statusValue = showArchivedFacilities ? 'archived' : 'active';

    const prevFilters = [...sideFilters];

    const indexFilterFound = prevFilters.findIndex((elem) => elem.type === 'status');
    if (indexFilterFound === -1) return;
    if (prevFilters[indexFilterFound].id === statusValue) return;
    prevFilters[indexFilterFound] = { id: statusValue, name: '', type: 'status' };

    setFilters(prevFilters);
  }, [showArchivedFacilities]);

  const handleShowCreateFacility = () => {
    setShowCreateFacility(true);
  };

  const handleHideCreateFacility = () => {
    setShowCreateFacility(false);
  };

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.MEASURE_FACILITIES}/${id}`);
  };

  const handleHideEditFacility = () => {
    setFacilityToEdit(undefined);
  };

  const handleHideDeleteFacility = () => {
    setFacilityToDelete('');
  };

  const addFacility = (value: Facility) => {
    let newFacilities: (Facility | WasteWaterTreatmentFacility)[] = [];
    if (facilities) {
      newFacilities = [...facilities];
    }
    newFacilities.unshift(value);
    setFacilities(newFacilities);
    setTotal((prev) => prev + 1);
    dispatch(setNotification(t('notification.createFacility')));
    handleHideCreateFacility();
  };

  const editFacility = (value: Facility | WasteWaterTreatmentFacility, id: string) => {
    const newFacilities = facilities ? [...facilities] : [];
    const indexFacilityFound = newFacilities.findIndex((elem) => elem.id === id);
    if (indexFacilityFound > -1) {
      newFacilities[indexFacilityFound] = value;
    }

    setFacilities(newFacilities);
    dispatch(setNotification(t('notification.editFacility')));
    handleHideEditFacility();
  };

  const convertToHub = (id: string) => {
    setFacilities((prev) => prev.filter((facility) => facility.id !== id));
    setTotal((prev) => prev - 1);
    dispatch(setNotification(t('notification.convertToHub')));
    setFacilityMoveToHubs(undefined);
  };

  const deleteFacility = (id: string) => {
    const newFacilities = facilities ? [...facilities] : [];
    const indexFacilityFound = newFacilities.findIndex((elem) => elem.id === id);
    if (indexFacilityFound > -1) {
      newFacilities.splice(indexFacilityFound, 1);
    }

    setFacilities(newFacilities);
    setTotal((prev) => prev - 1);
    dispatch(setNotification(t('notification.deleteFacility')));
    handleHideDeleteFacility();
  };

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const handleEditFacility = (id: string) => {
    const foundFacility = facilities?.find((facility) => facility.id === id);
    if (foundFacility) {
      setFacilityToEdit(foundFacility);
    }
  };

  const handleArchive = (id: string) => {
    // Change status of facility to 'archive', no need to remove it from list
    const newFacilities = facilities ? [...facilities] : [];
    const indexFacilityFound = newFacilities.findIndex((elem) => elem.id === id);
    if (indexFacilityFound > -1) {
      // remove facility from list
      newFacilities.splice(indexFacilityFound, 1);
    }
    setFacilities(newFacilities);
    dispatch(setNotification(t('notification.archiveFacility')));
    setFacilityToArchive(undefined);
    setTotal(total - 1);
    setTotal2(total2 + 1);
  };

  const handleDearchiveFacility = async (id: string) => {
    if (foundOrganization?.limit_facilities && total >= foundOrganization?.limit_facilities) {
      setShowLimitCompleted(true);
      return;
    }
    // Change status of facility to 'active', and remove it from list
    const response = await updateFacility(id, { status: 'active' });
    if (!response) return;
    const newFacilities = facilities ? [...facilities] : [];
    const indexFacilityFound = newFacilities.findIndex((elem) => elem.id === id);
    if (indexFacilityFound > -1) {
      // remove facility from list
      newFacilities.splice(indexFacilityFound, 1);
    }
    setFacilities(newFacilities);
    dispatch(setNotification(t('notification.dearchiveFacility')));
    setTotal(total + 1);
    setTotal2(total2 - 1);
  };

  const navigateToDatadis = () => navigate(ROUTES.MEASURE_DATADIS);

  const getSelectOptions = (elem: Facility) => {
    return [
      {
        id: 'dearchive',
        name: t('facilities.dearchive'),
        onClick: () => handleDearchiveFacility(elem.id),
        hide: elem.status === 'archived'
      },

      {
        id: 'edit',
        name: t('facilities.edit', { type: t('general.facility') }),
        onClick: () => handleEditFacility(elem.id),
        hide: elem.status !== 'archived'
      },
      {
        id: 'archive',
        name: t('facilities.archive'),
        onClick: () => setFacilityToArchive(elem),
        hide: elem.status !== 'archived'
      },
      {
        id: 'convertToHub',
        name: t('facilities.convertToHub'),
        onClick: () => setFacilityMoveToHubs(elem),
        hide: foundOrganization?.sector === 'transport' && elem.status !== 'archived'
      },
      {
        id: 'delete',
        name: t('facilities.delete', { type: t('general.facility') }),
        onClick: () => setFacilityToDelete(elem.id),
        hide: elem.status !== 'archived'
      }
    ];
  };

  if (!foundOrganization) {
    return null;
  }

  return (
    <section className='facilities'>
      <FiltersContext.Provider
        value={{
          setFilters,
          filters: sideFilters,
          setOpen,
          open,
          removeFilters: setRemoveFilters,
          remove,
          dates,
          setDates,
          clearDates,
          setClearDates
        }}>
        <Filters>
          {/* <FiltersDate /> */}
          {sideBarFilters?.map(
            (section, idx) =>
              section.options?.length > 0 && (
                <>
                  <FilterSection
                    options={section.options}
                    title={section.title}
                    elToShow={section.elToShow}
                    key={`section-${idx}`}
                  />
                </>
              )
          )}
        </Filters>
        <SectionHeader title={t('measureMain.title')} breadcrumb={<Breadcrumb />} />
        <div className='facilities__subheader'>
          <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
            <>
              <FormText
                icon={'/images/icons/search.svg'}
                placeholder={t('facilities.searchPlaceholder')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                value={searchValue}
                size={InputSize.SMALL}
              />
              <Sorting
                options={sortingOptions}
                label={t('input.sorting.orderBy')}
                placeholder={t('input.placeholderSelect')}
                onSort={onSort}
                defaultSort={defaultSort}
              />
              <FiltersHandlers />
              {flags?.datadis && Boolean(total) && (
                <Button
                  onClick={navigateToDatadis}
                  text={t('facilities.datadis')}
                  lookAndFeel={'primary'}
                  size='small'
                />
              )}
            </>
          </CardsFilter>
          <div className='main-bg-color card-border-color legend-wrapper'>
            <Button
              lookAndFeel={showArchivedFacilities ? 'primary' : 'secondary'}
              text={t('facilities.archivedFacilities')}
              size='small'
              iconNode={
                showArchivedFacilities ? (
                  <Icon icon={'archive'} color='white' />
                ) : (
                  <Icon icon={'archive'} color='gray-dark' />
                )
              }
              onClick={() => setShowArchivedFacilities(!showArchivedFacilities)}
            />
            <TotalLegend total={total} loading={loading} i18key={'facilities'} total2={total2} />
          </div>
        </div>
        <div id={'facility'} className='infinite-scroll-wrapper-card'>
          <InfiniteScroll
            dataLength={facilities ? facilities.length : 0}
            next={fetchMoreData}
            hasMore={hasMore}
            style={{ overflow: 'visible' }}
            endMessage={<></>}
            loader={undefined}
            scrollableTarget={'facility'}>
            <CardList
              listView={listView}
              title={t('facilities.newFacility')}
              description={t('facilities.newFacilityDescription')}
              buttons={
                <Button
                  lookAndFeel={total >= foundOrganization?.limit_facilities ? 'blocked' : 'primary'}
                  text={t('facilities.create')}
                  onClick={handleShowCreateFacility}
                  size='small'
                />
              }
              loading={loading}>
              {facilities?.map((elem) => {
                const total: number | undefined = elem.co2e
                  ? numberToDecimalNonZero(elem.co2e / 1000, 4)
                  : undefined;

                const commonContent = (
                  <>
                    <Card.Icon
                      icon={
                        elem.facility_purpose_type === 'waste_water_facilities'
                          ? '/images/icons/road.svg'
                          : '/images/icons/building.svg'
                      }
                      alt={elem.name}
                    />
                    <span className='headline4-font'>{limitString(elem.name, 25)}</span>
                    {elem.country && (
                      <span className='subtitle3-font'>
                        {
                          getCountries(i18n.resolvedLanguage).find(
                            (country) => country.id === elem.country
                          )?.name
                        }
                      </span>
                    )}
                    {elem.status === 'archived' && (
                      <Label lookAndFeel='disabled'>{t('vehicles.archived')}</Label>
                    )}
                    {total && <Card.Total total={total} unit={'t CO₂ eq.'} />}
                  </>
                );
                return (
                  <Card key={elem.id} listView={listView} onClick={() => onClickCard(elem.id)}>
                    <Card.Options>
                      <ButtonDropdown
                        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                        options={getSelectOptions(elem).filter(
                          (options) => options.hide === true
                        )}></ButtonDropdown>
                    </Card.Options>
                    {elem.status === 'archived' ? (
                      <Card.ContentDisabled>{commonContent}</Card.ContentDisabled>
                    ) : (
                      <Card.Content>{commonContent}</Card.Content>
                    )}
                  </Card>
                );
              })}
            </CardList>
          </InfiniteScroll>
        </div>
      </FiltersContext.Provider>

      <Modal
        show={showCreateFacility}
        onClose={handleHideCreateFacility}
        width='600px'
        maxWidth='600px'>
        <EditCreateFacility mode='create' addFacility={addFacility} user={user} />
      </Modal>
      <Modal
        show={!!facilityToEdit}
        onClose={handleHideEditFacility}
        width='600px'
        maxWidth='600px'>
        <EditCreateFacility
          mode='edit'
          user={user}
          facilityId={facilityToEdit?.id}
          editFacility={editFacility}
        />
      </Modal>
      <Modal
        show={facilityToDelete !== ''}
        onClose={handleHideDeleteFacility}
        width='428px'
        maxWidth='428px'>
        <DeleteFacility
          facilityToDelete={facilityToDelete}
          removeFacility={deleteFacility}
          closeModal={handleHideDeleteFacility}
        />
      </Modal>
      <Modal
        show={!!facilityToArchive}
        onClose={() => setFacilityToArchive(undefined)}
        width='428px'
        maxWidth='516px'>
        {facilityToArchive && (
          <ArchiveFacility
            facilityToArchive={facilityToArchive}
            closeModal={() => setFacilityToArchive(undefined)}
            handleArchive={handleArchive}
          />
        )}
      </Modal>
      <Modal
        show={showLimitCompleted}
        onClose={() => setShowLimitCompleted(false)}
        maxWidth='484px'>
        <LimitCompleted handleClose={() => setShowLimitCompleted(false)} />
      </Modal>
      <Modal
        show={facilityMoveToHubs !== undefined}
        onClose={() => setFacilityMoveToHubs(undefined)}
        width='600px'
        maxWidth='600px'>
        <MoveFacilityToHub facilityToMove={facilityMoveToHubs} convertToHub={convertToHub} />
      </Modal>
    </section>
  );
}

export default Facilities;
