import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { AxiosError } from 'axios';
import { useApiErrors } from 'customHooks/translations/useApiErrors';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useDispatch } from 'react-redux';
import { updateLCAOfficialMaterial } from 'services/api/lca/materialManagement';

export const useEditLCAOfficialMaterial = () => {
  const dispatch = useDispatch();
  const { t: tError } = useApiErrors();
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.materialDetail' });
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: updateLCAOfficialMaterial,
    onSuccess: async (data) => {
      queryClient.setQueryData<LCAOfficialMaterial>(
        ['lca-official-material', data.id],
        (oldData) => ({
          ...oldData,
          ...data
        })
      );

      queryClient.invalidateQueries({ queryKey: ['lca-materials'] });
      queryClient.invalidateQueries({ queryKey: ['lca-official-material'] });
      queryClient.invalidateQueries({ queryKey: ['lca-node-materials'] });
      queryClient.invalidateQueries({ queryKey: ['lca-node-metadata'] });
      queryClient.invalidateQueries({ queryKey: ['lca-transport'] });
      queryClient.invalidateQueries({ queryKey: ['lcaPortfolio'] });

      dispatch(setNotification(t('successEditMessage')));
    },
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        dispatch(setNotification(tError(error.response?.data)));
      }
    }
  });
  return {
    editLCAOfficialMaterial: mutate,
    isEditing: isPending
  };
};
