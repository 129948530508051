import './styles.scss';
type Props = {
  url: string;
};
function HubspotCalendar({ url }: Props) {
  return (
    <section className='meetings-iframe-container' data-src={url}>
      <iframe
        title={'hubspot'}
        style={{
          width: '100%',
          height: '100%',
          marginTop: '2rem'
        }}
        src={url}
      />
    </section>
  );
}

export default HubspotCalendar;
