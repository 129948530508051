import { useEffect, useState } from 'react';
import { getLicensePlates } from '../../../../../services/api/vehicle';

export type AllowedFuels = {
  [licensePlate: string]: {
    fuelIds: string[];
  };
};

const useGetLicensePlates = () => {
  const [licensePlates, setLicensePlates] = useState<string[]>([]);
  const [allowedFuels, setAllowedFuels] = useState<AllowedFuels>({});

  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    const response = await getLicensePlates();
    setLoading(false);

    if (!response) return;

    setLicensePlates(response.map((res: any) => res.license_plate));

    const formattedResponse: AllowedFuels = {};
    response.map((res: any) => {
      formattedResponse[res.license_plate] = {
        fuelIds: res.fuel_units.map((unit: any) => unit.id)
      };
    });

    setAllowedFuels(formattedResponse);
  };

  useEffect(() => {
    getData();
  }, []);

  return { licensePlates, allowedFuels, loading };
};

export default useGetLicensePlates;
