import Button from 'components/ui/button/Button';
import { CertificationPage } from 'components/ui/certificationPage/CertificationPage';
import Modal from 'components/ui/modal/Modal';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MoreInfoModal from './MoreInfoModal/MoreInfoModal';
import { isKey } from 'utils/isKey';
import { PNGS } from 'components/measure/esgMetrics/constants';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

export const DMHome = () => {
  const { t, i18n } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const { DM } = isKey(i18n.resolvedLanguage, PNGS) ? PNGS[i18n.resolvedLanguage] : PNGS.en;
  const navigate = useNavigate();

  return (
    <>
      <CertificationPage.Layout>
        <div className='flex'>
          <div className='flex-col'>
            <div className='flex-col justify-between'>
              <CertificationPage.Title>{t('title')}</CertificationPage.Title>
              <CertificationPage.Description>
                <Trans i18nKey='doubleMateriality.startDescription' />
              </CertificationPage.Description>
              <CertificationPage.SectionLayout>
                <CertificationPage.SectionTitle>{t('benefits')}</CertificationPage.SectionTitle>
                <CertificationPage.Checks
                  checks={[t('check1'), t('check2'), t('check3'), t('check4')]}
                />
              </CertificationPage.SectionLayout>
            </div>

            <CertificationPage.ButtonSection>
              <>
                <Button
                  lookAndFeel='primary'
                  text={t('moreInfoModal.configureResults')}
                  size='medium'
                  onClick={() =>
                    navigate(`${ROUTES.MEASURE_ESG_METRICS}/double_materiality/results`)
                  }
                  className='mt-4'
                  style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
                />
                <Button
                  lookAndFeel='secondary'
                  text={t('moreInfoModal.iWantToStartOne')}
                  size='medium'
                  onClick={() => {
                    navigate(`${ROUTES.MEASURE_ESG_METRICS}/double_materiality/stakeholders`);
                  }}
                  className='mt-4'
                  style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
                />
              </>
            </CertificationPage.ButtonSection>
          </div>
        </div>
        <DM className='w-full' />
      </CertificationPage.Layout>
      <Modal
        show={showMoreInfo}
        onClose={() => setShowMoreInfo(false)}
        maxWidth='613px'
        width='613px'>
        <MoreInfoModal onClose={() => setShowMoreInfo(false)} />
      </Modal>
    </>
  );
};
