import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import LineChart2 from 'components/ui/charts/LineChart2';
import List, { ListColumn } from '../common/list/List';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { numberToScientificNotation } from 'utils/numberToDecimal';
import { useTranslation } from 'react-i18next';
import useDataCo2 from '../hooks/useDataCo2';
import { scope3_color } from 'styles/colors';
import { parseDataCo2 } from '../utils/parseDataCo2';
import { getRowsFromData } from '../utils/getRowsFromData';

type Props = {
  organizationCo2e: number;
  startDate: string;
  endDate: string;
  data: WaterTreatmentTotalImpact[];
};

export const WasteWater: React.FC<Props> = ({ data, organizationCo2e, startDate, endDate }) => {
  const selectedOrganization = useSelectedOrganization();
  const { t, i18n } = useTranslation();
  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });

  if (!selectedOrganization) {
    return null;
  }

  const columns: ListColumn[] = [
    { title: t('ghgReport.month') },
    { title: t('ghgReport.co2eq.(tns)') }
  ];

  const rows: string[][] = getRowsFromData(data, dataCo2, i18n.resolvedLanguage);

  const totalCo2 = data.reduce((acc, elem) => acc + elem.co2e, 0);

  const parsedData = parseDataCo2(dataCo2, data);

  return (
    <div className='ghg-purchases'>
      <LineChartWrapper
        legends={[
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <LineChart2
          labels={monthLabels}
          chartData={[
            {
              label: 'scope3',
              borderColor: scope3_color,
              data: parsedData.map((elem) => elem.y),
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              stepped: false,
              backgroundColor: 'rgba(41, 69, 255, 0.1)',
              fill: true,
              tension: 0
            }
          ]}
          disabled={false}
        />
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={'/images/icons/calendar50.svg'}
          columns={columns}
          rows={rows}
          totalRow={[numberToScientificNotation(totalCo2 / 1000)]}
        />
        <DescriptionBox
          descriptions={[
            t('ghgReport.wasteWaterEmissionsDescription.total', {
              total: numberToScientificNotation(totalCo2 / 1000),
              percentage: numberToScientificNotation((totalCo2 / organizationCo2e) * 100),
              tradeName: selectedOrganization.trade_name ?? ''
            })
          ]}
        />
      </div>
    </div>
  );
};
