import { HTMLAttributes } from 'react';
import Tooltip from '../../tooltip/Tooltip';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
  tooltipText?: string;
  labelPosition?: 'left' | 'right';
}

function SlideToggle({
  label,
  checked,
  setChecked,
  disabled = false,
  tooltipText = '',
  labelPosition = 'right',
  ...rest
}: Props) {
  const onToggle = () => {
    if (!disabled) {
      setChecked(!checked);
    }
  };

  return (
    <div className='slide-toggle-wrapper' {...rest}>
      {label && labelPosition === 'left' && (
        <span className={`${!checked ? 'body3-font disabled-text-color' : 'body2-font'}`}>
          {label}
        </span>
      )}
      <div className='slide-toggle' onClick={onToggle}>
        <input className='status' type='checkbox' name='status' />
        <div
          className={`status-switch input-border ${
            disabled ? 'disabled' : checked ? 'highlight-bg-color' : 'bg-gray'
          }`}>
          <div className={`${checked ? 'right-check' : 'left-check'}`}></div>
          {/* <div className={`right-check ${checked ? '' : 'hide'}`}></div> */}
        </div>
      </div>
      {label && labelPosition === 'right' && (
        <span className={`${!checked ? 'body3-font disabled-text-color' : 'body2-font'}`}>
          {label}
        </span>
      )}
      {tooltipText ? <Tooltip text={tooltipText} /> : null}
    </div>
  );
}

export default SlideToggle;
