import Modal from 'components/ui/modal/Modal';
import { useModalManager } from '../ModalManager';
import Button from 'components/ui/button/Button';
import { ReactComponent as ConfigureTeamSVG } from '../../../../../../assets/img/configureTeam.svg';

export const ConfigureModal = () => {
  const { closeModal } = useModalManager();

  return (
    <Modal.WithPortal show onClose={closeModal}>
      <div className='h-full flex flex-col gap-y-4'>
        <Modal.Header title={'Configurar equipo'} />
        <ConfigureTeamSVG width={'80vw'} height={'65vh'} />
        <Modal.Buttons>
          <Button lookAndFeel='primary' size='medium' onClick={closeModal}>
            Guardar
          </Button>
        </Modal.Buttons>
      </div>
    </Modal.WithPortal>
  );
};
