import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SCOPES_ARRAY } from '../../../../constants';
import { ROUTES } from '../../../../constants/routes';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import useTreatmentSector from '../../../../customHooks/useTreatmentSector';
import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import CheckboxMultiple from '../../../ui/formComponents/checkboxMultiple/CheckboxMultiple';
import FormElement from '../../../ui/formComponents/formElement/FormElement';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormSectionTitle from '../../../ui/formComponents/formSectionTitle/FormSectionTitle';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import useGetIntensityMetrics from '../../useGetIntensityMetrics';
import './styles.scss';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import { getIntensityMetricsByIdPaginated } from '../../../../services/api/intensityMetrics';
import { convertDateBackToFront } from '../../../../utils/convertDates';
import FormCalendarDoubleSelect from '../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import Modal from '../../../ui/modal/Modal';
import DisclaimerConfirm from './DisclaimerConfirm';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../ui/tooltip/Tooltip';

type FormData = {
  startDate: string;
  endDate: string;
  intensityMetric: SelectOptionFormat;
  errors: ErrorType[];
};
type Props = {
  onCloseModal: () => void;
};
function DcycleReportDataForm({ onCloseModal }: Props) {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const navigate = useNavigate();

  const { groupByCategory: categoriesGrouped } = useTreatmentSector();
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  let options: SelectOptionFormat[] = [];

  let loadingMetrics = false;

  if (flags?.intensityMetrics) {
    const { intensityMetricGroups, loading } = useGetIntensityMetrics();

    options = intensityMetricGroups.map((group) => ({
      id: group.id,
      name: group.unit
    }));

    loadingMetrics = loading;
  }

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    intensityMetric: {
      id: '',
      name: ''
    },
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleEditOrgDescription = async () => {
    const optionalFields: string[] = [];
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    if (categoriesSelected.length === 0) {
      newErrors.push({
        error: 'categories',
        description: t('orgDescriptionModal.dcycle_report.selectAtLeastOneCategory')
      });
    }

    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    let foundIntensityMetric = undefined;

    if (formData.intensityMetric.id && formData.intensityMetric.id !== 'employees') {
      const intensityMetrics = await getIntensityMetricsByIdPaginated(
        formData.intensityMetric.id,
        1,
        100
      );
      foundIntensityMetric = intensityMetrics.items.find(
        (elem: IntensityMetricReport) =>
          convertDateBackToFront(elem.start_date) === formData.startDate &&
          convertDateBackToFront(elem.end_date) === formData.endDate
      );

      if (!foundIntensityMetric) {
        setShowDisclaimer(true);
        return;
      }
    }
    window.open(
      `${ROUTES.COMMUNICATE_WEB}?start_date=${formData.startDate}&end_date=${
        formData.endDate
      }&intensity_metric=${foundIntensityMetric.id ?? ''}&categories=${categoriesSelected.join(
        ','
      )}`
    );
  };

  const handleSelectCategory = (id: string) => {
    // Add category to categoriesSelected
    setCategoriesSelected((prev) => [...prev, id]);
    setFormData((prev) => ({
      ...prev,
      errors: prev.errors.filter((elem) => elem.error !== 'categories')
    }));
  };

  const handleRemoveCategory = (id: string) => {
    // Remove category from categoriesSelected
    setCategoriesSelected((prev) => prev.filter((elem) => elem !== id));
  };

  const handleSelectAllCategoriesScope = (id: string) => {
    const scope = parseInt(id);
    setFormData((prev) => ({
      ...prev,
      errors: prev.errors.filter((elem) => elem.error !== 'categories')
    }));
    // Find all categories with scope in categoriesGrouped
    const categoriesScope = categoriesGrouped
      .filter((elem) => elem.scope === scope)
      .map((elem) => elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, ''));
    const categoriesSelectedScope = categoriesSelected.filter((elem) =>
      categoriesScope.includes(elem)
    );

    // If all categories are selected, remove them
    if (categoriesSelectedScope.length === categoriesScope.length) {
      setCategoriesSelected((prev) => prev.filter((elem) => !categoriesScope.includes(elem)));
    } else {
      // If not, add them but remove duplicates
      const categoriesToAdd = categoriesScope.filter((elem) => !categoriesSelected.includes(elem));
      setCategoriesSelected((prev) => [...prev, ...categoriesToAdd]);
    }
  };

  const checkIfAllCategoriesSelected = (scope: number) => {
    const categoriesScope = categoriesGrouped
      .filter((elem) => elem.scope === scope)
      .map((elem) => elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, ''));
    const categoriesSelectedScope = categoriesSelected.filter((elem) =>
      categoriesScope.includes(elem)
    );
    return categoriesSelectedScope.length === categoriesScope.length;
  };

  const errorCategory = formData.errors.find((elem) => elem.error === 'categories');
  return (
    <div className='organization-description-form'>
      <FormHeader
        title={t('orgDescriptionModal.dcycle_report.completeData')}
        description={t(`orgDescriptionModal.dcycle_report.completeDataDescription`)}
      />
      <FormWrapper>
        <FormElementFull>
          <FormCalendarDoubleSelect
            label={t('orgDescriptionModal.selectDateRange')}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            startDate={formData.startDate ? (formData.startDate as string) : ''}
            endDate={formData.endDate ? (formData.endDate as string) : ''}
            error={formData.errors.find(
              (error) =>
                error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
            )}
          />
        </FormElementFull>
        <FormSelect
          icon='/images/icons/intensityMetric.svg'
          placeholder={t('orgDescriptionModal.selectOption')}
          label={
            <span className='flex gap-1'>
              {t('orgDescriptionModal.intensityMetric')} ·
              <span
                className='link'
                onClick={() => {
                  navigate(ROUTES.CUSTOM_INTENSITY_METRICS + '?open=create');
                  onCloseModal();
                }}>
                {t('orgDescriptionModal.ghg_report.form.generalInfo.createNewMetric')}
              </span>
              <Tooltip
                text={t('orgDescriptionModal.ghg_report.form.generalInfo.createNewMetricTooltip')}
              />
            </span>
          }
          options={options}
          value={formData.intensityMetric}
          onChange={onChangeValue('intensityMetric')}
          error={formData.errors.find((elem) => elem.error === 'intensityMetric')}
          loading={loadingMetrics}
        />
        {SCOPES_ARRAY.map((scope) => (
          <>
            <FormElementFull key={scope}>
              <div className='checkbox-wrapper'>
                <FormSectionTitle>
                  <span>{t(`dashboard.scope${scope}`)}</span>
                </FormSectionTitle>
                <CheckboxMultiple
                  id={scope.toString()}
                  onSelect={handleSelectAllCategoriesScope}
                  onRemove={handleSelectAllCategoriesScope}
                  selected={checkIfAllCategoriesSelected(scope)}
                />
                <span className='body1-font select-all'>{t('orgDescriptionModal.selectAll')}</span>
              </div>
            </FormElementFull>
            {categoriesGrouped
              .filter((elem) => elem.scope === scope && elem.category !== 'employees_in_labore')
              .map((elem) => {
                const categoryParsed = elem.category
                  .replace(/_in_labore/g, '')
                  .replace(/_in_itinere/, '');
                return (
                  <FormElement key={categoryParsed}>
                    <div className='checkbox-wrapper-sbti flex items-center gap-2'>
                      <CheckboxMultiple
                        id={categoryParsed}
                        onSelect={handleSelectCategory}
                        onRemove={handleRemoveCategory}
                        selected={categoriesSelected.includes(categoryParsed)}
                      />
                      <span className='body1-font'>{t(`categories.${categoryParsed}`)}</span>
                    </div>
                  </FormElement>
                );
              })}
          </>
        ))}
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleEditOrgDescription}
          text={t(`orgDescriptionModal.dcycle_report.saveAndGenerateReport`)}
          size='medium'
        />
      </FormButtonSection>
      {errorCategory && (
        <span className='error-text-color body2-font'>{errorCategory.description}</span>
      )}
      <Modal.WithPortal
        show={showDisclaimer}
        onClose={() => setShowDisclaimer(false)}
        width='584px'
        maxWidth='584px'>
        <DisclaimerConfirm
          handleClose={() => setShowDisclaimer(false)}
          formData={formData}
          categoriesSelected={categoriesSelected}
        />
      </Modal.WithPortal>
    </div>
  );
}

export default DcycleReportDataForm;
