import { ReactNode } from 'react';
import ErrorText from '../../../errorText/ErrorText';
import InputTextArea from '../../inputTextArea/InputTextArea';
import InputLabel from '../../inputUtils/inputWrapper/components/InputLabel';
import { useErrorTranslations } from 'customHooks/translations';

type InputTextAreaType = React.ComponentProps<typeof InputTextArea>;

type Props = InputTextAreaType & {
  label?: ReactNode;
  error?: ErrorType;
  tooltip?: string;
  showRequiredField?: boolean;
};

const FormTextArea = ({ label, tooltip, className, ...rest }: Props) => {
  const { t } = useErrorTranslations();
  const classes = ['input'];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      {label && <InputLabel tooltip={tooltip} label={label} />}
      <InputTextArea {...rest} />
      {rest.error && (
        <ErrorText style={{ marginTop: '0.5rem' }}>
          {rest.error?.description ?? t('error.requiredField')}
        </ErrorText>
      )}
    </div>
  );
};

export default FormTextArea;
