import { useTranslation } from 'react-i18next';
import { highlight_color, highlight_color2 } from '../../../../styles/colors';
import { FacilityReport } from '../../../../types/entities/facility';
import HorizontalBar from '../common/horizontalBar/HorizontalBar';
import List from '../common/list/List';
import './styles.scss';
import Dot from '../../../ui/dot/Dot';
import useStationaryEmissions, {
  FROM_G_TO_TON,
  FROM_KG_TO_TON
} from './hooks/useStationaryEmissions';
import numberToDecimal, { numberToScientificNotation } from 'utils/numberToDecimal';

type Props = {
  facilities: FacilityReport[];
  categories: string[];
};

function StationaryEmissions({ facilities, categories }: Props) {
  const { t } = useTranslation();

  const {
    groupedFacilitiesCo2e,
    totalCo2e,
    totalCo2,
    totalCH4,
    totalN2O,
    totalCo2eCH4,
    totalCo2eN20,
    totalCo2Biomass,
    remainingFugitiveCo2e,
    remainingStationaryCo2e,
    columns2,
    rows
  } = useStationaryEmissions({
    facilities,
    categories
  });

  return (
    <>
      <div className='stationary-emissions__header'>
        <div className='legend'>
          <Dot type='gradient' />
          <span className='body2-font'>{t('ghgReport.stationary')}</span>
        </div>
        <div className='legend'>
          <Dot type='gray' />
          <span className='body2-font'>{t('ghgReport.fugitive')}</span>
        </div>
        <span className='body2-font'>t CO₂ eq.</span>
      </div>
      {groupedFacilitiesCo2e.length > 0 &&
        remainingStationaryCo2e > 0 &&
        remainingFugitiveCo2e > 0 && (
          <div className='stationary-emissions__bar-chart on-card-gray-bg-color'>
            {groupedFacilitiesCo2e.slice(0, 3).map((facility, index) => (
              <div className='stationary-emissions__bar-chart__item' key={index}>
                <span className='body2-font facility-name'>{`${facility.name.substring(0, 20)}${
                  facility.name.length > 20 ? '...' : ''
                }`}</span>
                <HorizontalBar
                  total={totalCo2e}
                  values={facility.categories
                    .filter((elem) => elem.ghg_gas === 'co2e')
                    .sort((a) => (a.category === 'stationary' ? -1 : 1))
                    .map((elem) => ({
                      value: elem.total_emissions,
                      color:
                        elem.category === 'stationary'
                          ? `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                          : 'rgba(209, 212, 216, 0.5)'
                    }))}
                />
                <span className='body2-font facility-total-co2'>
                  {numberToDecimal(facility.total_emissions / FROM_KG_TO_TON)}
                </span>
              </div>
            ))}
            {remainingStationaryCo2e > 0 ||
              (remainingFugitiveCo2e > 0 && (
                <div className='stationary-emissions__bar-chart__item' key='remaining'>
                  <span className='body2-font facility-name'>{t('ghgReport.remaining')}</span>
                  <HorizontalBar
                    total={totalCo2e}
                    values={[
                      {
                        value: numberToDecimal(remainingStationaryCo2e),
                        color: `linear-gradient(to right, ${highlight_color} 0%, ${highlight_color2} 100%)`
                      },
                      {
                        value: numberToDecimal(remainingFugitiveCo2e),
                        color: 'rgba(209, 212, 216, 0.5)'
                      }
                    ]}
                  />
                  <span className='body2-font facility-total-co2'>
                    {numberToDecimal(
                      (remainingStationaryCo2e + remainingFugitiveCo2e) / FROM_KG_TO_TON
                    )}
                  </span>
                </div>
              ))}
          </div>
        )}
      {/* <ReportTable columns={columns} data={rows} /> */}
      <div className='smaller'>
        <List
          icon={'/images/icons/building.svg'}
          columns={columns2}
          rows={rows}
          totalRow={[
            '',
            numberToScientificNotation(totalN2O / FROM_G_TO_TON),
            numberToScientificNotation(totalCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2 / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2Biomass / FROM_KG_TO_TON),
            numberToScientificNotation(totalCo2eN20 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2eCH4 / FROM_G_TO_TON),
            numberToScientificNotation(totalCo2e / FROM_KG_TO_TON)
          ]}
        />
      </div>
      <div
        style={{
          marginTop: 'auto'
        }}></div>
      <div
        style={{
          marginTop: 'auto'
        }}></div>
    </>
  );
}

export default StationaryEmissions;
