import './style.scss';
import './../style.scss';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useCarouselTranslation } from '../hooks/useCarouselTranslation';
import Line from './line/Line';
import type { Props } from '../StepCarousel';
import useRole from 'customHooks/useRole';

const StepBig = ({ text, isSelected, className, disabled, blocked, error, ...rest }: Props) => {
  const { t } = useCarouselTranslation();

  if (isSelected) {
    className = `${className} selected`;
  } else if (disabled || blocked) {
    className = `${className} blocked`;
  } else if (error) {
    className = `${className} error`;
  }

  const { roleName } = useRole();

  const textComponentBig = blocked ? (
    <TooltipWrapper asChild text={t('general.blockedByRole', { role: roleName })} delay={800}>
      <span className='flex items-center gap-x-1'>
        {t(text)}
        <Icon icon='lock' color='gray-warm' size='medium' />
      </span>
    </TooltipWrapper>
  ) : (
    <span>{t(text)}</span>
  );

  return (
    <div className={className} {...rest}>
      {textComponentBig}
      <Line isSelected={isSelected} isBlocked={blocked || disabled} error={error} />
    </div>
  );
};

export default StepBig;
