import { useTranslation } from 'react-i18next';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import useRole from 'customHooks/useRole';

const useCategoryOptions = () => {
  const flags = useFeatureFlags();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard'
  });
  const { t: tGeneral } = useTranslation('translation', {
    keyPrefix: 'general'
  });

  const { permissions, roleName } = useRole();

  const disableSocialAndGovernance = !permissions?.find(
    (permission: Permission) => permission.tag === PermissionTags.EMISSION_SOCIAL_GOVERNANCE_ALL
  );
  const categoryOptions = [
    {
      id: 'environmental',
      name: t('environmental')
    },
    {
      id: 'socialAndGovernance',
      name: t('socialAndGovernance'),
      blocked: disableSocialAndGovernance,
      tooltip: disableSocialAndGovernance
        ? tGeneral('blockedByRole', { role: roleName })
        : undefined
    }
  ];

  if (flags?.showControlPanel) {
    categoryOptions.push({
      id: 'controlPanel',
      name: t('controlPanel')
    });
  }
  return categoryOptions;
};

export default useCategoryOptions;
