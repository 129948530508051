import { getUrl } from 'utils/url';
import apiFetch from '../../utils/apiFetch';
import { User } from 'types/entities/user';

export const checkMembership = async (user_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/memberships/users/${user_id}/organizations/${organization_id}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteMembership = async (user_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/v1/organizations/${organization_id}/users/${user_id}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getMembershipsPaginated = async (urlOptions?: URLOptions) => {
  try {
    const response = await apiFetch('GET', getUrl('/v1/memberships', urlOptions));

    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateFundMembership = async (organization_id: string, role: string) => {
  try {
    const response = await apiFetch('PUT', '/memberships/fund', {
      organization_id,
      role
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const addMembership = async (
  email: string,
  organization_id: string,
  role_id: string,
  send_email = true,
  lang: string
) => {
  try {
    const response = await apiFetch('POST', '/v1/memberships', {
      email,
      organization_id,
      role_id,
      send_email,
      lang
    });
    return { ...response.data, isInvitation: response.data.status === 'pending' } as User;
  } catch (err) {
    return null;
  }
};

export const editMembership = async (user_id: string, organization_id: string, role_id: string) => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/v1/organizations/${organization_id}/users/${user_id}`,
      {
        role_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};
