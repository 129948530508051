export const ADMIN_ROUTES = {
  ADMIN: '/admin',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_USERS: '/admin/users',
  ADMIN_PURCHASES: '/admin/purchases',
  ADMIN_PURCHASES_CODE_MANAGEMENT: '/admin/purchases/code-management',
  ADMIN_EMAILS: '/admin/emails',
  ADMIN_SHIPMENTS: '/admin/shipments',
  ADMIN_BUSINESS_TRAVEL: '/admin/travels',
  ADMIN_LCA: '/admin/lca',
  ADMIN_LCA_SCENARIOS: '/admin/scenarios_lca',
  ADMIN_INVOFOX: '/admin/invofox',
  ADMIN_SNIPPET_UPLOAD: '/admin/snippet-upload',
  ADMIN_VEHICLE_CONSUMPTIONS: '/admin/vehicle-consumptions',
  ADMIN_TASKS: '/admin/tasks',
  ADMIN_CLEAN_TOTAL_IMPACTS: '/admin/clean-total-impacts'
};
