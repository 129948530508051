import { ReactNode } from 'react';
import TooltipWrapper from './TooltipWrapper';
import './styles.scss';
import Icon from '../icon/Icon';
import { IconName } from 'types/utils/iconName';

type Props = {
  text: string | ReactNode;
  asChild?: boolean;
  position?: 'top' | 'right' | 'left';
  icon?: string;
  iconV2?: IconName;
  size?: string;
  containerPosition?: DOMRect;
  style?: React.CSSProperties;
  blocked?: boolean;
};

const Tooltip = ({ icon, iconV2 = 'information', style, blocked, ...rest }: Props) => {
  if (icon) {
    return (
      <TooltipWrapper {...rest} style={style} shouldAppear={!blocked}>
        <img src={icon} alt='info-icon' className='w-3 h-3 pointer' />
      </TooltipWrapper>
    );
  }

  return (
    <TooltipWrapper {...rest} style={style} shouldAppear={!blocked}>
      <Icon
        icon={iconV2}
        color={blocked ? 'gray-warm' : 'pending'}
        size='small'
        className={blocked ? '' : 'pointer'}
      />
    </TooltipWrapper>
  );
};

const Uncontrolled = ({ icon = '/images/icons/info.svg', style, ...rest }: Props) => {
  return (
    <TooltipWrapper.Uncontrolled {...rest} style={style}>
      <img src={icon} alt='info-icon' className='w-3 h-3 pointer' />
    </TooltipWrapper.Uncontrolled>
  );
};

Tooltip.Uncontrolled = Uncontrolled;

export default Tooltip;
