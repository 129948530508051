import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { HEAVY_FILE_STATUS } from '../../../../types/entitiesEnums/heavyFilesStatus';
import { CATEGORIES_TRANSLATION, CategoriesUploadedFiles } from '../constants';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import { StepText } from 'types/stepCarousel';
import useRole from 'customHooks/useRole';

const useConstants = () => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const { permissions } = useRole();

  const selectOptions: SelectOptionFormat[] = [
    { id: HEAVY_FILE_STATUS.ERROR, name: t('status.error') },
    { id: HEAVY_FILE_STATUS.IN_PROGRESS, name: t('status.pending') },
    { id: HEAVY_FILE_STATUS.IN_QUEUE, name: t('status.queue') },
    { id: HEAVY_FILE_STATUS.SUCCESS, name: t('status.success') },
    { id: HEAVY_FILE_STATUS.DELETED, name: t('status.deleted') },
    { id: HEAVY_FILE_STATUS.DELETING, name: t('status.deleting') },
    { id: '', name: t('status.all') }
  ];

  const steps: StepText[] = [
    {
      id: CategoriesUploadedFiles.VEHICLES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.VEHICLES]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_VEHICLE_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_VEHICLE_ALL
      )
    },

    {
      id: CategoriesUploadedFiles.BUSINESS_TRAVELS,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.BUSINESS_TRAVELS]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_BUSINESS_TRAVEL_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM]),
      blocked: !permissions?.find(
        (permission: Permission) =>
          permission.tag === PermissionTags.EMISSION_TRANSPORT_DOWNSTREAM_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.TRANSPORT_UPSTREAM,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.TRANSPORT_UPSTREAM]),
      blocked: !permissions?.find(
        (permission: Permission) =>
          permission.tag === PermissionTags.EMISSION_TRANSPORT_UPSTREAM_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.PURCHASES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.PURCHASES]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_PURCHASE_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.WASTES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.WASTES]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.EMISSION_WASTE_ALL
      )
    },
    {
      id: CategoriesUploadedFiles.ESG,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.ESG]),
      blocked: !permissions?.find(
        (permission: Permission) => permission.tag === PermissionTags.CSRD_SENSITIVE_ALL
      )
    }
  ];

  if (selectedOrganization?.sector === 'transport') {
    steps.push(
      {
        id: CategoriesUploadedFiles.LOGISTICS_RECHARGES,
        text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.LOGISTICS_RECHARGES])
      },
      {
        id: CategoriesUploadedFiles.LOGISTICS_REQUESTS,
        text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.LOGISTICS_REQUESTS])
      }
    );
  }
  return { selectOptions, steps, categories: CategoriesUploadedFiles };
};

export default useConstants;
