import { useEffect, useState } from 'react';
import { OutputData, outputData as initialOutputData } from '../data/outputData';
import { BaseData, baseData as initialBaseData } from '../data/baseData';
import { useColumns } from './useColumns';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

type UseBaseOutputDataProps = {
  selectedData: 'baseData' | 'outputData';
  searchValue: string;
  onVerify: () => void;
};

export const useBaseOutputData = ({
  selectedData,
  searchValue,
  onVerify
}: UseBaseOutputDataProps) => {
  const [baseData, setBaseData] = useState<BaseData[]>(initialBaseData);
  const [outputData, setOutputData] = useState<OutputData[]>(initialOutputData);
  const columns = useColumns({ selectedData, onVerify });
  const org = useSelectedOrganization();
  const { frameworkPlans } = useFrameworksPlans(org?.id);

  useEffect(() => {
    if (!frameworkPlans?.some((plan) => plan.purchased && plan.visible)) {
      setBaseData([]);
      setOutputData([]);
      return;
    }

    setBaseData(
      initialBaseData.filter((item) => item.data.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setOutputData(
      initialOutputData.filter((item) =>
        item.data.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [selectedData, searchValue, frameworkPlans]);

  return {
    data: selectedData === 'baseData' ? baseData : outputData,
    columns,
    totalToComplete:
      selectedData === 'baseData'
        ? baseData.filter((item) => item.status !== 'COMPLETE').length
        : outputData.filter((item) => item.status !== 'COMPLETE').length,
    totalCompleted:
      selectedData === 'baseData'
        ? baseData.filter((item) => item.status === 'COMPLETE').length
        : outputData.filter((item) => item.status === 'COMPLETE').length
  };
};
