import SuccessLabel from 'components/ui/statusLabels/successLabel/SuccessLabel';
import { TAG_STATUS } from './constants';
import { AssignedTagStatus } from './types';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import ErrorLabel from 'components/ui/statusLabels/errorLabel/ErrorLabel';
import { useTranslation } from 'react-i18next';

type Props = {
  status: AssignedTagStatus;
  totalValue: number;
  max?: number;
  keyPrefix?: string;
};

export const AssignedTag: React.FC<Props> = ({
  status,
  totalValue,
  max = 100,
  keyPrefix = 'facilities.assignedTag'
}) => {
  const { t } = useTranslation('translation', { keyPrefix });

  if (status === TAG_STATUS.SUCCESS) {
    return <SuccessLabel>{t('success', { count: totalValue })}</SuccessLabel>;
  }

  if (status === TAG_STATUS.WARNING) {
    const leftOver = max - totalValue;
    return <WarningLabel>{t('warning', { count: totalValue, leftOver, max })}</WarningLabel>;
  }

  const leftOver = totalValue - max;
  return <ErrorLabel>{t('error', { count: totalValue, leftOver, max })}</ErrorLabel>;
};
