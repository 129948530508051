import { User } from 'types/entities/user';
import apiFetch from '../../utils/apiFetch';

export const getUserById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserLoggedIn = async () => {
  try {
    const response = await apiFetch('GET', '/v1/users/me/all');

    return response.data as User;
  } catch (error) {
    console.error(error);
    return null;
  }
};
