import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../../context/userContext';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import {
  getTreatmentById,
  patchTreatment,
  postTreatment
} from '../../../../../../../../services/api/treatment';
import { InvoiceType } from '../../../../../../../../types/entities/invoice';
import checkFormErrors from '../../../../../../../../utils/checkFormErrors';
import { formatDate } from '../../../../../../../../utils/formatDate';
import Button from '../../../../../../../ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import InputCalendar from 'components/ui/formComponents/inputCalendar/InputCalendar';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import CustomSkeletonLoader from '../../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import './styles.scss';
import useUnits from './useUnits';
import FormElementFull from 'components/ui/formComponents/formElementFull/FormElementFull';
import StepCarouselList from 'components/ui/stepCarouselList/StepCarouselList';
import useStepCarousel from 'components/ui/stepCarouselList/useStepCarousel';
import ContainerBox from 'components/ui/formComponents2/inputUtils/inputWrapper/components/ContainerBox';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';

export type FormDataTreatment = {
  name: string;
  startDate: string;
  endDate: string;
  m3WaterIn: number | undefined;
  kgBodPerM3WwtLine: number | undefined;
  kgRwwtLine: number | undefined;
  kgNPerM3WwtLine: number | undefined;
  kgSludge: number | undefined;
  kgBodPerKgSludgeLine: number | undefined;
  kgRSludgeLine: number | undefined;
  m3WaterOut: number | undefined;
  kgBodPerM3WwdLine: number | undefined;
  kgNPerM3WwdLine: number | undefined;
  fileUrl: string;
  facilityId: string;
  status: string;
  errors: ErrorType[];
};
export type TreatmentUnits = {
  caudalUnits: Unit[];
  dboUnits: Unit[];
  recoveredMethaneUnits: Unit[];
  nitrogenUnits: Unit[];
};
type Props = {
  addInvoice?: (value: InvoiceType) => void;
  editInvoice?: (id: string, invoice: InvoiceType) => void;
  id?: string;
  facilityId: string;
  onClose: () => void;
  viewOnly?: boolean;
};

const Treatment = ({ addInvoice, editInvoice, id, facilityId, onClose, viewOnly }: Props) => {
  const formDataEmpty: FormDataTreatment = {
    name: '',
    startDate: '',
    endDate: '',
    m3WaterIn: undefined,
    kgBodPerM3WwtLine: undefined,
    kgRwwtLine: undefined,
    kgNPerM3WwtLine: undefined,
    kgSludge: undefined,
    kgBodPerKgSludgeLine: undefined,
    kgRSludgeLine: undefined,
    m3WaterOut: undefined,
    kgBodPerM3WwdLine: undefined,
    kgNPerM3WwdLine: undefined,
    fileUrl: '',
    facilityId: facilityId,
    status: '',
    errors: []
  };

  const user = useContext(UserContext);
  const { t } = useTranslation();
  const { units } = useUnits();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [formData, setFormData] = useState<FormDataTreatment>(formDataEmpty);
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [methaneError, setMethaneError] = useState(false);
  const [tabError, setTabError] = useState<string[]>([]);
  const fieldsWwt = ['m3WaterIn', 'kgBodPerM3WwtLine', 'kgRwwtLine', 'kgNPerM3WwtLine'];
  const fieldsSludge = ['kgSludge', 'kgBodPerKgSludgeLine', 'kgRSludgeLine'];
  const fieldsWwd = ['m3WaterOut', 'kgBodPerM3WwdLine', 'kgNPerM3WwdLine'];
  const tabNames: ['wwt', 'sludge', 'wwd'] = ['wwt', 'sludge', 'wwd'];
  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    handleNext,
    handleBack,
    steps,
    isFirstElement,
    isLastElement
  } = useStepCarousel({
    stepsText: [
      { id: 'wwt', text: 'treatment.wwt' },
      {
        id: 'sludge',
        text: 'treatment.sludge'
      },
      {
        id: 'wwd',
        text: 'treatment.wwd'
      }
    ]
  });
  useEffect(() => {
    const getTreatmentToEdit = async () => {
      if (!user?.selectedOrganization || !id) return;

      setLoadingData(true);
      const response = await getTreatmentById(id, user.selectedOrganization);
      if (response?.response?.status >= 400) {
        setLoadingData(false);
        return;
      }

      setFormData({
        name: response.name,
        startDate: formatDate(new Date(response.start_date)),
        endDate: formatDate(new Date(response.end_date)),
        m3WaterIn: response.m3_water_in,
        kgBodPerM3WwtLine: response.kg_bod_per_m3_wwt_line,
        kgRwwtLine: response.kg_r_wwt_line,
        kgNPerM3WwtLine: response.kg_n_per_m3_wwt_line,
        kgSludge: response.kg_sludge,
        kgBodPerKgSludgeLine: response.kg_bod_per_kg_sludge_line,
        kgRSludgeLine: response.kg_r_sludge_line,
        m3WaterOut: response.m3_water_out,
        kgBodPerM3WwdLine: response.kg_bod_per_m3_wwd_line,
        kgNPerM3WwdLine: response.kg_n_per_m3_wwd_line,
        fileUrl: response.file_url,
        facilityId: response.facility_id,
        status: response.status,
        errors: []
      });
      setLoadingData(false);
    };

    getTreatmentToEdit();
  }, [units, id, user?.selectedOrganization]);
  const handleErrors = () => {
    const optionalFields = [
      'errors',
      'name',
      'kgRwwtLine',
      'kgRSludgeLine',
      'fileUrl',
      'facilityId',
      'status'
    ];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields, t);
    const newTabErrors: string[] = [];
    // check if the tab has errors on the fields it contains
    for (const tab of tabNames) {
      const fieldsForTab = eval(`fields${tab.charAt(0).toUpperCase() + tab.slice(1)}`);
      if (newErrors.some((error) => fieldsForTab.includes(error.error))) {
        newTabErrors.push(tab);
      }
    }
    setTabError(newTabErrors);
    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleCreateTreatment = async () => {
    if (!!handleErrors() || !user?.selectedOrganization || !user?.id || !addInvoice) return;
    setLoadingButton(true);

    const response = await postTreatment(formData, user.selectedOrganization, facilityId);
    if (
      response?.response?.status === 400 &&
      response?.response?.data === 'CH4_EMISSIONS_CANNOT_BE_NEGATIVE'
    ) {
      setMethaneError(true);
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'negative_ch4',
          description: t('treatment.negativeCh4')
        })
      }));
    }

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }
    const newInvoice: InvoiceType = {
      id: response.id,
      unit: units.caudalUnits?.find((unit) => unit.id === response.quantity_unit_id) || {
        id: '',
        name: '',
        type: ''
      },
      quantity: response.m3_water_in,
      kg_sludge: response.kg_sludge,
      m3_water_out: response.m3_water_out,
      invoice_id: response.name,
      cups: '',
      type: 'waste_water_treatment',
      status: response.status,
      start_date: new Date(response.start_date).toDateString(),
      end_date: new Date(response.end_date).toDateString(),
      user: {
        first_name: response.uploaded_by
      },
      percentage: response.percentage,
      facility_percentages: response.facility_percentages ?? [],
      base_quantity: response.base_quantity ?? response.incoming_water_quantity
    };
    addInvoice(newInvoice);
    setLoadingButton(false);
  };

  const handleEditTreatment = async () => {
    if (!!handleErrors() || !user?.selectedOrganization || !user?.id || !id || !editInvoice) return;
    setLoadingButton(true);
    const response = await patchTreatment(id, formData, user.selectedOrganization);
    if (
      response?.response?.status === 400 &&
      response?.response?.data === 'CH4_EMISSIONS_CANNOT_BE_NEGATIVE'
    ) {
      setMethaneError(true);
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'negative_ch4',
          description: t('treatment.negativeCh4')
        })
      }));
    }

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }
    const newInvoice: InvoiceType = {
      id: response.id,
      unit: {
        id: '',
        name: '',
        type: ''
      },
      quantity: response.m3_water_in,
      kg_sludge: response.kg_sludge,
      m3_water_out: response.m3_water_out,
      invoice_id: response.name,
      cups: '',
      type: 'waste_water_treatment',
      status: response.status,
      start_date: new Date(response.start_date).toDateString(),
      end_date: new Date(response.end_date).toDateString(),
      user: {
        first_name: response.uploaded_by
      },
      percentage: response.percentage,
      facility_percentages: response.facility_percentages ?? [],
      base_quantity: response.base_quantity ?? response.incoming_water_quantity
    };
    editInvoice(id, newInvoice);
    setLoadingButton(false);
  };

  const handleNextStep = () => handleChangeTab();
  useEffect(() => {
    if (methaneError) {
      setMethaneError(false);
      setFormData((prev) => ({
        ...prev,
        errors: []
      }));
    }
  }, [
    formData.kgBodPerKgSludgeLine,
    formData.kgBodPerM3WwdLine,
    formData.kgBodPerM3WwtLine,
    formData.kgNPerM3WwdLine,
    formData.kgNPerM3WwtLine,
    formData.kgRSludgeLine,
    formData.kgRwwtLine,
    formData.kgSludge,
    formData.m3WaterIn,
    formData.m3WaterOut
  ]);
  const handleChangeTab = (id?: string) => {
    let optionalFields = [
      'errors',
      'name',
      'kgRwwtLine',
      'kgRSludgeLine',
      'fileUrl',
      'facilityId',
      'status'
    ];
    // Check if the selected tab is already in the tabError state
    if (id && !tabError.includes(id)) {
      // Add fields for the selected tab to the optionalFields array
      let fieldsForTab: string[] = [];
      switch (id) {
        case 'wwt':
          fieldsForTab = fieldsWwt;
          break;
        case 'sludge':
          fieldsForTab = fieldsSludge;
          break;
        case 'wwd':
          fieldsForTab = fieldsWwd;
          break;
        default:
          break;
      }

      // Add these fields to optionalFields, ensuring no duplicates
      optionalFields = [...optionalFields, ...fieldsForTab].filter(
        (value, index, self) => self.indexOf(value) === index
      );
    }
    const errors = checkFormErrors(formData, formData.errors, optionalFields, t);

    // Update form errors
    let tabsWithErrors = [...tabError];
    if (errors.length) {
      for (const tab of tabNames) {
        const fieldsForTab = eval(`fields${tab.charAt(0).toUpperCase() + tab.slice(1)}`);
        if (!errors.some((error) => fieldsForTab.includes(error.error))) {
          tabsWithErrors = tabsWithErrors.filter((t) => t !== tab); // Remove tab if no errors for the tab
        }
      }
      setTabError(tabsWithErrors);
    } else {
      // If no errors, remove current step's tab error
      setTabError((prev) => prev.filter((tab) => tab !== (stepSelected?.id || 'wwt')));
    }
    // compare formData.errors with errors and only remove from formData.errors the errors that are not in errors, don't add new errors
    setFormData((prev) => ({
      ...prev,
      errors: prev.errors.filter((error) => errors.some((e) => e.error === error.error))
    }));
    if (id) {
      handleSelectCarousel(id);
    } else {
      handleNext();
    }
  };
  if (loadingData) return <CustomSkeletonLoader count={2} />;
  return (
    <>
      <FormWrapper>
        <InputWrapper iconV2='road' label={t('treatment.nameLabel')} disabled={viewOnly}>
          <InputText
            value={formData.name}
            onChange={onChangeValue('name')}
            placeholder={t('treatment.writeName')}
            disabled={viewOnly}
          />
        </InputWrapper>
        <InputCalendar
          mode='range'
          label={t('treatment.date')}
          startDateValue={formData.startDate}
          handleChangeStartDate={onChangeValue('startDate')}
          endDateValue={formData.endDate}
          handleChangeEndDate={onChangeValue('endDate')}
          error={formData.errors.find(
            (error) => error.error === 'startDate' || error.error === 'endDate'
          )}
          disabled={viewOnly}
        />
        <FormElementFull>
          <StepCarouselList
            steps={steps}
            handleSelect={handleChangeTab}
            lookAndFeel={'big'}
            errors={tabError}
          />
        </FormElementFull>
        {(stepSelected?.id === 'wwt' || !stepSelected) && (
          <>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.treatedWastewaterVolume')}
              error={formData.errors.find((error) => error.error === 'm3WaterIn')}
              disabled={viewOnly}>
              <InputText
                value={formData.m3WaterIn}
                placeholder={t('treatment.treatedVolume')}
                onChange={onChangeValue('m3WaterIn')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.m3')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.influentBODLoad')}
              error={formData.errors.find((error) => error.error === 'kgBodPerM3WwtLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgBodPerM3WwtLine}
                placeholder={t('treatment.bodLoad')}
                onChange={onChangeValue('kgBodPerM3WwtLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kgBODPerM3')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.methaneRecoveredAtPlantOptional')}
              error={formData.errors.find((error) => error.error === 'kgRwwtLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgRwwtLine}
                placeholder={t('treatment.quantity')}
                onChange={onChangeValue('kgRwwtLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.methaneKg')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.nitrogenPerM3InWaterTreatmentLine')}
              error={formData.errors.find((error) => error.error === 'kgNPerM3WwtLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgNPerM3WwtLine}
                placeholder={t('treatment.load')}
                onChange={onChangeValue('kgNPerM3WwtLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kgNitrogenPerM3')}</ContainerBox>
            </InputWrapper>
          </>
        )}
        {stepSelected?.id === 'sludge' && (
          <>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.drySludgeTotalWeight')}
              error={formData.errors.find((error) => error.error === 'kgSludge')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgSludge}
                placeholder={t('treatment.totalWeight')}
                onChange={onChangeValue('kgSludge')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kg')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.dboSludgeLoad')}
              error={formData.errors.find((error) => error.error === 'kgBodPerKgSludgeLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgBodPerKgSludgeLine}
                placeholder={t('treatment.bodLoad')}
                onChange={onChangeValue('kgBodPerKgSludgeLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kgBODPerKgSludge')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              className='treatment-last-child-unit'
              iconV2='vat'
              label={t('treatment.methaneRecoveredFromSludgeTreatmentOptional')}
              error={formData.errors.find((error) => error.error === 'kgRSludgeLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgRSludgeLine}
                placeholder={t('treatment.enterKgs')}
                onChange={onChangeValue('kgRSludgeLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.methaneKg')}</ContainerBox>
            </InputWrapper>
          </>
        )}
        {stepSelected?.id === 'wwd' && (
          <>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.dischargedTreatedWaterVolume')}
              error={formData.errors.find((error) => error.error === 'm3WaterOut')}
              disabled={viewOnly}>
              <InputText
                value={formData.m3WaterOut}
                placeholder={t('treatment.dischargeVolume')}
                onChange={onChangeValue('m3WaterOut')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.m3')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              iconV2='vat'
              label={t('treatment.effluentBODLoad')}
              error={formData.errors.find((error) => error.error === 'kgBodPerM3WwdLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgBodPerM3WwdLine}
                placeholder={t('treatment.bodLoad')}
                onChange={onChangeValue('kgBodPerM3WwdLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kgBODPerM3')}</ContainerBox>
            </InputWrapper>
            <InputWrapper
              className='treatment-last-child-unit'
              iconV2='vat'
              label={t('treatment.nitrogenPerM3InWaterDischargeLine')}
              error={formData.errors.find((error) => error.error === 'kgNPerM3WwdLine')}
              disabled={viewOnly}>
              <InputText
                value={formData.kgNPerM3WwdLine}
                placeholder={t('treatment.load')}
                onChange={onChangeValue('kgNPerM3WwdLine')}
                type='number'
                disabled={viewOnly}
              />
              <ContainerBox className='font-12'>{t('treatment.kgNitrogenPerM3')}</ContainerBox>
            </InputWrapper>
          </>
        )}
      </FormWrapper>
      {methaneError && (
        <div className='mt-6 mb-6'>
          <WarningLabel showIcon={true}>
            {formData.errors.find((error) => error.error === 'negative_ch4')?.description}
          </WarningLabel>
        </div>
      )}
      {!viewOnly && (
        <FormButtonSection>
          {isFirstElement ? (
            <Button
              lookAndFeel='secondary'
              text={tGeneral('closeWithoutSaving')}
              size='small'
              onClick={onClose}
            />
          ) : (
            <Button
              lookAndFeel='secondary'
              text={tGeneral('previous')}
              size='small'
              onClick={handleBack}
            />
          )}
          {isLastElement ? (
            <Button
              lookAndFeel='primary'
              text={t('treatment.confirm')}
              onClick={addInvoice ? handleCreateTreatment : handleEditTreatment}
              size='small'
              loading={loadingButton}
            />
          ) : (
            <Button
              lookAndFeel='primary'
              text={tGeneral('next')}
              size='small'
              onClick={handleNextStep}
            />
          )}
        </FormButtonSection>
      )}
    </>
  );
};

export default Treatment;
