import { BlueTag } from 'components/ui/blueTag/BlueTag';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import Icon from 'components/ui/icon/Icon';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { UserContext } from 'context/userContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PredefinedRoleIds } from 'types/entitiesEnums/roles';
import { User } from 'types/entities/user';
import { Column } from 'types/table';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import useRole from 'customHooks/useRole';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const { permissions } = useRole();
  const user = useContext(UserContext);
  const [memberToEdit, setMemberToEdit] = useState<User>();
  const [memberToDelete, setMemberToDelete] = useState<User>();

  const columns: Column[] = [
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      render: (_: unknown, member: User) => (
        <div className='flex items-center gap-1'>
          <Icon icon='user' color='gray' />
          {member.email}
          {member.isInvitation && (
            <Tooltip
              text={t('pendingInvite')}
              position='right'
              style={{ display: 'flex', alignItems: 'center' }}
            />
          )}
        </div>
      )
    },
    {
      title: t('firstName'),
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: t('lastName'),
      dataIndex: 'last_name',
      key: 'last_name',
      render: (text: string) => {
        return text ? text : '-';
      }
    },
    {
      title: t('phoneNumber'),
      dataIndex: 'phone_number',
      key: 'phone_number'
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => {
        return new Date(created_at).toLocaleDateString();
      }
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: (_: unknown, member: User) => {
        const isPredefined = member.role_id
          ? Object.values(PredefinedRoleIds).includes(member.role_id as PredefinedRoleIds)
          : false;
        return (
          <BlueTag disabled={member.isInvitation} highlighted={member.role === 'Administrator'}>
            {isPredefined && member.role
              ? t(`manageRoles.predefinedRoles.${member.role.toLowerCase()}`)
              : member.role}
          </BlueTag>
        );
      }
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_: unknown, member: User) => {
        const hasManageUsersPermission = !!permissions?.find(
          (permission: Permission) => permission.tag === PermissionTags.AUTH_USERS_ALL
        );
        const disabledEdit = (user && member.id === user.id) || !hasManageUsersPermission;

        return (
          <ButtonDropdown
            button={!disabledEdit && <Icon icon='elipsis_horizontal' color={'gray-dark'} />}
            options={[
              {
                id: `${member.id}-edit`,
                name: t('editRole'),
                onClick: () => {
                  setMemberToEdit(member);
                }
              },
              {
                id: `${member.id}-delete`,
                name: t('delete'),
                onClick: () => {
                  setMemberToDelete(member);
                }
              }
            ]}
            disabled={disabledEdit}
          />
        );
      }
    }
  ];
  return {
    columns,
    memberToEdit,
    memberToDelete,
    setMemberToEdit,
    setMemberToDelete
  };
};

export default useColumns;
