import InputNumber from '../../inputNumber/InputNumber';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type InputNumberType = React.ComponentProps<typeof InputNumber>;

type Props = InputNumberType & InputWrapperType;

const FormNumber = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <InputNumber {...props} />
    </InputWrapper>
  );
};

const WithOverlay = (props: Props & { overlay: string }) => {
  return (
    <InputWrapper {...props}>
      <InputNumber.WithOverlay {...props} />
    </InputWrapper>
  );
};

export default Object.assign(FormNumber, { WithOverlay });
