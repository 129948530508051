import Modal from 'components/ui/modal/Modal';
import { useState } from 'react';
import { createRole, deleteRole, editRole } from 'services/api/roles';
import ManageRoles from './ManageRoles';
import DeleteRole from './components/deleteRole/DeleteRole';
import useGetData from 'components/members/hooks/useGetData';
import CreateRoleWrapper, { FormData } from './components/createOrEditRole/CreateRoleWrapper';
import EditRoleWrapper from './components/createOrEditRole/EditRoleWrapper';

type Props = {
  showManageRoles: boolean;
  setShowManageRoles: (show: boolean) => void;
  roles: Role[];
  setRoles: (roles: Role[]) => void;
  isLoading: boolean;
};

const ManageRolesWrapper = ({
  showManageRoles,
  setShowManageRoles,
  roles,
  setRoles,
  isLoading
}: Props) => {
  const [showCreateRole, setShowCreateRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<Role>();
  const [roleToDelete, setRoleToDelete] = useState<Role>();

  const { members } = useGetData({});

  const handleClose = () => {
    if (roleToEdit) setRoleToEdit(undefined);
    else if (showCreateRole) setShowCreateRole(false);
    else if (roleToDelete) setRoleToDelete(undefined);
  };

  const handleAddRole = async (formData: FormData) => {
    const role: Role = await createRole({
      name: formData.name,
      description: '',
      permissions: formData.permissions
    });

    setRoles([role, ...roles]);
    handleClose();
  };

  const handleEditRole = async (formData: FormData) => {
    if (!roleToEdit) return;

    const permissionsToAdd = formData.permissions.filter(
      (permission) => !roleToEdit.permissions.find((p: Permission) => p.id === permission)
    );
    const permissionsToDelete = roleToEdit.permissions
      .filter((permission: Permission) => !formData.permissions.find((p) => p === permission.id))
      .map((p: Permission) => p.id);
    const newRole: Role = await editRole(
      {
        name: formData.name,
        description: '',
        permissions_to_add: permissionsToAdd,
        permissions_to_delete: permissionsToDelete
      },
      roleToEdit.id
    );
    setRoles(roles.map((r) => (r.id === newRole.id ? newRole : r)));
    handleClose();
  };

  const handleDeleteRole = async () => {
    if (!roleToDelete) return;
    const response = await deleteRole(roleToDelete.id);
    if (!response) return;
    setRoles(roles.filter((role) => role.id !== roleToDelete.id));
  };

  if (roleToEdit) {
    return (
      <Modal.WithPortal show={!!roleToEdit} onClose={handleClose} width='660px' maxWidth='660px'>
        <EditRoleWrapper onClose={handleClose} handleSubmit={handleEditRole} role={roleToEdit} />
      </Modal.WithPortal>
    );
  }

  if (showCreateRole) {
    return (
      <Modal.WithPortal show={showCreateRole} onClose={handleClose} width='660px' maxWidth='660px'>
        {showCreateRole && <CreateRoleWrapper onClose={handleClose} handleSubmit={handleAddRole} />}
      </Modal.WithPortal>
    );
  }

  if (roleToDelete) {
    return (
      <Modal.WithPortal show={!!roleToDelete} onClose={handleClose} width='590px' maxWidth='590px'>
        <DeleteRole
          onClose={handleClose}
          handleDelete={handleDeleteRole}
          roleName={roleToDelete.name}
        />
      </Modal.WithPortal>
    );
  }

  return (
    <Modal.WithPortal
      show={showManageRoles}
      onClose={() => setShowManageRoles(false)}
      width='900px'
      maxWidth='900px'>
      <ManageRoles
        setRoleToEdit={(role: Role) => {
          setRoleToEdit(role);
        }}
        setShowCreateRole={(show: boolean) => {
          setShowCreateRole(show);
        }}
        setRoleToDelete={(role: Role) => {
          setRoleToDelete(role);
        }}
        members={members}
        roles={roles}
        isLoading={isLoading}
      />
    </Modal.WithPortal>
  );
};

export default ManageRolesWrapper;
