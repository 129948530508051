import { ReactNode, createContext, useEffect } from 'react';
import { ROUTES } from '../../../../constants/routes';
import InteractiveTutorialTool from '../../interactiveTutorialTool/InteractiveTutorialTool';
import useTutorialSteps from './hooks/useTutorialSteps';
import { sessionStorageGetItem } from '../../../../utils/sessionStorage';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';

type Props = {
  children: ReactNode;
};

interface Tutorial {
  active: boolean;
  handleStart: () => void;
}

export const TutorialContext = createContext<Tutorial | null>(null);

const InteractiveTutorial = ({ children }: Props) => {
  // const user = useContext(UserContext);
  // const selectedOrganization = useSelectedOrganization();
  const { currentStep, handleStart } = useTutorialSteps();
  const organization = useSelectedOrganization();

  // const isMainTutorialCompleted = localStorageGetItem('isMainTutorialCompleted');

  // useEffect(() => {
  //   // Get session storage welcome modal show
  //   const showWelcomeModal = organization?.milestone ? false : true;
  //   if (!showWelcomeModal || window.location.pathname !== ROUTES.IMPROVE_DASHBOARD) return;

  //   handleStart();
  // }, [window.location.pathname]);

  return (
    <TutorialContext.Provider value={{ active: !!currentStep, handleStart }}>
      <InteractiveTutorialTool current={currentStep} show={!!currentStep}>
        {children}
      </InteractiveTutorialTool>
    </TutorialContext.Provider>
  );
};

export default InteractiveTutorial;
