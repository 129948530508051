import { useQuery } from '@tanstack/react-query';
import { getRdCodesByLowId } from 'services/api/waste';

type UseRdCodesParams<T extends object> = {
  lowId?: string;
  adapter?: (data: RdCode[]) => T[];
};

export const useRdCodes = <T extends object = RdCode>({ lowId, adapter }: UseRdCodesParams<T>) => {
  const { data, isLoading } = useQuery({
    queryKey: ['rd-codes', lowId],
    queryFn: () => getRdCodesByLowId({ lowId }),
    select: adapter
  });

  return { rdCodes: data ?? [], isLoading };
};
