import TooltipWrapper from '../../tooltip/TooltipWrapper';
import SlideToggle3 from './SlideToggle3';
import ToggleElement from './ToggleElement';

type ToggleOption = {
  name: React.ReactNode;
  id: string;
  tooltip?: string;
  blocked?: boolean;
};

interface Props extends React.ComponentProps<typeof SlideToggle3> {
  handleSwitchMode: (value: string) => void;
  idSelected: string;
  options: ToggleOption[];
}

const SlideToggleFacade = ({ handleSwitchMode, options, idSelected, ...rest }: Props) => {
  const handleOnClickElement = (id: string) => {
    handleSwitchMode(id);
  };
  return (
    <SlideToggle3 {...rest}>
      {options.map((option) => {
        const toogleOption = (
          <ToggleElement
            id={option.id}
            handleOnClick={handleOnClickElement}
            selected={idSelected === option.id}
            name={option.name}
            blocked={option.blocked}
          />
        );

        return option.tooltip ? (
          <TooltipWrapper
            text={option.tooltip}
            position='top'
            key={option.id}
            delay={option.blocked ? 800 : undefined}>
            {toogleOption}
          </TooltipWrapper>
        ) : (
          toogleOption
        );
      })}
    </SlideToggle3>
  );
};

export default SlideToggleFacade;
