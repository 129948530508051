import { Fragment, MouseEvent, ReactNode } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
import './styles.scss';

type ChildrenLayoutProps = {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
};
const ChildrenLayout: React.FC<ChildrenLayoutProps> = ({ children, disabled, className }) => {
  const classes = ['input-element-wrapper-2'];

  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

type Props = {
  leftSection?: ReactNode;
  error?: ErrorType;
  disabled?: boolean;
  size?: InputSize;
  width?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode[] | ReactNode;
};

const InputWrapperPlainLabel = ({
  leftSection,
  error,
  disabled = false,
  size = InputSize.MEDIUM,
  width,
  onClick,
  children
}: Props) => {
  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) e.stopPropagation();
    if (!onClick) return;
    onClick(e);
  };

  const filteredChildren =
    children && Array.isArray(children) ? children.filter((node: any) => !!node) : [];

  return (
    <button
      type='button'
      className={`
        input-wrapper-plain flex ${size}
        ${disabled && !error ? 'input-disabled-border-color' : ''}
        ${disabled ? 'input-disabled-bg-color' : 'bg-white'}
        ${error ? 'error-border-color' : 'input-border-color'}
      `}
      onClick={(e) => handleOnClick(e as MouseEvent<HTMLButtonElement>)}
      style={{ minWidth: width, width }}>
      {leftSection && (
        <div
          className={`image-wrapper-input ${
            error ? 'error-border-color' : 'input-border-color'
          } ${size}`}>
          {leftSection}
        </div>
      )}
      {filteredChildren.map((node, index) => (
        <Fragment key={index}>
          <ChildrenLayout disabled={node.props.disabled} className={node.props.wrapperClassName}>
            {node}
          </ChildrenLayout>
          {index < (filteredChildren as ReactNode[]).length - 1 && <div className='divider-line' />}
        </Fragment>
      ))}
      {!Array.isArray(children) && <ChildrenLayout disabled={disabled}>{children}</ChildrenLayout>}
    </button>
  );
};

export default InputWrapperPlainLabel;
