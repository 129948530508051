import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import { adaptFormValuesToIROData, useDMIROsForm } from '../../hooks/useDMIROsForm';
import { DMIROsForm } from './DMIROsForm';
import { useIROByIdQuery, useUpdateIROMutation } from '../../queries/useIROsQuery';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClose: () => void;
  iroId: string;
};

export const DMIROsEditModal: React.FC<Props> = ({ show, onClose, iroId }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros.editModal'
  });
  const { data: iroData, isLoading: isLoadingIro } = useIROByIdQuery(iroId);
  const updateIROMutation = useUpdateIROMutation();

  const formHook = useDMIROsForm({
    iroData,
    onSubmit: async (values) => {
      try {
        await updateIROMutation.mutateAsync({ id: iroId, data: adaptFormValuesToIROData(values) });
        onClose();
      } catch (error) {
        console.error('Error updating IRO:', error);
      }
    }
  });

  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Content>
        {isLoadingIro ? (
          <>
            <CustomSkeletonLoader count={2} />
            <CustomSkeletonLoader count={1} />
            <CustomSkeletonLoader count={2} />
            <CustomSkeletonLoader count={1} />
          </>
        ) : (
          <DMIROsForm
            formData={formHook.formData}
            setFormData={formHook.setFormData}
            selectableTopics={formHook.selectableTopics}
            selectableSubtopics={formHook.selectableSubtopics}
            iroTypes={formHook.iroTypes}
            errors={formHook.formData.errors}
            disabled={isLoadingIro || updateIROMutation.isPending}
          />
        )}
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          text={t('cancel')}
          size='medium'
          onClick={onClose}
          disabled={isLoadingIro || updateIROMutation.isPending}
        />
        <Button
          lookAndFeel='primary'
          text={t('saveAndClose')}
          size='medium'
          onClick={formHook.handleSubmit}
          disabled={
            formHook.formData.errors.length > 0 ||
            isLoadingIro ||
            !iroData ||
            updateIROMutation.isPending
          }
          loading={isLoadingIro || updateIROMutation.isPending}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
