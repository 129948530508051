import Button from 'components/ui/button/Button';
import { HoldingCustomNode } from './HoldingCustomNode';
import Icon from 'components/ui/icon/Icon';
import { HoldingNodeData } from 'types/entities/holdings';
import { Handle, Position, useConnection, useNodes } from '@xyflow/react';
import { useHover } from 'customHooks/useHover';
import {
  getOrgLabel,
  getSourceHandleStyle,
  getTargetHandleStyle,
  getVariant,
  isValidOrgNode
} from './utils';
import { useHoldingTranslations } from 'customHooks/translations';
import { CreateNodeBTN } from '../CreateNodeBTN';
import { SwitchOrgBTN } from '../SwitchOrgBTN';
import { useContractParent } from '../../hooks/useContractParent';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { getIsInOrg } from '../../utils';
import { MODAL_NAMES, useModalManager } from '../ModalManager';
import { ICON_MAP, LIMIT_TYPES_ARRAY } from '../../constants';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

type Props = {
  id: string;
  data: HoldingNodeData;
  selected?: boolean;
};

export const CompanyNode: React.FC<Props> = ({ id, data, selected }) => {
  const { inProgress } = useConnection();
  const flags = useFeatureFlags();
  const { handleMouseEnter, handleMouseLeave, isHovered, nodeRef, isActivated } = useHover();
  const { openModal } = useModalManager();
  const { t } = useHoldingTranslations();

  const org = useSelectedOrganization();
  const { data: contractOrg } = useContractParent(org?.id);

  const nodes = useNodes();

  const isValidOrg = isValidOrgNode(data);

  const variant = getVariant(isValidOrg);

  const isInContractOrg = getIsInOrg(contractOrg, org);

  if (!org || !contractOrg) {
    return null;
  }

  const onClick = () => {
    openModal(MODAL_NAMES.ORGANIZATION, id);
  };

  const isFirstNode = nodes.length === 1;

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={nodeRef}
      className='relative'>
      {contractOrg !== data.org_id && (
        <Handle
          type='target'
          position={Position.Top}
          isConnectableEnd={isInContractOrg}
          isConnectableStart={false}
          style={getTargetHandleStyle(inProgress)}
        />
      )}
      <HoldingCustomNode.Layout selected={selected || (inProgress && isHovered)}>
        <div className='flex justify-between gap-x-5'>
          <div className='flex flex-col gap-y-5'>
            <div className='flex gap-x-4'>
              <HoldingCustomNode.Icon icon='cards' disabled={!isValidOrg} />
              <div className='flex flex-col gap-y-1'>
                <span
                  className='weight-400 font-12 inter text-neutral-gray-warm-20 max-w-25ch ellipsis'
                  title={getOrgLabel(t, data.cif)}>
                  {getOrgLabel(t, data.cif)}
                </span>
                <span
                  className='weight-700 font-14 inter text-neutral-gray-cold-20 max-w-20ch ellipsis'
                  title={data.name}>
                  {data.name || '-'}
                </span>
              </div>
            </div>
            {flags?.webinar060325 && (
              <div className='flex gap-x-1'>
                {['HC', 'EINF', 'ISO 50001'].map((fr, idx) => (
                  <span
                    key={idx}
                    className='font-12 weight-400 p-1 rounded-4 text-secondary-purple-20 bg-secondary-purple-80'>
                    {fr}
                  </span>
                ))}
              </div>
            )}
            <div className='flex gap-x-2 items-center w-full'>
              {isInContractOrg && (
                <>
                  {flags?.webinar060325 && (
                    <Button
                      lookAndFeel='primary'
                      text={'Configurar equipo'}
                      onClick={() => openModal(MODAL_NAMES.CONFIGURE)}
                      style={{ width: 'fit-content' }}
                      iconNode={<Icon icon='members' color='white' />}
                      size='small'
                    />
                  )}
                  <Button
                    lookAndFeel={variant.lookAndFeel}
                    text={t(variant.i18nKey)}
                    onClick={onClick}
                    style={{ width: 'fit-content' }}
                    iconNode={<Icon icon={variant.icon.name} color={variant.icon.color} />}
                    size='small'
                  />
                  {flags?.webinar060325 && (
                    <Button
                      lookAndFeel='secondary'
                      style={{ width: 'fit-content' }}
                      iconNode={<Icon icon='external_link' color='gray-dark' />}
                      size='small'
                    />
                  )}
                </>
              )}
              <SwitchOrgBTN org_id={data.org_id} />
            </div>
          </div>
          <div className='flex flex-col gap-y-2 items-end'>
            {LIMIT_TYPES_ARRAY.map((limitType) => {
              return (
                <HoldingCustomNode.Info.Tooltip data={data} category={limitType} key={limitType}>
                  <HoldingCustomNode.Info icon={ICON_MAP[limitType]} disabled={!isValidOrg}>
                    {isValidOrg ? data[limitType] : '-'}
                  </HoldingCustomNode.Info>
                </HoldingCustomNode.Info.Tooltip>
              );
            })}
          </div>
        </div>
      </HoldingCustomNode.Layout>
      <Handle
        type='source'
        position={Position.Bottom}
        className='custom-source-handle'
        style={getSourceHandleStyle(isActivated || isHovered, !isInContractOrg)}
        isConnectable={isInContractOrg}
      />
      {(isActivated || isHovered || isFirstNode) && isInContractOrg && (
        <CreateNodeBTN node_id={data.id} isFirstNode={isFirstNode} />
      )}
    </div>
  );
};
