import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import getCountryName from 'utils/getCountryName';

type Props = {
  setFinalUserToEdit: (finalUser: DMFinalUser) => void;
  setFinalUserToDelete: (finalUser: DMFinalUser) => void;
};

export const useColumns = ({ setFinalUserToEdit, setFinalUserToDelete }: Props) => {
  const { t, i18n } = useTranslation();

  const buildActions = (finalUser: DMFinalUser) => [
    {
      id: `${finalUser.id}-edit`,
      name: t('doubleMateriality.context.finalUsers.edit'),
      onClick: () => {
        setFinalUserToEdit(finalUser);
      }
    },
    {
      id: `${finalUser.id}-delete`,
      name: t('doubleMateriality.context.finalUsers.delete'),
      onClick: () => {
        if (finalUser.id) {
          setFinalUserToDelete(finalUser);
        }
      }
    }
  ];

  const columns: Column[] = [
    {
      title: t('doubleMateriality.context.finalUsers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('doubleMateriality.context.finalUsers.country'),
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => getCountryName(country, i18n.resolvedLanguage as Lang)
    },
    {
      dataIndex: 'importance',
      key: 'importance',
      title: t('doubleMateriality.context.finalUsers.importance'),
      render: (_, row: DMSupplier) => (
        <div
          className={`text-tag body3-font ${
            row.highImportance ? 'tag2-bg-text-color' : 'tag-bg-text-color'
          } w-fit-content p-1 rounded-4`}>
          {row.highImportance
            ? t('doubleMateriality.context.high')
            : t('doubleMateriality.context.low')}
        </div>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      render: (_, finalUser: DMFinalUser) => <ButtonDropdown options={buildActions(finalUser)} />
    }
  ];
  return columns;
};
