import { ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../tooltip/Tooltip';
import './styles.scss';

type Props = {
  id?: string;
  icon?: string;
  label?: string;
  placeholder: string;
  value: string;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: string; // small for smaller paddings and margins
  tooltip?: string;
  disabled?: boolean;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputSearch = ({
  icon,
  label,
  placeholder,
  value,
  onChangeValue,
  error,
  fontClass = 'input-font',
  height = '33px',
  size = '',
  tooltip = '',
  disabled = false,
  id = ''
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className='input-search'
      onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}>
      {(label || tooltip) && (
        <div className='label-wrapper'>
          {label && <label className='input-label-font on-light-text-color'>{label}</label>}
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      <div
        className={`input-search__search flex ${fontClass} ${size} ${
          disabled ? 'input-disabled-styles' : ''
        } ${error ? 'error-border-color' : 'input-border-color'}`}
        style={{ height }}>
        {icon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        <input
          type='text'
          className={`input-search__search__content on-light-text-color ${fontClass} ${
            disabled ? 'transparent-bg-color' : ''
          } ${error ? 'error-border-color' : 'input-border-color'}`}
          value={value}
          placeholder={placeholder}
          onChange={onChangeValue}
          autoFocus
          id={id}
        />
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputSearch;
