import { useInfiniteQuery } from '@tanstack/react-query';
import { adaptSuppliersBackToFront } from 'adapters/suppliers';
import { fetchDMSuppliers } from 'services/api/sotOrganizationData';

export const useObtainSuppliers = ({
  year,
  company_name
}: {
  year: string;
  company_name: string;
}) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['providers', year, company_name],
    queryFn: fetchDMSuppliers,
    initialPageParam: 1,
    select: adaptSuppliersBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};
