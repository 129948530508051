import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useState } from 'react';
import { TOTPCode } from 'components/onboarding/totpCodeInput/TOTPCode';
import { useErrorTranslations } from 'customHooks/translations';

type MultifactorQRModalProps = {
  QRValue: string;
  onSubmitCode: (code: string) => Promise<void>;
  onClose: () => void;
};

enum MFASteps {
  QR = 'QR',
  TOTP = 'TOTP',
  Success = 'Success'
}

export const MultifactorQRModal = ({ QRValue, onSubmitCode, onClose }: MultifactorQRModalProps) => {
  const { t } = useTranslation('', { keyPrefix: 'profile.securityAndAccesses.mfa' });
  const { t: tGeneral } = useTranslation('', { keyPrefix: 'general' });
  const { t: tError } = useErrorTranslations();
  const [step, setStep] = useState<MFASteps>(MFASteps.QR);
  const [totpError, setTotpError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitCode = async (code: string) => {
    try {
      setIsLoading(true);
      await onSubmitCode(code);
      setStep(MFASteps.Success);
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      if (errorCode === 'SOFTWARE_TOKEN_MFA_NOT_FOUND') {
        setTotpError(tError('softwareTokenMFANotFound'));
        return;
      }
      setTotpError(tError('invalidVerificationCode'));
    } finally {
      setIsLoading(false);
    }
  };

  if (step === MFASteps.Success) {
    return (
      <>
        <Modal.Header title={t('successTitle')} />
        <Modal.Content>
          <p className='m-0'>{t('successDescription')}</p>
          <Button
            lookAndFeel='primary'
            text={tGeneral('close')}
            onClick={onClose}
            size='medium'
            className='mt-5'
          />
        </Modal.Content>
      </>
    );
  }

  return (
    <>
      <Modal.Header title={t('title')} />
      <Modal.Content>
        {step === MFASteps.QR ? (
          <>
            <p className='m-0 mb-6'>{t('description')}</p>
            <div className='flex justify-center place-center'>
              <QRCode value={QRValue} size={200} />
            </div>
            <p className='flex place-center justify-center gap-x-1 w-full'>
              <span className='font-body-b1-r'>{t('cantScanQR')}</span>
              <Button
                className='font-body-b1-r'
                lookAndFeel='link'
                onClick={() => {
                  window.open(QRValue, '_blank');
                }}>
                {t('thisLink')}
              </Button>
            </p>
            <div className='flex gap-x-3 mt-5'>
              <Button
                lookAndFeel='secondary'
                text={tGeneral('cancel')}
                onClick={onClose}
                size='medium'
              />
              <Button
                lookAndFeel='primary'
                text={tGeneral('next')}
                onClick={() => setStep(MFASteps.TOTP)}
                size='medium'
              />
            </div>
          </>
        ) : (
          <TOTPCode onSubmit={handleSubmitCode} isLoading={isLoading} errorMessage={totpError} />
        )}
      </Modal.Content>
    </>
  );
};
