import { useEffect, useRef } from 'react';

type Props = {
  name: string;
  addWidthCallback?: (width: number) => void;
};
const Category = ({ name, addWidthCallback }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Get the width in px of the tag from the ref
    const width = ref.current?.clientWidth;
    // If the width is not null, call the callback function
    if (width && addWidthCallback) {
      addWidthCallback(width);
    }
  }, []);

  return (
    <div
      className='font-12 weight-400 tag-bg-text-color tag-border-color rounded-4 px-2 py-1 whitespace-nowrap'
      ref={ref}>
      {name}
    </div>
  );
};

export default Category;
