import * as Tooltip from '@radix-ui/react-tooltip';
import { HTMLProps, ReactNode, useState } from 'react';
import './styles.scss';

type Props = {
  text: string | ReactNode;
  position?: 'top' | 'right' | 'left' | 'bottom';
  children: ReactNode;
  size?: string;
  style?: HTMLProps<HTMLDivElement>['style'];
  shouldAppear?: boolean;
  disableHoverableContent?: boolean;
  asChild?: boolean;
  wordBreak?: 'break-all' | 'keep-all' | 'normal';
  delay?: number;
};

const TooltipWrapperBase = ({
  text,
  shouldAppear = true,
  position = 'top',
  size = 'medium',
  children,
  asChild = false,
  disableHoverableContent,
  style,
  delay
}: Props) => {
  if (!shouldAppear) return <>{children}</>;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const render = asChild ? (
    children
  ) : (
    <div className={`tooltip ${size} cursor-pointer`} style={style}>
      {children}
    </div>
  );

  return (
    <Tooltip.Provider delayDuration={0} skipDelayDuration={0}>
      <Tooltip.Root
        open={isOpen}
        onOpenChange={handleOpenChange}
        disableHoverableContent={disableHoverableContent ?? true}
        delayDuration={delay}>
        <Tooltip.Trigger asChild={asChild} onClick={() => setIsOpen(true)}>
          {render}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side={position} style={{ zIndex: 101 }}>
            <Tooltip.Arrow className='tooltip-arrow' />
            {text}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const UncontrolledTooltipWrapper = ({ text, ...rest }: Props) => {
  return (
    <TooltipWrapperBase
      {...rest}
      text={
        <div className='p-2 border-box text-left rounded-5 tag2-bg-text-color tooltip-font'>
          {text}
        </div>
      }
    />
  );
};

const TooltipWrapper = ({ text, wordBreak, ...rest }: Props) => {
  return (
    <TooltipWrapperBase
      {...rest}
      text={
        <div className={`tooltip-modal tag2-bg-text-color tooltip-font ${wordBreak}`}>{text}</div>
      }
    />
  );
};

TooltipWrapper.Uncontrolled = UncontrolledTooltipWrapper;

export default TooltipWrapper;
