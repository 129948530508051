import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRdCodesByLowId } from '../../../../../../../../../services/api/waste';

type Props = {
  lowId?: string;
};

const useGetRdCodes = ({ lowId }: Props) => {
  const { t } = useTranslation();
  const [rdCodes, setRdCodes] = useState<any[]>([]);
  const [rdCodesNuvo, setRdCodesNuvo] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await getRdCodesByLowId({
        lowId
      });

      setRdCodesNuvo(
        response.map((elem: { rd_id: string; rd_name: string; rd_code: string }) => ({
          label: elem.rd_name,
          value: elem.rd_id,
          type: 'string'
        }))
      );
      setRdCodes(
        response.map((elem: { rd_id: string; rd_name: string; rd_code: string }) => ({
          id: elem.rd_id,
          name: `${elem.rd_code} ${t(`rd_codes.${elem.rd_name}`)}`
        }))
      );
      setLoading(false);
    };

    getData();
  }, [lowId]);

  return {
    rdCodes,
    rdCodesNuvo,
    loading
  };
};

export default useGetRdCodes;
