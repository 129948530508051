import React, { Dispatch, SetStateAction } from 'react';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import useYearOptions from '../../../../../../customHooks/useYearOptions';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { useTranslation } from 'react-i18next';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../ui/stepCarouselList/useStepCarousel';
import FormSocialLaboral from './formSocialLaboral/FormSocialLaboral';
import {
  AttachmentSocialData,
  FormSocialData,
  OptionalQuestion
} from '../../../../../../types/entities/socialData';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormElementShort from '../../../../../ui/formComponents/formElementShort/FormElementShort';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import FormEnvironment from './formEnvironment/FormEnvironment';
import FormPIAS from './formPIAS/FormPIAS';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import FormTextArea from '../../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import FileUploader from '../../../../../ui/formComponents2/fileUploader/FileUploader';
import FileAttached from '../../../../../tasks/components/form/components/fileAttached/FileAttached';
import useRole from 'customHooks/useRole';

type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
  handleSubmit: () => void;
  onClose: () => void;
  yearsAnswered: string[];
  questions: OptionalQuestion[];
  units: SelectOptionFormat[];
  loadingButton: boolean;
};

const FormResponse = ({
  formData,
  setFormData,
  onClose,
  handleSubmit,
  yearsAnswered,
  questions,
  units,
  loadingButton
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'socialAndGovernance' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { isFundRole: disabled } = useRole();

  const yearOptions = useYearOptions();

  const { onChangeValue } = useOnChangeValue<FormSocialData>({ setFormData });

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    handleNext,
    handleBack,
    steps,
    isFirstElement,
    isLastElement
  } = useStepCarousel({
    stepsText: [
      { id: 'socialAndLaboral', text: 'socialAndGovernance.socialAndLaboralTag' },
      {
        id: 'environment',
        text: 'socialAndGovernance.environment'
      },
      {
        id: 'pias',
        text: 'socialAndGovernance.paiQuestions'
      }
    ]
  });

  const handleRemoveFile = async (
    id: string,
    key:
      | 'socialLaboralEvidenceAttached'
      | 'environmentEvidenceAttached'
      | 'optionalQuestionsEvidenceAttached'
  ) => {
    if (disabled) return;
    if (!formData[key] || !(key in formData)) return;
    // const parsedFiles = formData[key]?.map((elem) => ({
    //   id: elem.file_name,
    //   file: elem,
    //   url: elem.file_url
    // }));
    // const foundAttachment = parsedFiles?.find((elem) => elem.id === id);
    setFormData((prev) => ({
      ...prev,
      socialLaboralEvidenceAttached: prev[key]?.filter((elem) => elem.file_name !== id)
    }));
  };

  const renderEvidenceFormTextArea = (
    commentKey: Exclude<keyof FormSocialData, 'errors'>,
    attachmentKey:
      | 'socialLaboralEvidenceAttached'
      | 'environmentEvidenceAttached'
      | 'optionalQuestionsEvidenceAttached'
  ) => {
    return (
      <>
        <FormElementFull>
          <FormTextArea
            label={
              <span className='flex' style={{ gap: '0.25rem' }}>
                {t('evidenceLabel1')}{' '}
                <FileUploader
                  callback={(files: File[]) => {
                    const parsedFiles: AttachmentSocialData[] = files.map((file) => ({
                      id: file.name,
                      file_name: file.name,
                      file_url: URL.createObjectURL(file),
                      description: '',
                      category: '',
                      file
                    }));
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    setFormData((prev: any) => ({
                      ...prev,
                      [attachmentKey]: prev[attachmentKey]
                        ? [...prev[attachmentKey], ...parsedFiles]
                        : [...parsedFiles]
                    }));
                  }}
                  disabled={disabled}>
                  <span className='highlight-text-color'>{t('addDocuments')}</span>
                </FileUploader>{' '}
                {t('evidenceLabel2')}
              </span>
            }
            tooltip={t('evidenceTooltip')}
            onChange={onChangeValue(commentKey)}
            value={commentKey in formData ? formData[commentKey] : ''}
            disabled={disabled}
          />
        </FormElementFull>
        {formData[attachmentKey] && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
            {formData[attachmentKey]?.map(
              (elem) =>
                elem.file_url && (
                  <FileAttached
                    key={elem.id}
                    file={{
                      id: elem.id,
                      url: elem.file_url,
                      name: elem.file_name
                    }}
                    handleRemove={() => handleRemoveFile(elem.file_name, attachmentKey)}
                  />
                )
            )}
          </div>
        )}
      </>
    );
  };

  const renderFormByStep = () => {
    if (stepSelected?.id === 'socialAndLaboral') {
      return (
        <>
          <FormSocialLaboral formData={formData} setFormData={setFormData} />
          {renderEvidenceFormTextArea(
            'socialLaboralEvidenceComments',
            'socialLaboralEvidenceAttached'
          )}
        </>
      );
    }
    if (stepSelected?.id === 'environment') {
      return (
        <>
          <FormEnvironment formData={formData} setFormData={setFormData} units={units} />
          {renderEvidenceFormTextArea('environmentEvidenceComments', 'environmentEvidenceAttached')}
        </>
      );
    }
    if (stepSelected?.id === 'pias') {
      return (
        <>
          <FormPIAS formData={formData} setFormData={setFormData} questions={questions} />
          {renderEvidenceFormTextArea(
            'optionalQuestionsEvidenceComments',
            'optionalQuestionsEvidenceAttached'
          )}
        </>
      );
    }
    return null;
  };

  const handleNextStep = () => {
    if (disabled) {
      // If role fund always allow to go to next step
      handleNext();
      return;
    }
    const optionalFields = [
      'socialLaboralEvidenceAttached',
      'environmentEvidenceAttached',
      'optionalQuestionsEvidenceAttached',
      'socialLaboralEvidenceComments',
      'environmentEvidenceComments',
      'optionalQuestionsEvidenceComments'
    ];

    if (stepSelected?.id === 'socialAndLaboral') {
      optionalFields.push(
        'biodiversityZone',
        'quantityWaste',
        'unitWaste',
        'naceCode',
        'optionalQuestions'
      );
    }

    if (stepSelected?.id === 'environment') {
      optionalFields.push(
        'hasGlobalCompact',
        'isGlobalCompactViolated',
        'isRelatedGuns',
        'anualRevenue',
        'womanRevenue',
        'manRevenue',
        'numberWomanBoardDirectors',
        'numberMenBoardDirectors',
        'optionalQuestions'
      );
    }

    if (stepSelected?.id === 'pias') {
      optionalFields.push(
        'biodiversityZone',
        'quantityWaste',
        'unitWaste',
        'naceCode',
        'hasGlobalCompact',
        'isGlobalCompactViolated',
        'isRelatedGuns',
        'anualRevenue',
        'womanRevenue',
        'manRevenue',
        'numberWomanBoardDirectors',
        'numberMenBoardDirectors'
      );
    }

    const errors = checkFormErrors(formData, formData.errors, optionalFields);
    if (errors.length) {
      setFormData((prev) => ({ ...prev, errors }));
      return;
    }
    handleNext();
  };

  return (
    <>
      <FormWrapper className='overflow-y-auto max-h-60svh'>
        <FormElementShort>
          <FormSelect
            icon='/images/icons/calendar.svg'
            label={t('selectYear')}
            onChange={onChangeValue('year')}
            value={formData.year}
            options={yearOptions.years.filter((year) => !yearsAnswered.includes(year.id))}
            error={formData.errors.find((error) => error.error === 'year')}
            sort={false}
            disabled={disabled}
          />
        </FormElementShort>
        <FormElementFull>
          <StepCarouselList steps={steps} handleSelect={handleSelectCarousel} lookAndFeel={'big'} />
        </FormElementFull>
        {renderFormByStep()}
      </FormWrapper>
      <FormButtonSection>
        {isFirstElement ? (
          <Button
            lookAndFeel='secondary'
            text={tGeneral('closeWithoutSaving')}
            size='small'
            onClick={onClose}
          />
        ) : (
          <Button
            lookAndFeel='secondary'
            text={tGeneral('previous')}
            size='small'
            onClick={handleBack}
          />
        )}
        {isLastElement ? (
          <Button
            lookAndFeel='primary'
            text={tGeneral('saveAndClose')}
            size='small'
            onClick={handleSubmit}
            disabled={disabled}
            loading={loadingButton}
          />
        ) : (
          <Button
            lookAndFeel='primary'
            text={tGeneral('next')}
            size='small'
            onClick={handleNextStep}
          />
        )}
      </FormButtonSection>
    </>
  );
};

export default FormResponse;
