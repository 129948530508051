import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentForm, TransportSection } from 'types/entities/shipment';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  formData: ShipmentForm;
  setFormData: Dispatch<SetStateAction<ShipmentForm>>;
};

const useEditTransportSections = ({ formData, setFormData }: Props) => {
  const { t } = useTranslation();

  const addTransportSection = (index: number) => {
    const newTransportSections = [...formData.transportSections];
    let origin = '';
    if (index + 1 > 0) {
      origin = newTransportSections[index].destination;
    }
    newTransportSections.splice(index + 1, 0, {
      location_origin_id: '',
      location_destination_id: '',
      origin,
      destination: '',
      transport_type: '',
      transport_kilometers: ''
    });
    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections
    }));
  };

  const removeTransportSection = (index: number) => {
    const newTransportSections = [...formData.transportSections];
    // if it is not the first one nor the last one
    if (index + 1 > 0 && index + 1 < newTransportSections.length) {
      newTransportSections[index + 1].origin = newTransportSections[index - 1].destination;
    }
    newTransportSections.splice(index, 1);

    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections
    }));
  };

  const modifyTransportSection = (index: number, transportSection: TransportSection) => {
    const newTransportSections = [...formData.transportSections];
    newTransportSections[index] = transportSection;
    if (index + 1 < formData.transportSections.length) {
      newTransportSections[index + 1].origin = transportSection.destination;
    }

    let newErrors = formData.errors.filter((error) => error.error !== 'invalidTravelMode');

    // Check if origin and destination are not empty
    if (transportSection.destination !== '' && transportSection.origin !== '') {
      newErrors = newErrors.filter((elem) => elem.error !== `transportSectionsRoute-${index}`);
    }
    if (transportSection.transport_type !== '') {
      newErrors = newErrors.filter(
        (elem) => elem.error !== `transportSectionsTransportType-${index}`
      );
    }
    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections,
      errors: newErrors
    }));
  };

  const checkTransportSectionErrors = () => {
    const newErrors: ErrorType[] = [];
    formData.transportSections.forEach((elem, index) => {
      if (!elem.transport_kilometers && (elem.destination === '' || elem.origin === '')) {
        newErrors.push({ error: `transportSectionsRoute-${index}` });
      }
      if (elem.transport_type === '') {
        newErrors.push({ error: `transportSectionsTransportType-${index}` });
      }
      if (!elem.transport_kilometers && elem.destination === elem.origin) {
        newErrors.push({
          error: `transportSectionsRoute-${index}`,
          description: t('error.originAndDestinationCannotBeTheSame')
        });
      }
    });
    return newErrors;
  };

  const transportSectionsFrontToBack = (id: string) => {
    const transportSections: TransportSection[] = [];
    formData.transportSections.forEach((elem, index) => {
      let transport_type = elem.transport_type;
      let travel_method = undefined;
      let electric = false;
      if (
        elem.transport_type === 'truck' ||
        elem.transport_type === 'car' ||
        elem.transport_type === 'motorbike' ||
        elem.transport_type === 'bicycle'
      ) {
        transport_type = 'road';
        travel_method = elem.transport_type;
      }
      if (elem.transport_type === 'electric_car') {
        transport_type = 'road';
        travel_method = 'car';
        electric = true;
      }
      if (elem.transport_type === 'electric_bicycle') {
        transport_type = 'road';
        travel_method = 'bicycle';
        electric = true;
      }
      if (elem.transport_type === 'electric_kick_scooter') {
        transport_type = 'road';
        travel_method = 'electric_kick_scooter';
        electric = true;
      }

      transportSections.push({
        location_origin_id:
          index === 0 ? uuidv4() : transportSections[index - 1].location_destination_id,
        location_destination_id: uuidv4(),
        origin: elem.origin,
        destination: elem.destination,
        transport_type,
        travel_method,
        electric,
        transport_route_id: id
      });
    });
    return transportSections;
  };

  const INVALID_TRAVEL_MODE_ERRORS = [
    'INVALID_TRAVEL_MODE_BETWEEN_PLACES',
    'INVALID_TRANSPORT_SECTION_PROVIDED',
    'SAME_COUNTRY_FOR_MARITIME_TRANSPORT'
  ];

  return {
    addTransportSection,
    removeTransportSection,
    modifyTransportSection,
    checkTransportSectionErrors,
    transportSectionsFrontToBack,
    INVALID_TRAVEL_MODE_ERRORS
  };
};

export default useEditTransportSections;
