import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { useDeleteFinalUser } from './hooks/useFinalUsers';

type ModalFinalUserProps = {
  finalUserData?: DMFinalUser;
  closeModal: () => void;
};

export const ModalDeleteFinalUser = ({ finalUserData, closeModal }: ModalFinalUserProps) => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality.context.finalUsers' });
  const { deleteFinalUser } = useDeleteFinalUser();

  return (
    <div className='delete-modal'>
      <FormHeader title={t('delete')} description={t('areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={() => {
            deleteFinalUser(finalUserData?.id ?? '');
            closeModal();
          }}
        />
      </FormButtonSection>
    </div>
  );
};
