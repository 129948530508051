import { DMIROsBanner } from './DMIROsBanner';
import { DMIROsTable } from './DMIROsTable';

export const DMIROs = () => {
  return (
    <>
      <DMIROsBanner />
      <DMIROsTable />
    </>
  );
};
