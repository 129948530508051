import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
import numberToDecimal from '../../../utils/numberToDecimal';
import Button from '../button/Button';
import IconBackground from '../iconBackground/IconBackground';
import './styles.scss';
import Icon from '../icon/Icon';
import { IconName } from 'types/utils/iconName';

type Props = {
  card: CardTooltip;
  data: CardData;
};
const CardContent = ({ card, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const handleOnClickCard = () => {
    if (!card.disabled && !card.freeTrial && !card.blocked) {
      if (card.onClickCallback && user?.selectedOrganization && !user.email.includes('dcycle')) {
        card.onClickCallback();
      }
      navigate(data.route);
    }
  };

  const renderTotal = (total: number | string | undefined, unit: string | undefined) => {
    if (card.freeTrial) {
      return <Button lookAndFeel='blocked' text={t('blockedButton.improvePlan')} size='tiny' />;
    }
    return (
      <div className='total-info'>
        {typeof total === 'number' ? (
          <span className='highlight-text-color small-card-font'>
            {numberToDecimal(total / 1000)}
          </span>
        ) : (
          <span
            className={`${
              card.disabled ? 'disabled-text-color' : 'highlight-text-color'
            } small-card-font`}>
            {total}
          </span>
        )}
        {unit && <p className='body1-font'>t CO₂ eq.</p>}
      </div>
    );
  };

  let icon = undefined;
  let iconV2 = undefined;
  if (data.icon.includes('.svg')) {
    icon = data.icon;
  } else {
    iconV2 = data.icon as IconName;
  }

  return (
    <div
      className={`content ${card.blocked ? 'blocked' : ''}`}
      key={data.title}
      onClick={handleOnClickCard}>
      <IconBackground
        icon={icon}
        iconV2={iconV2}
        size={'large'}
        alt={data.title}
        blocked={card.blocked}
      />
      <h1 className='flex align-items headline4-font gap-2'>
        {data.title}
        {card.blocked && <Icon icon='lock' color='gray-warm' />}
      </h1>
      <p className='subtitle3-font'>{data.description}</p>
      {!card.blocked && renderTotal(data.total, data.unit)}
    </div>
  );
};

export default CardContent;
