import { Dispatch, SetStateAction } from 'react';
import {
  AssignedTag,
  ConsumptionPercentages,
  useFormLineHelpers
} from '../../../createInvoice/components/common/ConsumptionPercentages';
import { RDLine } from './RDLine';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useTranslation } from 'react-i18next';
import InputLabel from 'components/ui/formComponents2/inputUtils/inputWrapper/components/InputLabel';
import { useRDLineStatus } from '../hooks/useRDLineStatus';
import { type RDLineType } from '../types/RDLine';

type FormRDProps = {
  lines: RDLineType[];
  setLines: Dispatch<SetStateAction<RDLineType[]>>;
};

export const FormRD: React.FC<FormRDProps> = ({ lines, setLines }) => {
  const { t } = useTranslation();

  const { onEdit, onDelete, onAdd } = useFormLineHelpers({ setLines });
  const { status, totalValue } = useRDLineStatus({ lines });

  return (
    <div className='flex-col gap-y-2'>
      <div className='grid grid-cols-12'>
        <div className={`${lines.length > 1 ? 'col-span-11' : 'col-span-12'} flex gap-x-4`}>
          <div className='grow'>
            <InputLabel label={t('waste.rd')} tooltip={t('waste.rdToolTipUploadPdf')} />
          </div>
          <div className='w-30'>
            <InputLabel label={t('waste.percentage')} />
          </div>
        </div>
      </div>
      <ConsumptionPercentages.Base
        lines={lines}
        onDelete={onDelete}
        onEdit={onEdit}
        LineComponent={RDLine}
      />

      <div className='flex gap-x-4 justify-between mt-2'>
        <Button
          lookAndFeel='link-dark'
          iconNode={<Icon icon='add' size='medium' color='gray-dark' />}
          onClick={() => onAdd({ id: '', name: '', value: '0' })}
          text={t('waste.addRDCode')}
        />
        <AssignedTag status={status} totalValue={totalValue} keyPrefix='waste.assignedTag' />
      </div>
    </div>
  );
};
