import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EsgFrameworkCompleteness } from '../../../../../types/entities/esgMetrics';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import HorizontalCard from 'components/ui/cardPanel/HorizontalCard';
import FrameworkProgressBar from './components/FrameworkProgressBar';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import { formatDate } from 'utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import logoLogicReturner from './components/FrameworkDashboard/utils/logoLogicReturner';
import checkMaxPercentage from 'utils/limitPercentageCalculator';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import FrameworksModal from 'components/dashboard/frameworksModal/FrameworksModal';
import useFrameworks from 'customHooks/api/useFrameworksPlans';
import { FrameworkPlan, OrganizationFramework } from 'services/api/frameworks';
import { allowedFrameworkDashboards } from './components/FrameworkDashboard/utils/constants';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import useOrganizationFrameworks from 'customHooks/api/useOrganizationFrameworks';

type Props = {
  data: EsgFrameworkCompleteness[];
  loading: boolean;
};
interface MinimizedFrameworkData {
  framework: string;
  defaultTotal: number;
  fixedCompleted?: number;
  fixedTotal?: number;
  defaultDate: Date;
  maxPercentage?: number;
  defaultFrom?: string;
  defaultCompleted?: number;
}

// Type for the extended framework object
interface ExtendedFramework {
  framework: string;
  total: number;
  completed: number;
  blocked: boolean;
  date: string;
  logo: string;
  config: boolean;
}
const FrameworkProgress = ({ data, loading }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const flags = useFeatureFlags();
  const navigate = useNavigate();
  const [extendedData, setExtendedData] = useState<EsgFrameworkCompleteness[]>([]);
  const [frameworkModalOpen, setFrameworkModalOpen] = useState(false);
  const xOrganizationId = localStorage.getItem('x-organization-id');
  const saveFrameworksHandler = () => {
    const dataToSave = {
      seen: true
    };
    // Save with organization-specific key
    localStorage.setItem(
      `seenFrameworksSelectorModal-${xOrganizationId}`,
      JSON.stringify(dataToSave)
    );
    setFrameworkModalOpen(false);
  };
  const { frameworkPlans, frameworksLoading } = useFrameworks(xOrganizationId || undefined);
  const { organizationFrameworks } = useOrganizationFrameworks();
  const getFrameworkPlanByName = (
    name: string | null | undefined,
    plans: FrameworkPlan[] | null | undefined,
    organizationFrameworks: OrganizationFramework[] | null | undefined
  ): (FrameworkPlan & { reporting_start_date: string; reporting_end_date: string }) | null => {
    if (!plans || !Array.isArray(plans) || !name || !organizationFrameworks) {
      return null; // Return null if plans is invalid or name is null/undefined
    }
    // Find the FrameworkPlan by name
    const plan = plans.find(
      (plan) => plan?.framework && plan.framework.name.toLowerCase() === name.toLowerCase()
    );

    if (!plan) {
      return null; // Return null if no plan matches the name
    }
    // Find the corresponding OrganizationFramework by framework ID
    const organizationFramework = organizationFrameworks.find(
      (orgFramework) => orgFramework.framework_id === plan.framework.id
    );

    // Merge the plan with reporting fields from the OrganizationFramework
    return {
      ...plan,
      reporting_start_date: organizationFramework?.reporting_start_date || '', // Add reporting fields if found
      reporting_end_date: organizationFramework?.reporting_end_date || '' // Add reporting fields if found
    };
  };

  const reverseDate = (date?: string | null): string | null | undefined => {
    if (!date) return date; // Return null or undefined as is
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    if (!loading && data && !frameworksLoading && frameworkPlans) {
      // Minimized framework data array
      const frameworksData: MinimizedFrameworkData[] = [
        {
          framework: 'ghg_protocol',
          defaultTotal: 100,
          fixedCompleted: 8,
          fixedTotal: 100,
          defaultDate: new Date(2025, 0, 1)
        },
        {
          framework: 'csrd',
          defaultTotal: 100,
          fixedCompleted: 11,
          fixedTotal: 100,
          defaultDate: new Date(2024, 11, 31),
          maxPercentage: 15
        },
        {
          framework: 'einf',
          defaultTotal: 100,
          fixedCompleted: 16,
          fixedTotal: 100,
          defaultDate: new Date(2024, 11, 31),
          maxPercentage: 15
        },
        {
          framework: 'miteco',
          defaultTotal: 100,
          defaultDate: new Date(2025, 6, 31),
          defaultFrom: 'ghg_protocol'
        },
        {
          framework: 'iso_14064',
          defaultTotal: 100,
          defaultDate: new Date(2024, 6, 31),
          defaultFrom: 'ghg_protocol'
        },
        {
          framework: 'sbti',
          defaultTotal: 100,
          defaultDate: new Date(2025, 0, 1),
          maxPercentage: 81,
          defaultFrom: 'ghg_protocol'
        },
        {
          framework: 'iso_14001',
          defaultTotal: 100,
          defaultDate: new Date(2025, 0, 1),
          defaultCompleted: 45
        },
        {
          framework: 'cdp',
          defaultTotal: 100,
          defaultDate: new Date(2025, 0, 1),
          defaultCompleted: 40
        },
        {
          framework: 'ecovadis',
          defaultTotal: 100,
          defaultDate: new Date(2025, 0, 1),
          defaultCompleted: 30
        }
      ];

      // Helper function to build extended framework object
      const buildFrameworkObject = ({
        framework,
        defaultTotal,
        defaultDate,
        maxPercentage,
        defaultFrom,
        defaultCompleted,
        fixedCompleted,
        fixedTotal
      }: MinimizedFrameworkData): ExtendedFramework => {
        const frameworkPlan = getFrameworkPlanByName(
          framework,
          frameworkPlans,
          organizationFrameworks
        );
        const frameworkEntry = data.find(
          (item) => item.framework.toLowerCase() === framework.toLowerCase()
        );

        let isBlocked = false;
        if (framework === 'einf') {
          isBlocked = localStorage.getItem('einfEnabled') === null;
        } else {
          isBlocked = !(frameworkPlan?.purchased && frameworkPlan?.visible);
        }

        const formattedDate =
          reverseDate(frameworkPlan?.reporting_end_date) || formatDate(defaultDate);
        const defaultFromEntry = data.find(
          (item) => item.framework.toLowerCase() === defaultFrom?.toLowerCase()
        );
        return {
          framework,
          total: fixedTotal || frameworkEntry?.total || defaultFromEntry?.total || defaultTotal,
          completed:
            fixedCompleted ??
            (maxPercentage
              ? checkMaxPercentage(
                  frameworkEntry?.completed || defaultFromEntry?.completed || defaultCompleted || 0,
                  frameworkEntry?.total || defaultTotal,
                  maxPercentage
                )
              : frameworkEntry?.completed || defaultFromEntry?.completed || defaultCompleted || 0),
          blocked: isBlocked,
          date: formattedDate,
          logo: logoLogicReturner(framework, isBlocked),
          config: !!(
            frameworkPlan?.plan_start_date &&
            frameworkPlan?.plan_end_date &&
            frameworkPlan?.plan_start_date === frameworkPlan?.plan_end_date
          )
        };
      };

      let filteredFrameworks = frameworksData;

      if (!flags?.webinar060325) {
        filteredFrameworks = frameworksData.filter((item) => item.framework !== 'einf');
      } else {
        filteredFrameworks = frameworksData.filter((item) => item.framework !== 'iso_14064');
      }

      // Build extended array
      const extendedArray: ExtendedFramework[] = filteredFrameworks.map(buildFrameworkObject);

      // Reorder to place ghg_protocol and csrd first
      const sortedArray = extendedArray.sort((a, b) => {
        if (a.framework === 'ghg_protocol') return -1;
        if (b.framework === 'ghg_protocol') return 1;
        if (a.framework === 'csrd') return -1;
        if (b.framework === 'csrd') return 1;
        return 0;
      });

      setExtendedData(sortedArray);
    }
  }, [loading, frameworksLoading, JSON.stringify(frameworkPlans), JSON.stringify(data)]);

  if (loading || frameworksLoading) {
    return <LoaderCard />;
  }
  return (
    <CardPanel>
      <CardPanel.Header
        title={t('title')}
        style={{
          alignItems: 'center'
        }}>
        <Button
          style={{
            whiteSpace: 'nowrap',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
          lookAndFeel='link-dark'
          size='small'
          iconNode={<Icon icon='settings' color='gray-dark' />}
          onClick={() => setFrameworkModalOpen(true)}>
          {t('configureFrameworks')}
        </Button>
      </CardPanel.Header>
      <CardPanel.Body>
        {extendedData.map((elem) => (
          <HorizontalCard
            key={'elem.id'}
            className={`${elem.blocked ? '' : 'card-button'}`}
            // if the card is blocked, do nothing, otherwise navigate to the framework details page
            onClick={() => {
              if (!elem.blocked && !elem.config) {
                if (allowedFrameworkDashboards.includes(elem.framework)) {
                  navigate(
                    getUrl(ROUTES.IMPROVE_DASHBOARD_TOTAL + `/${elem.framework}`, {
                      queryParams: generateQueryParamsFromObject({ mode: 'controlPanel' })
                    })
                  );
                }
              }
            }}>
            <FrameworkProgressBar
              key={elem.framework}
              title={
                t(elem.framework, {
                  keyPrefix: 'controlPanel.frameworkProgress.frameworks'
                }) || elem.framework.replaceAll('_', ' ')
              }
              total={elem.total}
              completed={elem.completed}
              date={elem.date}
              blocked={elem.blocked}
              logo={elem.logo}
              config={elem.config}
              submitted={elem?.submitted || false}
              id={
                getFrameworkPlanByName(elem.framework, frameworkPlans, organizationFrameworks)
                  ?.id || null
              }
            />
          </HorizontalCard>
        ))}
      </CardPanel.Body>
      {frameworkModalOpen && flags?.sotDcycleDemos && (
        <FrameworksModal
          saveFrameworksHandler={saveFrameworksHandler}
          modalOpen={frameworkModalOpen}
        />
      )}
    </CardPanel>
  );
};

export default FrameworkProgress;
