import React, { Dispatch, SetStateAction } from 'react';
import {
  FormSocialData,
  OptionalQuestion,
  OptionalQuestionResponse
} from '../../../../../types/entities/socialData';
import { useTranslation } from 'react-i18next';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import { TOOLTIP_LIST } from '../../../../../constants/tooltipQuestions';
import useRole from 'customHooks/useRole';

type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
  question: OptionalQuestion;
};
const QuestionFloat = ({ formData, setFormData, question }: Props) => {
  const { t } = useTranslation();
  const { isFundRole: disabled } = useRole();

  return (
    <FormNumber
      tooltip={
        TOOLTIP_LIST.includes(question.name)
          ? t(`funds.questionsTooltip.${question.name}`)
          : undefined
      }
      icon='/images/icons/catalog.svg'
      label={t(`funds.questions.${question.name}`)}
      placeholder={t('socialAndGovernance.enterQuantity')}
      value={
        (formData.optionalQuestions?.find(
          (element: OptionalQuestionResponse) => element.id === question.id
        )?.response as string) ?? ''
      }
      maxLength={10}
      onChange={(value: string) => {
        const foundQuestionIndex =
          formData.optionalQuestions?.findIndex(
            (element: OptionalQuestionResponse) => element.id === question.id
          ) ?? -1;
        if (foundQuestionIndex === -1 || !formData.optionalQuestions) {
          const prevOptionalQuestions = formData.optionalQuestions
            ? [...formData.optionalQuestions]
            : [];
          prevOptionalQuestions.push({
            id: question.id,
            type: question.type,
            response: value
          });
          setFormData((prev) => ({
            ...prev,
            optionalQuestions: [...prevOptionalQuestions],
            errors: formData.errors.filter((error) => error.error !== question.id)
          }));
          return;
        }
        // update question value
        const newOptionalQuestions = [...formData.optionalQuestions];
        newOptionalQuestions[foundQuestionIndex] = {
          id: question.id,
          type: question.type,
          response: value
        };
        setFormData((prev) => ({
          ...prev,
          optionalQuestions: newOptionalQuestions,
          errors: formData.errors.filter((error) => error.error !== question.id)
        }));
      }}
      error={formData.errors.find((error) => error.error === question.id)}
      disabled={disabled}
    />
  );
};

export default QuestionFloat;
