import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../../../types/table';
import Button from '../../../../../../../ui/button/Button';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import DisabledLabel from '../../../../../../../ui/statusLabels/disabledLabel/DisabledLabel';
import PendingLabel from '../../../../../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../../../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../../../../../ui/tooltip/TooltipWrapper';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';
import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import Label from 'components/ui/label/Label';
import Icon from 'components/ui/icon/Icon';

type Props = {
  setDatapointToEdit: (datapoint: FrameworkDatapoint) => void;
  setDatapointToAssign: (datapoint: FrameworkDatapoint) => void;
  handleEditRelevance: (value: SelectOptionFormat, datapoint: FrameworkDatapoint) => void;
  setShowPremiumModal: (value: boolean) => void;
  category?: string;
  subcategory?: string;
  language?: string;
};

const capitalizeFirstLetter = (text: string) => {
  if (text?.length === 0) {
    return text; // Return the empty string if input is empty
  }
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};
const getTranslatedName = (datapoint: FrameworkDatapoint, currentLanguage: string) => {
  switch (currentLanguage) {
    case 'es':
      return datapoint.name_es;
    case 'pt':
      return datapoint.name_pt;
    default:
      return datapoint.name_en;
  }
};
const useColumns = ({
  setDatapointToEdit,
  setDatapointToAssign,
  handleEditRelevance,
  setShowPremiumModal,
  category,
  subcategory,
  language
}: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const statusLabel = {
    COMPLETE: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
    // WITH_DATA: <PendingLabel>{t('general.withData')}</PendingLabel>,
    WITH_DATA: <SuccessLabel>{t('general.withData')}</SuccessLabel>,
    WITHOUT_DATA: (
      <DisabledLabel icon='error' style={{ paddingInline: '8px', paddingBlock: '2px' }}>
        {t('general.withoutData')}
      </DisabledLabel>
    )
  };

  const renderTypeTag = (elem: FrameworkDatapoint) => {
    return (
      <div
        className='text-tag body3-font tag-bg-text-color tag-space-between'
        style={{ width: 'fit-content', padding: '4px 8px' }}>
        {capitalizeFirstLetter(t(`esgMetrics.dataTypes.${elem.csrd_data_type}`))}
      </div>
    );
  };
  // on social, first and last tabs

  const columns: Column[] = [
    {
      title: t('esgMetrics.general.idDatapoint'),
      dataIndex: 'datapoint_id',
      key: 'datapoint_id'
    },
    {
      title: t('esgMetrics.general.datapoint'),
      dataIndex: 'name',
      key: 'name',
      width: '55%',
      render: (_: unknown, record: FrameworkDatapoint) => (
        <span>{getTranslatedName(record, language || 'en')}</span>
      )
    },
    {
      title: t('esgMetrics.general.type'),
      dataIndex: 'csrd_data_type',
      key: 'csrd_data_type',
      render: (_: unknown, record: FrameworkDatapoint) => renderTypeTag(record)
    },
    {
      title: t('esgMetrics.general.state'),
      dataIndex: 'status',
      key: 'state',
      width: '10%',
      render: (_: unknown, data: FrameworkDatapoint) =>
        statusLabel[!data.with_data && !data.with_files ? 'WITHOUT_DATA' : 'WITH_DATA']
    },
    {
      title: t('controlPanel.metrics.responsible'),
      dataIndex: 'task',
      key: 'task',
      width: '10%',
      render: (_: unknown, data: FrameworkDatapoint) => (
        <Button
          iconNode={<Icon icon='user' color={data.task ? 'white' : 'gray-dark'} size='medium' />}
          size='tiny'
          lookAndFeel={data.task ? 'primary' : 'secondary'}
          onClick={() => setDatapointToAssign(data)}>
          <div>
            {data.task
              ? `${data.task.first_name} ${data.task.last_name}`
              : t('controlPanel.metrics.unassigned')}
          </div>
        </Button>
      )
    },
    {
      title: t('esgMetrics.general.relevance'),
      dataIndex: 'mandatory',
      key: 'mandatory',
      render: (mandatory: boolean) => (
        <TooltipWrapper text={t('esgMetrics.general.relevanceBlockedTooltip')} shouldAppear={false}>
          <div style={{ width: '3rem' }}>
            <FormSelect
              value={{
                id: mandatory ? 'mandatory' : 'not_mandatory',
                name: t(`esgMetrics.${mandatory ? 'mandatory' : 'not_mandatory'}`)
              }}
              onChange={() => null}
              options={[
                {
                  id: 'mandatory',
                  name: t('esgMetrics.mandatory')
                },
                {
                  id: 'not_mandatory',
                  name: t('esgMetrics.not_mandatory')
                }
              ]}
              disabled={true}
              sort={false}
              placeholder=' '
            />
          </div>
        </TooltipWrapper>
      )
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '12%',
      render: (_: unknown, data: FrameworkDatapoint) => {
        return data.id ? (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.edit')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        ) : (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.addResponse')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        );
      }
    }
  ];
  return columns;
};

export default useColumns;
