import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../../../../../customHooks/useFeatureFlags';

import SelectCardWrapper from '../../../../../../../../ui/selectCard/SelectCardWrapper';
import './styles.scss';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import useRole from 'customHooks/useRole';

type Props = {
  mode: string;
  onClickCard: (id: string) => void;
  error?: ErrorType;
  type: string;
  facilityType: string;
  action: 'create' | 'edit';
  facilityCategories: string[];
};
type SelectInvoiceCard = Card & {
  tooltip?: string;
};

const SelectInvoiceType = ({
  mode,
  onClickCard,
  error,
  type,
  facilityType,
  action,
  facilityCategories
}: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const { permissions, roleName } = useRole();

  const blockedTooltip = t('general.blockedByRole', { role: roleName });

  const cardBlocking = {
    heat: !permissions?.find(
      (permission: Permission) => permission.tag === PermissionTags.EMISSION_COMBUSTION_ALL
    ),
    recharge: !permissions?.find(
      (permission: Permission) => permission.tag === PermissionTags.EMISSION_RECHARGE_ALL
    ),
    electricity: !permissions?.find(
      (permission: Permission) => permission.tag === PermissionTags.EMISSION_ELECTRICITY_ALL
    ),
    water: !permissions?.find(
      (permission: Permission) => permission.tag === PermissionTags.EMISSION_WATER_ALL
    ),
    waste: !permissions?.find(
      (permission: Permission) => permission.tag === PermissionTags.EMISSION_WASTE_ALL
    )
  };

  const cards: SelectInvoiceCard[] = [
    {
      id: 'heat',
      icon: '/images/icons/fire50.svg',
      title: t('facilityDetail.combustion'),
      disabled: cardBlocking['heat'],
      tooltip: cardBlocking['heat'] ? blockedTooltip : undefined
    },
    {
      id: 'recharge',
      icon: '/images/icons/velocimeter.svg',
      title: t('facilityDetail.recharge'),
      tooltip: cardBlocking['recharge']
        ? blockedTooltip
        : mode === 'auto'
        ? t('facilityDetail.addManuallyTooltip')
        : t('facilityDetail.rechargeTooltip'),
      disabled: mode === 'auto' || cardBlocking['recharge']
    },
    {
      id: 'electricity',
      icon: '/images/icons/thunder50.svg',
      title: t('facilityDetail.electricity'),
      disabled: cardBlocking['electricity'],
      tooltip: cardBlocking['electricity'] ? blockedTooltip : undefined
    },
    {
      id: 'water',
      icon: '/images/icons/water.svg',
      title: t('facilityDetail.water'),
      disabled: cardBlocking['water'],
      tooltip: cardBlocking['water'] ? blockedTooltip : undefined
    }
  ];

  cards.push({
    id: 'waste',
    icon: '/images/icons/waste.svg',
    title: t('facilityDetail.waste'),
    disabled: cardBlocking['waste'],
    tooltip: cardBlocking['waste'] ? blockedTooltip : undefined
  });

  if (
    facilityType === 'waste_water_facilities' &&
    mode === 'manual' &&
    action === 'create' &&
    flags?.treatments
  ) {
    cards.push({
      id: 'waste_water_treatment',
      icon: '/images/icons/road.svg',
      title: t('facilityDetail.treatment'),
      disabled: false
    });
  }

  const cardFound = cards.find((card) => card.id === type);

  let cardsFiltered = [...cards];
  if (facilityCategories && facilityCategories.length > 0) {
    cardsFiltered = cards.filter((elem) =>
      elem.id === 'waste'
        ? facilityCategories.includes('wastes')
        : facilityCategories.includes(elem.id)
    );
  }
  return (
    <div className='select-invoice-type'>
      <h3 className='body1-bold-font'>{t('facilityDetail.selectInvoiceType')}</h3>
      <SelectCardWrapper
        cards={cardsFiltered}
        onClickCard={onClickCard}
        error={error}
        type={cardFound || { id: '', icon: '', title: '' }}
      />
    </div>
  );
};

export default SelectInvoiceType;
